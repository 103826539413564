import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';

import PublishIcon from '@mui/icons-material/Publish';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import HttpsIcon from '@mui/icons-material/Https';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import { ReportGmailerrorred } from '@mui/icons-material';
import MergeTypeIcon from '@mui/icons-material/MergeType';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

import { AdministrationModes, QuizzicalContext, RunStates, ScreenModes } from '../../Context/QuizzicalContext';
import GenericList, { ListRowContentMode } from '../Lists/GenericList';
import downloadFiles from '../../Libraries/jsonDigestion';
import { PendingImport, PendingImportState } from '../../Classes/Import/PendingImport';
import HelpIcon from '../Forms/HelpIcon';
import download from '../../Libraries/download';
import TimestampFormatEditor from '../TimestampFormatEditor';
import { doTimestampSubstitution } from '../../Libraries/TimestampSubstitution';
import { whereSelected } from '../../Classes/SelectImplementation';
import ConfirmationDialog from '../../Screens/Dialogs/ConfirmationDialog';
import globalMenuHelper from '../../Context/globalMenuHelper';

export default function DataImportManager(props) {
    const state = React.useContext(QuizzicalContext);
    const [uploads, setUploads] = React.useState([]);
    const [records, setRecords] = React.useState([]);
    const [forceRefresh, setForceRefresh] = React.useState(false);
    const [selectedImports, setSelectedImports] = React.useState([]);

    const [willCompress, setWillCompress] = React.useState(false);
    const [exportAsExam, setExportAsExam] = React.useState(false);
    const [insertTimestamp, setInsertTimestamp] = React.useState(false);
    const [timestampFormat, setTimestampFormat] = React.useState('MMMM-dd-yyyy HH-mm-ss tp');
    const [exportFileName, setExportFileName] = React.useState('myExport');
    const [exportMergeResult, setExportMergeResult] = React.useState(true);
    const [mergeUnique, setMergeUnique] = React.useState(false);

    const [lastImportSuccess, setLastImportSuccessful] = React.useState(false);
    const [lastMergeSuccess, setLastMergeSuccessful] = React.useState(false);

    React.useEffect(() => {
        const timer = setInterval(() => {
            if (checkProcessRecords()) {
                setForceRefresh(!forceRefresh);
            }
        }, 1000);
        // clearing interval
        return () => clearInterval(timer);
    });

    // processes records by unencrypting them, then converting them to objects
    const checkProcessRecords = () => {
        let operationsRemaining = 5; // don't process more records than this

        // decrypt
        let work = records.filter((r) => {
            return [PendingImportState.Encrypted].includes(r.getState());
        });

        if (work.length > 0) {
            for (let i = 0; i < work.length && operationsRemaining > 0; i++) {
                work[i].decompress();
                operationsRemaining--;
            }
        }

        // revert to an object
        work = records.filter((r) => {
            return [PendingImportState.Unencrypted].includes(r.getState());
        });

        if (work.length > 0) {
            for (let i = 0; i < work.length && operationsRemaining > 0; i++) {
                work[i].objectify();
                let c = work[i].content;
                operationsRemaining--;
            }
        } // else is we do nothing this tick

        let actualSelected = whereSelected(records, true).map((r) => {
            return r.getId();
        });
        if (actualSelected !== selectedImports) {
            setSelectedImports(actualSelected);
        }

        return (operationsRemaining < 5);
    }

    const onFileUpload = (event) => {
        let attempted = downloadFiles(
            event,
            (err) => {
                // will deal with later
            },
            (start) => {
                uploads.push(start);
            },
            (prgrs) => {
                let match = getUploadById(prgrs.id);
                if (match !== undefined) {
                    match.progress = prgrs.progress;
                    setForceRefresh(!forceRefresh);
                }
            },
            (done) => {
                let match = getUploadById(done.id);
                if (match !== undefined) {
                    match.progress = 1;
                    match.content = done.content;
                    records.push(new PendingImport(match, records));
                    setForceRefresh(!forceRefresh);
                }
            },
            () => {
                setForceRefresh(!forceRefresh);
            }
        );

        //if (!attempted) alert("Gah");
    }

    const getUploadById = (id) => {
        let matches = uploads.filter((r) => {
            return r.id === id;
        });

        if (matches.length === 1) {
            return matches[0];
        } else {
            return undefined;
        }
    }

    /* const onUploadCompleted = (items) => {
        let recs = [...records];
        for (let i = 0; i < items.length; i++) {
            items[i].progress = 2;
            recs.push(new PendingImport(items[i]));
        }

        setRecords(recs);
    } */

    // returns the items that are still being uploaded
    const getUploading = () => {
        return uploads.filter((u) => {
            return u.progress < 1;
        });
    }

    // returns the items that are still being processed (decrypted, etc)
    const getProcessing = () => {
        return records.filter((r) => {
            return [
                PendingImportState.Encrypted,
                PendingImportState.Unencrypted,
                PendingImportState.DuplicateImport,
                PendingImportState.Error]
                .includes(r.getState())
        });
    }

    // returns the items that are still pending import (awaiting user confirmation)
    const getPending = () => {
        return records.filter((r) => {
            return [PendingImportState.ObjectConverted].includes(r.getState())
        });
    }

    const onImportSelected = (selected, unselected, allSelected) => {
        setSelectedImports(allSelected);
    }

    const getUploadItems = () => {
        let items = getUploading().map((item, i) => {
            let output = undefined;
            if (item.progress < 1) {
                output = (
                    <ListItem disablePadding>
                        <ListItemIcon>
                            <FileUploadIcon />
                        </ListItemIcon>
                        <ListItemText primary={
                            <Grid sx={{ width: '100%' }}>
                                <Typography>{item.file}</Typography>
                                <LinearProgress variant="determinate" value={item.progress * 100} />
                            </Grid>
                        } />
                    </ListItem>
                );
            } else if (item.progress === 1) {
                output = (
                    <ListItem disablePadding>
                        <ListItemIcon>
                            <FileUploadIcon />
                        </ListItemIcon>
                        <ListItemText primary={
                            <Grid sx={{ width: '100%' }}>
                                <Typography>{item.file}</Typography>
                            </Grid>
                        } />
                    </ListItem>
                );
            }

            return output;
        });

        return items;
    }

    const getProcessItems = () => {
        let items = getProcessing().map((item, i) => {
            let output = undefined;
            if (item.getState() === PendingImportState.Encrypted) {
                output = (
                    <ListItem disablePadding>
                        <ListItemIcon>
                            <HttpsIcon />
                        </ListItemIcon>
                        <ListItemText primary={
                            <Grid sx={{ width: '98%' }}>
                                <Typography>{item.getTitle()}</Typography>
                                <LinearProgress variant="indeterminate" />
                            </Grid>
                        } />
                    </ListItem>
                );
            } else if (item.getState() === PendingImportState.Unencrypted) {
                output = (
                    <ListItem disablePadding>
                        <ListItemIcon>
                            <AutorenewOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={
                            <Grid sx={{ width: '98%' }}>
                                <Typography>{item.getTitle()}</Typography>
                                <LinearProgress variant="indeterminate" />
                            </Grid>
                        } />
                    </ListItem>
                );
            } else if (item.getState() === PendingImportState.DuplicateImport) {
                output = (
                    <ListItem disablePadding>
                        <ListItemIcon>
                            <Tooltip title="This file contains a duplicate of an already loaded import file.">
                                <WarningIcon />
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={
                            <Grid sx={{ width: '98%' }}>
                                <Typography>{item.getTitle()}</Typography>
                                <LinearProgress color='warning' variant='determinate' value={100} />
                            </Grid>
                        } />
                    </ListItem>
                );
            } else if (item.getState() === PendingImportState.Error) {
                output = (
                    <ListItem disablePadding>
                        <ListItemIcon>
                            <Tooltip title="There was an error during the attempt to import this record.  Make sure it is the correct file format.">
                                <ReportGmailerrorred />
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={
                            <Grid sx={{ width: '98%' }}>
                                <Typography>{item.getTitle()}</Typography>
                                <LinearProgress color='error' variant='determinate' value={100} />
                            </Grid>
                        } />
                    </ListItem>
                );
            }

            return output;
        });

        return items;
    }

    const getExtension = () => {
        return willCompress ? ".admnPckg.sqish" : ".admnPckg";
    }

    const getExtensionColor = () => {
        let result = "inherit";
        if (exportFileName !== undefined) {
            if (exportFileName.endsWith(getExtension())) {
                result = 'text.secondary';
            }
        }

        return result;
    }

    const onEditFilename = (event) => {
        setExportFileName(event.target.value);
    }

    const getPendingItems = () => {
        let items = getPending().map((item, i) => {
            let output = undefined;
            if (item.progress < 1) {
                output = (
                    <ListItem disablePadding>
                        <ListItemIcon>
                            <FileUploadIcon />
                        </ListItemIcon>
                        <ListItemText primary={
                            <Grid sx={{ width: '98%' }}>
                                <Typography>{item.file}</Typography>
                                <LinearProgress variant="determinate" value={item.progress * 100} />
                            </Grid>
                        } />
                    </ListItem>
                );
            } else if (item.progress === 1) {
                output = (
                    <ListItem disablePadding>
                        <ListItemIcon>
                            <FileUploadIcon />
                        </ListItemIcon>
                        <ListItemText primary={
                            <Grid sx={{ width: '100%' }}>
                                <Typography>{item.file}</Typography>
                                <LinearProgress variant="indeterminate" />
                            </Grid>
                        } />
                    </ListItem>
                );
            } else if (item.progress > 1) {
                output = (
                    <ListItem disablePadding>
                        <ListItemIcon>
                            <FileUploadIcon />
                        </ListItemIcon>
                        <ListItemText primary={
                            <Grid sx={{ width: '100%' }}>
                                <Typography>{item.file}</Typography>
                            </Grid>
                        } />
                    </ListItem>
                );
            }

            return output;
        });

        return items;
    }

    const getUploadedPercentage = () => {
        let uploading = getUploading();
        let complete = uploading.filter((item) => {
            return item.progress === 1;
        }).length;

        return (complete / uploading.length) * 100;
    }

    const getProcessedPercentage = () => {
        let processing = getProcessing();

        let partial = processing.filter((item) => {
            return item.getState() === PendingImportState.Unencrypted;
        }).length;
        let complete = processing.filter((item) => {
            return item.getState() === PendingImportState.ObjectConverted;
        }).length;

        let total = (partial * 0.5) + complete;

        return (total / processing.length) * 100;
    }

    const getImportById = (id) => {
        let matches = records.filter((pi) => {
            return pi.getId() === id;
        });

        if (matches.length === 1) {
            return matches[0];
        } else {
            return undefined;
        }
    }

    const handleImport = () => {
        for (let i = 0; i < selectedImports.length; i++) {
            let pi = getImportById(selectedImports[i]);
            if (pi !== undefined) {
                pi.importInto(state);
            }
        }

        setLastImportSuccessful(true);
    }

    const handleMerge = () => {
        let main = getImportById(selectedImports[0]);

        for (let i = 1; i < selectedImports.length; i++) {
            let pi = getImportById(selectedImports[i]);
            if (pi !== undefined) {
                main.mergeWith(pi);
            }
        }

        if (exportMergeResult) {
            let fileName = exportFileName + (insertTimestamp ? '-' + doTimestampSubstitution(timestampFormat) : '') + getExtension();
            download(main.getJson(), fileName);
        }

        setLastMergeSuccessful(true);
    }

    return (
        <Stack padding={2} spacing={2}>
            <Stack
                container sx={{ alignItems: 'center' }}
                direction="row-reverse">
                <HelpIcon help="Upload a valid export file to import" />
                <Button
                    color="inherit"
                    component="label"
                    variant="outlined"
                    startIcon={<PublishIcon />}
                    sx={{ marginRight: "1rem" }}>
                    <Typography>Upload</Typography>
                    <input type="file" accept=".admnPckg, .admnPckg.sqish" hidden multiple onChange={onFileUpload} />
                </Button>
            </Stack>
            <Paper sx={{
                height: '100%'
            }}>
                <Collapse timeout="auto" in={getUploading().length > 0} unmountOnExit>
                    <List
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                <Stack
                                    spacing={2}
                                    direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
                                    <CircularProgress variant='determinate' value={getUploadedPercentage()} />
                                    <Typography>Uploading...</Typography>
                                </Stack>
                            </ListSubheader>
                        }>
                        {getUploadItems()}
                    </List>
                </Collapse>
                <Collapse timeout="auto" in={getProcessing().length > 0} unmountOnExit>
                    <List
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                <Stack
                                    spacing={2}
                                    direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
                                    <CircularProgress variant='determinate' value={getProcessedPercentage()} />
                                    <Typography>Processing Files...</Typography>
                                </Stack>
                            </ListSubheader>
                        }>
                        {getProcessItems()}
                    </List>
                </Collapse>
                <Collapse timeout="auto" in={getPending().length > 0} unmountOnExit>
                    <Stack>
                        <GenericList
                            propagateDefaultSelectedState={true}
                            defaultSelectedState={true}
                            isReadOnly={true}
                            label="Pending Imports"
                            showHeaderLabel={true}
                            getTargetItems={() => {
                                return getPending();
                            }}
                            setTargetItems={(items) => {
                                // ignore attempts to update
                            }}
                            getDefaultItem={() => {
                                return null;
                            }}
                            onSelectionChanged={onImportSelected} />

                    </Stack>
                </Collapse>
            </Paper>
            <Collapse timeout="auto" in={getPending().length > 0} unmountOnExit>
                <Stack
                    direction="row"
                    spacing={2}
                    container sx={{ alignItems: 'center' }}>
                    <Button
                        variant="outlined"
                        color="inherit"
                        startIcon={<PublishIcon />}
                        disabled={selectedImports.length === 0}
                        onClick={handleImport}>
                        Import
                    </Button>
                    <HelpIcon help="Fully import any selected items" />
                </Stack>
            </Collapse>
            <Collapse timeout="auto" in={getPending().length > 1} unmountOnExit>
                <Stack
                    sx={{ alignItems: 'center' }}
                    direction="row"
                    spacing={2}>
                    <Stack
                        sx={{ alignItems: 'center' }}
                        direction="row">
                        <FormControlLabel control={<Checkbox value={mergeUnique} onChange={(e) => { setMergeUnique(!mergeUnique) }} />} label="Merge unique configuration items" />
                        <HelpIcon help="This will combine the unique elements in things like the parameters on questions into a single group in the result" />
                    </Stack>
                    <FormControlLabel control={<Checkbox value={exportMergeResult} onChange={(e) => { setExportMergeResult(!exportMergeResult) }} />} label="Export Merge Result" />
                </Stack>
                <Collapse timeout="auto" in={exportMergeResult === false} unmountOnExit>
                    <Grid
                        marginTop={3}
                        spacing={1}>
                        <Stack
                            direction="row"
                            justify="flex-end"
                            alignItems="center">
                            <TextField
                                fullWidth
                                id="export-package-name"
                                required
                                label="Exported Package Name"
                                value={exportFileName}
                                onChange={onEditFilename} />
                            {
                                insertTimestamp ?
                                    <>
                                        <Typography style={{ height: '100%', verticalAlign: "middle" }}>&nbsp;-&nbsp;</Typography>
                                        <TimestampFormatEditor
                                            default={state.DefaultExportTimestamp}
                                            updateTarget={(newValue) => { setTimestampFormat(newValue); }}
                                            initialValue={timestampFormat}
                                        />
                                    </> : ""
                            }
                            <Typography color={getExtensionColor()} style={{ height: '100%', verticalAlign: "middle" }}>{getExtension()}</Typography>
                        </Stack>
                    </Grid>
                    <Grid>
                        <FormControlLabel control={<Checkbox value={willCompress} onChange={(e) => { setWillCompress(!willCompress) }} />} label="Compress Export" />
                        <FormControlLabel control={<Checkbox value={insertTimestamp} onChange={(e) => { setInsertTimestamp(!insertTimestamp) }} />} label="Time Filename" />
                        <FormControlLabel control={<Checkbox value={exportAsExam} onChange={(e) => { setExportAsExam(!exportAsExam) }} />} label="Export as Exam" />
                    </Grid>
                </Collapse>
                <Stack
                    spacing={2}
                    marginTop={3}
                    direction="row-reverse"
                    sx={{ alignItems: 'center' }}>
                    <HelpIcon help={"Combines multiple imports into a single and adds them to the system.  Can optionally export the merged results for safekeeping, as well.\n\nMerge operation functions by comparing items' unique identification numbers and maintaining those found in the first selected record (top, by default) as the primary"} />
                    <Button
                        startIcon={<MergeTypeIcon />}
                        disabled={selectedImports.length < 2}
                        color="inherit"
                        variant='outlined'
                        onClick={handleMerge}>Merge</Button>
                </Stack>
            </Collapse>
            <ConfirmationDialog
                title='Import Successful'
                confirmText='Okay'
                message={state.Mode.AdministrationMode !== AdministrationModes.Normal ?
                    'Exam imported successfully.  You will now be taken to the presentation screen.' :
                    'The import succeeded, and your records have been created.'}
                isOpen={lastImportSuccess}
                onCancel={(e) => {
                    setLastImportSuccessful(false);
                    globalMenuHelper.menuRefresher(state.Mode.AdministrationMode);
                }}
                onConfirm={(e) => {
                    setLastImportSuccessful(false);
                    globalMenuHelper.menuRefresher(state.Mode.AdministrationMode);
                }}
            />
            <ConfirmationDialog
                title='Merge Successful'
                confirmText='Okay'
                message='The merge succeeded.'
                isOpen={lastMergeSuccess}
                onCancel={(e) => { setLastMergeSuccessful(false); }}
                onConfirm={(e) => { setLastMergeSuccessful(false); }}
            />
        </Stack>
    );
}