import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import CancelIcon from '@mui/icons-material/Close';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CommentIcon from '@mui/icons-material/Comment';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import UndoIcon from '@mui/icons-material/Undo';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import WifiFindIcon from '@mui/icons-material/WifiFind';

import { QuizzicalContext } from '../../Context/QuizzicalContext';
import Subject from '../../Classes/Subjects';
import { FillInQuestion, MultipleChoiceQuestion, PoolQuestion } from '../../Classes/Questions';
import { randomId } from '@mui/x-data-grid-generator';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} mountOnEnter unmountOnExit />;
});

export default function CreateContentDialog(props) {
    const state = React.useContext(QuizzicalContext);
    const [host, setHost] = React.useState(undefined);
    const [stage, setStage] = React.useState(0);
    const [isOpen, setIsOpen] = React.useState(false);
    const [questionType, setQuestionType] = React.useState(-1);

    React.useEffect(() => {
        if (props.focus !== host) {
            setHost(props.focus);
        }

        if (props.open !== isOpen) {
            setStage(0);
            setQuestionType(-1);
            setIsOpen(props.open);
        }

        if (props.questionStart === true) {
            setQuestionType(props.questionType);
            setStage(2); // question
        }
    }, [props]);

    React.useEffect(() => {
        if (stage === 1) {
            setStage(0);
            setQuestionType(-1);
            props.onClose(Subject.getDefault(host, false, "A new subject"));
        }

        if (questionType === 0) {// fill in
            setStage(0);
            setQuestionType(-1);
            props.onClose(FillInQuestion.getDefault(host, "Fill In - " + randomId(), state.Mode.DefaultDuration, state.Mode.DefaultDwell));
        } else if (questionType === 1) { // multiple choice
            setStage(0);
            setQuestionType(-1);
            props.onClose(MultipleChoiceQuestion.getDefault(host, "Multiple Choice - " + randomId(), state.Mode.DefaultDuration, state.Mode.DefaultDwell, false, false));
        } else if (questionType === 2) { // pool question
            setStage(0);
            setQuestionType(-1);
            props.onClose(PoolQuestion.getDefault(host, "Sourced - " + randomId()));
        }
    }, [questionType, stage]);

    const handleClose = () => {
        props.onClose(undefined);
    };

    const handleMenuClicked = (newStage, selectedQuestionType) => {
        switch (newStage) {
            case 0: // default
            case 1: // add subject
            case 2: // enter question decision mode
                setStage(newStage);
                break;
        }

        if (selectedQuestionType !== undefined) {
            if (selectedQuestionType === 0 ||
                selectedQuestionType === 1 ||
                selectedQuestionType === 2) {
                setQuestionType(selectedQuestionType);
            }
        }
    }

    var choiceDisplay = (<div />);
    switch (stage) {
        case 0: // starting (choose between subject and question)
            choiceDisplay = (
                <Box sx={{ width: '100%', maxWidth: 360 }}>
                    <List subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            Choose a type of content
                        </ListSubheader>
                    }>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { handleMenuClicked(1); }}>
                                <ListItemIcon>
                                    <CommentIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Add Subject"
                                    secondary="Subjects are scriptable containers for questions.  Subjects share their variables and parameters across all child questions." />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { handleMenuClicked(2); }}>
                                <ListItemIcon>
                                    <ContactSupportIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Add Question"
                                    secondary="Questions are used to pose problems to users.  They provide the questions and the answer, as well as a means to derive a simplified version of both for exporting in answer sheets during presentations." />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            );
            break;
        case 1: // chose subject
            choiceDisplay = (
                <Box sx={{ width: '100%', maxWidth: 360 }}>
                    <Typography color="inherit" variant="h6" component="div" style={{ textAlign: 'center' }}>Click Add to create your Subject</Typography>
                </Box>
            );
            break;
        case 2: // choose a type of question
            choiceDisplay = (
                <Box sx={{ width: '100%', maxWidth: 360 }}>
                    <List subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            Choose a type of question
                        </ListSubheader>
                    }>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { handleMenuClicked(3, 0); }}>
                                <ListItemIcon>
                                    <FormatPaintIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Add Fill In"
                                    secondary="Fill In questions compose the bulk of what users see.  They present a scenario / problem and hide the answer until either going to that phase (via timer) or when the appropriate button is clicked." />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { handleMenuClicked(3, 1); }}>
                                <ListItemIcon>
                                    <FactCheckIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Add Multiple Choice"
                                    secondary="Multiple Choice questions display both the question and possible answers simultaneously, but only confirm the correct options when going to the proper phase (via timer) or when the appropriate button is clicked." />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { handleMenuClicked(3, 2); }}>
                                <ListItemIcon>
                                    <WifiFindIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Add Sourced"
                                    secondary="Sourced questions display questions they pull from a target pool. (either sequentially or after shuffling)" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { handleMenuClicked(0); }}>
                                <ListItemIcon>
                                    <UndoIcon />
                                </ListItemIcon>
                                <ListItemText primary="Back" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            );
            break;
        case 3: // chose a type of question
            choiceDisplay = (
                <Box sx={{ width: '100%', maxWidth: 360 }}>
                    <Typography color="inherit" variant="h6" component="div" style={{ textAlign: 'center' }}>Click Add to create your Question</Typography>
                </Box>
            );
            break;
    }

    var dialog = (<div />);
    if (host !== undefined && host !== null) {
        dialog = (
            <Dialog
                open={isOpen}
                onClose={handleClose}
                TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <SettingsApplicationsIcon />
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Choose a Content Type</Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    {choiceDisplay}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus color="inherit" onClick={handleClose}>
                        <Stack direction="row">
                            <CancelIcon />
                            <div />
                            <Typography color="inherit" variant="button" component="div" style={{ textAlign: 'right' }}>Cancel</Typography>
                        </Stack>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (dialog);
}
