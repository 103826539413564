import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Unstable_Grid2';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImageEditorDialog(props) {
    const [focus, setFocus] = React.useState(undefined);
    const [isOpen, setIsOpen] = React.useState(false);

    const [crop, setCrop] = React.useState(undefined);
    const [rotation, setRotation] = React.useState(0);
    const [zoom, setZoom] = React.useState(1);
    const imgRef = React.useRef(undefined);

    React.useEffect(() => {
        if (props.details !== undefined) {
            if (props.details.focus !== focus) {
                setFocus(props.details.focus);
            }

            if (props.details.isOpen !== isOpen) {
                setIsOpen(props.details.isOpen);
            }
        } else {
            resetControls();
            setIsOpen(false);
        }
    }, [props]);

    React.useEffect(() => {
        if (focus !== undefined &&
            focus.getContent().x !== 0 &&
            focus.getContent().y !== 0 &&
            focus.getContent().width !== 0 &&
            focus.getContent().height !== 0) {
            setCrop({
                unit: 'px', // Can be 'px' or '%'
                x: focus.getContent().x,
                y: focus.getContent().y,
                width: focus.getContent().width,
                height: focus.getContent().height
            });
        } else if (props.dimensions !== undefined) {
            setCrop({
                unit: 'px', // Can be 'px' or '%'
                x: 0,
                y: 0,
                width: props.dimensions.width,
                height: props.dimensions.height
            });
        }
    }, [focus]);

    const resetControls = () => {
        setCrop(undefined);
        setRotation(0);
        setZoom(1);
    }

    const handleClose = () => {
        props.onClose(undefined);
    };

    /**
 * @param {File} image - Image File Object
 * @param {Object} pixelCrop - pixelCrop Object provided by react-image-crop
 * @param {String} fileName - Name of the returned file in Promise
 */
    function getCroppedImage(image, pixelCrop, fileName) {

        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        );

        // As Base64 string
        const base64Image = canvas.toDataURL('image/jpeg');
        return base64Image;
    }

    const handleConfirm = React.useCallback(async (event) => {
        try {
            focus.setContent({
                ...focus.getContent(),
                x: crop.x,
                y: crop.y,
                width: crop.width,
                height: crop.height,
                edit: getCroppedImage(imgRef.current, crop, "result.jpg"),
            });
        } catch (e) {
            console.error(e)
        }

        props.onClose(focus);
    }, [focus, crop])

    var dialog = (<div />);
    if (focus !== undefined && focus !== null) {
        dialog = (
            <Dialog
                fullScreen
                open={isOpen}
                onClose={handleClose}
                TransitionComponent={Transition}>
                <AppBar>
                    <Toolbar>
                        <ImageIcon />
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Image Editing</Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            <Stack direction="row">
                                <CancelIcon />
                                <div />
                                <Typography color="inherit" variant="button" component="div" style={{ textAlign: 'right' }}>Cancel</Typography>
                            </Stack>
                        </Button>
                        <Button autoFocus color="inherit" onClick={handleConfirm}>
                            <Stack direction="row">
                                <SaveIcon />
                                <div />
                                <Typography color="inherit" variant="button" component="div" style={{ textAlign: 'right' }}>Save</Typography>
                            </ Stack>
                        </Button>
                    </Toolbar>
                </AppBar>
                <Toolbar id="back-to-top-anchor" />
                <Grid>
                    <Grid xs={{ height: '100%', width: '100%' }}>
                        <ReactCrop
                            crop={crop}
                            onChange={c => setCrop(c)}>
                            <img
                                ref={imgRef}
                                src={focus.getContent().base} />
                        </ReactCrop>
                    </Grid>
                    {/* <Grid>
                        <Grid>
                            <Typography variant="overline">
                                Zoom
                            </Typography>
                            <Slider
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.01}
                                aria-labelledby="Zoom"
                                onChange={(e, zoom) => setZoom(zoom)}
                            />
                        </Grid>
                        <Grid>
                            <Typography variant="overline">
                                Rotation
                            </Typography>
                            <Slider
                                value={rotation}
                                min={0}
                                max={360}
                                step={1}
                                aria-labelledby="Rotation"
                                onChange={(e, rotation) => setRotation(rotation)}
                            />
                        </Grid>
                    </Grid> */}
                </Grid>
            </Dialog>
        );
    }

    return (
        <div>
            {dialog}
        </div>
    );
}
