import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Switch } from '@mui/material';
import { randomId } from '@mui/x-data-grid-generator';
import Collapse from '@mui/material/Collapse';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { QuizzicalContext } from '../../../Context/QuizzicalContext';
import TopicsManager from '../../TopicsManager';
import RegionLayoutEditor from '../../ContentEditor/RegionLayoutEditor';
import GenericList from '../../Lists/GenericList';
import FieldContainer from '../../Forms/FieldContainer';
import FEH_Form from '../../Forms/FEH_Form';
import VariableDefinition from '../../../Classes/VariableDefinition';
import { FillInQuestion, MultipleChoiceQuestion, PoolQuestion } from '../../../Classes/Questions';
import { NumericalSequenceParameter, PoolParameter, RandomizedDecimalParameter, RandomizedIntegerParameter, SequencialParameter } from '../../../Classes/Parameters';
import { TabPanel, a11yProps } from '../../../Libraries/TabPanel';
import ScriptItem from '../../../Classes/ScriptItem';
import RegionLayoutEditorDialog from '../../../Screens/Dialogs/RegionLayoutEditorDialog';

export default function SubjectEditor(props) {
    const state = props.state;
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [forceRefresh, setForceRefresh] = React.useState(false);
    const [defaultFieldSet, setDefaultFieldSet] = React.useState(randomId());
    const [tabIndex, setTabIndex] = React.useState(0);

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }
    }, [props]);

    React.useEffect(() => {
        // we need to redraw the page when the state changes
    }, [state]);

    const handleTabChanged = (event, newValue) => {
        if (tabIndex === 0) {
            updateFocus();
            setFocus(focus);
        }
        setTabIndex(newValue);
    };

    const updateFocus = () => {
        if (tabIndex === 0) {
            // if we aren't on the details tab then there won't be any of the expected controls.
            // skip the update and just return what we have (which will be up-to-date)
            focus.setTitle(document.getElementById("subject-title").value);
            focus.setShuffled(document.getElementById("subject-shuffled").checked);
            focus.setProvidesLayout(document.getElementById("subject-layout-provider").checked);
        }

        return focus;
    }

    const onDescriptionUpdated = (o) => {
        /* if (o !== undefined) {
            focus.propagateLayout();
        } */
        setForceRefresh(!forceRefresh);
    }

    if (isReadOnly) {
        return ( // readonly
            <Box
                sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleTabChanged} aria-label="basic tabs example">
                        <Tab label="Details" {...a11yProps(0)} />
                        <Tab label="Pre-Step Scripts" {...a11yProps(1)} />
                        <Tab label="Post-Step Scripts" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={tabIndex} index={0} sx={{ width: '100%', height: '100%' }}>
                    <Grid spacing={2}>
                        <Stack direction="row">
                            <Typography color="inherit" variant="body" component="div">Title:&nbsp;&nbsp;&nbsp;</Typography>
                            <Typography color="inherit" variant="caption" component="div">{focus.getTitle()}</Typography>
                        </Stack>
                        <Stack direction="row" style={{ alignItems: 'center' }}>
                            <Typography color="inherit" variant="body" component="div">Shuffled:&nbsp;&nbsp;</Typography>
                            <Typography color="inherit" variant="caption" component="div">{focus.isShuffled() ? "Yes" : "No"}</Typography>
                        </Stack>
                        <Stack direction="row" style={{ alignItems: 'center' }}>
                            <Typography color="inherit" variant="body" component="div">Dictates Layout:&nbsp;&nbsp;</Typography>
                            <Typography color="inherit" variant="caption" component="div">{focus.isLayoutProvider() ? "Yes" : "No"}</Typography>
                        </Stack>
                        {
                            focus.isLayoutProvider() === true ?
                                <Stack direction="row" spacing={2}>
                                    <Typography color="inherit" variant="body" component="div">Layout:</Typography>
                                    <RegionLayoutEditor
                                        sx={{
                                            position: 'relative',
                                            width: '32px',
                                            height: '32px',
                                        }}
                                        focus={focus.getDescription()}
                                        isReadOnly={isReadOnly}
                                        asMosaic={true} />
                                </Stack> : ''
                        }
                        {/* <VariableManager focus={focus} isReadOnly={isReadOnly} /> */}
                        <TopicsManager focus={focus} isReadOnly={isReadOnly} />
                        {/* <GenericStorageManagerList
                        help="Variables maintain their values across iterations and can be accessed by any script within the containing Subject's hierarchy."
                        isReadOnly={isReadOnly}
                        isCollapsable={false}
                        label="Variables"
                        nameLabel='Variable'
                        valueLabel='Initial Value'
                        validationType='variable'
                        manager={focus.getVariables()}
                        setTargetItems={(items) => {
                            focus.getVariables(items);
                        }}
                        getDefaultItem={() => {
                            let i = 0;
                            while (focus.getVariables().containsName("new-variable-" + i)) {
                                i++;
                            }

                            return {
                                type: "variable",
                                host: focus,
                                obj: StorageItem.getDefault("new-variable-" + i, undefined),
                                nameLabel: 'Variable',
                                valueLabel: 'Initial Value'
                            };
                        }} /> */}
                        <GenericList
                            rows={6}
                            propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                            defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                            state={state}
                            help={"Variables maintain their values across iterations and can be accessed by any script within the containing Subject's hierarchy.\n\nNote that the order of items does not matter."}
                            isReadOnly={isReadOnly}
                            isCollapsable={true}
                            label="Variables"
                            getTargetItems={() => {
                                return focus.getVariables();
                            }}
                            setTargetItems={(items) => {
                                focus.setVariables(items);
                            }}
                            getDefaultItem={() => {
                                return VariableDefinition.getDefault(focus, 'variable-' + focus.getVariables().length, undefined, undefined);
                            }}
                            validPastes={[
                                VariableDefinition.ClassName(),
                            ]} />
                        {/* <ParameterList isReadOnly={isReadOnly} focus={focus} label="Parameters" /> */}
                        {/* <GenericList
                        isReadOnly={isReadOnly}
                        isCollapsable={false}
                        label="Parameters"
                        manager={focus.getParameters()}
                        setTargetItems={(items) => {
                            let correctItems = items.filter((item) => {
                                return isImmediateChild(focus, item, undefined, 'getId') === true;
                            });
                            focus.setParameters(correctItems);
                        }}
                        getDefaultItem={() => {
                            return ["parameter", focus];
                        }} /> */}
                        <GenericList
                            rows={6}
                            propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                            defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                            state={state}
                            help={"Parameters are recalculated upon each iteration.  They are made available to any scripts within the Subject's hierarchy.\n\nNote that the order of items does not matter."}
                            isReadOnly={isReadOnly}
                            isCollapsable={true}
                            label="Parameters"
                            getTargetItems={() => {
                                return focus.getParameters();
                            }}
                            setTargetItems={(items) => {
                                focus.setParameters(items);
                            }}
                            getDefaultItem={() => {
                                return ["parameter", focus];
                            }}
                            getTitleText={(item) => {
                                return item.getName();
                            }}
                            validPastes={[
                                PoolParameter.ClassName(),
                                SequencialParameter.ClassName(),
                                NumericalSequenceParameter.ClassName(),
                                RandomizedDecimalParameter.ClassName(),
                                RandomizedIntegerParameter.ClassName(),
                            ]} />
                        {/* <QuestionList isReadOnly={isReadOnly} focus={focus} label="Questions" /> */}
                        <GenericList
                            rows={6}
                            propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                            defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                            state={state}
                            help="Questions within a Subject will be displayed according to the containing Subject's shuffle rule until they are exhausted."
                            isReadOnly={isReadOnly}
                            isCollapsable={false}
                            label="Questions"
                            getTargetItems={() => {
                                return focus.getChildren();
                            }}
                            setTargetItems={(items) => {
                                focus.setChildren(items);
                            }}
                            getDefaultItem={() => {
                                return ["question", focus];
                            }}
                            validPastes={[
                                FillInQuestion.ClassName(),
                                MultipleChoiceQuestion.ClassName(),
                                PoolQuestion.ClassName(),
                            ]} />
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={1} sx={{ width: '100%', height: '100%' }}>
                    <Typography color="inherit" component="div">These pre-scripts are executed in order, each time before this question is displayed.</Typography>
                    {/* <ScriptList
                            focus={scriptListingSource}
                            property="getPreScripts" /> */}
                    <GenericList
                        isReadOnly={isReadOnly}
                        isCollapsable={false}
                        label="Pre-Step Script"
                        showHeaderLabel={false}
                        getTargetItems={() => {
                            return focus.getPreScripts();
                        }}
                        setTargetItems={(items) => {
                            focus.setPreScripts(items);
                        }}
                        getDefaultItem={() => {
                            return ScriptItem.getDefault(focus, undefined, undefined);
                        }}
                        validPastes={[
                            ScriptItem.ClassName(),
                        ]} />
                </TabPanel>
                <TabPanel value={tabIndex} index={2} sx={{ width: '100%', height: '100%' }}>
                    <Typography color="inherit" component="div">These post-scripts are executed in order, each time after this question is displayed.</Typography>
                    {/* <ScriptList
                            focus={scriptListingSource}
                            property="getPostScripts" /> */}
                    <GenericList
                        isReadOnly={isReadOnly}
                        isCollapsable={false}
                        label="Pre-Step Script"
                        showHeaderLabel={false}
                        getTargetItems={() => {
                            return focus.getPostScripts();
                        }}
                        setTargetItems={(items) => {
                            focus.setPostScripts(items);
                        }}
                        getDefaultItem={() => {
                            return ScriptItem.getDefault(focus, undefined, undefined);
                        }}
                        validPastes={[
                            ScriptItem.ClassName(),
                        ]} />
                </TabPanel>
            </Box>
        );
    } else {
        return ( // editable
            <FEH_Form
                height="88vH"
                formId="SubjectForm"
                type="Subject"
                getInstance={updateFocus}
                onClose={props.onClose}
                onConfirm={props.onConfirm}
                onReportMessages={props.onReportMessages}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleTabChanged} aria-label="basic tabs example">
                        <Tab label="Details" {...a11yProps(0)} />
                        <Tab label="Pre-Step Scripts" {...a11yProps(1)} />
                        <Tab label="Post-Step Scripts" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={tabIndex} index={0} sx={{ width: '100%', height: '70vH' }}>
                    <Stack spacing={2}>
                        <FieldContainer
                            display="flow"
                            fieldSet={defaultFieldSet}
                            label="Title"
                            help="The subject's title"
                            fullWidth
                            required>
                            <TextField
                                disabled={isReadOnly}
                                id="subject-title"
                                fullWidth
                                required
                                defaultValue={focus.getTitle()} />
                        </FieldContainer>
                        <FieldContainer
                            fieldSet='Shuffled_Subject'
                            swap
                            label="Shuffled"
                            help="If true, the subject's contents will be displayed in random order">
                            <Switch
                                id="subject-shuffled"
                                defaultChecked={focus.isShuffled()} />
                        </FieldContainer>
                        <FieldContainer
                            fieldSet='Layout_Provider_Subject'
                            swap
                            label="Dictates Layout"
                            help="Subjects define a layout that, when this option is on, will be used as a template when a question is added">
                            <Switch
                                id="subject-layout-provider"
                                defaultChecked={focus.isLayoutProvider()}
                                onChange={(e) => {
                                    focus.setProvidesLayout(document.getElementById("subject-layout-provider").checked);
                                    //setForceRefresh(!forceRefresh);
                                }} />
                        </FieldContainer>
                        <FieldContainer
                            swap
                            fieldSet='Layout_Manager_Subject'
                            help={"Edit template layout.\n\nNote that only future questions will use this layout, and only if Dictates Layout is on."}>
                            {/* <RegionLayoutManagementDialog
                                original={focus.getDescription()}
                                onConfirm={onDescriptionUpdated}
                                isReadOnly={isReadOnly} /> */}
                            <RegionLayoutEditorDialog
                                original={focus.getDescription()}
                                onConfirm={onDescriptionUpdated}
                                isReadOnly={isReadOnly}
                            />
                        </FieldContainer>
                        {/* {
                        focus.isLayoutProvider() || document.getElementById("subject-layout-provider").checked === true ?
                            <FieldContainer
                                swap
                                fieldSet='Layout_Manager_Subject'
                                help={"Edit the subject's layout.\n\nNote that this will overwrite the layouts of any exiting child questions."}>
                                <RegionLayoutManagementDialog
                                    original={focus.getDescription()}
                                    onConfirm={onDescriptionUpdated}
                                    isReadOnly={isReadOnly} />
                            </FieldContainer> : ''
                    } */}
                        {/* 
                    <VariableManager focus={focus} isReadOnly={isReadOnly} /> */}
                        <TopicsManager focus={focus} isReadOnly={isReadOnly} directions={"Select subject's topics"} unmountOnExit onReportMessages={props.onReportMessages} />
                        {/* <GenericStorageManagerList
                        fullscreen={false}
                        help={"Variables maintain their values across iterations and can be accessed by any script within the containing Subject's hierarchy.\n\nNote that the order of items does not matter."}
                        isReadOnly={isReadOnly}
                        isCollapsable={false}
                        label="Variables"
                        nameLabel='Variable'
                        valueLabel='Initial Value'
                        validationType='variable'
                        manager={focus.getVariables()}
                        setTargetItems={(items) => {
                            focus.getVariables(items);
                        }}
                        getDefaultItem={() => {
                            let i = 0;
                            while (focus.getVariables().containsName("new-variable-" + i)) {
                                i++;
                            }

                            return {
                                type: "variable",
                                host: focus,
                                obj: StorageItem.getDefault("new-variable-" + i, undefined),
                                nameLabel: 'Variable',
                                valueLabel: 'Initial Value',
                                tertiaryLabel: 'Comment',
                            };
                        }} /> */}
                        <GenericList
                            rows={6}
                            propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                            defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                            state={state}
                            help={"Variables maintain their values across iterations and can be accessed by any script within the containing Subject's hierarchy.\n\nNote that the order of items does not matter."}
                            isReadOnly={isReadOnly}
                            isCollapsable={false}
                            label="Variables"
                            getTargetItems={() => {
                                return focus.getVariables();
                            }}
                            setTargetItems={(items) => {
                                focus.setVariables(items);
                            }}
                            getDefaultItem={() => {
                                return VariableDefinition.getDefault(focus, 'variable-' + focus.getVariables().length, undefined, undefined);
                            }}
                            validPastes={[
                                VariableDefinition.ClassName(),
                            ]} />
                        {/* <ParameterList isReadOnly={isReadOnly} focus={focus} label="Parameters" /> */}
                        {/* <GenericStorageManagerList
                        help="Parameters are recalculated upon each iteration.  They are made available to any scripts within the Subject's hierarchy."
                        isReadOnly={isReadOnly}
                        isCollapsable={false}
                        label="Parameters"
                        nameLabel='Name'
                        valueLabel='Initial Value'
                        validationType='parameter'
                        manager={focus.getParameters()}
                        setTargetItems={(items) => {
                            focus.getParameters(items);
                        }}
                        getDefaultItem={() => {
                            let i = 0;
                            while (focus.getParameters().containsName("new-parameter-" + i)) {
                                i++;
                            }

                            return {
                                type: "parameter",
                                host: focus,
                                obj: StorageItem.getDefault("new-parameter-" + i, undefined),
                                nameLabel: 'Name',
                                valueLabel: 'Initial Value'
                            };
                        }} /> */}
                        <GenericList
                            rows={6}
                            propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                            defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                            state={state}
                            help={"Parameters are recalculated upon each iteration.  They are made available to any scripts within the Subject's hierarchy.\n\nNote that the order of items does not matter."}
                            isReadOnly={isReadOnly}
                            isCollapsable={false}
                            label="Parameters"
                            getTargetItems={() => {
                                return focus.getParameters();
                            }}
                            setTargetItems={(items) => {
                                focus.setParameters(items);
                            }}
                            getDefaultItem={() => {
                                return ["parameter", focus];
                            }}
                            getTitleText={(item) => {
                                return item.getName();
                            }}
                            validPastes={[
                                PoolParameter.ClassName(),
                                SequencialParameter.ClassName(),
                                NumericalSequenceParameter.ClassName(),
                                RandomizedDecimalParameter.ClassName(),
                                RandomizedIntegerParameter.ClassName(),
                            ]} />
                        {/* <QuestionList isReadOnly={isReadOnly} focus={focus} label="Questions" /> */}
                        <GenericList
                            rows={6}
                            propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                            defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                            state={state}
                            help="Questions within a Subject will be displayed according to the containing Subject's shuffle rule until they are exhausted."
                            isReadOnly={isReadOnly}
                            isCollapsable={false}
                            label="Questions"
                            getTargetItems={() => {
                                return focus.getChildren();
                            }}
                            setTargetItems={(items) => {
                                focus.setChildren(items);
                            }}
                            getDefaultItem={() => {
                                return ["question", focus];
                            }}
                            validPastes={[
                                FillInQuestion.ClassName(),
                                MultipleChoiceQuestion.ClassName(),
                                PoolQuestion.ClassName(),
                            ]} />
                    </Stack>
                </TabPanel>
                <TabPanel value={tabIndex} index={1} sx={{ width: '100%', height: '73vH' }}>
                    <Typography color="inherit" component="div">These pre-scripts are executed in order, each time before this question is displayed.</Typography>
                    {/* <ScriptList
                            focus={scriptListingSource}
                            property="getPreScripts" /> */}
                    <GenericList
                        isReadOnly={isReadOnly}
                        isCollapsable={false}
                        label="Pre-Step Script"
                        showHeaderLabel={false}
                        getTargetItems={() => {
                            return focus.getPreScripts();
                        }}
                        setTargetItems={(items) => {
                            focus.setPreScripts(items);
                        }}
                        getDefaultItem={() => {
                            return ScriptItem.getDefault(focus, undefined, undefined);
                        }}
                        validPastes={[
                            ScriptItem.ClassName(),
                        ]} />
                </TabPanel>
                <TabPanel value={tabIndex} index={2} sx={{ width: '100%', height: '73vH' }}>
                    <Typography color="inherit" component="div">These post-scripts are executed in order, each time after this question is displayed.</Typography>
                    {/* <ScriptList
                            focus={scriptListingSource}
                            property="getPostScripts" /> */}
                    <GenericList
                        isReadOnly={isReadOnly}
                        isCollapsable={false}
                        label="Pre-Step Script"
                        showHeaderLabel={false}
                        getTargetItems={() => {
                            return focus.getPostScripts();
                        }}
                        setTargetItems={(items) => {
                            focus.setPostScripts(items);
                        }}
                        getDefaultItem={() => {
                            return ScriptItem.getDefault(focus, undefined, undefined);
                        }}
                        validPastes={[
                            ScriptItem.ClassName(),
                        ]} />
                </TabPanel>
            </FEH_Form>
        );
    }
}