import ShuffleIcon from '@mui/icons-material/Shuffle';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AnimationIcon from '@mui/icons-material/Animation';
import SearchIcon from '@mui/icons-material/Search';
import ShuffleOnIcon from '@mui/icons-material/ShuffleOn';

import { shuffle } from '../Libraries/Ordering';
import { randomId } from "@mui/x-data-grid-generator";

import { jsonifyArray } from '../Libraries/generalUtility';
import QuestionChoiceDefinition from './QuestionChoiceDefinition';
import SelectedImplementation from './SelectImplementation';
import { StepResult } from './Stepping';

export const ParameterTypes = {
    NotSet: -1,
    RandomizedInteger: 0,
    RandomizedDecimal: 1,
    Sequential: 2,
    Sequence: 3,
    Pool: 4
};

class Parameter extends SelectedImplementation {
    constructor() {
        super();
        this.isClonedInstance = false;
        this.onTitleUpdated = undefined;
        this.current = 0;
    }

    initCreate(parent, name) {
        this.parent = parent;
        this.name = name;
        this.id = randomId();
        this.type = ParameterTypes.NotSet;

        return this;
    }

    initJson(parent, struct) {
        this.parent = parent;
        this.id = struct.id;
        this.type = struct.type;
        this.name = struct.name;

        return this;
    }

    setOnTitleUpdated(handler) {
        this.onTitleUpdated = handler;
    }

    isClone() {
        return this.isClonedInstance;
    }

    setIsClone() {
        this.isClonedInstance = true;
    }

    getParameterType() {
        return this.type;
    }

    setParameterType(type) {
        this.type = type;
    }

    getParent() {
        return this.parent;
    }

    setParent(parent) {
        this.parent = parent;
    }

    setId(id) {
        this.id = id;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    setName(name) {
        let n = this.name;
        this.name = name;

        if (this.name !== n &&
            this.onTitleUpdated !== undefined &&
            typeof this.onTitleUpdated === 'function') {
            this.onTitleUpdated(this, n, this.name);
        }
    }

    getIcon(color = "inherit") {
        return undefined;
    }

    getSelectedIcon(color = "inherit") {
        return this.getIcon(color);
    }

    initStep(state) {

    }

    getCurrent(logId) {

    }

    step(executionIndex) {
        return StepResult.Stepped;
    }

    update(executionIndex) {
        // do nothing in base class
    }

    getJson() {
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "Parameter";
    }
}

// implements a parameter that returns a random integer value between an upper and lower bound (inclusive)
export class RandomizedIntegerParameter extends Parameter {
    constructor() {
        super();
    }

    initCreate(parent, name, minimum, maximum) {
        super.initCreate(parent, name);
        this.setParameterType(ParameterTypes.RandomizedInteger);

        this.minimum = minimum;
        this.maximum = maximum;

        return this;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);
        this.setParameterType(ParameterTypes.RandomizedInteger);

        this.minimum = struct.minimum;
        this.maximum = struct.maximum;

        return this;
    }

    static getDefault(parent, name, minimum, maximum) {
        return new RandomizedIntegerParameter().initCreate(parent, name, minimum, maximum);
    }

    getMaximum() {
        return this.maximum;
    }

    setMaximum(maximum) {
        this.maximum = maximum;
    }

    getMinimum() {
        return this.minimum;
    }

    setMinimum(minimum) {
        this.minimum = minimum;
    }

    getIcon(color = "inherit") {
        return <ShuffleIcon color={color} />;
    }

    initStep(state) {
        this.update(-1);
    }

    getCurrent(logId) {
        return this.current;
    }

    step(executionIndex) {
        return StepResult.Stepped;
    }

    update(executionIndex) {
        this.current = Math.floor(Math.random() * (this.maximum - this.minimum)) + this.minimum;
    }

    clone() {
        let clone = new RandomizedIntegerParameter().initCreate(this.parent, this.name, this.minimum, this.maximum);
        clone.setId(this.getId());

        return clone;
    }

    getJson() {
        return {
            ...super.getJson(),
            minimum: this.minimum,
            maximum: this.maximum,
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "RandomizedIntegerParameter";
    }
}

// implements a parameter that returns a random decimal value between an upper and lower bound (inclusive)
export class RandomizedDecimalParameter extends RandomizedIntegerParameter {
    constructor() {
        super();
    }

    initCreate(parent, name, minimum, maximum) {
        super.initCreate(parent, name);
        this.setParameterType(ParameterTypes.RandomizedDecimal);

        this.minimum = minimum;
        this.maximum = maximum;

        return this;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);
        this.setParameterType(ParameterTypes.RandomizedDecimal);

        this.minimum = struct.minimum;
        this.maximum = struct.maximum;

        return this;
    }

    static getDefault(parent, name, minimum, maximum) {
        return new RandomizedDecimalParameter().initCreate(parent, name, minimum, maximum);
    }

    getIcon(color = "inherit") {
        return <ShuffleOnIcon color={color} />;
    }

    step(executionIndex) {
        return StepResult.Stepped;
    }

    update(executionIndex) {
        this.current = (Math.random() * (this.maximum - this.minimum)) + this.minimum;
    }

    clone() {
        let clone = new RandomizedDecimalParameter().initCreate(this.parent, this.name, this.minimum, this.maximum);
        clone.setId(this.getId());

        return clone;
    }

    getJson() {
        return {
            ...super.getJson(),
            minimum: this.minimum,
            maximum: this.maximum,
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "RandomizedDecimalParameter";
    }
}

// implements a numerical parameter that changes over a range
export class NumericalSequenceParameter extends Parameter {
    constructor() {
        super();
    }

    initCreate(parent, name, from, to, step, looping) {
        super.initCreate(parent, name);
        this.setParameterType(ParameterTypes.Sequence);

        this.looping = looping;
        this.start = from;
        this.end = to;
        this.current = from;
        this.valueStep = step;

        return this;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);
        this.setParameterType(ParameterTypes.Sequence);

        this.looping = struct.looping;
        this.start = struct.start;
        this.end = struct.end;
        this.valueStep = struct.step;
        this.current = struct.start;

        return this;
    }

    static getDefault(parent, name, start, end, step, looping) {
        return new NumericalSequenceParameter().initCreate(parent, name, start, end, step, looping);
    }

    getLooping() {
        return this.looping;
    }

    setLooping(looping) {
        this.looping = looping;
    }

    getStart() {
        return this.start;
    }

    setStart(start) {
        this.start = start;
    }

    getEnd() {
        return this.end;
    }

    setEnd(end) {
        this.end = end;
    }

    getStep() {
        return this.valueStep;
    }

    setStep(step) {
        this.valueStep = step;
    }

    getIcon(color = "inherit") {
        return <ArrowRightAltIcon color={color} />;
    }

    initStep(state) {
        this.current = (this.valueStep * -1);
    }

    getCurrent(logId) {
        return this.current;
    }

    step(executionIndex) {
        return this.current >= this.end && this.looping === false ? StepResult.Failure : StepResult.Stepped;
    }

    update(executionIndex) {
        if (this.current >= this.end && this.looping) {
            this.current = 0;
        } else if (this.current < this.end) {
            this.current += this.valueStep;
        }
    }

    clone() {
        let clone = new NumericalSequenceParameter().initCreate(this.parent, this.name, this.start, this.end, this.valueStep, this.looping);
        clone.setId(this.getId());

        return clone;
    }

    getJson() {
        return {
            ...super.getJson(),
            looping: this.looping,
            start: this.start,
            end: this.end,
            step: this.valueStep,
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "NumericalSequenceParameter";
    }
}

// implements a parameter that returns a sequence of values
export class SequencialParameter extends Parameter {
    constructor() {
        super();
        this.items = [];
    }

    initCreate(parent, name, looping) {
        super.initCreate(parent, name);
        this.setParameterType(ParameterTypes.Sequential);

        this.looping = looping;
        this.items = [];

        return this;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);
        this.setParameterType(ParameterTypes.Sequential);

        this.looping = struct.looping;
        this.items = struct.items.map((item, i) => {
            return new QuestionChoiceDefinition().initJson(this, item);
        });

        return this;
    }

    static getDefault(parent, name, looping) {
        return new SequencialParameter().initCreate(parent, name, looping);
    }

    getLooping() {
        return this.looping;
    }

    setLooping(looping) {
        this.looping = looping;
    }

    getItems() {
        return this.items;
    }

    setItems(items) {
        if (Array.isArray(items)) {
            this.items = items;
        }
    }

    getIcon(color = "inherit") {
        return <AnimationIcon color={color} />;
    }

    initStep(state) {
        this.current = -1;
    }

    getCurrent(logId) {
        return this.items[this.current].getValue();
    }

    step(executionIndex) {
        return this.current >= this.items.length && this.looping === false ? StepResult.Failure : StepResult.Stepped;
    }

    update(executionIndex) {
        if (this.current < (this.items.length - 1)) {
            this.current++;
        } else {
            this.current = 0;
        }
    }

    clone() {
        let clone = new SequencialParameter().initCreate(this.parent, this.name, this.looping);
        clone.setId(this.getId());

        let items = [];
        for (let i = 0; i < this.items.length; i++) {
            items.push(this.items[i].clone());
        }
        clone.setItems(items);

        return clone;
    }

    getJson() {
        return {
            ...super.getJson(),
            looping: this.looping,
            items: jsonifyArray(this.items),
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "SequencialParameter";
    }
}

// implements a parameter that returns a pool of values in random order
export class PoolParameter extends SequencialParameter {
    constructor() {
        super();
    }

    initCreate(parent, name, looping) {
        super.initCreate(parent, name, looping);
        this.setParameterType(ParameterTypes.Pool);

        return this;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);
        this.setParameterType(ParameterTypes.Pool);

        return this;
    }

    static getDefault(parent, name, looping) {
        return new PoolParameter().initCreate(parent, name, looping);
    }

    getIcon(color = "inherit") {
        return <SearchIcon color={color} />;
    }

    initStep(state) {
        this.current = -1;
        this.working = shuffle([...this.items]);
        for (let i = 0; i < 10; i++) {
            this.working = shuffle(this.working);
        }
    }

    getCurrent(logId) {
        return this.working[this.current].getValue();
    }

    step(executionIndex) {
        return this.current >= this.items.length && this.looping === false ? StepResult.Failure : StepResult.Stepped;
    }

    update(executionIndex) {
        if (this.current < (this.working.length - 1)) {
            this.current++;
        } else {
            this.current = 0;
            this.working = shuffle([...this.items]);
            for (let i = 0; i < 10; i++) {
                this.working = shuffle(this.working);
            }
        }
    }

    clone() {
        let clone = new PoolParameter().initCreate(this.parent, this.name, this.looping);
        clone.setId(this.getId());

        let clonedItems = this.getItems().map((o, i) => {
            let c = undefined;
            if (o.clone !== undefined) {
                c = o.clone();
            } else {
                c = JSON.stringify(o);
                c = JSON.parse(c);
            }

            return c;
        });
        clone.setItems(clonedItems);

        return clone;
    }

    getJson() {
        return {
            ...super.getJson(),
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "PoolParameter";
    }
}