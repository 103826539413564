import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import DescriptionIcon from '@mui/icons-material/Description';

import { QuizzicalContext } from '../../Context/QuizzicalContext';

import FEH_GenericProvider from '../../Controls/Editors/FEH_GenericProvider';
import BreadcrumbHelper from '../../Controls/BreadcrumbHelper';
import { FeedbackMessage, MessageSeverities } from '../../Classes/FeedbackMessage';

import CreateParameterDialog from './CreateParameterDialog';
import CreateQuestionDialog from './CreateQuestionDialog';
import CreateContentDialog from './CreateContentDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} mountOnEnter unmountOnExit />;
});

/*
** Generic FormEditorHost
** Implements a standard editor that supports all types edited in this manner (via dialog)
**
** Uses forms for submitting, handles validation, and displays relayed messages for the user.
**
** Property attributes:
**
** open: <boolean>, whether or not the dialog is displayed
** isReadOnly: <boolean>, if the contained forms and resulting dialogs are in readonly mode
** fullscreen: <boolean>, whether or not the dialog is fullscreen when displayed
** target: <object>, the target type to edit.
    To create a choice-requiring-type, such as Parameters, set the target to the keystring and clone to false.
    This can be done via individual or the details attribute.

    Valid choice options are:
    parameter
    question
    content

** clone: <boolean>, whether or not to edit a clone of the focus, or the actual focus itself
** details: <an object of the form>: (below is the default)
    {
        original: undefined,
        isOpen: false,
        isReadOnly: false,
        isFullscreen: true,
        editClone: true,
        bucket: undefined,
    }

    This parameter supercedes all of others, updating all values as a whole

**  bucket: <anything>: any object.  This item will be returned upon the completion of the editing process
**
** Method Attributes:
**
** onClose: function(bucket): called upon the cancelation of the editing process by the user
** onSubmit: function(target, bucket): called upon the successful submission of any edits by the user
*/
export default function FormEditorHost(props) {
    const state = React.useContext(QuizzicalContext);
    const [bucket, setBucket] = React.useState(undefined);
    const [details, setDetails] = React.useState({
        target: undefined,
        original: undefined,
        isOpen: false,
        isReadOnly: false,
        isFullscreen: true,
        editClone: true,
        bucket: undefined,
    });

    const [messages, setMessages] = React.useState([]);
    const [titleForceRefresh, setTitleForceRefresh] = React.useState('');

    React.useEffect(() => {
        let result = { ...details };

        if (props.details !== details &&
            props.details !== undefined) {
            result = getProperCloneTarget(props.details);
        } else {
            if (props.bucket !== details.bucket &&
                props.bucket !== undefined) {
                result.bucket = props.bucket;
            }

            if (props.clone !== details.editClone &&
                props.clone !== undefined) {
                result.editClone = props.clone;
            }

            if (props.open !== details.isOpen &&
                props.open !== undefined) {
                result.isOpen = props.open;
            }

            if (props.isReadOnly !== details.isReadOnly &&
                props.isReadOnly !== undefined) {
                result.isReadOnly = props.isReadOnly;
            }

            if (props.fullscreen !== details.isFullscreen &&
                props.fullscreen !== undefined) {
                result.isFullscreen = props.fullscreen;
            }

            if (props.target !== details.target &&
                props.target !== undefined) {
                result.original = props.target;
                result = getProperCloneTarget(result);
            }
        }

        setDetails(result);
    }, [props]);

    const getProperCloneTarget = (specifics) => {
        let result = { ...specifics };

        if (specifics.original !== undefined) {
            if (specifics.editClone === true) {
                if (specifics.original.clone !== undefined) {
                    result.target = specifics.original.clone();

                    if (result.target.setIsClone !== undefined) {
                        result.target.setIsClone();
                        result.target.setSelectGhost(specifics.original);
                    }
                } else {
                    result.target = result.original;
                }
            } else {
                result.target = result.original;
            }
        }

        return result;
    }

    const handleEntityCreatedReturn = (o) => {
        if (o !== undefined) {
            let specific = { ...details, original: o };
            setDetails(getProperCloneTarget(specific));
        } else if (props.onClose !== undefined) {
            props.onClose(details.bucket);
        }
    }

    const handleClose = () => {
        if (props.onClose !== undefined) {
            details.target.setSelectGhost(undefined);
            props.onClose(details.bucket);
        }
        setMessages([]);
    };

    const handleConfirm = () => {
        if (props.onSubmit !== undefined) {
            details.target.setSelectGhost(undefined);
            props.onSubmit(details.target, details.bucket);
        }
        setMessages([]);
    };

    const getHeaderText = (item = details.target) => {
        let result = "";

        if (item.getParent !== undefined) {
            result = BreadcrumbHelper(item);
        } else {
            if (item.getTitle !== undefined) {
                result = item.getTitle(state);
            } else if (item.getName !== undefined) {
                result = item.getName();
            }
        }

        return result;
    }

    const onMessagesRelayed = (messages) => {
        if (Array.isArray(messages)) {
            let megs = [];
            for (let i = 0; i < messages.length; i++) {
                if (messages[i].constructor.ClassName() === FeedbackMessage.ClassName()) {
                    megs.push(messages[i]);
                } else {
                    megs.push(new FeedbackMessage(messages[i], MessageSeverities.Error));
                }
            }

            setMessages(megs);
        }
    };

    let display = undefined;
    if (details !== undefined &&
        details.target !== undefined &&
        Array.isArray(details.target) &&
        typeof details.target[0] === 'string') {

        switch (details.target[0].toLowerCase()) {
            case 'parameter':
                display = (
                    <CreateParameterDialog
                        state={props.state !== undefined && props.state !== null ? props.state : state}
                        focus={details.target[1]}
                        open={details.isOpen}
                        disabled={details.isReadOnly}
                        onClose={handleEntityCreatedReturn} />
                );
                break;
            case 'question':
                display = (
                    <CreateQuestionDialog
                        state={props.state !== undefined && props.state !== null ? props.state : state}
                        focus={details.target[1]}
                        open={details.isOpen}
                        disabled={details.isReadOnly}
                        onClose={handleEntityCreatedReturn} />
                );
                break;
            case 'content':
                display = (
                    <CreateContentDialog
                        state={props.state !== undefined && props.state !== null ? props.state : state}
                        focus={details.target[1]}
                        open={details.isOpen}
                        disabled={details.isReadOnly}
                        onClose={handleEntityCreatedReturn} />
                );
                break;
        }
    } else {
        let messageListing = messages.map((o, i) => {
            return (<ListItem disablePadding>
                <ListItem>
                    {i === 0 || messages[i - 1].getIcon() !== o.getIcon() ?
                        (<ListItemIcon>
                            {o.getIcon()}
                        </ListItemIcon>) : undefined}
                    <ListItemText
                        inset={!(i === 0 || messages[i - 1].getIcon() !== o.getIcon())}
                        primary={o.getText()} />
                </ListItem>
            </ListItem>);
        });

        if (details !== undefined &&
            details.target !== undefined &&
            details.target !== null) {
            if (details.isFullscreen) {
                display = (
                    <Dialog
                        fullScreen
                        open={details.isOpen}
                        onClose={handleClose}
                        TransitionComponent={Transition}>
                        <AppBar>
                            <Toolbar>
                                <Typography sx={{ flex: 1 }} variant="h6" component="div">{getHeaderText()}</Typography>
                            </Toolbar>
                        </AppBar>
                        <Toolbar id="back-to-top-anchor" />
                        <Stack spacing={1}>
                            <Collapse key={"collapse-message-listing"} timeout="auto" in={messageListing.length > 0} unmountOnExit>
                                <List>
                                    {messageListing}
                                </List>
                            </Collapse>
                            <FEH_GenericProvider
                                state={props.state !== undefined && props.state !== null ? props.state : state}
                                propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                                defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                                focus={details.target}
                                isReadOnly={details.isReadOnly}
                                onReportMessages={onMessagesRelayed}
                                onClose={handleClose}
                                onConfirm={handleConfirm} />
                        </Stack>
                    </Dialog>
                );
            } else {
                display = (
                    <Dialog
                        open={details.isOpen}
                        onClose={handleClose}
                        TransitionComponent={Transition}>
                        <DialogTitle>
                            <Stack container direction="row" spacing={1}>
                                {details.target.getSelectedIcon !== undefined ? details.target.getSelectedIcon() : <DescriptionIcon />}
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">{getHeaderText()}</Typography>
                            </Stack>
                        </DialogTitle>
                        <DialogContent>
                            <Stack spacing={1}>
                                <Collapse key={"collapse-message-listing"} timeout="auto" in={messageListing.length > 0} unmountOnExit>
                                    <List subheader="Test">
                                        {messageListing}
                                    </List>
                                </Collapse>
                                <FEH_GenericProvider
                                    propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                                    defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                                    state={props.state !== undefined && props.state !== null ? props.state : state}
                                    focus={details.target}
                                    isReadOnly={details.isReadOnly}
                                    onReportMessages={onMessagesRelayed}
                                    onClose={handleClose}
                                    onConfirm={handleConfirm} />
                            </Stack>
                        </DialogContent>
                    </Dialog>
                );
            }
        }
    }

    return display;
}