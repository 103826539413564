import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';

/* 
**  the Tip control encapsulates a ToolTip to seemlessly omit it if the contents are disabled.
**
** Property Attributes:
**
** title: passed to the tooltip as usual
** omit: whether or not to output the tooltip or just its contents
**
**
*/

export default function Tip(props) {
    if (props.omit === true) {
        return (props.children);
    } else {
        return (
            <Tooltip title={props.title}>
                {props.children}
            </Tooltip>
        );
    }
}