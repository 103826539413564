import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Switch } from '@mui/material';
import { randomId } from '@mui/x-data-grid-generator';

import { QuizzicalContext, RunStates, ScreenModes } from '../../../Context/QuizzicalContext';
import QuestionPoolSelector from '../../QuestionPoolSelector';
import FieldContainer from '../../Forms/FieldContainer';
import FEH_Form from '../../Forms/FEH_Form';

export default function PoolQuestionEditor(props) {
    const state = props.state;
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [defaultFieldSet, setDefaultFieldSet] = React.useState(randomId());

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }
    }, [props]);

    const updateFocus = () => {
        focus.setTitle(document.getElementById("question-title").value);
        focus.setDuration(+document.getElementById("question-duration").value);
        focus.setDwell(+document.getElementById("question-dwell").value);

        return focus;
    }

    /* const onEdited = (event) => {
        if (event.target.id === "question-title") {
            focus.setTitle(event.target.value);
        } else if (event.target.id === "question-duration") {
            focus.setDuration(event.target.value);
        } else if (event.target.id === "question-dwell") {
            focus.setDwell(event.target.value);
        }
    } */

    if (isReadOnly) {
        return ( // readonly
            <Box
                sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                }}>
                <Grid>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Typography color="inherit" variant="body" component="div">Title:&nbsp;&nbsp;&nbsp;</Typography>
                        <Typography color="inherit" variant="caption" component="div">{focus.getTitle()}</Typography>
                    </Stack>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Stack direction="row">
                            <Typography color="inherit" variant="body" component="div">Duration:&nbsp;&nbsp;&nbsp;</Typography>
                            <Typography color="inherit" variant="caption" component="div">{focus.getDuration()}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Stack direction="row">
                            <Typography color="inherit" variant="body" component="div">Dwell:&nbsp;&nbsp;&nbsp;</Typography>
                            <Typography color="inherit" variant="caption" component="div">{focus.getDwell()}</Typography>
                        </Stack>
                    </Stack>
                    <QuestionPoolSelector focus={focus} isReadOnly={isReadOnly} />
                </Grid>
            </Box>
        );
    } else {
        return ( // editable
            <FEH_Form
                height="88vH"
                formId="PoolQuestion"
                type="PoolQuestion"
                getInstance={updateFocus}
                onClose={props.onClose}
                onConfirm={props.onConfirm}
                onReportMessages={props.onReportMessages}>
                <Stack spacing={2}>
                    <FieldContainer
                        display="flow"
                        fieldSet={defaultFieldSet}
                        label="Title"
                        help="The question's name"
                        fullWidth
                        required>
                        <TextField
                            disabled={isReadOnly}
                            id="question-title"
                            fullWidth
                            required
                            defaultValue={focus.getTitle()} />
                    </FieldContainer>
                    <FieldContainer
                        display="flow"
                        fieldSet={defaultFieldSet}
                        label="Override Duration"
                        help={"The amount of time to display the question without its answer.\n\nThis value will override the corresponding value on the sourced question."}>
                        <TextField
                            disabled={isReadOnly}
                            id="question-duration"
                            type="number"
                            defaultValue={focus.getDuration()} />
                    </FieldContainer>
                    <FieldContainer
                        display="flow"
                        fieldSet={defaultFieldSet}
                        label="Override Dwell"
                        help={"The amount of time to display the question with its answer.  This follows the duration's conclusion.\n\nThis value will override the corresponding value on the sourced question."}>
                        <TextField
                            disabled={isReadOnly}
                            id="question-dwell"
                            type="number"
                            defaultValue={focus.getDwell()} />
                    </FieldContainer>
                    <FieldContainer
                        display="flow"
                        fieldSet="QuestionPoolSelectorSet"
                        label="Source Pool"
                        help="Select the source pool for questions."
                        required>
                        <QuestionPoolSelector focus={focus} isReadOnly={isReadOnly} />
                    </FieldContainer>
                </Stack>
            </FEH_Form >
        );
    }
}