import { HierarchicalItemBase, HierarchicalItemTypes } from '../BaseClassHierarchy';
import { getDependencySet } from '../../Libraries/generalUtility';

import ClassIcon from '@mui/icons-material/Class';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import { randomId } from '@mui/x-data-grid-generator';

import { searchTopicsByFullLabel, searchTopicSet } from '../Topics';

// represents a class that students can take
export class Course extends HierarchicalItemBase {
    constructor() {
        super();
    }

    initCreate(parentItem, icon, selectedIcon, topics, title, description) {
        super.initCreate(parentItem, icon, selectedIcon, topics, HierarchicalItemTypes.Classes, title, description);

        this.identityCode = randomId();
        this.startDate = undefined;
        this.endDate = undefined;
        this.locationId = undefined;

        return this;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);
        
        this.icon = <ClassOutlinedIcon />;
        this.selectedIcon = <ClassIcon />;

        this.identityCode = struct.identityCode;
        this.startDate = struct.startDate;
        this.endDate = struct.endDate;
        this.locationId = struct.locationId;

        return this;
    }

    static getDefault(parent, title, description) {
        return new Course().initCreate(parent, <ClassOutlinedIcon />, <ClassIcon />, [], title, description);
    }

    getDependencies(rootCall = true, state) {
        let items = [];//.concat(this.topics);
        for (let i = 0; i < this.topics.length; i++) {
            items.push(searchTopicsByFullLabel(state.Topics, this.topics[i]));
        }

        if (this.getLocationId() !== undefined) {
            items.push(this.getLocationId());
        }

        if (rootCall) {
            return getDependencySet(items);
        }

        return items;
    }

    getCode() {
        return this.identityCode;
    }

    setCode(code) {
        this.identityCode = code;
    }

    getStartDate() {
        return this.startDate;
    }

    setStartDate(startDate) {
        this.startDate = startDate;
    }

    getEndDate() {
        return this.endDate;
    }

    setEndDate(endDate) {
        this.endDate = endDate;
    }

    getLocationId() {
        return this.locationId;
    }

    setLocationId(addressId) {
        this.locationId = addressId;
    }

    clone() {
        let clone = new Course().initCreate(this.getParent(), this.getIcon(), this.getSelectedIcon(), [...this.getTopics()], this.getTitle(), this.getDescription());

        clone.setId(this.getId());
        clone.setCode(this.getCode());
        clone.setStartDate(this.getStartDate());
        clone.setEndDate(this.getEndDate());
        clone.setLocationId(this.getLocationId());

        return clone;
    }

    getJson() {
        return {
            ...super.getJson(),
            identityCode: this.identityCode,
            startDate: this.startDate,
            endDate: this.endDate,
            locationId: this.locationId,
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "Course";
    }
}