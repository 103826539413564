import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Switch } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { randomId } from '@mui/x-data-grid-generator';

import AddLinkIcon from '@mui/icons-material/AddLink';
import LinkOffIcon from '@mui/icons-material/LinkOff';

import { QuizzicalContext, RunStates, ScreenModes } from '../../../Context/QuizzicalContext';
import GenericList from '../../Lists/GenericList';
import { RoleAssociation, AssociationTypes } from '../../../Classes/Personnel/RoleAssociation';
import TopicsManager from '../../../Controls/TopicsManager';
import FieldContainer from '../../Forms/FieldContainer';
import FEH_Form from '../../Forms/FEH_Form';

export default function FEH_PeopleEditor(props) {
    const state = props.state;
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [defaultFieldSet, setDefaultFieldSet] = React.useState(randomId());

    const [minorStateTrigger, setMinorStateTrigger] = React.useState(false);
    const ageRef = React.createRef();

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }
    }, [props]);

    React.useEffect(() => {
        if (ageRef.current) {
            ageRef.current.focus();
        }
    }, [minorStateTrigger])

    const updateFocus = () => {
        focus.setCode(document.getElementById("person-identity-code").value);
        focus.setAge(+document.getElementById("person-age").value);
        if (!focus.isMinor()) {
            focus.setFirstName(document.getElementById("person-firstName").value);
            focus.setMiddleName(document.getElementById("person-middleName").value);
            focus.setLastName(document.getElementById("person-lastName").value);
        }

        return focus;
    }

    const onEdited = (event) => {
        /* if (event.target.id === "person-identity-code") {
            focus.setCode(event.target.value);
        } else if (event.target.id === "person-age") {
            let num = parseInt(event.target.value, 10);
            if (!isNaN(num)) {
                focus.setAge(num);
                setIsMinor(focus.isMinor());
            }
        } else if (event.target.id === "person-firstName") {
            focus.setFirstName(event.target.value);
        } else if (event.target.id === "person-middleName") {
            focus.setMiddleName(event.target.value);
        } else if (event.target.id === "person-lastName") {
            focus.setLastName(event.target.value);
        } */

        if (event.target.id === "person-age") {
            let num = parseInt(event.target.value, 10);
            if (!isNaN(num)) {
                let orig = focus.isMinor();
                focus.setAge(num);
                if (orig !== focus.isMinor()) {
                    setMinorStateTrigger(!minorStateTrigger);
                }
            }
        }

        return true;
    }

    if (isReadOnly) {
        return ( // readonly
            <Box
                sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                }}>
                <Grid spacing={2}>
                    <Stack container direction="row" sx={{ alignItems: 'center' }} spacing={2}>
                        <Typography color="inherit" variant="body" component="div">Personnel Code:</Typography>
                        <Typography color="inherit" variant="caption" component="div">{focus.getCode()}</Typography>
                    </Stack>
                    {
                        !focus.isMinor() ?
                            <>
                                <Stack container direction="row" sx={{ alignItems: 'center' }} spacing={2}>
                                    <Typography color="inherit" variant="body" component="div">First Name:</Typography>
                                    <Typography color="inherit" variant="caption" component="div">{focus.getFirstName()}</Typography>
                                </Stack>
                                <Stack container direction="row" sx={{ alignItems: 'center' }} spacing={2}>
                                    <Typography color="inherit" variant="body" component="div">Middle Name:</Typography>
                                    <Typography color="inherit" variant="caption" component="div">{focus.getMiddleName()}</Typography>
                                </Stack>
                                <Stack container direction="row" sx={{ alignItems: 'center' }} spacing={2}>
                                    <Typography color="inherit" variant="body" component="div">Last Name:</Typography>
                                    <Typography color="inherit" variant="caption" component="div">{focus.getLastName()}</Typography>
                                </Stack>
                            </> : ""
                    }
                    <Stack container direction="row" sx={{ alignItems: 'center' }} spacing={2}>
                        <Typography color="inherit" variant="body" component="div">Age:</Typography>
                        <Typography color="inherit" variant="caption" component="div">{focus.getAge()}</Typography>
                    </Stack>
                    <TopicsManager
                        focus={focus}
                        isReadOnly={isReadOnly}
                        topic="Proficiencies" />
                    <GenericList
                        rows={6}
                        propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                        defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                        state={state}
                        fullscreen={false}
                        isReadOnly={isReadOnly}
                        isExpandable={false}
                        overrideAddIcon={<AddLinkIcon />}
                        overrideDeleteSelectedIcon={<LinkOffIcon />}
                        label="Role"
                        isCollapsable={false}
                        getTargetItems={() => {
                            return state.Associations.filter((role) => {
                                return role.getWhoId() === focus.getId();
                            });
                        }}
                        setTargetItems={(items) => {
                            let clone = [...state.Associations.filter((role) => {
                                return role.getWhoId() !== focus.getId();
                            })];
                            for (let i = 0; i < items.length; i++) {
                                clone.push(items[i]);
                            };

                            state.setAssociations(items);
                        }}
                        getDefaultItem={() => {
                            return RoleAssociation.getDefault(focus.getId(), AssociationTypes.None, undefined);
                        }} />
                </Grid>
            </Box>
        );
    } else {
        return ( // editable
            <FEH_Form
                height="88vH"
                formId="PersonForm"
                type="Person"
                getInstance={updateFocus}
                onClose={props.onClose}
                onConfirm={props.onConfirm}
                onReportMessages={props.onReportMessages}>
                <Stack spacing={2}>
                    <FieldContainer
                        display="flow"
                        fieldSet={defaultFieldSet}
                        label="Identity Code"
                        help="The individual's identification code"
                        fullWidth
                        required>
                        <TextField
                            disabled={isReadOnly}
                            id="person-identity-code"
                            fullWidth
                            required
                            defaultValue={focus.getCode()} />
                    </FieldContainer>
                    <FieldContainer
                        display="flow"
                        fieldSet={defaultFieldSet}
                        label="Age"
                        help="The individual's age"
                        required>
                        <TextField
                            key="age-key"
                            inputRef={ageRef}
                            disabled={isReadOnly}
                            id="person-age"
                            required
                            type="number"
                            defaultValue={focus.getAge() || 0}
                            onChange={onEdited} />
                    </FieldContainer>
                    <Collapse
                        timeout="auto"
                        in={!focus.isMinor()}
                        unmountOnExit
                        onEntered={() => ageRef.current.focus()}>
                        <Stack spacing={1}>
                            <FieldContainer
                                display="flow"
                                fieldSet={defaultFieldSet}
                                label="First Name"
                                help="The individual's first name"
                                fullWidth
                                required>
                                <TextField
                                    disabled={isReadOnly}
                                    id="person-firstName"
                                    fullWidth
                                    required
                                    defaultValue={focus.getFirstName()} />
                            </FieldContainer>
                            <FieldContainer
                                display="flow"
                                fieldSet={defaultFieldSet}
                                label="Middle Name"
                                help="The individual's middle name"
                                fullWidth
                                required>
                                <TextField
                                    disabled={isReadOnly}
                                    id="person-middleName"
                                    fullWidth
                                    required
                                    defaultValue={focus.getMiddleName()} />
                            </FieldContainer>
                            <FieldContainer
                                display="flow"
                                fieldSet={defaultFieldSet}
                                label="Last Name"
                                help="The individual's last name"
                                fullWidth
                                required>
                                <TextField
                                    disabled={isReadOnly}
                                    id="person-lastName"
                                    fullWidth
                                    required
                                    defaultValue={focus.getLastName()} />
                            </FieldContainer>
                        </Stack>
                    </Collapse>
                    <TopicsManager
                        focus={focus}
                        isReadOnly={isReadOnly}
                        directions={"Select proficiencies"}
                        topic="Proficiencies"
                        unmountOnExit
                        onReportMessages={props.onReportMessages} />
                    <GenericList
                        rows={6}
                        propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                        defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                        state={state}
                        fullscreen={false}
                        isReadOnly={isReadOnly}
                        isExpandable={false}
                        label="Role"
                        isCollapsable={false}
                        overrideAddIcon={<AddLinkIcon />}
                        overrideDeleteSelectedIcon={<LinkOffIcon />}
                        getTargetItems={() => {
                            return state.Associations.filter((role) => {
                                return role.getWhoId() === focus.getId();
                            });
                        }}
                        setTargetItems={(items) => {
                            let clone = [...state.Associations.filter((role) => {
                                return role.getWhoId() !== focus.getId();
                            })];
                            for (let i = 0; i < items.length; i++) {
                                clone.push(items[i]);
                            };

                            state.setAssociations(clone);
                        }}
                        getDefaultItem={() => {
                            return RoleAssociation.getDefault(focus.getId(), AssociationTypes.None, undefined);
                        }} />
                </Stack>
            </FEH_Form>
        );
    }
}