import * as React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import { randomId } from '@mui/x-data-grid-generator';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';

import FullscreenIcon from '@mui/icons-material/Fullscreen';

import Editor from '@monaco-editor/react';

import TermInsertion from './ContentEditor/TermInsertion';
import Subject from '../Classes/Subjects';
import { FillInQuestion, MultipleChoiceQuestion } from '../Classes/Questions';
import FullscreenCodeEditor from '../Screens/Dialogs/FullscreenCodeEditor';

export default function InsertableJavascriptEditor(props) {
    const [focus, setFocus] = React.useState(props.focus);
    const [sourceEntity, setSourceEntity] = React.useState(props.source);
    const [targetScriptGetter, setTargetScriptGetter] = React.useState(undefined);
    const [targetScriptSetter, setTargetScriptSetter] = React.useState(undefined);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [isMouseOver, setIsMouseOver] = React.useState(props.isMouseOver || false);
    const [disableTermInsertion, setDisableTermInsertion] = React.useState(props.disableTermInsertion === true);

    const [isExpanded, setIsExpanded] = React.useState(false);

    const theme = useTheme();
    const editorRef = React.useRef(null);
    const monacoInstance = React.useRef(null);

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.source !== undefined &&
            props.source !== null &&
            props.source !== sourceEntity) {
            setSourceEntity(props.source);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }

        if (props.isMouseOver !== undefined &&
            props.isMouseOver !== null &&
            props.isMouseOver !== isMouseOver) {
            setIsMouseOver(props.isMouseOver);
        }

        if (props.setter !== undefined &&
            props.setter !== null &&
            props.setter !== targetScriptSetter) {
            setTargetScriptSetter(props.setter);
        }

        if (props.getter !== undefined &&
            props.getter !== null &&
            props.getter !== targetScriptGetter) {
            setTargetScriptGetter(props.getter);
        }

        if (props.disableTermInsertion !== undefined &&
            props.disableTermInsertion !== null &&
            props.disableTermInsertion !== disableTermInsertion) {
            setDisableTermInsertion(props.disableTermInsertion);
        }
    }, [props]);

    const handleEditorChange = (value, event) => {
        focus[targetScriptSetter](value);
    }

    const handleEditorDidMount = (editor, monaco) => {
        // here is the editor instance
        // you can store it in `useRef` for further usage
        editorRef.current = editor;
        monacoInstance.current = monaco;
    }

    const onInsertItem = (text, isVariable) => {
        if (editorRef !== undefined &&
            editorRef.current !== null &&
            editorRef.current !== undefined) {
            const editor = editorRef.current;
            var selection = editor.getSelection();
            var op = { range: selection, text: text, forceMoveMarkers: true };
            editor.executeEdits("my-source", [op]);
        }
    }

    let themeName = (theme.palette.mode === 'dark' ? "vs-dark" : 'vs-light');

    let fabItems = [];
    if (disableTermInsertion === false) {
        fabItems.push(
            <TermInsertion
                key="termInsertion-control"
                isVisible={true}
                entity={sourceEntity !== undefined ? sourceEntity : focus}
                onInsert={onInsertItem} />
        );
    }

    if (props.inEditor !== true) {
        fabItems.push(
            <Button
                key="fullscreen-button"
                onClick={(e) => {
                    setIsExpanded(true);
                }}>
                <FullscreenIcon />
            </Button>
        );
    }

    let fabMenu = (
        <Stack
            direction="row"
            sx={props.fabStyling === undefined ? {
                position: 'absolute',
                top: -5,
                right: -20,
            } : props.fabStyling}>
            {fabItems}
        </Stack>
    );

    if (focus !== undefined &&
        targetScriptGetter !== undefined &&
        targetScriptSetter !== undefined) {
        /* let ace = <AceEditor
            readOnly={props.isReadOnly}
            ref={editorRef}
            style={props.style}
            placeholder="Enter your script here..."
            mode="javascript"
            theme={themeName}
            name="scriptEditor"
            onChange={handleEditorChange}
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={focus[targetScriptGetter]()}
            setOptions={{
                enableMultiselect: false,
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
                fontFamily: "Courier New",
            }} />; */

        let editor = <Box
            sx={{
                height: (props.height || '100%'),
                width: (props.width || '98%'),
            }}>
            <Editor
                width="100%"
                height="100%"
                defaultLanguage="javascript"
                defaultValue={focus[targetScriptGetter]() || "// enter your script here"}
                onChange={handleEditorChange}
                theme={themeName}
                onMount={handleEditorDidMount}
                options={{ readOnly: props.isReadOnly !== undefined ? props.isReadOnly : false }}
            />
            {/* <Box sx={{ height: '10%', width: '100%', backgroundColor: 'yellow' }}>
                
            </Box> */}
        </Box>

        /* return (
            <Box
                fullWidth
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                }}>
                {ace}
                {props.isReadOnly === false ? fabMenu : ''}
            </Box>
        ); */

        return <>
            <Box sx={props.styling !== undefined ? props.styling : undefined}>
                {isExpanded === false ? editor : ''}
                <FullscreenCodeEditor
                    disableTermInsertion={disableTermInsertion}
                    source={sourceEntity}
                    focus={focus}
                    scriptSetter={props.setter}
                    scriptGetter={props.getter}
                    isOpen={isExpanded}
                    onConfirm={(e) => { setIsExpanded(false); }} />
            </Box>
            {props.isReadOnly === false ? fabMenu : ''}
        </>
    } else {
        return '';
    }
}