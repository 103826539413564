import { ParentHierarchicalItemBase, HierarchicalItemTypes } from '../BaseClassHierarchy';
import { randomId } from '@mui/x-data-grid-generator';
import { getDependencySet } from '../../Libraries/generalUtility';
import Dependency from '../Dependency';
import { jsonifyArray } from '../../Libraries/generalUtility';

import HomeWorkIcon from '@mui/icons-material/HomeWork';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import { Course } from './Course';
import { Address } from './Address';
import { whereSelected } from '../SelectImplementation';

// organizations have classes and faculty
// classes are the organization's children
// persons are associated with it as either faculty or students
export class Organization extends ParentHierarchicalItemBase {
    constructor() {
        super();

        this.identityCode = randomId();
        this.addresses = [];
        this.courses = [];
    }

    initCreate(parentItem, icon, selectedIcon, title, description) {
        super.initCreate(parentItem, icon, selectedIcon, [], HierarchicalItemTypes.Organizations, title, description);

        this.identityCode = randomId();
        this.addresses = [];
        this.courses = [];

        return this;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);
        
        this.icon = <HomeWorkOutlinedIcon />;
        this.selectedIcon = <HomeWorkIcon />;

        this.identityCode = struct.identityCode;
        this.addresses = struct.addresses.map((a, i) => {
            return new Address().initJson(this, a);
        });
        this.courses = struct.courses.map((c, i) => {
            return new Course().initJson(this, c);
        });
        this.children = struct.children.map((o, i) => {
            return new Organization().initJson(this, o);
        });

        return this;
    }

    static getDefault(parent, title, description) {
        return new Organization().initCreate(parent, <HomeWorkOutlinedIcon />, <HomeWorkIcon />, title, description);
    }

    getDependencies(rootCall = true, state) {
        let items = [].concat(super.getDependencies(false, state));

        // children
        for (let i = 0; i < this.getChildren().length; i++) {
            items = items.concat(this.getChildren()[i].getDependencies(false, state));
        }

        // courses
        for (let i = 0; i < this.getCourses().length; i++) {
            items = items.concat(this.getCourses()[i].getDependencies(false, state));
        }

        // addresses
        for (let i = 0; i < this.getAddresses().length; i++) {
            items = items.concat(this.getAddresses()[i].getDependencies(false, state));
        }

        if (rootCall) {
            return getDependencySet(items);
        }

        return items;
    }

    getCode() {
        return this.identityCode;
    }

    setCode(code) {
        this.identityCode = code;
    }

    getAddresses() {
        return this.addresses;
    }

    setAddresses(addresses) {
        if (Array.isArray(addresses)) this.addresses = addresses;
    }

    getCourses() {
        return this.courses;
    }

    setCourses(courses) {
        if (Array.isArray(courses)) this.courses = courses;
    }

    clone(onlySelected) {
        let clone = new Organization().initCreate(this.getParent(), this.getIcon(), this.getSelectedIcon(), this.getTitle(), this.getDescription());

        clone.setId(this.getId());
        clone.setCode(this.getCode());

        let clones = whereSelected(this.getAddresses(), onlySelected).map((o, i) => {
            return o.clone();
        });
        clone.setAddresses(clones);

        clones = whereSelected(this.getChildren(), onlySelected).map((o, i) => {
            return o.clone(onlySelected);
        });
        clone.setChildren(clones);

        clones = whereSelected(this.getCourses(), onlySelected).map((o, i) => {
            return o.clone();
        });
        clone.setCourses(clones);

        return clone;
    }

    getJson() {
        return {
            ...super.getJson(),
            addresses: jsonifyArray(this.getAddresses()),
            courses: jsonifyArray(this.getCourses()),
            identityCode: this.identityCode,
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "Organization";
    }
}