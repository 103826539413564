import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { Checkbox } from '@mui/material';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel, a11yProps } from '../Libraries/TabPanel';

import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ScrollTop from './ScrollTop';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import DashboardIcon from '@mui/icons-material/Dashboard';
import QuizIcon from '@mui/icons-material/Quiz';
import ContactsIcon from '@mui/icons-material/Contacts';
import Groups2Icon from '@mui/icons-material/Groups2';
import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LinkIcon from '@mui/icons-material/Link';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import PublishIcon from '@mui/icons-material/Publish';
import DownloadIcon from '@mui/icons-material/Download';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { AdministrationModes, QuizzicalContext, RunStates, ScreenModes } from '../Context/QuizzicalContext';
import globalMenuHelper from '../Context/globalMenuHelper';

export default function AppMenu(props) {
    const state = React.useContext(QuizzicalContext);
    const [displayAdministratorAssignmentDialog, setdisplayAdministratorAssignmentDialog] = React.useState(true);
    const [tempAdmin, setTempAdmin] = React.useState(null);
    const [tabIndex, setTabIndex] = React.useState(0);
    const [tabMouseOverTracking, setTabMouseOverTracking] = React.useState({});
    const [forceRefresh, setForceRefresh] = React.useState(undefined);

    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    const [dialOpen, setDialOpen] = React.useState(false);

    if (!Object.isFrozen(globalMenuHelper)) {
        globalMenuHelper.menuRefresher = (adminMode) => {
            if (adminMode === AdministrationModes.Exam) {
                setTabIndex(1);
                state.setScreenMode(1);
            } else if (adminMode === AdministrationModes.Practice) {
                setTabIndex(1);
                state.setScreenMode(1);
            } else {
                setTabIndex(8);
                state.setScreenMode(ScreenModes.Import);
            }
        };
        Object.freeze(globalMenuHelper);
    }

    React.useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener("resize", handleResize)

        handleResize()

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [setWindowWidth]);

    const theme = useTheme();

    const handleTabChanged = (event, newValue) => {
        setTabIndex(newValue);
        state.setScreenMode(newValue);
    };

    const updateCursorState = (key, value) => {
        tabMouseOverTracking[key] = value;
        setTabMouseOverTracking({ ...tabMouseOverTracking });
    }

    const handleDialMenuOpen = () => setDialOpen(true);
    const handleDialMenuClose = () => setDialOpen(false);
    const handleDialMenuChosen = (index) => {
        setTabIndex(index);
        state.setScreenMode(index);
        setDialOpen(false);
    }

    /* const handlePlayMenuClick = () => {
        if (state.Mode.RunState === RunStates.Running) {
            state.setRunState(RunStates.Idle);
        } else {
            state.setRunState(RunStates.Running);
        }
    };

    const handleRecordMenuClick = () => {
        if (state.Mode.RunState === RunStates.Recording) {
            state.setRunState(RunStates.Idle);
        } else {
            state.setRunState(RunStates.Recording);
        }
    }; */

    const dialog = (
        <Dialog open={displayAdministratorAssignmentDialog} onClose={() => { setdisplayAdministratorAssignmentDialog(!displayAdministratorAssignmentDialog); }}>
            <DialogTitle>Assign Administrator</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please enter your name
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Administrator's Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={tempAdmin}
                    onChange={(event) => { setTempAdmin(event.target.value); }}
                />
            </DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={() => { setdisplayAdministratorAssignmentDialog(false); }}>Cancel</Button>
                <Button color="inherit" onClick={() => { setdisplayAdministratorAssignmentDialog(false); state.setAdministratorName(tempAdmin); }}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );

    var adminDefinerButtonVariant = null;
    if (state.AdministratorName === undefined || state.AdministratorName === null || state.AdministratorName.trim() === "" || state.AdministratorName === "Unassigned") {
        adminDefinerButtonVariant = "outlined";
    } else {
        adminDefinerButtonVariant = "text";
    }

    let items = [
        {
            label: 'Dashboard',
            icon: <DashboardIcon color="inherit" />,
            tabIndex: 0,
        },
        {
            label: 'Presentation',
            icon: <SlideshowIcon color="inherit" />,
            tabIndex: 1,
        },
        {
            label: 'Assignments',
            icon: <QuizIcon color="inherit" />,
            tabIndex: 2,
        },
        {
            label: 'Personnel',
            icon: <Groups2Icon color="inherit" />,
            tabIndex: 3,
        },
        {
            label: 'Question Pools',
            icon: <LibraryBooksIcon color="inherit" />,
            tabIndex: 4,
        },
        {
            label: 'Organizations',
            icon: <HomeWorkIcon color="inherit" />,
            tabIndex: 5,
        },
        {
            label: 'Roles',
            icon: <LinkIcon color="inherit" />,
            tabIndex: 6,
        },
        {
            label: 'Settings',
            icon: <SettingsApplicationsIcon color="inherit" />,
            tabIndex: 7,
        },
        {
            label: 'Imports',
            icon: <PublishIcon color="inherit" />,
            tabIndex: 8,
        },
        {
            label: 'Exports',
            icon: <DownloadIcon color="inherit" />,
            tabIndex: 9,
        },
        {
            label: 'Legacy Import',
            icon: <UploadFileIcon color="inherit" />,
            tabIndex: 10,
        },
    ];

    let labels = {
        'Dashboard': (<Stack direction="row" justify="flex-end" alignItems="center" spacing={1} color={theme.palette.mode === 'light' ? "primary.contrastText" : "primary.light"}>
            <DashboardIcon />
            <Collapse key={"collapse-Dashboard"} timeout="auto" in={tabMouseOverTracking['Dashboard'] || tabIndex === ScreenModes.Dashboard} orientation='horizontal' unmountOnExit>
                <Typography>Dashboard</Typography>
            </Collapse>
        </Stack>),
        'Assignments': (<Stack direction="row" justify="flex-end" alignItems="center" spacing={1} color={theme.palette.mode === 'light' ? "primary.contrastText" : "primary.light"}>
            <QuizIcon color="inherit" />
            <Collapse key={"collapse-Assignments"} timeout="auto" in={tabMouseOverTracking['Assignments'] || tabIndex === ScreenModes.Assignments} orientation='horizontal' unmountOnExit>
                <Typography>Assignments</Typography>
            </Collapse>
        </Stack>),
        'Personnel': (<Stack direction="row" justify="flex-end" alignItems="center" spacing={1} color={theme.palette.mode === 'light' ? "primary.contrastText" : "primary.light"}>
            <Groups2Icon color="inherit" />
            <Collapse key={"collapse-Personnel"} timeout="auto" in={tabMouseOverTracking['Personnel'] || tabIndex === ScreenModes.People} orientation='horizontal' unmountOnExit>
                <Typography>Personnel</Typography>
            </Collapse>
        </Stack>),
        'Question Pools': (<Stack direction="row" justify="flex-end" alignItems="center" spacing={1} color={theme.palette.mode === 'light' ? "primary.contrastText" : "primary.light"}>
            <LibraryBooksIcon color="inherit" />
            <Collapse key={"collapse-Question-Pools"} timeout="auto" in={tabMouseOverTracking['Question Pools'] || tabIndex === ScreenModes.Pools} orientation='horizontal' unmountOnExit>
                <Stack>
                    <Typography>Question</Typography>
                    <Typography>Pools</Typography>
                </Stack>
            </Collapse>
        </Stack>),
        'Organizations': (<Stack direction="row" justify="flex-end" alignItems="center" spacing={1} color={theme.palette.mode === 'light' ? "primary.contrastText" : "primary.light"}>
            <HomeWorkIcon color="inherit" />
            <Collapse key={"collapse-Organizations"} timeout="auto" in={tabMouseOverTracking['Organizations'] || tabIndex === ScreenModes.Organizations} orientation='horizontal' unmountOnExit>
                <Typography>Organizations</Typography>
            </Collapse>
        </Stack>),
        'Roles': (<Stack direction="row" justify="flex-end" alignItems="center" spacing={1} color={theme.palette.mode === 'light' ? "primary.contrastText" : "primary.light"}>
            <LinkIcon color="inherit" />
            <Collapse key={"collapse-Roles"} timeout="auto" in={tabMouseOverTracking['Roles'] || tabIndex === ScreenModes.Associations} orientation='horizontal' unmountOnExit>
                <Typography>Associations</Typography>
            </Collapse>
        </Stack>),
        'Settings': (<Stack direction="row" justify="flex-end" alignItems="center" spacing={1} color={theme.palette.mode === 'light' ? "primary.contrastText" : "primary.light"}>
            <SettingsApplicationsIcon color="inherit" />
            <Collapse key={"collapse-Settings"} timeout="auto" in={tabMouseOverTracking['Settings'] || tabIndex === ScreenModes.Settings} orientation='horizontal' unmountOnExit>
                <Typography>Settings</Typography>
            </Collapse>
        </Stack>),
        'Presentation': (<Stack direction="row" justify="flex-end" alignItems="center" spacing={1} color={theme.palette.mode === 'light' ? "primary.contrastText" : "primary.light"}>
            <SlideshowIcon color="inherit" />
            <Collapse key={"collapse-Presentation"} timeout="auto" in={tabMouseOverTracking['Presentation'] || tabIndex === ScreenModes.Presentation} orientation='horizontal' unmountOnExit>
                <Typography>Presentation</Typography>
            </Collapse>
        </Stack>),
        'Imports': (<Stack direction="row" justify="flex-end" alignItems="center" spacing={1} color={theme.palette.mode === 'light' ? "primary.contrastText" : "primary.light"}>
            <PublishIcon color="inherit" />
            <Collapse key={"collapse-Imports"} timeout="auto" in={tabMouseOverTracking['Imports'] || tabIndex === ScreenModes.Import} orientation='horizontal' unmountOnExit>
                <Typography>Imports</Typography>
            </Collapse>
        </Stack>),
        'Exports': (<Stack direction="row" justify="flex-end" alignItems="center" spacing={1} color={theme.palette.mode === 'light' ? "primary.contrastText" : "primary.light"}>
            <DownloadIcon color="inherit" />
            <Collapse key={"collapse-Exports"} timeout="auto" in={tabMouseOverTracking['Exports'] || tabIndex === ScreenModes.Export} orientation='horizontal' unmountOnExit>
                <Typography>Exports</Typography>
            </Collapse>
        </Stack>),
        'Legacy Import': (<Stack direction="row" justify="flex-end" alignItems="center" spacing={1} color={theme.palette.mode === 'light' ? "primary.contrastText" : "primary.light"}>
            <UploadFileIcon color="inherit" />
            <Collapse key={"collapse-Exports"} timeout="auto" in={tabMouseOverTracking['Legacy Import'] || tabIndex === ScreenModes.LegacyImport} orientation='horizontal' unmountOnExit>
                <Stack>
                    <Typography>Legacy</Typography>
                    <Typography>Import</Typography>
                </Stack>
            </Collapse>
        </Stack>),
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar>
                <Stack>
                    <Toolbar>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', flexGrow: 1, alignItems: 'center' }}>
                            <SpeedDial
                                hidden={windowWidth > 600 || state.Mode.AdministrationMode !== AdministrationModes.Normal}
                                direction='down'
                                ariaLabel="Compressed Adminsterfy Menu"
                                sx={{ position: 'absolute', top: 16, left: 16 }}
                                icon={<SpeedDialIcon />}
                                onClose={handleDialMenuClose}
                                onOpen={handleDialMenuOpen}
                                open={dialOpen}
                            >
                                {items.map((item) => (
                                    <SpeedDialAction
                                        key={item.label}
                                        icon={item.icon}
                                        tooltipTitle={item.label}
                                        tooltipOpen
                                        onClick={(e) => { handleDialMenuChosen(item.tabIndex); }}
                                    />
                                ))}
                            </SpeedDial>
                            <Collapse
                                key={"collapse-Dashboard-Tabs"}
                                timeout="auto"
                                in={windowWidth > 600 && state.Mode.AdministrationMode === AdministrationModes.Normal}
                                orientation='horizontal'
                                unmountOnExit>
                                <Tabs
                                    value={tabIndex}
                                    onChange={handleTabChanged}
                                    aria-label="Adminsterfy Main Menu"
                                    variant="scrollable"
                                    scrollButtons="auto">
                                    <Tab
                                        label={labels['Dashboard']}
                                        {...a11yProps(ScreenModes.Dashboard)}
                                        onMouseEnter={(e) => { updateCursorState('Dashboard', true); }}
                                        onMouseLeave={(e) => { updateCursorState('Dashboard', false); }} />
                                    <Tab
                                        label={labels['Presentation']}
                                        {...a11yProps(ScreenModes.Presentation)}
                                        onMouseEnter={(e) => { updateCursorState('Presentation', true); }}
                                        onMouseLeave={(e) => { updateCursorState('Presentation', false); }} />
                                    <Tab
                                        label={labels['Assignments']}
                                        {...a11yProps(ScreenModes.Assignments)}
                                        onMouseEnter={(e) => { updateCursorState('Assignments', true); }}
                                        onMouseLeave={(e) => { updateCursorState('Assignments', false); }} />
                                    <Tab
                                        label={labels['Personnel']}
                                        {...a11yProps(ScreenModes.People)}
                                        onMouseEnter={(e) => { updateCursorState('Personnel', true); }}
                                        onMouseLeave={(e) => { updateCursorState('Personnel', false); }} />
                                    <Tab
                                        label={labels['Question Pools']}
                                        {...a11yProps(ScreenModes.Pools)}
                                        onMouseEnter={(e) => { updateCursorState('Question Pools', true); }}
                                        onMouseLeave={(e) => { updateCursorState('Question Pools', false); }} />
                                    <Tab
                                        label={labels['Organizations']}
                                        {...a11yProps(ScreenModes.Organizations)}
                                        onMouseEnter={(e) => { updateCursorState('Organizations', true); }}
                                        onMouseLeave={(e) => { updateCursorState('Organizations', false); }} />
                                    <Tab
                                        label={labels['Roles']}
                                        {...a11yProps(ScreenModes.Associations)}
                                        onMouseEnter={(e) => { updateCursorState('Roles', true); }}
                                        onMouseLeave={(e) => { updateCursorState('Roles', false); }} />
                                    <Tab
                                        label={labels['Settings']}
                                        {...a11yProps(ScreenModes.Settings)}
                                        onMouseEnter={(e) => { updateCursorState('Settings', true); }}
                                        onMouseLeave={(e) => { updateCursorState('Settings', false); }} />
                                    <Tab
                                        label={labels['Imports']}
                                        {...a11yProps(ScreenModes.Import)}
                                        onMouseEnter={(e) => { updateCursorState('Imports', true); }}
                                        onMouseLeave={(e) => { updateCursorState('Imports', false); }} />
                                    <Tab
                                        label={labels['Exports']}
                                        {...a11yProps(ScreenModes.Export)}
                                        onMouseEnter={(e) => { updateCursorState('Exports', true); }}
                                        onMouseLeave={(e) => { updateCursorState('Exports', false); }} />
                                    <Tab
                                        label={labels['Legacy Import']}
                                        {...a11yProps(ScreenModes.LegacyImport)}
                                        onMouseEnter={(e) => { updateCursorState('Legacy Import', true); }}
                                        onMouseLeave={(e) => { updateCursorState('Legacy Import', false); }} />
                                </Tabs>
                            </Collapse>
                        </Box>
                        <Tooltip title={"Loaded content version number - " + (state.Mode.Version === undefined ? "Unversioned" : state.Mode.Version)}>
                            <Typography
                                sx={{
                                    width: 150,
                                    background: '#000000',
                                    marginRight: 1,
                                }}
                                variant='caption'>
                                {state.Mode.Version === undefined ? "Unversioned" : state.Mode.Version}
                            </Typography>
                        </Tooltip>
                        <Tooltip title="Change Instructor">
                            <Button
                                sx={{
                                    textTransform: 'none'
                                }}
                                color="inherit"
                                variant={adminDefinerButtonVariant} onClick={() => { setdisplayAdministratorAssignmentDialog(true); }}>{state.AdministratorName || "Unassigned"}</Button>
                        </Tooltip>
                        <Tooltip title="Change Instructor">
                            <IconButton color="inherit" variant={adminDefinerButtonVariant} onClick={() => { setdisplayAdministratorAssignmentDialog(true); }}><ContactsIcon /></IconButton>
                        </Tooltip>
                        <Tooltip title="Change Theme">
                            <Checkbox
                                checked={state.theme === 'light'}
                                onChange={(event) => {
                                    state.setTheme(event.target.checked);
                                    state.setTheme(event.target.checked);
                                }}
                                icon={<Brightness3Icon />}
                                checkedIcon={<BrightnessHighIcon sx={{ color: "#FFFFFF" }} />} />
                        </Tooltip>
                    </Toolbar>
                </Stack>
            </AppBar>
            {dialog}
            <Toolbar id="back-to-top-anchor" />
            <Box sx={{ my: 2 }}>
                {props.children}
            </Box>
            <Toolbar id="go-to-bottom-anchor" />
            <ScrollTop>
                <Fab size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
        </Box>
    );
}