// hierarchy: <LayoutRegionManager> - 1-many -> <LayoutRegion> - 1-many -> <LayoutContent>
// the region manager manages the size and position of the regions
// the regions manage their content and region merges (they are what the user sees)

import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import ViewComfyOutlinedIcon from '@mui/icons-material/ViewComfyOutlined';

import { grid } from '@mui/system';
import { ContentSources, ContentTypes, LayoutBase } from './LayoutMeta';
import { LayoutRegion } from './LayoutRegion';
import { jsonifyArray } from '../../Libraries/generalUtility';
import ScriptItem from '../ScriptItem';

export class LayoutRegionManager extends LayoutBase {
    constructor() {
        super();

        this.gridWidth = 0;
        this.gridHeight = 0;
        this.regions = [];

        this.preScripts = [];
        this.postScripts = [];
        this.title = undefined;
        this.isExposition = false;
    }

    initCreate(parent, gridWidth = 2, gridHeight = 2) {
        super.initCreate(parent);

        this.gridWidth = gridWidth;
        this.gridHeight = gridHeight;

        this.remakeRegions();

        this.preScripts = [];
        this.postScripts = [];
        this.title = undefined;
        this.isExposition = false;

        return this;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);

        this.gridWidth = struct.gridWidth;
        this.gridHeight = struct.gridHeight;
        this.regions = struct.regions.map((region, i) => {
            return new LayoutRegion().initJson(this, region,
                this.onLocationXGrew, this.onLocationXShrunk,
                this.onLocationYGrew, this.onLocationYShrunk,
                this.onLocationRightGrew, this.onLocationRightShrunk,
                this.onLocationBottomGrew, this.onLocationBottomShrunk);
        });

        if (Array.isArray(struct.preScripts)) {
            this.preScripts = struct.preScripts.map((script, i) => {
                return new ScriptItem().initJson(this, script);
            });
        }
        if (Array.isArray(struct.postScripts)) {
            this.postScripts = struct.postScripts.map((script, i) => {
                return new ScriptItem().initJson(this, script);
            });
        }

        this.title = struct.title;
        this.isExposition = struct.isExposition;

        return this;
    }

    getIcon() {
        return <ViewComfyOutlinedIcon />;
    }

    getSelectedIcon() {
        return <ViewComfyIcon />;
    }

    getTitle() {
        return this.title;
    }

    setTitle(title) {
        this.title = title;
    }

    isExplanatory() {
        return this.isExposition === true;
    }

    setIsExposition(isExposition) {
        this.isExposition = (isExposition === true);
    }

    hasAnswers() {
        return this.regions.filter((r) => {
            return r.getSource() === ContentSources.Answer;
        }).length > 0;
    }

    isCompleted() {
        let sourcesDone = this.regions.filter((r) => {
            return r.getSource() !== ContentSources.Unassigned;
        }).length;

        let typesDone = this.regions.filter((r) => {
            return r.getType() !== ContentTypes.None ||
            r.getSource() === ContentSources.Reserved;
        }).length;

        return (sourcesDone + typesDone) === (this.regions.length * 2);
    }

    getPreScripts() {
        return this.preScripts;
    }

    setPreScripts(scripts) {
        if (Array.isArray(scripts)) {
            this.preScripts = scripts;
        }
    }

    getPostScripts() {
        return this.postScripts;
    }

    setPostScripts(scripts) {
        if (Array.isArray(scripts)) {
            this.postScripts = scripts;
        }
    }

    onLocationXGrew = (source, amount, oValue, nValue) => {
        // find the others who encroach on its new territory and reshape them.
        // Any that are reduced to 0 Right get removed.
        let encroachers = this.findOverlaps(
            this.regions,
            source.getX(),
            source.getY(),
            source.getRight(),
            source.getBottom(),
            source.getId());

        // anything we find gets reshaped.
        // x grew so we're looking left, that means everything that encroaches gets it's right shrunk
        for (let i = 0; i < encroachers.length; i++) {
            encroachers[i].shiftRight(-1, true);

            if (encroachers[i].getRight() < 0) {
                this.regions.splice(this.regions.indexOf(encroachers[i]), 1);
            }
        }
    }

    onLocationXShrunk = (source, amount, oValue, nValue) => {
        // find the others who encroach on its new territory and reshape them.
        // Any that are reduced to 0 Right get removed.
        let encroachers = this.findOverlaps(
            this.regions,
            source.getX(),
            source.getY(),
            source.getRight(),
            source.getBottom(),
            source.getId());

        // anything we find gets reshaped.
        // x shrunk so we're looking right, that means everything that encroaches gets it's x and right shrunk
        for (let i = 0; i < encroachers.length; i++) {
            encroachers[i].shiftX(-1, true);
            encroachers[i].shiftRight(-1, true);

            if (encroachers[i].getRight() < 0 ||
                encroachers[i].getX() < 0) {
                this.regions.splice(this.regions.indexOf(encroachers[i]), 1);
            }
        }
    }

    onLocationYGrew = (source, amount, oValue, nValue) => {
        // find the others who encroach on its new territory and reshape them.
        // Any that are reduced to 0 Bottom get removed.
        let encroachers = this.findOverlaps(
            this.regions,
            source.getX(),
            source.getY(),
            source.getRight(),
            source.getBottom(),
            source.getId());

        // anything we find gets reshaped.
        // y grew so we're looking down, that means everything that encroaches gets it's y enlarged and it's bottom shrunk
        for (let i = 0; i < encroachers.length; i++) {
            encroachers[i].shiftY(1, true);
            encroachers[i].shiftBottom(-1, true);

            if (encroachers[i].getBottom() < 0) {
                this.regions.splice(this.regions.indexOf(encroachers[i]), 1);
            }
        }
    }

    onLocationYShrunk = (source, amount, oValue, nValue) => {
        // find the others who encroach on its new territory and reshape them.
        // Any that are reduced to 0 Bottom get removed.
        let encroachers = this.findOverlaps(
            this.regions,
            source.getX(),
            source.getY(),
            source.getRight(),
            source.getBottom(),
            source.getId());

        // anything we find gets reshaped.
        // y shrunk so we're looking up, that means everything that encroaches gets it's it's bottom shrunk
        for (let i = 0; i < encroachers.length; i++) {
            encroachers[i].shiftBottom(-1, true);

            if (encroachers[i].getBottom() < 0) {
                this.regions.splice(this.regions.indexOf(encroachers[i]), 1);
            }
        }
    }

    onLocationRightGrew = (source, amount, oValue, nValue) => {
        // find the others who encroach on its new territory and reshape them.
        // Any that are reduced to 0 Right get removed.
        let encroachers = this.findOverlaps(
            this.regions,
            source.getX(),
            source.getY(),
            source.getRight(),
            source.getBottom(),
            source.getId());

        // anything we find gets reshaped.
        // right grew so we're looking right, that means everything that encroaches gets it's right and x shrunk
        for (let i = 0; i < encroachers.length; i++) {
            encroachers[i].shiftX(-1, true);
            encroachers[i].shiftRight(-1, true);

            if (encroachers[i].getRight() < 0) {
                this.regions.splice(this.regions.indexOf(encroachers[i]), 1);
            }
        }
    }

    onLocationRightShrunk = (source, amount, oValue, nValue) => {
        if (source.getRight() < 0) {
            this.regions.splice(this.regions.indexOf(source), 1);
        }
    }

    onLocationBottomGrew = (source, amount, oValue, nValue) => {
        // find the others who encroach on its new territory and reshape them.
        // Any that are reduced to 0 Bottom get removed.
        let encroachers = this.findOverlaps(
            this.regions,
            source.getX(),
            source.getY(),
            source.getRight(),
            source.getBottom(),
            source.getId());

        // anything we find gets reshaped.
        // bottom grew so we're looking down, that means everything that encroaches gets it's y grown and bottom shrunk
        for (let i = 0; i < encroachers.length; i++) {
            encroachers[i].shiftY(1, true);
            encroachers[i].shiftBottom(-1, true);

            if (encroachers[i].getBottom() < 0) {
                this.regions.splice(this.regions.indexOf(encroachers[i]), 1);
            }
        }
    }

    onLocationBottomShrunk = (source, amount, oValue, nValue) => {
        if (source.getBottom() < 0) {
            this.regions.splice(this.regions.indexOf(source), 1);
        }
    }

    remakeRegions(regionSet) {
        let content = (regionSet === undefined ? this.getRegions() : regionSet);
        this.regions = [];

        if (content !== undefined) {
            // loop through the existing ones, check if they're inside and copy them over if they are
            for (let i = 0; i < content.length; i++) {
                if (content[i].isEntirelyWithin(this.gridWidth, 0, 0, this.gridHeight)) {
                    this.regions.push(content[i]);
                }
            }
        }

        this.ensureRegionalCoverage();
    }

    ensureRegionalCoverage() {
        for (let x = 0; x < this.gridWidth; x++) {
            for (let y = 0; y < this.gridHeight; y++) {
                let col = this.findOverlaps(this.regions, x, y, 0, 0);
                if (col.length === 0) {
                    this.regions.push(
                        new LayoutRegion().initCreate(this, x, y, 0, 0,
                            this.onLocationXGrew.bind(this),
                            this.onLocationXShrunk.bind(this),
                            this.onLocationYGrew.bind(this),
                            this.onLocationYShrunk.bind(this),
                            this.onLocationRightGrew.bind(this),
                            this.onLocationRightShrunk.bind(this),
                            this.onLocationBottomGrew.bind(this),
                            this.onLocationBottomShrunk.bind(this)));
                }
            }
        }
    }

    findRegionIn(set, x, y, id) {
        if (!Array.isArray(set)) return undefined;
        if (set.length === 0) return undefined;

        let found = {};
        let result = set.filter((o) => {
            if (o === undefined) return false;
            if (id !== undefined) {
                if (found[o.getId()] === undefined) {
                    found[o.getId()] = o.contains(x, y) && (o.getId() !== id);
                    return found[o.getId()];
                } else {
                    return false;
                }
            } else {
                return o.contains(x, y);
            }
        });

        if (result.length == 1) {
            return result[0];
        } else {
            return undefined;
        }
    }

    findOverlaps(set, x, y, right, bottom, id) {
        if (!Array.isArray(set)) return [];
        if (set.length === 0) return [];

        let found = [];
        // looks for any region with any corner within the defined area (x, y, right, bottom) and returns it / them
        for (let i = 0; i < set.length; i++) {
            let f = set[i]; // f = focus
            if (f === undefined) continue;

            if (id == undefined || (id !== undefined && f.getId() !== id)) {
                // check all four corner individually
                if (f.collides(x, y, right, bottom)) {
                    found.push(f);
                }
            }
        }

        return found;
    }

    done() {
        this.ensureRegionalCoverage();
    }

    getHeight() {
        return this.gridHeight;
    }

    getWidth() {
        return this.gridWidth;
    }

    setDimensions(width, height) {
        if (width > 0 &&
            height > 0 &&
            (this.gridWidth != width || this.gridHeight != height)) {
            this.gridWidth = width;
            this.gridHeight = height;

            this.remakeRegions();
        }
    }

    getRegions() {
        if (this.regions === undefined) return undefined;
        return this.regions.filter((o) => {
            return o !== undefined;
        });
    }

    getRegionsBySource(source) {
        return this.getRegions().filter((o) => {
            return o.getSource() === source;
        });
    }

    getRegionsByType(type) {
        return this.getRegions().filter((o) => {
            return o.getType() === type;
        });
    }

    /* findContaining(x, y, id) {
        return this.findRegionIn(this.regions, x, y, id);
    }

    // coordSet = [{x, y}, ...]
    findContainingSet(coordSet) {
        if (Array.isArray(coordSet)) {
            let result = [];
            for (let i = 0; i < coordSet.length; i++) {
                let response = this.findContaining(coordSet[i].x, coordSet[i].y);
                if (response !== undefined) result.push(response);
            }

            if (result.length > 0) {
                return result;
            } else {
                return undefined;
            }
        }

        return undefined;
    } */

    clone() {
        let cloned = new LayoutRegionManager().initCreate(this.getParent(), this.getWidth(), this.getHeight());
        cloned.setId(this.getId());

        let clonedRegions = this.getRegions().map((o, i) => {
            let clone = o.clone();
            clone.setParent(cloned);
            clone.setEvents(
                cloned.onLocationXGrew.bind(cloned),
                cloned.onLocationXShrunk.bind(cloned),
                cloned.onLocationYGrew.bind(cloned),
                cloned.onLocationYShrunk.bind(cloned),
                cloned.onLocationRightGrew.bind(cloned),
                cloned.onLocationRightShrunk.bind(cloned),
                cloned.onLocationBottomGrew.bind(cloned),
                cloned.onLocationBottomShrunk.bind(cloned));
            return clone;
        });
        cloned.remakeRegions(clonedRegions);

        let scripts = [];
        for (let i = 0; i < this.preScripts.length; i++) {
            scripts.push(this.preScripts[i].clone());
        }
        cloned.setPreScripts(scripts);

        scripts = [];
        for (let i = 0; i < this.postScripts.length; i++) {
            scripts.push(this.postScripts[i].clone());
        }
        cloned.setPostScripts(scripts);
        
        cloned.title = this.getTitle();
        cloned.isExposition = this.isExplanatory();

        return cloned;
    }

    cloneFrom(target) {
        //this.setParent(target.getParent());
        this.setId(target.getId());
        this.setDimensions(target.getWidth(), target.getHeight());

        let clonedRegions = target.getRegions().map((r, i) => {
            let region = r.clone !== undefined ? r.clone() : r;
            region.setParent(this);
            region.setEvents(
                this.onLocationXGrew.bind(this),
                this.onLocationXShrunk.bind(this),
                this.onLocationYGrew.bind(this),
                this.onLocationYShrunk.bind(this),
                this.onLocationRightGrew.bind(this),
                this.onLocationRightShrunk.bind(this),
                this.onLocationBottomGrew.bind(this),
                this.onLocationBottomShrunk.bind(this));
            return region;
        });
        this.remakeRegions(clonedRegions);

        let scripts = [];
        for (let i = 0; i < target.getPreScripts().length; i++) {
            scripts.push(target.getPreScripts()[i].clone());
        }
        this.setPreScripts(scripts);

        scripts = [];
        for (let i = 0; i < target.getPostScripts().length; i++) {
            scripts.push(target.getPostScripts()[i].clone());
        }
        this.setPostScripts(scripts);
        
        this.title = target.getTitle();
        this.isExposition = target.isExplanatory();
    }

    copy(target) {
        let id = this.id;
        let parent = this.parent;

        this.cloneFrom(target);
        this.id = id;
        this.parent = parent;

    }

    getJson() {
        return {
            ...super.getJson(),
            gridWidth: this.gridWidth,
            gridHeight: this.gridHeight,
            regions: jsonifyArray(this.regions),
            preScripts: jsonifyArray(this.preScripts),
            postScripts: jsonifyArray(this.postScripts),
            title: this.title,
            isExposition: this.isExposition === true,
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "LayoutRegionManager";
    }
}