import React, { useState } from 'react'
import { QuestionTopics } from '../Classes/Topics';
import { useThemeDetector } from '../Libraries/useThemeDetector';

export const RunStates = {
    Idle: 0,
    Running: 1,
    Recording: 2,
    Paused: 3
};

export const ScreenModes = {
    Dashboard: 0,
    Presentation: 1,
    Assignments: 2,
    People: 3,
    Pools: 4,
    Organizations: 5,
    Associations: 6,
    Settings: 7,
    Import: 8,
    Export: 9,
    LegacyImport: 10,
};

export const AdministrationModes = {
    Normal: 0,
    Practice: 1,
    Exam: 2,
}

export const QuizzicalContext = React.createContext({
    Clipboard: [],
    AdministratorName: undefined,
    Associations: [],
    People: [],
    Organizations: [],
    Topics: [],
    DefaultExportTimestamp: 'MMMM-dd-yyyy HH-mm-ss tp',
    loading: true,
    theme: 'light',
    Mode: {
        RunState: RunStates.Idle,
        Screen: ScreenModes.Dashboard,
        ShowAnswers: true,
        ContinuousPlay: false,
        DefaultDuration: 50,
        DefaultDwell: 50,
        GlobalDwellHandicapModifier: 1.0,
        GlobalDurationHandicapModifier: 1.0,
        AdministrationMode: AdministrationModes.Normal,
        Version: undefined,
    },
    Examinations: {
        Assignments: [],
        Pools: [],
    },
    Editing: {
        FocusedAssignment: undefined,
        FocusedAssignmentOpen: false,
        FocusedSection: undefined,
        FocusedSectionOpen: false,
        FocusedSubject: undefined,
        FocusedSubjectOpen: false,
    },

    setAdministratorName: (adminName) => { },
    setAssignments: (assignments) => { },
    setAssociations: (associations) => { },
    setPeople: (people) => { },
    setOrganizations: (organizations) => { },
    setPools: (pools) => { },
    setRunState: (runstate) => { },
    setTheme: (useLightTheme) => { },
    setScreenMode: (modeNumber) => { },
    setShowAnswers: (showAnswers) => { },
    setContinuousPlay: (continuousPlay) => { },
    setDefaultDuration: (duration) => { },
    setDefaultDwell: (dwell) => { },
    setGlobalDwellHandicapModifier: (multipler) => { },
    setGlobalDurationHandicapModifier: (multiplier) => { },
    setTopics: (topics) => { },
    setDefaultExportTimestamp: (timestamp) => { },
    setAdministrationMode: (adminMode) => { },
    setClipboard: (clip) => { },
    setVersion: (version) => { },

    getScreenModeLabel: () => { },
    getRunStateVerb: () => { },
    getRecordStateVerb: () => { },
    getPlayStateVerb: () => { },
    getPausedStateVerb: () => { },
    getActionStateVerb: () => { },
    getClipboard: (classType) => { },
});

export const QuizzicalContextProvider = (props) => {
    const setAdministratorName = (adminName) => {
        setState(state = ({ ...state, AdministratorName: adminName }));
    }

    const setAssignments = (assignments) => {
        setState(
            state = ({
                ...state, Examinations: {
                    ...state.Examinations, Assignments: assignments
                }
            }));
    }

    const setAssociations = (associations) => {
        setState(state = ({ ...state, Associations: associations }));
    }

    const setPeople = (people) => {
        setState(state = ({ ...state, People: people }));
    }

    const setOrganizations = (organizations) => {
        setState(state = ({ ...state, Organizations: organizations }));
    }

    const setPools = (pools) => {
        setState(
            state = ({
                ...state, Examinations: {
                    ...state.Examinations, Pools: pools
                }
            }));
    }

    const setRunState = (runstate) => {
        setState(
            state = ({
                ...state, Mode: {
                    ...state.Mode, RunState: runstate
                }
            }));
    }

    const setTheme = (useLightTheme) => {
        if (useLightTheme) {
            state = { ...state, theme: 'light' };
        } else {
            state = { ...state, theme: 'dark' };
        }
        setState(state);
    }

    const setScreenMode = (modeNumber) => {
        setState(
            state = ({
                ...state, Mode: {
                    ...state.Mode, Screen: modeNumber
                }
            })
        );
    }

    const setShowAnswers = (showAnswers) => {
        setState(
            state = ({
                ...state, Mode: {
                    ...state.Mode, ShowAnswers: showAnswers
                }
            })
        );
    }

    const setContinuousPlay = (continuousPlay) => {
        setState(
            state = ({
                ...state, Mode: {
                    ...state.Mode, ContinuousPlay: continuousPlay
                }
            })
        );
    }

    const getScreenModeLabel = () => {
        let label = null;
        switch (state.Mode.Screen) {
            case ScreenModes.Dashboard:
                label = "Dashboard";
                break;
            case ScreenModes.Assignments:
                label = state.getRunStateVerb() + " Management";
                break;
            case ScreenModes.People:
                label = "Personnel Management";
                break;
            case ScreenModes.Testing:
                if (state.Mode.ShowAnswers === true) {
                    label = "Assignment";
                } else {
                    label = "Examination";
                }
                break;
            case ScreenModes.Pools:
                label = "Question Pool Management";
                break;
            case ScreenModes.Organizations:
                label = "Organization Management";
                break;
            case ScreenModes.Associations:
                label = "Personnel Association Management";
                break;
            case ScreenModes.Settings:
                label = "Configuration Management";
                break;
            case ScreenModes.Presentation:
                label = "Presentation";
                break;
            case ScreenModes.Import:
                label = "Data Import Management";
                break;
            case ScreenModes.Export:
                label = "Data Export Management";
                break;
        };

        return label;
    }

    const setDefaultDuration = (duration) => {
        setState(
            state = ({
                ...state, Mode: {
                    ...state.Mode, DefaultDuration: duration
                }
            })
        );
    }

    const setDefaultDwell = (dwell) => {
        setState(
            state = ({
                ...state, Mode: {
                    ...state.Mode, DefaultDwell: dwell
                }
            })
        );
    }

    const setGlobalDwellHandicapModifier = (modifier) => {
        setState(
            state = ({
                ...state, Mode: {
                    ...state.Mode, GlobalDwellHandicapModifier: modifier
                }
            })
        );
    }

    const setGlobalDurationHandicapModifier = (modifier) => {
        setState(
            state = ({
                ...state, Mode: {
                    ...state.Mode, GlobalDurationHandicapModifier: modifier
                }
            })
        );
    }

    const setTopics = (topics) => {
        setState(state = ({ ...state, Topics: topics }));
    }

    const setDefaultExportTimestamp = (timestamp) => {
        setState(state = ({ ...state, DefaultExportTimestamp: timestamp }));
    }

    const setAdministrationMode = (adminMode) => {
        setState(
            state = ({
                ...state, Mode: {
                    ...state.Mode, AdministrationMode: adminMode
                }
            })
        );
    }

    const setClipboard = (clip) => {
        setState(
            state = ({
                ...state, Clipboard: [
                    ...state.Clipboard, clip
                ]
            })
        );
    }

    const setVersion = (version) => {
        setState(
            state = ({
                ...state, Mode: {
                    ...state.Mode, Version: version
                }
            })
        );
    }

    const getRunStateVerb = () => {
        let label = null;
        if (state.Mode.ShowAnswers === true) {
            label = "Assignment";
        } else {
            label = "Examination";
        }

        return label;
    }

    const getRecordStateVerb = () => {
        let label = null;
        if (state.Mode.RunState === RunStates.Recording) {
            label = "Recording";
        } else {
            label = "Record";
        }

        return label;
    }

    const getPlayStateVerb = () => {
        let label = null;
        if (state.Mode.RunState === RunStates.Running) {
            label = "Playing";
        } else {
            label = "Play";
        }

        return label;
    }

    const getPausedStateVerb = () => {
        let label = null;
        if (state.Mode.RunState === RunStates.Paused) {
            label = "Paused";
        } else {
            label = "Pause";
        }

        return label;
    }

    const getActionStateVerb = () => {
        let label = null;
        switch (state.Mode.RunState) {
            case RunStates.Idle:
                label = "Ready";
                break;
            case RunStates.Paused:
                label = "Paused";
                break;
            case RunStates.Recording:
                label = "Recording";
                break;
            case RunStates.Running:
                label = "Playing";
                break;
        }

        return label;
    }

    const getClipboard = (classTypes) => {
        // search through the clipboard array for the first set that only contains the provided type
        let result = undefined;
        for (let i = state.Clipboard.length - 1; i > -1; i--) {
            if (Array.isArray(state.Clipboard[i])) {
                let matchCount = state.Clipboard[i].filter((item) => {
                    return (item.constructor.ClassName === undefined ? false : classTypes.includes(item.constructor.ClassName()));
                }).length;

                if (matchCount === state.Clipboard[i].length) {
                    result = state.Clipboard[i];
                }
            } else {
                if (state.Clipboard[i].constructor.ClassName !== undefined &&
                    classTypes.includes(state.Clipboard[i].constructor.ClassName())) {
                    result = [state.Clipboard[i]];
                }
            }

            if (result !== undefined) {
                break;
            }
        }

        return result;
    }

    const initState = {
        Clipboard: [],
        AdministratorName: undefined,
        Associations: [],
        People: [],
        Organizations: [],
        Topics: QuestionTopics,
        DefaultExportTimestamp: 'MMMM-dd-yyyy HH-mm-ss tp',
        theme: 'light',
        loading: true,
        Mode: {
            RunState: RunStates.Idle,
            Screen: ScreenModes.Dashboard,
            ShowAnswers: true,
            ContinuousPlay: false,
            DefaultDuration: 50,
            DefaultDwell: 50,
            GlobalDwellHandicapModifier: 1.0,
            GlobalDurationHandicapModifier: 1.0,
            AdministrationMode: AdministrationModes.Normal,
        },
        Examinations: {
            Assignments: [],
            Pools: [],
        },
        Editing: {

        },

        setAdministratorName: setAdministratorName,
        setAssignments: setAssignments,
        setAssociations: setAssociations,
        setPeople: setPeople,
        setOrganizations: setOrganizations,
        setPools: setPools,
        setRunState: setRunState,
        setTheme: setTheme,
        setScreenMode: setScreenMode,
        setShowAnswers: setShowAnswers,
        setContinuousPlay: setContinuousPlay,
        setDefaultDuration: setDefaultDuration,
        setDefaultDwell: setDefaultDwell,
        setGlobalDwellHandicapModifier: setGlobalDwellHandicapModifier,
        setGlobalDurationHandicapModifier: setGlobalDurationHandicapModifier,
        setTopics: setTopics,
        setDefaultExportTimestamp: setDefaultExportTimestamp,
        setAdministrationMode: setAdministrationMode,
        setClipboard: setClipboard,
        setVersion: setVersion,

        getScreenModeLabel: getScreenModeLabel,
        getRunStateVerb: getRunStateVerb,
        getRecordStateVerb: getRecordStateVerb,
        getPlayStateVerb: getPlayStateVerb,
        getPausedStateVerb: getPausedStateVerb,
        getActionStateVerb: getActionStateVerb,
        getClipboard: getClipboard,
    }

    var setState = (newState) => {
        forceState(newState);
    };
    var [state, forceState] = useState(initState);

    return (
        <QuizzicalContext.Provider value={state}>
            {props.children}
        </QuizzicalContext.Provider>
    )
}