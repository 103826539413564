import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import DownloadIcon from '@mui/icons-material/Download';
import ReplayIcon from '@mui/icons-material/Replay';

import { getQuestionSet, getReplayDownload, getStudentSheet } from '../../Libraries/SheetGeneration';
import { QuizzicalContext, RunStates, ScreenModes } from '../../Context/QuizzicalContext';
import { randomId } from '@mui/x-data-grid-generator';
import FileNamingDialog from './FileNamingDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AttendanceTrackingDialog(props) {
    const [isOpen, setIsOpen] = React.useState(props.isOpen);
    const [attendanceData, setAttendanceData] = React.useState({
        studentData: [],
        selectionData: {},
        questionList: [],
        lessonKey: undefined,
    });
    const [exportFileName, setExportFileName] = React.useState(undefined);
    const [passthroughData, setPassthroughData] = React.useState(undefined);
    const [fileExtension, setFileExtension] = React.useState(undefined);

    React.useEffect(() => {
        let changed = false;

        if (typeof props.getStudents === 'function') {
            attendanceData.studentData = props.getStudents();
            let sIds = attendanceData.studentData.map((s) => {
                return s.getId();
            });

            if (props.attendanceData === undefined) {
                attendanceData.selectionData = {};
                for (let i = 0; i < sIds.length; i++) {
                    attendanceData.selectionData[sIds[i]] = false; // default everyone to not present
                }
                props.onAttendanceDataUpdated(attendanceData.selectionData);
            } else {
                attendanceData.selectionData = props.attendanceData;
            }

            changed = true;
        }

        if (props.lessonKey !== undefined &&
            props.lessonKey !== null &&
            props.lessonKey !== attendanceData.lessonKey) {
            attendanceData.lessonKey = props.lessonKey;
            changed = true;
        }

        if (props.isOpen !== undefined &&
            props.isOpen !== null &&
            typeof props.isOpen === 'boolean' &&
            props.isOpen !== isOpen) {
            setIsOpen(props.isOpen);
        }

        if (changed === true) {
            setAttendanceData({ ...attendanceData });
        }
    }, [props]);

    const handleClose = (event) => {
        props.onCancel(event, props.bucket);
    };

    const toggleAttendance = (studentId) => {
        attendanceData.selectionData[studentId] = !attendanceData.selectionData[studentId];
        setAttendanceData({ ...attendanceData });
    }

    const handleDownloadReplay = () => {
        setFileExtension('.rply');
        setExportFileName('replays');
    }

    const handleDownloadQuestions = () => {
        setFileExtension('.csv');
        setExportFileName('questions');
    }

    const handleDownloadStudentAnswers = (title) => {
        setPassthroughData(title);
        setFileExtension('.csv');
        setExportFileName('student');
    }

    const handleConfirm = (event, bucket, fileName) => {
        if (exportFileName === 'replays') {
            getReplayDownload(attendanceData.lessonKey, props.historicalQuestions, fileName);
        } else if (exportFileName === 'questions') {
            getQuestionSet(attendanceData.lessonKey, props.historicalQuestions, fileName);
        } else if (exportFileName === 'student') {
            getStudentSheet(attendanceData.lessonKey, bucket, props.historicalQuestions, fileName);
        }

        setExportFileName(undefined);
    }

    let items = attendanceData.studentData.map((student, i) => {
        return (
            <ListItem
                alignItems="flex-start"
                key={student.getId() + '-' + i}
                secondaryAction={
                    <Tooltip arrow title={"Download an answer sheet for " + student.getTitle(false)}>
                        <IconButton onClick={(e) => { handleDownloadStudentAnswers(student.getTitle(false)); }}><DownloadIcon /></IconButton>
                    </Tooltip>
                }>
                <ListItemAvatar>
                    <Tooltip arrow title="Click to toggle student's attendance status">
                        <IconButton onClick={(e) => {
                            toggleAttendance(student.getId());
                            props.onAttendanceDataUpdated(attendanceData.selectionData);
                        }}>
                            {attendanceData.selectionData[student.getId()] === true ? student.getSelectedIcon() : student.getIcon()}
                        </IconButton>
                    </Tooltip>
                </ListItemAvatar>
                <ListItemText
                    primary={student.getTitle()}
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="caption" >
                                {attendanceData.selectionData[student.getId()] === true ? "Present" : "Absent"}
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
        );
    })

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle>
                <Stack direction="row" alignItems={'center'}>
                    <Typography sx={{ flexGrow: 1 }}>Student Attedance</Typography>
                    {props.replay === false ?
                        <Tooltip arrow title="Download these questions as a reusable quiz file">
                            <IconButton onClick={handleDownloadReplay}><ReplayIcon /></IconButton>
                        </Tooltip> : ''}
                    <Tooltip arrow title="Download a teacher's answer sheet">
                        <IconButton onClick={handleDownloadQuestions}><DownloadIcon /></IconButton>
                    </Tooltip>
                    <Button color="inherit" onClick={handleClose}>Close</Button>
                </Stack>
            </DialogTitle>
            <DialogContent
                sx={{
                    height: '80Vh',
                    width: '60Vh',
                }}>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {items}
                </List>
                <FileNamingDialog
                    name={exportFileName === 'student' ? passthroughData : undefined}
                    isOpen={exportFileName !== undefined}
                    filename={exportFileName}
                    bucket={passthroughData}
                    onCancel={(e) => {
                        setExportFileName(undefined);
                    }}
                    onConfirm={handleConfirm}
                    extension={fileExtension}
                    cancelText="Cancel"
                    confirmText="Save" />
            </DialogContent>
        </Dialog>
    );
}
