import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';

import { QuizzicalContext, ScreenModes } from '../Context/QuizzicalContext';

export default function DashboardScreen(props) {
    const state = useContext(QuizzicalContext);

    return (
        <Typography color="inherit" variant="h6" component="div" style={{ textAlign: 'center' }} sx={{ flexGrow: 1 }}>{"Dashboard"}</Typography>
    );
}