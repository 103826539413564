import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

// A simple progress bar dialog
// requires the following parameters (most are functions):
//      job: the label to display
//      work() : boolean
//          This function should execute a step of the work to be performed and return a value indicating success.
//      current() : int
//          Should return the current amount of work has been completed
//      total() : int
//          Should return the total number of work to complete
//      done() : void
//          Called when all work is done and the dialog is closed
//      onConfirm(workSuccessful) : void
//          Called when the user clicks the button.  Will execute *after* done().
//
export default function ProgressDialog(props) {
    const [isOpen, setIsOpen] = React.useState(props.isOpen);
    const [processState, setProcessState] = React.useState({
        hadError: false,
        iterations: 0,
    });

    React.useEffect((orig) => {
        if (isOpen !== props.isOpen &&
            props.isOpen !== undefined &&
            props.isOpen !== null) {
            setIsOpen(props.isOpen);
        }
    }, [props]);

    React.useEffect(() => {
        if (isOpen &&
            props.current() < props.total()) {

            let timeout = 100;
            let work = () => {
                let start = Date.now();
                let workResult = props.work(); // true for success, false otherwise
                timeout = Date.now() - start;
                
                let outcome = false;
                if (typeof workResult === 'boolean') {
                    outcome = workResult;
                } else {
                    outcome = workResult.outcome;
                }

                setProcessState({
                    hadError: !outcome,
                    iterations: props.current(),
                });

                if (props.current() < props.total()) {
                    setTimeout(work, timeout);
                }
            }

            setTimeout(work, timeout);
        }
    }, [isOpen]);

    const getProgressColor = () => {
        if (processState.hadError === true) {
            return 'error';
        } else {
            if (props.current() === props.total()) {
                if (props.requireConfirm === true) {
                    return 'info';
                } else {
                    handleConfirm();
                }
            } else {
                return 'success';
            }
        }
    }

    const handleConfirm = (event) => {
        if (props.current() === props.total()) {
            props.done();
        }
        props.onConfirm(processState.hadError === false);
    }

    return (
        <div>
            <Dialog
                open={isOpen}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{props.job}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Typography color="inherit" variant="caption" >
                            <span style={{ whiteSpace: 'pre-line' }}>
                                {props.process}
                            </span>
                        </Typography>
                        <LinearProgress variant="determinate" color={getProgressColor()} value={(props.current() / props.total()) * 100} />
                        <Stack direction="row-reverse">
                            <Typography variant="caption" >{props.total()}</Typography>
                            <Typography variant="caption" >/</Typography>
                            <Typography variant="caption" >{props.current()}</Typography>
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    {
                        props.requireConfirm === true || processState.hadError === true ?
                            <Button
                                color="inherit"
                                onClick={handleConfirm}
                                disabled={props.current() < props.total()}>Close
                            </Button> : ''
                    }
                </DialogActions>
            </Dialog>
        </div >
    );
}