import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Switch } from '@mui/material';

export default function GenerateQuestionPool(props) {
    const [focus, setFocus] = React.useState(props.focus);
    const [isDisabled, setIsDisabled] = React.useState(props.isDisabled || false);

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isDisabled !== undefined &&
            props.isDisabled !== null &&
            props.isDisabled !== isDisabled) {
            setIsDisabled(props.isDisabled);
        }
    }, [props]);

    const onEdited = (event) => {
        if (event.target.id === "questionpool-title") {
            focus.setTitle(event.target.value);
        } else if (event.target.id === "questionpool-description") {
            focus.setDescription(event.target.value);
        } else if (event.target.id === "questionpool-shuffled") {
            focus.setShuffled(event.target.checked);
        }
    }

    return ( // editable
        <Box
            sx={{
                paddingTop: 2,
                paddingLeft: 2,
                paddingRight: 2,
                fontSize: '0.875rem',
                fontWeight: '700',
            }}>
            <Grid container spacing={4}>
                <Grid xs={12}>
                    <TextField
                        disabled={isDisabled}
                        id="questionpool-title"
                        fullWidth
                        required
                        label="Title"
                        defaultValue={focus.getTitle()}
                        onChange={onEdited} />
                </Grid>
                <Grid xs={12}>
                    <TextField
                        disabled={isDisabled}
                        id="questionpool-description"
                        fullWidth
                        required
                        multiline
                        rows={4}
                        label="Description"
                        defaultValue={focus.getDescription()}
                        onChange={onEdited} />
                </Grid>
                <Grid xs={12}>
                    <Stack direction="row">
                        <Typography color="inherit" variant="h6" component="div">Shuffled</Typography>
                        <Switch
                            disabled={isDisabled}
                            id="questionpool-shuffled"
                            defaultChecked={focus.isShuffled()}
                            onChange={onEdited} />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}