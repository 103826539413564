// hierarchy: <LayoutRegionManager> - 1-many -> <LayoutRegion> - 1-many -> <LayoutContent>
// the region manager manages the size and position of the regions
// the regions manage their content and region merges (they are what the user sees)

import { LayoutBase, ContentTypes, ContentSources } from './LayoutMeta';

export class LayoutRegion extends LayoutBase {
    constructor() {
        super();
    }

    initCreate(parent, x, y, right, bottom,
        onLocationXGrew, onLocationXShrunk,
        onLocationYGrew, onLocationYShrunk,
        onLocationRightGrew, onLocationRightShrunk,
        onLocationBottomGrew, onLocationBottomShrunk) {
        super.initCreate(parent);

        this.onLocationXGrew = onLocationXGrew;
        this.onLocationXShrunk = onLocationXShrunk;
        this.onLocationYGrew = onLocationYGrew;
        this.onLocationYShrunk = onLocationYShrunk;
        this.onLocationRightGrew = onLocationRightGrew;
        this.onLocationRightShrunk = onLocationRightShrunk;
        this.onLocationBottomGrew = onLocationBottomGrew;
        this.onLocationBottomShrunk = onLocationBottomShrunk;
        this.x = x;
        this.y = y;
        this.bottom = bottom;
        this.right = right;
        this.content = undefined;
        this.type = ContentTypes.None;
        this.source = ContentSources.Unassigned;

        return this;
    }

    initJson(parent, struct,
        onLocationXGrew, onLocationXShrunk,
        onLocationYGrew, onLocationYShrunk,
        onLocationRightGrew, onLocationRightShrunk,
        onLocationBottomGrew, onLocationBottomShrunk) {
        super.initJson(parent, struct);

        this.x = struct.x;
        this.y = struct.y;
        this.bottom = struct.bottom;
        this.right = struct.right;
        this.content = struct.content;
        this.type = struct.type;
        this.source = struct.source;

        this.onLocationXGrew = onLocationXGrew;
        this.onLocationXShrunk = onLocationXShrunk;
        this.onLocationYGrew = onLocationYGrew;
        this.onLocationYShrunk = onLocationYShrunk;
        this.onLocationRightGrew = onLocationRightGrew;
        this.onLocationRightShrunk = onLocationRightShrunk;
        this.onLocationBottomGrew = onLocationBottomGrew;
        this.onLocationBottomShrunk = onLocationBottomShrunk;

        return this;
    }

    done() {
        if (this.getParent() !== undefined) {
            this.getParent().done();
        }
    }

    dropEvents() {
        this.onLocationXGrew = undefined;
        this.onLocationXShrunk = undefined;

        this.onLocationYGrew = undefined;
        this.onLocationYShrunk = undefined;

        this.onLocationRightGrew = undefined;
        this.onLocationRightShrunk = undefined;

        this.onLocationBottomGrew = undefined;
        this.onLocationBottomShrunk = undefined;
    }

    setEvents(
        onLocationXGrew, onLocationXShrunk,
        onLocationYGrew, onLocationYShrunk,
        onLocationRightGrew, onLocationRightShrunk,
        onLocationBottomGrew, onLocationBottomShrunk) {
        this.onLocationXGrew = onLocationXGrew;
        this.onLocationXShrunk = onLocationXShrunk;

        this.onLocationYGrew = onLocationYGrew;
        this.onLocationYShrunk = onLocationYShrunk;

        this.onLocationRightGrew = onLocationRightGrew;
        this.onLocationRightShrunk = onLocationRightShrunk;

        this.onLocationBottomGrew = onLocationBottomGrew;
        this.onLocationBottomShrunk = onLocationBottomShrunk;
    }

    shiftX(x, hush = false) {
        /* if ((this.x + x) < 0) return;
        if ((this.x + x) > this.getParent().getWidth()) return; */

        let origX = this.x;
        this.x += x;
        if (this.x !== origX && !hush) {
            //this.onLocationXUpdate(this, origX, this.x);
            let dif = this.x - origX;
            if (dif > 0) {
                this.onLocationXGrew(this, Math.abs(dif), origX, this.x);
            } else if (dif < 0) {
                this.onLocationXShrunk(this, Math.abs(dif), origX, this.x);
            }
        }

        return this.x;
    }

    getX() {
        return this.x;
    }

    shiftY(y, hush = false) {
        /* if ((this.y + y) < 0) return;
        if ((this.y + y) > this.getParent().getHeight()) return; */

        let origY = this.y;
        this.y += y;
        if (this.y !== origY && !hush) {
            let dif = this.y - origY;
            if (dif > 0) {
                this.onLocationYGrew(this, Math.abs(dif), origY, this.y);
            } else if (dif < 0) {
                this.onLocationYShrunk(this, Math.abs(dif), origY, this.y);
            }
        }

        return this.y;
    }

    getY() {
        return this.y;
    }

    getRight() {
        return this.right;
    }

    shiftRight(right, hush = false) {
        /* if ((this.right + right) < 0) return;
        if ((this.right + right) > this.getParent().getWidth()) return;
        if ((this.right + right + this.x) > this.getParent().getWidth()) return; */

        let origRight = this.right;
        this.right += right;
        if (this.right !== origRight && !hush) {
            let dif = this.right - origRight;
            if (dif > 0) {
                this.onLocationRightGrew(this, Math.abs(dif), origRight, this.right);
            } else if (dif < 0) {
                this.onLocationRightShrunk(this, Math.abs(dif), origRight, this.right);
            }
        }

        return this.right;
    }

    getBottom() {
        return this.bottom;
    }

    shiftBottom(bottom, hush = false) {
        /* if ((this.bottom + bottom) < 0) return;
        if ((this.bottom + bottom) > this.getParent().getHeight()) return;
        if ((this.bottom + bottom + this.y) > this.getParent().getHeight()) return; */

        let origBottom = this.bottom;
        this.bottom += bottom;
        if (this.bottom !== origBottom && !hush) {
            let dif = this.bottom - origBottom;
            if (dif > 0) {
                this.onLocationBottomGrew(this, Math.abs(dif), origBottom, this.bottom);
            } else if (dif < 0) {
                this.onLocationBottomShrunk(this, Math.abs(dif), origBottom, this.bottom);
            }
        }

        return this.bottom;
    }

    contains(x, y) {
        let hasX = (this.x >= x && (this.x - this.right) <= x);
        let hasY = (this.y <= y && (this.y + this.bottom) >= y);

        return hasX && hasY;
    }

    collides(x, y, right, bottom) {
        let xCollide = false;
        let yCollide = false;

        if (this.getX() >= x && (this.getX() - this.getRight()) <= x) {
            xCollide = true;
        } else if (x >= this.getX() && (x - right) <= this.getX()) {
            xCollide = true;
        }

        if (this.getY() <= y && (this.getY() + this.getBottom()) >= y) {
            yCollide = true;
        } else if (y <= this.getY() && (y + bottom) >= this.getY()) {
            yCollide = true;
        }

        return xCollide && yCollide;
    }

    isEntirelyWithin(upperLeftX, upperLeftY, lowerRightX, lowerRightY) {
        let withinX = (this.x <= upperLeftX && (this.x - this.right) >= lowerRightX);
        let withinY = (this.y >= upperLeftY && (this.y + this.bottom) <= lowerRightY);

        return withinX && withinY;
    }

    getContent() {
        return this.content;
    }

    setContent(content) {
        this.content = content;
    }

    getType() {
        return this.type;
    }

    setType(contentType) {
        this.type = contentType;
    }

    getSource() {
        return this.source;
    }

    setSource(sourceType) {
        this.source = sourceType;
    }

    getDims() {
        return "coords: {" + this.getX() + "," + this.getY() + "} - dims {" + this.getRight() + "," + this.getBottom() + "}";
    }

    clone() {
        let cloned = new LayoutRegion().initCreate(
            this.getParent(),
            this.getX(),
            this.getY(),
            this.getRight(),
            this.getBottom(),
            this.getParent().onLocationXGrew.bind(this.getParent()),
            this.getParent().onLocationXShrunk.bind(this.getParent()),
            this.getParent().onLocationYGrew.bind(this.getParent()),
            this.getParent().onLocationYShrunk.bind(this.getParent()),
            this.getParent().onLocationRightGrew.bind(this.getParent()),
            this.getParent().onLocationRightShrunk.bind(this.getParent()),
            this.getParent().onLocationBottomGrew.bind(this.getParent()),
            this.getParent().onLocationBottomShrunk.bind(this.getParent()));

        cloned.setId(this.getId());
        if (this.getContent() !== undefined) {
            cloned.setContent(this.getContent().clone !== undefined ? this.getContent().clone() : this.getContent());
        }
        cloned.setType(this.getType());
        cloned.setSource(this.getSource());

        return cloned;
    }

    getJson() {
        return {
            ...super.getJson(),
            x: this.x,
            y: this.y,
            bottom: this.bottom,
            right: this.right,
            content: this.content,
            type: this.type,
            source: this.source,
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "LayoutRegion";
    }
}