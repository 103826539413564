import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Switch } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { randomId } from '@mui/x-data-grid-generator';

import { QuizzicalContext, RunStates, ScreenModes } from '../../../Context/QuizzicalContext';
import { Countries } from '../../../Classes/Personnel/AddressMeta';
import FieldContainer from '../../Forms/FieldContainer';
import FEH_Form from '../../Forms/FEH_Form';

export default function FEH_AddressEditor(props) {
    const state = props.state;
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [defaultFieldSet, setDefaultFieldSet] = React.useState(randomId());

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }
    }, [props]);

    const updateFocus = () => {
        focus.setStreet(document.getElementById("address-citystate").value);
        focus.setCityState(document.getElementById("address-citystate").value);
        focus.setPostalCode(document.getElementById("address-postalCode").value);
        focus.setTitle(document.getElementById("address-title").value);
        focus.setDescription(document.getElementById("address-description").value);
        focus.setPublicFacing(document.getElementById("address-publicFacing").checked);

        return focus;
    }

    /* const onEdited = (event) => {
        if (event.target.id === "address-street") {
            focus.setStreet(event.target.value);
        } else if (event.target.id === "address-citystate") {
            focus.setCityState(event.target.value);
        } else if (event.target.id === "address-postalCode") {
            focus.setPostalCode(event.target.value);
        } else if (event.target.id === "address-title") {
            focus.setTitle(event.target.value);
        } else if (event.target.id === "address-description") {
            focus.setDescription(event.target.value);
        } else if (event.target.id === "address-publicFacing") {
            focus.setPublicFacing(event.target.checked);
        }
    } */

    const onCountryChanged = (event) => {
        focus.setCountry(event.target.value);
    }

    if (isReadOnly) {
        return ( // readonly
            <Box
                sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                }}>
                <Grid>
                    <Grid>
                        <Stack container direction="row" sx={{ alignItems: 'center' }} spacing={1}>
                            <Typography color="inherit" variant="caption" component="div">{focus.getTitle()}</Typography>
                        </Stack>
                        {focus.getDescription() !== undefined ? <Stack>
                            <Typography color="inherit" variant="caption" component="div">
                                <pre style={{ fontFamily: 'inherit' }}>
                                    {focus.getDescription() || "No Description"}
                                </pre>
                            </Typography>
                        </Stack> : ""}
                    </Grid>
                    <Grid xs={12}>
                        <Typography color="inherit" variant="caption" component="div">{focus.getCountry()}</Typography>
                        <Typography color="inherit" variant="caption" component="div">{focus.getStreet()}</Typography>
                        <Stack direction="row" container spacing={1}>
                            <Typography color="inherit" variant="caption" component="div">{focus.getCityState()}</Typography>
                            <Typography color="inherit" variant="caption" component="div">{focus.getPostalCode()}</Typography>
                        </Stack>
                    </Grid>
                    <Grid>
                        <Stack direction="row" sx={{ alignItems: 'center' }}>
                            <Typography color="inherit" variant="body" component="div">Public Facing</Typography>
                            <Typography color="inherit" variant="caption" component="div">{focus.getPublicFacing() ? "Yes" : "No"}</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        );
    } else {
        let countryListing = Countries.map((c, i) => {
            return <MenuItem key={"countryItem-" + i} value={c.name}>{c.name}</MenuItem>;
        });

        return ( // editable
            <FEH_Form
                height="88vH"
                formId="AddressForm"
                type="Address"
                getInstance={updateFocus}
                onClose={props.onClose}
                onConfirm={props.onConfirm}
                onReportMessages={props.onReportMessages}>
                <Stack spacing={2}>
                    <FieldContainer
                        display="flow"
                        fieldSet={defaultFieldSet}
                        label="Title"
                        help="The address's title"
                        fullWidth
                        required>
                        <TextField
                            disabled={isReadOnly}
                            id="address-title"
                            fullWidth
                            required
                            defaultValue={focus.getTitle()} />
                    </FieldContainer>
                    <FieldContainer
                        display="flow"
                        fieldSet={defaultFieldSet}
                        label="Description"
                        help="The address's description"
                        fullWidth>
                        <TextField
                            disabled={isReadOnly}
                            id="address-description"
                            fullWidth
                            multiline
                            rows={4}
                            defaultValue={focus.getDescription()} />
                    </FieldContainer>
                    <FieldContainer
                        ignoreLabelWidth
                        display="flow"
                        fieldSet='Address_Segments_1'
                        label="Street"
                        help="The address's street"
                        required>
                        <TextField
                            sx={{ minWidth: 600 }}
                            disabled={isReadOnly}
                            id="address-street"
                            required
                            defaultValue={focus.getStreet()} />
                    </FieldContainer>
                    <Stack direction="row" spacing={1}>
                        <FieldContainer
                            ignoreLabelWidth
                            display="flow"
                            fieldSet='Address_Segments_2'
                            label="City, State, District"
                            help="The address's city, state, or district"
                            required>
                            <TextField
                                sx={{ minWidth: 300 }}
                                disabled={isReadOnly}
                                id="address-citystate"
                                required
                                defaultValue={focus.getCityState()} />
                        </FieldContainer>
                        <FieldContainer
                            ignoreLabelWidth
                            display="flow"
                            fieldSet='Address_Segments_3'
                            label="Postal Code"
                            help="The address's postal or zip code"
                            required>
                            <TextField
                                sx={{ minWidth: 150 }}
                                disabled={isReadOnly}
                                id="address-postalCode"
                                required
                                defaultValue={focus.getPostalCode()} />
                        </FieldContainer>
                    </Stack>
                    <FieldContainer
                        ignoreLabelWidth
                        display="flow"
                        fieldSet='Address_Segments_4'
                        label="Country"
                        help="The country where the address resides"
                        required>
                        <Select
                            sx={{ minWidth: 300 }}
                            required
                            id="address-country"
                            value={focus.getCountry()}
                            onChange={onCountryChanged}>
                            {countryListing}
                        </Select>
                    </FieldContainer>
                    <FieldContainer
                        fieldSet='PublicFacing_Address'
                        swap
                        label="Public Facing"
                        help="Whether this address is available to the public">
                        <Switch id="address-publicFacing" defaultChecked={focus.getPublicFacing()} />
                    </FieldContainer>
                </Stack>
            </FEH_Form>
        );
    }
}