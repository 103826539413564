import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmationDialog(props) {
    const [isOpen, setIsOpen] = React.useState(props.isOpen);
    const [bucket, setBucket] = React.useState(props.bucket);

    React.useEffect((orig) => {
        if (isOpen !== props.isOpen && 
            props.isOpen !== undefined && 
            props.isOpen !== null) {
            setIsOpen(props.isOpen);
        }

        if (bucket !== props.bucket) {
            setBucket(props.bucket);
        }
    }, [props]);

    const handleClose = (event) => {
        props.onCancel(event, props.bucket);
    };

    const handleConfirm = (event) => {
        props.onConfirm(event, props.bucket);
    }

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>{props.message}</DialogContent>
                <DialogActions>
                    {props.cancelText !== undefined ? <Button color="inherit" onClick={handleClose}>{props.cancelText}</Button> : ''}
                    {props.confirmText !== undefined ? <Button color="inherit" onClick={handleConfirm}>{props.confirmText}</Button> : ''}
                </DialogActions>
            </Dialog>
        </div >
    );
}