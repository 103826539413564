import { HierarchicalRootBase, HierarchicalItemTypes } from './BaseClassHierarchy';
import QuizIcon from '@mui/icons-material/Quiz';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import Section from './Sections';
import { whereSelected } from './SelectImplementation';
import { StepResult } from './Stepping';
import { shuffle } from '../Libraries/Ordering';

///
///  Assignment -> Section -> Subject -> Question
///
export default class Assignment extends HierarchicalRootBase {
    constructor() {
        super();
    }

    initCreate(icon, selectedIcon, shuffled, title, description, topics) {
        super.initCreate(icon, selectedIcon, topics, HierarchicalItemTypes.Assignments, title, description, shuffled);

        return this;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);

        this.icon = <QuizOutlinedIcon />;
        this.selectedIcon = <QuizIcon />;

        this.children = struct.children.map((section, i) => {
            return new Section().initJson(this, section);
        });

        return this;
    }

    static getDefault(title, description) {
        return new Assignment().initCreate(<QuizOutlinedIcon />, <QuizIcon />, false, title, description, []);
    }

    clone(onlySelected = undefined, stripEmptyChildren = false) {
        let clone = new Assignment().initCreate(this.icon, this.selectedIcon, this.shuffled, this.title, this.description, [...this.topics]);
        clone.setId(this.getId());

        let clonedChildren = whereSelected(this.getChildren(), onlySelected).map((o, i) => {
            return o.clone(onlySelected, stripEmptyChildren);
        }).filter((o) => {
            let result = true;
            if (stripEmptyChildren === true) {
                if (o.isEmpty !== undefined) {
                    result = o.isEmpty() === false;
                }
            }

            return result;
        });
        clone.setChildren(clonedChildren);

        return clone;
    }

    getJson() {
        return {
            ...super.getJson(),
            _type: this.constructor.ClassName(),
        };
    }

    initStep(state) {
        this.current = 0;
        if (this.isShuffled()) {
            this.working = shuffle([...this.children]);
        } else {
            this.working = [...this.children];
        }

        for (let i = 0; i < this.working.length; i++) {
            this.working[i].initStep(state);
        }
    }

    getCurrent() {
        return this.working[this.current].getCurrent();
    }

    step(executionIndex) {
        let outcome = this.working[this.current].step(executionIndex);

        if (outcome === StepResult.Stepped) {
            this.current++;
            if (this.current >= this.working.length) {
                this.current = 0;
                if (this.isShuffled()) {
                    shuffle(this.working);
                }

                outcome = StepResult.Stepped;
            } else {
                outcome = StepResult.SteppedWithMore;
            }
        }

        return outcome;
    }

    update(executionIndex) {
        this.working[this.current].update(executionIndex);
    }

    static ClassName() {
        return "Assignment";
    }
}