import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Switch } from '@mui/material';
import { randomId } from '@mui/x-data-grid-generator';
import Collapse from '@mui/material/Collapse';

import TopicsManager from '../../TopicsManager';
import GenericList from '../../Lists/GenericList';
import Section from '../../../Classes/Sections';
import ArbitraryTopicSetListing from '../../ArbitraryTopicSetListing';
import { AdministrationModes } from '../../../Context/QuizzicalContext';

export default function FEH_PendingImport(props) {
    const [focus, setFocus] = React.useState(props.focus);
    const [defaultFieldSet, setDefaultFieldSet] = React.useState(randomId());

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }
    }, [props]);

    const getAdministrationMode = () => {
        let outcome = undefined;
        switch (focus.getAdministrationMode()) {
            case AdministrationModes.Normal:
                outcome = "Normal";
                break;
            case AdministrationModes.Exam:
                outcome = "Exam Mode";
                break;
            case AdministrationModes.Practice:
                outcome = "Practice Mode";
                break;
        }

        return outcome;
    }

    if (focus === undefined) {
        return (<div />);
    }

    let details = focus.obj.Settings !== undefined ? [
        <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
            <Typography color="inherit" variant="body" component="div">Default Duration:</Typography>
            <Typography color="inherit" variant="caption" component="div">{focus.obj.Settings.getDefaultDuration()}</Typography>
        </Stack>,
        <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
            <Typography color="inherit" variant="body" component="div">Default Dwell:</Typography>
            <Typography color="inherit" variant="caption" component="div">{focus.obj.Settings.getDefaultDwell()}</Typography>
        </Stack>,
        <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
            <Typography color="inherit" variant="body" component="div">Default Export Timestamp:</Typography>
            <Typography color="inherit" variant="caption" component="div">{focus.obj.Settings.getDefaultExportTimestamp()}</Typography>
        </Stack>,
        <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
            <Typography color="inherit" variant="body" component="div">Global Duration Handicap Modifier:</Typography>
            <Typography color="inherit" variant="caption" component="div">{focus.obj.Settings.getGlobalDurationHandicapModifier()}</Typography>
        </Stack>,
        <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
            <Typography color="inherit" variant="body" component="div">Global Dwell Handicap Modifier:</Typography>
            <Typography color="inherit" variant="caption" component="div">{focus.obj.Settings.getGlobalDwellHandicapModifier()}</Typography>
        </Stack>,
    ] : [];

    // this control is always read only
    return (
        <Box
            sx={{
                paddingTop: 2,
                paddingLeft: 2,
                paddingRight: 2,
                bgcolor: (theme) =>
                    theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                color: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                border: '1px solid',
                borderColor: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                borderRadius: 2,
                fontSize: '0.875rem',
                fontWeight: '700',
            }}>
            <Grid spacing={2}>
                <Stack>
                    {details}
                    <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
                        <Typography color="inherit" variant="body" component="div">Content Type:</Typography>
                        <Typography color="inherit" variant="caption" component="div">{getAdministrationMode()}</Typography>
                    </Stack>
                    <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
                        <Typography color="inherit" variant="body" component="div">Version:</Typography>
                        <Typography color="inherit" variant="caption" component="div">{focus.obj.Version === undefined ? "Unversioned" : focus.obj.Version}</Typography>
                    </Stack>
                </Stack>
                <ArbitraryTopicSetListing topics={focus.obj.Topics} />
                {focus.obj.Examinations.Assignments.length > 0 ?
                    <GenericList
                        propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                        defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                        state={focus.obj}
                        rows={6}
                        isReadOnly={true}
                        label="Assignments"
                        isCollapsable={true}
                        getTargetItems={() => {
                            return focus.obj.Examinations.Assignments;
                        }}
                        setTargetItems={(items) => {
                            // intentionally do nothing (it's readonly anyway)
                        }}
                        getDefaultItem={() => {
                            return null;
                        }} /> : ''
                }
                {focus.obj.Examinations.Pools.length > 0 ?
                    <GenericList
                        propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                        defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                        state={focus.obj}
                        rows={6}
                        isReadOnly={true}
                        label="Question Pools"
                        isCollapsable={true}
                        getTargetItems={() => {
                            return focus.obj.Examinations.Pools;
                        }}
                        setTargetItems={(items) => {
                            // intentionally do nothing (it's readonly anyway)
                        }}
                        getDefaultItem={() => {
                            return null;
                        }} /> : ''
                }
                {focus.obj.People.length > 0 ?
                    <GenericList
                        propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                        defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                        state={focus.obj}
                        rows={6}
                        isReadOnly={true}
                        label="People"
                        isCollapsable={true}
                        getTargetItems={() => {
                            return focus.obj.People;
                        }}
                        setTargetItems={(items) => {
                            // intentionally do nothing (it's readonly anyway)
                        }}
                        getDefaultItem={() => {
                            return null;
                        }} /> : ''
                }
                {focus.obj.Organizations.length > 0 ?
                    <GenericList
                        propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                        defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                        state={focus.obj}
                        rows={6}
                        isReadOnly={true}
                        label="Organizations"
                        isCollapsable={true}
                        getTargetItems={() => {
                            return focus.obj.Organizations;
                        }}
                        setTargetItems={(items) => {
                            // intentionally do nothing (it's readonly anyway)
                        }}
                        getDefaultItem={() => {
                            return null;
                        }} /> : ''
                }
                {focus.obj.Associations.length > 0 ?
                    <GenericList
                        propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                        defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                        state={focus.obj}
                        rows={6}
                        isReadOnly={true}
                        label="Roles"
                        isCollapsable={true}
                        getTargetItems={() => {
                            return focus.obj.Associations;
                        }}
                        setTargetItems={(items) => {
                            // intentionally do nothing (it's readonly anyway)
                        }}
                        getDefaultItem={() => {
                            return null;
                        }} /> : ''
                }
            </Grid>
        </Box>
    );
}