import InfoIcon from '@mui/icons-material/Info';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import ClassName from './ClassName';

export const MessageSeverities = {
    Information: 0,
    Error: 1,
    Warning: 2,
    Tip: 3,
};

export class FeedbackMessage extends ClassName{
    constructor(text, severity = MessageSeverities.Error) {
        super();
        this.text = text;
        this.severity = severity;
    }

    getText() {
        return this.text;
    }

    getIcon() {
        let icon = <QuestionMarkIcon />;

        switch (this.severity) {
            case MessageSeverities.Information:
                icon = <InfoIcon />
                break;
            case MessageSeverities.Warning:
                icon = <WarningIcon />
                break;
            case MessageSeverities.Error:
                icon = <ErrorIcon />
                break;
            case MessageSeverities.Tip:
                icon = <LiveHelpIcon />
                break;
        }

        return icon;
    }

    static ClassName() {
        return "FeedbackMessage";
    }
}