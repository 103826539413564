import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useEffect } from 'react';

import CancelIcon from '@mui/icons-material/Close';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import WifiFindIcon from '@mui/icons-material/WifiFind';

import { QuizzicalContext } from '../../Context/QuizzicalContext';
import { FillInQuestion, MultipleChoiceQuestion, PoolQuestion } from '../../Classes/Questions';
import { randomId } from '@mui/x-data-grid-generator';
import Subject from '../../Classes/Subjects';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} mountOnEnter unmountOnExit />;
});

export default function CreateQuestionDialog(props) {
    const state = React.useContext(QuizzicalContext);
    const [host, setHost] = React.useState(undefined);
    const [isOpen, setIsOpen] = React.useState(false);
    const [questionType, setQuestionType] = React.useState(-1);

    useEffect(() => {
        if (props.open !== isOpen) {
            setIsOpen(props.open);
        }

        if (props.focus !== host &&
            props.focus !== undefined) {
            setHost(props.focus);
        }
    }, [props]);

    useEffect(() => {
        if (questionType === 0) {// fill in
            setQuestionType(-1);
            props.onClose(getPropagatedQuestion(FillInQuestion.getDefault(host, "Fill In - " + randomId(), state.Mode.DefaultDuration, state.Mode.DefaultDwell, "")));
        } else if (questionType === 1) { // multiple choice
            setQuestionType(-1);
            props.onClose(getPropagatedQuestion(MultipleChoiceQuestion.getDefault(host, "Multiple Choice - " + randomId(), state.Mode.DefaultDuration, state.Mode.DefaultDwell, false)));
        } else if (questionType === 2) { // pool question
            props.onClose(getPropagatedQuestion(PoolQuestion.getDefault(host, "Sourced - " + randomId())));
        }
    }, [questionType]);

    const getPropagatedQuestion = (question) => {
        // when new questions are created, we have to propagate the layout into them if the parent is a Subject
        // but Pool Questions do not have layouts (they pull the layout from the selected question from the target pool)
        if (host.constructor.ClassName() === Subject.ClassName() &&
            host.isLayoutProvider() === true &&
            question.constructor.ClassName() !== PoolQuestion.ClassName()) {
            question.getDescription()[0].copy(host.getDescription());
        }

        return question;
    }

    const handleClose = () => {
        props.onClose(undefined);
    };

    const handleMenuClicked = (selectedQuestionType) => {
        if (selectedQuestionType !== undefined) {
            if (selectedQuestionType === 0 ||
                selectedQuestionType === 1 ||
                selectedQuestionType === 2) {
                setQuestionType(selectedQuestionType);
            }
        }
    }

    var choiceDisplay = (
        <Box sx={{ width: '100%', maxWidth: 360 }}>
            <List subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    Choose a type of question
                </ListSubheader>
            }>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => { handleMenuClicked(0); }}>
                        <ListItemIcon>
                            <FormatPaintIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Add Fill In"
                            secondary="Fill In questions compose the bulk of what users see.  They present a scenario / problem and hide the answer until either going to that phase (via timer) or when the appropriate button is clicked." />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => { handleMenuClicked(1); }}>
                        <ListItemIcon>
                            <FactCheckIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Add Multiple Choice"
                            secondary="Multiple Choice questions display both the question and possible answers simultaneously, but only confirm the correct options when going to the proper phase (via timer) or when the appropriate button is clicked." />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => { handleMenuClicked(2); }}>
                        <ListItemIcon>
                            <WifiFindIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Add Sourced"
                            secondary="Sourced questions display questions they pull from a target pool. (either sequentially or after shuffling)" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    var dialog = (<div />);
    if (host !== undefined && host !== null) {
        dialog = (
            <Dialog
                open={isOpen}
                onClose={handleClose}
                TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <SettingsApplicationsIcon />
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Choose a question type</Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    {choiceDisplay}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus color="inherit" onClick={handleClose}>
                        <Stack direction="row">
                            <CancelIcon />
                            <div />
                            <Typography color="inherit" variant="button" component="div" style={{ textAlign: 'right' }}>Cancel</Typography>
                        </Stack>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (dialog);
}
