import { HierarchicalItemBase, HierarchicalItemTypes }  from './BaseClassHierarchy';
import JavascriptIcon from '@mui/icons-material/Javascript';

///
///  Stores a script for use during assignment execution
///
export default class ScriptItem extends HierarchicalItemBase {
    constructor() {
        super();
    }

    initCreate(parentItem, icon, selectedIcon, title, description, script) {
        super.initCreate(parentItem, icon, selectedIcon, undefined, HierarchicalItemTypes.Script, title, description);

        this.script = script;

        return this;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);
        
        this.icon = <JavascriptIcon />;
        this.selectedIcon = <JavascriptIcon />;

        this.script = struct.script;

        return this;
    }

    static getDefault(parentScriptable, title, description) {
        return new ScriptItem().initCreate(parentScriptable, <JavascriptIcon />, <JavascriptIcon />, title, description);
    }

    setContent(script) {
        this.script = script;
    }

    getContent() {
        return this.script;
    }

    clone() {
        let clone = new ScriptItem().initCreate(this.parent, this.icon, this.selectedIcon, this.title, this.description, this.script);
        clone.setId(this.getId());
        
        return clone;
    }

    getJson() {
        return {
            ...super.getJson(),
            script: this.script,
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "ScriptItem";
    }
}