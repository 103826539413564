import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';

export default function IconSelectionGroup(props) {
    const [direction, setDirection] = React.useState("row");
    const [content, setContent] = React.useState(undefined);

    React.useEffect(() => {
        if (props.direction !== undefined &&
            props.direction !== direction &&
            (props.direction === "row" || props.direction === "column")) {
            setDirection(props.direction);
        }

        if (Array.isArray(props.content)) {
            let result = [];
            props.content.map((o, i) => {
                if (o.label !== undefined && o.icon !== undefined) {
                    result.push({ label: o.label, icon: o.icon });
                }
            });
            setContent(result);
        }
    }, [props]);

    const itemSelected = (event, item) => {
        if (props.onSelection !== undefined) {
            props.onSelection(item);
        }
    }

    let display = <div />;
    if (Array.isArray(content)) {
        let items = content.map((o, i) => {
            return (
                <Tooltip title={o.label}>
                    <Button
                        disabled={o.disabled === true}
                        key={o.label + "-id-" + i}
                        onClick={(e) => { 
                            itemSelected(e, o); 
                            e.preventDefault(); }}>
                        {o.icon}
                    </Button>
                </Tooltip>
            );
        });

        switch (direction) {
            case "row":
                display = (
                    <ButtonGroup
                        size="small"
                        variant='text'
                        color="inherit">
                        {items}
                    </ButtonGroup>
                );
                break;
            case "column":
                display = (
                    <ButtonGroup
                        size="small"
                        orientation='vertical'
                        color="inherit">
                        {items}
                    </ButtonGroup>
                );
                break;
        }
    }
    return display;
}