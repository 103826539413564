import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Switch } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { randomId } from '@mui/x-data-grid-generator';

import { QuizzicalContext, RunStates, ScreenModes } from '../../../Context/QuizzicalContext';
import { AssociationTypes } from '../../../Classes/Personnel/RoleAssociation';
import GenericSelector from '../../GenericSelector';
import FieldContainer from '../../Forms/FieldContainer';
import FEH_Form from '../../Forms/FEH_Form';
import { Person } from '../../../Classes/Personnel/Person';
import { Organization } from '../../../Classes/Personnel/Organization';
import { Course } from '../../../Classes/Personnel/Course';

export default function FEH_AssociationEditor(props) {
    const state = props.state;
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [forceRefresh, setForceRefresh] = React.useState(false);
    const [defaultFieldSet, setDefaultFieldSet] = React.useState(randomId());

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }
    }, [props]);

    const updateFocus = () => {
        return focus;
    }

    const getAssociationTypeLabel = (type) => {
        let result = undefined;

        switch (type) {
            case AssociationTypes.Faculty:
                result = "works for"
                break;
            case AssociationTypes.Instructor:
                result = "teaches"
                break;
            case AssociationTypes.Student:
                result = "enrolled in"
                break;
            case AssociationTypes.None:
                result = "unasigned";
                break;
        }

        return result;
    }

    const getAssociationTypeTarget = (type) => {
        let result = undefined;

        switch (type) {
            case AssociationTypes.Faculty:
                result = Organization.ClassName()
                break;
            case AssociationTypes.Instructor:
                result = Course.ClassName()
                break;
            case AssociationTypes.Student:
                result = Course.ClassName()
                break;
            case AssociationTypes.None:
                result = undefined;
                break;
        }

        return result;
    }

    const handleChange = (event) => {
        if (focus.getType() !== event.target.value) {
            focus.setType(event.target.value);

            setForceRefresh(!forceRefresh);
        }
    };

    if (isReadOnly) {
        return ( // readonly
            <Box
                sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                }}>
                <Stack direction="row" spacing={1}>
                    <GenericSelector
                        query={[]}
                        root={state.People}
                        focus={focus}
                        selectionRetrieval="getWhoId"
                        selectionStorage="setWhoId"
                        label="Who"
                        multiMode={false}
                        selectionType={Person.ClassName()}
                        isReadOnly={isReadOnly} />
                    <Typography color="inherit" variant="body" component="div">{getAssociationTypeLabel(focus.getType())}</Typography>
                    <GenericSelector
                        query={() => {
                            // we want to query orgs and courses at *any* depth
                            // easiest way to do that is recursion
                            // this defines a recursive query that searches for orgs under orgs ad infinium
                            // it searches for courses off of each org

                            // this works because when records are no longer found,
                            // the query will cleanly terminate and return the result

                            let orgNode = {
                                get: 'getChildren',
                                items: [],
                                return: true,
                            };
                            let courseNode = {
                                get: 'getCourses',
                                return: true,
                            };

                            orgNode.items.push(orgNode);
                            if (getAssociationTypeTarget(focus.getType()) === "Course") {
                                orgNode.items.push(courseNode);
                            }

                            let query = [orgNode, courseNode];

                            return query;
                        }}
                        root={state.Organizations}
                        focus={focus}
                        selectionRetrieval="getWhatId"
                        selectionStorage="setWhatId"
                        label="What"
                        multiMode={false}
                        selectionType={getAssociationTypeTarget(focus.getType())}
                        isReadOnly={isReadOnly} />
                </Stack>
            </Box>
        );
    } else {
        let items = Object.keys(AssociationTypes).map((item, i) => {
            return (
                <MenuItem
                    key={'at-' + i}
                    value={AssociationTypes[item]}>
                    {getAssociationTypeLabel(AssociationTypes[item])}
                </MenuItem>
            );
        });

        return ( // editable
            <FEH_Form
                formId="AssociationForm"
                type="RoleAssociation"
                getInstance={updateFocus}
                onClose={props.onClose}
                onConfirm={props.onConfirm}
                onReportMessages={props.onReportMessages}>
                <Stack spacing={1}>
                    <GenericSelector
                        isReadOnly={isReadOnly}
                        query={[]}
                        root={state.People}
                        focus={focus}
                        selectionRetrieval="getWhoId"
                        selectionStorage="setWhoId"
                        label="Who"
                        multiMode={false}
                        selectionType={Person.ClassName()} />
                    <FormControl fullWidth>
                        <InputLabel id="association-nature-label">Nature</InputLabel>
                        <Select
                            labelId="association-nature-label"
                            id="association-nature"
                            value={focus.getType()}
                            label="Nature"
                            onChange={handleChange}>
                            {items}
                        </Select>
                    </FormControl>
                    <GenericSelector
                        cannotQueryMessage={"Please select a relationship object and nature."}
                        query={(() => {
                            // we want to query orgs and courses at *any* depth
                            // easiest way to do that is recursion
                            // this defines a recursive query that searches for orgs under orgs ad infinium
                            // it searches for courses off of each org

                            // this works because when records are no longer found,
                            // the query will cleanly terminate and return the result
                            let query = [];

                            let orgNode = {
                                get: 'getChildren',
                                items: [],
                                return: true,
                            };
                            let courseNode = {
                                get: 'getCourses',
                                return: true,
                            };

                            orgNode.items.push(orgNode);
                            if (getAssociationTypeTarget(focus.getType()) === "Course") {
                                orgNode.items.push(courseNode);

                                query.push(courseNode);
                            }

                            query.unshift(orgNode);

                            return query;
                        })()}
                        root={state.Organizations}
                        focus={focus}
                        selectionRetrieval="getWhatId"
                        selectionStorage="setWhatId"
                        label="What"
                        multiMode={false}
                        selectionType={getAssociationTypeTarget(focus.getType())}
                        isReadOnly={isReadOnly}
                        disabled={getAssociationTypeTarget(focus.getType()) === undefined} />
                </Stack>
            </FEH_Form >
        );
    }
}