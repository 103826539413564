import * as React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import Grid from '@mui/material/Unstable_Grid2';
import { randomId } from '@mui/x-data-grid-generator';

import ClearIcon from '@mui/icons-material/Clear';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import ConfirmationDialog from '../../Screens/Dialogs/ConfirmationDialog';

import TermInsertion from './TermInsertion';
import { LayoutRegion } from '../../Classes/Layout/LayoutRegion';

export default function TextContentEditor(props) {
    const [entity, setEntity] = React.useState(props.entity); // this is the subject / question that this manager ultimately resides on
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [isMouseOver, setIsMouseOver] = React.useState(props.isMouseOver || false);

    const [isDeleteRequested, setIsDeleteRequested] = React.useState(false);
    const textFieldId = "region-script-" + randomId();

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }

        if (props.isMouseOver !== undefined &&
            props.isMouseOver !== null &&
            props.isMouseOver !== isMouseOver) {
            setIsMouseOver(props.isMouseOver);
        }

        if (props.entity !== undefined &&
            props.entity !== null &&
            props.entity !== entity) {
            setEntity(props.entity);
        }
    }, [props]);

    const onEdited = (event) => {
        if (event.target.id === textFieldId) {
            focus.setContent(event.target.value);

            if (props.onNeedRefresh !== undefined) {
                // request a refresh in the parent control
                // this is to sync the containing control and its parent editing screen 
                // with the modifications without redrawing the entire hierarchy
                props.onNeedRefresh();
            }
        }
    }

    let display = (<div />);
    if (focus !== undefined &&
        focus.constructor.ClassName() === LayoutRegion.ClassName()) {
        if (isReadOnly) {
            {/* <Grid
                style={{
                    height: '100%',
                    width: '100%',
                }}>
                    <Grid>
                    <Typography color="inherit" variant="body" component="div">Content</Typography>
                    </Grid>
                    <Grid>
                    <div
                            style={{
                                fontFamily: 'inherit',
                                backgroundColor: 'transparent',
                                height: '100%',
                                width: '100%',
                                overflow: 'scroll',
                                whiteSpace: 'nowrap',
                            }}>
                            {focus.getContent() || "No content"}
                        </div>
                    </Grid>
                </Grid> */}

            display = (
                <Box
                    padding={1}
                    style={{
                        height: '100%',
                        width: '100%',
                    }}>
                    <div
                        style={{
                            overflow: 'scroll',
                            display: 'grid',
                            height: '100%',
                            width: '100%',
                        }}>
                        <pre
                            style={{
                                fontFamily: 'inherit',
                                backgroundColor: 'transparent',
                            }}>
                            {focus.getContent() || "No content"}
                        </pre>
                    </div>
                </Box>
            );
        } else {
            let fabMenu = (
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        position: 'absolute',
                        top: -35,
                        right: 16,
                    }}>
                    <TermInsertion
                        isVisible={isMouseOver}
                        entity={entity}
                        target={textFieldId} />
                </Stack>
            );

            display = (
                <Box
                    sx={{
                        marginTop: '4px',
                        position: 'relative',
                        textAlign: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: 'calc(100% - 6px)',
                    }}>
                    <TextField
                        inputProps={props.dimensions !== undefined ? {
                            style: {
                                minHeight: props.dimensions.height - 40,
                                height: props.dimensions.height - 40,
                                overflow: 'auto',
                            }
                        } : {}}
                        id={textFieldId}
                        fullWidth
                        required
                        multiline={true}
                        label="Content"
                        placeholder="Enter your content"
                        defaultValue={focus.getContent()}
                        onChange={onEdited} />
                    {fabMenu}
                    <ConfirmationDialog
                        bucket={undefined}
                        isOpen={isDeleteRequested}
                        title={"Deletion Confirmation"}
                        message={"Permanently Delete Region?"}
                        cancelText={"No"}
                        confirmText={"Yes"}
                        onCancel={() => { setIsDeleteRequested(false); }}
                        onConfirm={(e) => { props.onRemove(e, focus); }} />
                </Box>
            );
        }
    }

    return display;
}