import { HierarchicalItemBase, HierarchicalItemTypes } from '../BaseClassHierarchy';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { randomId } from '@mui/x-data-grid-generator';

// a human in the system
export class Person extends HierarchicalItemBase {
    constructor() {
        super();
    }

    initCreate(icon, selectedIcon, topics) {
        super.initCreate(undefined, icon, selectedIcon, topics, HierarchicalItemTypes.People);

        this.identityCode = randomId();
        this.age = 0;
        this.firstName = undefined;
        this.lastName = undefined;
        this.middleName = undefined;

        return this;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);

        this.icon = <AccountCircleOutlinedIcon />;
        this.selectedIcon = <AccountCircleIcon />;

        this.identityCode = struct.identityCode;
        this.age = struct.age;
        this.firstName = struct.firstName;
        this.middleName = struct.middleName;
        this.lastName = struct.lastName;

        return this;
    }

    static getDefault() {
        return new Person().initCreate(<AccountCircleOutlinedIcon />, <AccountCircleIcon />, []);
    }

    getTitle(withComma = true) {
        let result = undefined;
        if (this.isMinor()) {
            result = this.getCode();
        } else {
            result = (withComma ? this.getLastName() + ', ' + this.getFirstName() : this.getFirstName() + ' ' + this.getLastName());
        }

        return result;
    }
    setTitle = undefined;
    getDescription = undefined;
    setDescription = undefined;

    isMinor() {
        if (this.age === undefined || isNaN(this.age)) return true;
        return this.age < 18;
    }

    getCode() {
        return this.identityCode;
    }

    setCode(code) {
        let t = this.getTitle();
        this.identityCode = code;

        if (this.title !== t &&
            this.onTitleUpdated !== undefined &&
            typeof this.onTitleUpdated === 'function') {
            this.onTitleUpdated(this, t, this.getTitle());
        }
    }

    getAge() {
        return this.age;
    }

    setAge(age) {
        this.age = parseInt(age + '', 10);

        if (this.isMinor()) {
            this.setFirstName(undefined);
            this.setLastName(undefined);
            this.setMiddleName(undefined);
        }
    }

    getFirstName() {
        return this.firstName;
    }

    setFirstName(firstName) {
        let t = this.getTitle();
        this.firstName = firstName;

        if (this.title !== t &&
            this.onTitleUpdated !== undefined &&
            typeof this.onTitleUpdated === 'function') {
            this.onTitleUpdated(this, t, this.getTitle());
        }
    }

    getLastName() {
        return this.lastName;
    }

    setLastName(lastName) {
        let t = this.getTitle();
        this.lastName = lastName;

        if (this.title !== t &&
            this.onTitleUpdated !== undefined &&
            typeof this.onTitleUpdated === 'function') {
            this.onTitleUpdated(this, t, this.getTitle());
        }
    }

    getMiddleName() {
        return this.middleName;
    }

    setMiddleName(middleName) {
        this.middleName = middleName;
    }

    clone() {
        let clone = new Person().initCreate(this.getIcon(), this.getSelectedIcon(), [...this.getTopics()]);

        clone.setId(this.getId());
        clone.setCode(this.getCode());
        clone.setAge(this.getAge());
        clone.setFirstName(this.getFirstName());
        clone.setLastName(this.getLastName());
        clone.setMiddleName(this.getMiddleName());

        return clone;
    }

    getJson() {
        return {
            ...super.getJson(),
            identityCode: this.identityCode,
            age: this.age,
            firstName: this.firstName,
            middleName: this.middleName,
            lastName: this.lastName,
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "Person";
    }
}