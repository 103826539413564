import React, { useContext, useState } from 'react';

import { QuizzicalContext, ScreenModes } from '../Context/QuizzicalContext';
import GenericList from '../Controls/Lists/GenericList';
import QuestionPool from '../Classes/Pools';
/* import QuestionPoolList from '../Controls/Lists/QuestionPoolList'; */

export default function QuestionPoolScreen(props) {
    const state = useContext(QuizzicalContext);
    /* return (
        <QuestionPoolList isReadOnly={false} />
    ); */

    return (<GenericList
        rows={9}
        state={state}
        isReadOnly={false}
        isCollapsable={false}
        label="Question Pool"
        showHeaderLabel={false}
        getTargetItems={() => {
            return state.Examinations.Pools;
        }}
        setTargetItems={(items) => {
            state.setPools(items);
        }}
        getDefaultItem={() => {
            return QuestionPool.getDefault(undefined, undefined);
        }}
        validPastes={[
            QuestionPool.ClassName(),
        ]} />);
}