import * as React from 'react';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import { Checkbox } from '@mui/material';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';

import TopicIcon from '@mui/icons-material/Topic';
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

import Pluralize from 'pluralize';

import { QuizzicalContext, RunStates, ScreenModes } from '../Context/QuizzicalContext';
import { searchTopicsByFullLabel, Topic } from '../Classes/Topics';
import { OutlinedFlagRounded } from '@mui/icons-material';
import HelpIcon from './Forms/HelpIcon';
import TopicEditor from './Editors/Assignments/TopicEditor';

export default function TopicsManager(props) {
    const state = React.useContext(QuizzicalContext);
    const [mainOpen, setMainOpen] = React.useState(true);
    const [addOpen, setAddOpen] = React.useState(false);
    const [focus, setFocus] = React.useState(undefined);
    const [isReadOnly, setIsReadOnly] = React.useState(undefined);
    const [expanders, setExpanders] = React.useState({});
    const [selecteds, setSelecteds] = React.useState({});
    const [forceRefresh, setForceRefresh] = React.useState(false);
    const [editTopicTarget, setEditTopicTarget] = React.useState(undefined);
    const [IndentationDepth, setIndentationDepth] = React.useState(4);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const onPopHandleDefined = (node) => {
        if (node !== null &&
            node !== undefined) {
            setAnchorEl(node);
        }
    }

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }

        if (props.indent !== undefined &&
            props.indent !== null &&
            props.indent !== IndentationDepth) {
            setIndentationDepth(props.indent);
        }
    }, [props]);

    const buildExpanderTracking = (topics, target) => {
        let changed = false;
        if (Array.isArray(topics)) { // an array of topics
            for (var i = 0; i < topics.length; i++) {
                changed = buildExpanderTracking(topics[i], target) || changed;
            }
        } else {
            let updateRequired = target[topics.getFullLabel()] === undefined;
            if (updateRequired) {
                target[topics.getFullLabel()] = false;
                changed = true;
            }

            for (var i = 0; i < topics.getSubtopics().length; i++) {
                changed = buildExpanderTracking(topics.getSubtopics()[i], target) || changed;
            }
        }

        return changed;
    }

    const buildSelectionTracking = (topics, target, canDefaultActive) => {
        let changed = false;
        if (Array.isArray(topics)) { // an array of topics
            for (var i = 0; i < topics.length; i++) {
                changed = buildSelectionTracking(topics[i], target, canDefaultActive) || changed;
            }
        } else {
            let selectedValue = canDefaultActive && (focus.getTopics().includes(topics.getFullLabel()));
            let inheritedValue = focus.getChildTopics !== undefined ?
                focus.getChildTopics(focus.getTopics()).includes(topics.getFullLabel()) :
                false;
            let updateRequired =
                (target[topics.getFullLabel()] === undefined) ||
                (selectedValue !== target[topics.getFullLabel()].selected) ||
                (inheritedValue !== target[topics.getFullLabel()].inherited);
            if (updateRequired) {
                target[topics.getFullLabel()] = getSelectStateTracker(selectedValue, inheritedValue);
                changed = true;
            }

            for (var i = 0; i < topics.getSubtopics().length; i++) {
                changed = buildSelectionTracking(topics.getSubtopics()[i], target, canDefaultActive) || changed;
            }
        }

        return changed;
    }

    const getSelectStateTracker = (selected, inherited) => {
        return {
            selected: selected,
            inherited: inherited
        };
    }

    const updateTrackers = () => {
        if (focus !== undefined) {
            // ensure that the use state contains an expansion tracker for each existing topic
            var changed = buildExpanderTracking(state.Topics, expanders, false);
            if (changed) {
                setExpanders(expanders);
            }

            // ensure that the use state contains a selection tracker for each existing topic
            changed = buildSelectionTracking(state.Topics, selecteds, true);
            if (changed) {
                setSelecteds(selecteds);
            }
        }
    }

    const handleExpand = (event, o) => {
        var expndrs = { ...expanders };
        expndrs[o.getFullLabel()] = !expndrs[o.getFullLabel()];

        setExpanders(expndrs);
    }

    const handleSelect = (event, o) => {
        var slctds = { ...selecteds };
        slctds[o.getFullLabel()] = getSelectStateTracker(
            slctds[o.getFullLabel()] === undefined ? true : !slctds[o.getFullLabel()].selected,
            slctds[o.getFullLabel()] === undefined ? false : slctds[o.getFullLabel()].inherited);

        setSelecteds(slctds);

        // update the topics on the focus
        let cloned = [...focus.getTopics()];
        if (slctds[o.getFullLabel()].selected === true) {
            cloned.push(o.getFullLabel());
        } else {
            let index = cloned.indexOf(o.getFullLabel());
            if (index > -1) {
                cloned.splice(index, 1);
            }
        }

        focus.setTopics(cloned);
        setForceRefresh(!forceRefresh);
    }

    const handleClick = (event) => {
        if (addOpen === true) {
            setAddOpen(false);
        }
        setMainOpen(!mainOpen);
        setEditTopicTarget(undefined);
    };

    const handleRemoveTopic = (event, o) => {
        var slctds = { ...selecteds };
        slctds[o] = getSelectStateTracker(
            slctds[o] === undefined ? false : !slctds[o].selected,
            slctds[o] === undefined ? false : slctds[o].inherited);

        setSelecteds(slctds);

        let cloned = [...focus.getTopics()];
        let index = cloned.indexOf(o);
        if (index > -1) {
            cloned.splice(index, 1);
        }

        focus.setTopics(cloned);
        setForceRefresh(!forceRefresh);
    }

    const handleAddTopic = (event, o) => {
        setMainOpen(false);
        setAddOpen(true);
    }

    const getLabel = () => {
        let result = 'Topic';
        if (props.topic !== undefined) {
            result = props.topic;
        }

        return result;
    }

    const getIndentation = (topic) => {
        let getDistanceToRoot = (topic) => {
            let t = topic;
            let r = 0;
            while (t.getParentTopic() !== undefined && t.getParentTopic() !== null) {
                t = t.getParentTopic();
                r++;
            }

            return r;
        }

        let count = getDistanceToRoot(topic);
        let indent = ' '.repeat(parseInt(IndentationDepth + '', 10)).repeat(count);
        return indent;
    }

    const getAddNewButton = (topic) => {
        if (isReadOnly) return '';
        return (
            <Tooltip title={"Create a new " + getLabel().toLowerCase() + " under this one"}>
                <IconButton
                    sx={{
                        textTransform: 'none'
                    }}
                    key={"new-topic-item"}
                    aria-describedby={"clicky"}
                    onClick={(e) => { setEditTopicTarget(topic); }}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
        );
    }

    const getHelpIcon = (topic) => {
        if (isReadOnly) return '';
        return (
            <HelpIcon help={topic.getDescription()} />
        );
    }

    const onConfirmTopicAdded = (host, topic) => {
        // we successfully created a topic
        host.getSubtopics().push(topic);
        topic.setParentTopic(host);

        props.onReportMessages([]);
        state.setTopics([...state.Topics]);
        setEditTopicTarget(undefined);
    }

    if (focus === undefined) return (<div />);

    updateTrackers();

    const renderList = (topics) => {
        let result = null;
        if (Array.isArray(topics)) { // array of topics
            result = topics.map((topic, i) => renderList(topic));
        } else if (topics.getSubtopics().length > 0) {  // single topic with children
            result = (
                <div key={topics.getId() + "-0"}>
                    <ListItemButton key={topics.getId() + "-1"} onClick={handleExpand.bind("", -1, topics)}>
                        <pre>{getIndentation(topics)}</pre>
                        {topics.canSelect() === true ? (
                            <Checkbox
                                key={topics.getId() + "-2"}
                                checked={selecteds[topics.getFullLabel()] !== undefined && (selecteds[topics.getFullLabel()].selected || selecteds[topics.getFullLabel()].inherited)}
                                onChange={(e) => { handleSelect(e, topics); }}
                                onClick={(e) => { e.stopPropagation(); }}
                                icon={<TopicOutlinedIcon />}
                                checkedIcon={selecteds[topics.getFullLabel()] === undefined ? <TopicIcon /> : (selecteds[topics.getFullLabel()].selected ? <TopicIcon /> : (selecteds[topics.getFullLabel()].inherited ? <TopicIcon color="secondary" /> : <TopicIcon />))} />
                        ) :
                            (<IconButton key={topics.getId() + "-3"} disabled><TopicIcon /></IconButton>)}
                        <ListItemText key={topics.getId() + "-4"} primary={topics.getSimpleLabel()} />
                        {getHelpIcon(topics)}
                        {getAddNewButton(topics)}
                        {expanders[topics.getFullLabel()] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse key={"collapse" + topics.getId()} component="li" timeout="auto" in={expanders[topics.getFullLabel()]} unmountOnExit>
                        <Paper>
                            {Array.isArray(topics.getSubtopics())
                                ? topics.getSubtopics().map((topic) => renderList(topic))
                                : null}
                        </Paper>
                    </Collapse>
                </div>
            );
        } else { // empty single topic
            result = (
                <ListItemButton key={topics.getId() + "-0"}>
                    <pre>{getIndentation(topics)}</pre>
                    {topics.canSelect() === true ? (
                        <Checkbox
                            key={topics.getId() + "-1"}
                            checked={selecteds[topics.getFullLabel()] !== undefined && (selecteds[topics.getFullLabel()].selected || selecteds[topics.getFullLabel()].inherited)}
                            onChange={(e) => { handleSelect(e, topics); }}
                            onClick={(e) => { e.stopPropagation(); }}
                            icon={<TopicOutlinedIcon />}
                            checkedIcon={selecteds[topics.getFullLabel()] === undefined ? <TopicIcon /> : (selecteds[topics.getFullLabel()].selected ? <TopicIcon /> : (selecteds[topics.getFullLabel()].inherited ? <TopicIcon color="secondary" /> : <TopicIcon />))} />
                    ) :
                        (<IconButton key={topics.getId() + "-2"} disabled><TopicIcon /></IconButton>)}
                    <ListItemText key={topics.getId() + "-3"} primary={topics.getSimpleLabel()} />
                    {getHelpIcon(topics)}
                    {getAddNewButton(topics)}
                </ListItemButton>
            );
        }

        return result;
    }

    // build the item list
    let listing = [(<div />)];
    if (focus !== undefined) {
        if (focus.getChildTopics !== undefined) {
            listing = focus.getChildTopics(focus.getTopics()).map((topicId, i) => {
                let topic = searchTopicsByFullLabel(state.Topics, topicId);
                if (topic !== undefined) {
                    return (
                        <Tooltip
                            title={<span style={{ whiteSpace: 'pre-line' }}>{topic.getDescription() + "\n\n" + "This " + getLabel().toLowerCase() + " was inherited."}</span>}>
                            <Button
                                sx={{
                                    textTransform: 'none'
                                }}
                                key={"topic-item-" + i}
                                aria-describedby={"clicky"}
                                variant="outlined"
                                color='secondary'>
                                <TopicIcon />&nbsp;&nbsp;
                                <Typography color="inherit" variant="inherit" component="div" style={{ textAlign: 'left' }}>{topic.getSimpleLabel()}</Typography>
                            </Button>
                        </Tooltip>
                    );
                }
            });
        }
        let startingLength = listing.length;
        listing = listing.concat(focus.getTopics().map((topicId, i) => {
            let topic = searchTopicsByFullLabel(state.Topics, topicId);
            if (topic !== undefined) {
                if (isReadOnly) {
                    return (
                        <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{topic.getDescription()}</span>}>
                            <Button
                                sx={{
                                    textTransform: 'none'
                                }}
                                key={"topic-item-" + (i + startingLength)}
                                aria-describedby={"clicky"}
                                variant="outlined"
                                color='inherit'>
                                <TopicIcon fontSize='small' />&nbsp;&nbsp;
                                <Typography color="inherit" variant="inherit" component="div" style={{ textAlign: 'left' }}>{topic.getSimpleLabel()}</Typography>
                            </Button>
                        </Tooltip>
                    );
                } else {
                    return (
                        <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{topic.getDescription()}</span>}>
                            <Button
                                sx={{
                                    textTransform: 'none'
                                }}
                                key={"topic-item-" + (i + startingLength)}
                                onClick={(event) => { handleRemoveTopic(event, topicId) }}
                                aria-describedby={"clicky"}
                                variant="outlined"
                                color='inherit'>
                                <TopicIcon fontSize='small' />&nbsp;&nbsp;
                                <Typography color="inherit" variant="inherit" component="div" style={{ textAlign: 'left' }}>{topic.getSimpleLabel()}</Typography>&nbsp;&nbsp;
                                <ClearIcon fontSize='small' />
                            </Button>
                        </Tooltip>
                    );
                }
            }
        }));
    }
    if (!isReadOnly) {
        listing.push(
            <Tooltip title={"Associate a new " + getLabel().toLowerCase() + "."}>
                <Button
                    sx={{
                        textTransform: 'none'
                    }}
                    key={"topic-item-" + listing.length}
                    aria-describedby={"clicky"}
                    variant="text"
                    color='inherit'
                    onClick={(event) => { handleAddTopic(event, undefined) }}>
                    <AddIcon fontSize='small' />&nbsp;&nbsp;
                    <Typography color="inherit" variant="inherit" component="div" style={{ textAlign: 'left' }}>Add {Pluralize(getLabel(), 1)}</Typography>
                </Button>
            </Tooltip>
        );

        let lcSubjects = Pluralize(getLabel(), 3).toLowerCase();
        let lcSubject = Pluralize(getLabel(), 1).toLowerCase();
        listing.push(
            <HelpIcon
                key="topicmanager-help-icon"
                help={
                    <Stack spacing={1}>
                        <Typography variant='inherit'>Allows the addition of {lcSubjects}.</Typography>
                        <Typography variant='inherit'>{Pluralize(getLabel(), 3)} bubble up through the hierarchy.  That is, parent entities will share any {lcSubjects} added by children.</Typography>
                        <Stack spacing={1} direction="row"><TopicIcon color="inherit" fontSize='small' /><Typography variant='inherit'> is through association.</Typography></Stack>
                        <Stack spacing={1} direction="row"><TopicIcon color="secondary" fontSize='small' /><Typography variant='inherit'> is through hierarchical bubbling.</Typography></Stack>
                        <Typography variant='inherit'>Click the Add button to add a new {lcSubject}.  Click an associated {lcSubject} to remove it.</Typography>
                        <Typography variant='inherit'>Note that bubbled {lcSubjects} must be removed from their source, rather than any inheriters.</Typography>
                    </Stack>} />
        );
    }

    let output = undefined;
    if (isReadOnly) {
        output = (
            <Box
                elevation={3}
                key={"readonly-root"}
                sx={{
                    width: '100%',
                    maxheight: 400,
                    overflow: 'auto'
                }}>
                {listing}
            </Box>
        );
    } else {
        output = (
            <div key={"topicControl-root"}>
                <Box
                    ref={onPopHandleDefined}
                    elevation={3}
                    key={"interactive-root"}
                    sx={{
                        width: '100%',
                        maxHeight: 350,
                        overflow: 'auto'
                    }}>
                    {listing}
                </Box>
                <Popover
                    open={addOpen}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={handleClick}>
                    <Stack>
                        <Collapse in={editTopicTarget !== undefined} unmountOnExit>
                            {
                                isReadOnly || editTopicTarget === undefined ? '' :
                                    <TopicEditor
                                        onReportMessages={props.onReportMessages}
                                        isRoot={editTopicTarget.getParentTopic() === undefined ||
                                            editTopicTarget.getParentTopic() === null}
                                        onConfirm={onConfirmTopicAdded}
                                        onCancel={() => {
                                            setEditTopicTarget(undefined);
                                        }}
                                        target={editTopicTarget} />
                            }
                        </Collapse>
                        <Collapse in={editTopicTarget === undefined}>
                            <Box
                                elevation={3}
                                key={"interactive-root"}
                                sx={{
                                    width: '50vW',
                                    maxHeight: 350,
                                    overflow: 'auto'
                                }}>
                                <List
                                    sx={{ width: '100%' }}
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                    subheader={
                                        <ListSubheader component="div" id="topic-list-subheader">
                                            <Stack direction='row' padding={0} margin={0}>
                                                <div style={{ flexGrow: 1 }}>
                                                    {props.directions !== undefined ? props.directions : 'Please select ' + Pluralize(getLabel(), 3)}
                                                </div>
                                                <Button onClick={handleClick}><ClearIcon fontSize='small' color="inherit" /></Button>
                                            </Stack>
                                        </ListSubheader>}>
                                    {renderList(state.Topics)}
                                </List>
                            </Box>
                        </Collapse>
                    </Stack>

                </Popover>
            </div>
        );
    }

    return output;
}
