import React, { useContext } from 'react';
/* import RootOrganizationList from '../Controls/Lists/RootOrganizationList'; */
import GenericList from '../Controls/Lists/GenericList';
import { Organization } from '../Classes/Personnel/Organization';

import { QuizzicalContext, ScreenModes } from '../Context/QuizzicalContext';

export default function OrganizationScreen(props) {
    const state = useContext(QuizzicalContext);

    /* return (
        <RootOrganizationList isReadOnly={false} />
    ); */

    return (
        <GenericList
            rows={9}
            state={state}
            isReadOnly={false}
            isCollapsable={false}
            label="Organization"
            showHeaderLabel={false}
            getTargetItems={() => {
                return state.Organizations;
            }}
            setTargetItems={(items) => {
                state.setOrganizations(items);
            }}
            getDefaultItem={() => {
                return Organization.getDefault(undefined, undefined, undefined);
            }}
            validPastes={[
                Organization.ClassName(),
            ]} />);
}