import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Switch } from '@mui/material';
import { randomId } from '@mui/x-data-grid-generator';

import { QuizzicalContext, RunStates, ScreenModes } from '../../../Context/QuizzicalContext';
import TopicsManager from '../../TopicsManager';
import RegionLayoutManagementDialog from '../../../Screens/Dialogs/RegionLayoutManagementDialog';
import RegionLayoutEditor from '../../ContentEditor/RegionLayoutEditor';
import GenericList from '../../Lists/GenericList';
import GenericStorageManagerList from '../../Lists/GenericStorageManagerList';
import FieldContainer from '../../Forms/FieldContainer';
import FEH_Form from '../../Forms/FEH_Form';
import { StorageItem } from '../../../Classes/StorageManager';
import { isImmediateChild } from '../../../Libraries/generalUtility';

export default function FEH_Parameter_RandomIntegerEditor(props) {
    const state = props.state;
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [defaultFieldSet, setDefaultFieldSet] = React.useState(randomId());

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }
    }, [props]);

    const updateFocus = () => {
        focus.setName(document.getElementById("randInt-name").value);
        focus.setMaximum(+document.getElementById("randInt-maximum").value);
        focus.setMinimum(+document.getElementById("randInt-minimum").value);

        return focus;
    }

    if (isReadOnly) {
        return ( // readonly
            <Box
                sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                }}>
                <Grid>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Typography color="inherit" variant="body" component="div">Name:&nbsp;&nbsp;&nbsp;</Typography>
                        <Typography color="inherit" variant="caption" component="div">{focus.getName()}</Typography>
                    </Stack>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Stack direction="row">
                            <Typography color="inherit" variant="body" component="div">Maximum:&nbsp;&nbsp;&nbsp;</Typography>
                            <Typography color="inherit" variant="caption" component="div">{focus.getMaximum()}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Stack direction="row">
                            <Typography color="inherit" variant="body" component="div">Minimum:&nbsp;&nbsp;&nbsp;</Typography>
                            <Typography color="inherit" variant="caption" component="div">{focus.getMinimum()}</Typography>
                        </Stack>
                    </Stack>
                </Grid>
            </Box>
        );
    } else {
        return ( // editable
            <FEH_Form
                height="88vH"
                formId="ParameterIntegerRandomized"
                type="RandomizedIntegerParameter"
                getInstance={updateFocus}
                onClose={props.onClose}
                onConfirm={props.onConfirm}
                onReportMessages={props.onReportMessages}>
                <Stack spacing={2}>
                    <FieldContainer
                        display="flow"
                        fieldSet={defaultFieldSet}
                        label="Name"
                        help="The parameter's name"
                        fullWidth
                        required>
                        <TextField
                            disabled={isReadOnly}
                            id="randInt-name"
                            fullWidth
                            required
                            label="Name"
                            defaultValue={focus.getName()} />
                    </FieldContainer>
                    <FieldContainer
                        display="flow"
                        fieldSet={defaultFieldSet}
                        label="Maximum"
                        help="The maximum possible value"
                        fullWidth
                        required>
                        <TextField
                            disabled={isReadOnly}
                            id="randInt-maximum"
                            type="number"
                            label="Maximum"
                            defaultValue={focus.getMaximum()} />
                    </FieldContainer>
                    <FieldContainer
                        display="flow"
                        fieldSet={defaultFieldSet}
                        label="Minimum"
                        help="The minimum possible value"
                        fullWidth
                        required>
                        <TextField
                            disabled={isReadOnly}
                            id="randInt-minimum"
                            type="number"
                            label="Minimum"
                            defaultValue={focus.getMinimum()} />
                    </FieldContainer>
                </Stack>
            </FEH_Form>
        );
    }
}