import { ShuffleableParentHierarchicalItemBase, HierarchicalItemTypes } from './BaseClassHierarchy';
import CommentIcon from '@mui/icons-material/Comment';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { LayoutRegionManager } from './Layout/LayoutRegionManager';
import { jsonifyArray } from '../Libraries/generalUtility';
import { NumericalSequenceParameter, PoolParameter, RandomizedDecimalParameter, RandomizedIntegerParameter, SequencialParameter } from './Parameters';
import VariableDefinition from './VariableDefinition';
import ScriptItem from './ScriptItem';
import { FillInQuestion, MultipleChoiceQuestion, PoolQuestion } from './Questions';
import { whereSelected } from './SelectImplementation';
import { shuffle } from '../Libraries/Ordering';
import { StepResult } from './Stepping';
import runScript from '../Libraries/runScript';

///
///  Assignment -> Section -> Subject -> Question
///
export default class Subject extends ShuffleableParentHierarchicalItemBase {
    constructor() {
        super();

        this.variables = [];
        this.parameters = [];

        this.description = new LayoutRegionManager().initCreate(this, 3, 3);
        this.preScripts = [];
        this.postScripts = [];

        this.layoutProvider = false;
    }

    initCreate(parentSection, icon, selectedIcon, topics, shuffled, title) {
        super.initCreate(parentSection, icon, selectedIcon, topics, HierarchicalItemTypes.Subjects, shuffled, title);

        this.variables = [];
        this.parameters = [];

        this.description = new LayoutRegionManager().initCreate(this, 3, 3);
        this.preScripts = [];
        this.postScripts = [];

        this.layoutProvider = false;

        return this;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);

        this.icon = <CommentOutlinedIcon />;
        this.selectedIcon = <CommentIcon />;

        this.children = struct.children.map((content, i) => {
            let outcome = undefined;
            switch (content._type) {
                case 'FillInQuestion':
                    outcome = new FillInQuestion().initJson(this, content);
                    break;
                case 'MultipleChoiceQuestion':
                    outcome = new MultipleChoiceQuestion().initJson(this, content);
                    break;
                case 'PoolQuestion':
                    outcome = new PoolQuestion().initJson(this, content);
                    break;
                default:
                    // do nothing
                    break;
            }

            return outcome;
        });
        this.parameters = struct.parameters.map((parameter, i) => {
            let outcome = undefined;
            switch (parameter._type) {
                case 'RandomizedIntegerParameter':
                    outcome = new RandomizedIntegerParameter().initJson(this, parameter);
                    break;
                case 'RandomizedDecimalParameter':
                    outcome = new RandomizedDecimalParameter().initJson(this, parameter);
                    break;
                case 'NumericalSequenceParameter':
                    outcome = new NumericalSequenceParameter().initJson(this, parameter);
                    break;
                case 'SequencialParameter':
                    outcome = new SequencialParameter().initJson(this, parameter);
                    break;
                case 'PoolParameter':
                    outcome = new PoolParameter().initJson(this, parameter);
                    break;
                default:
                    // do nothing
                    break;
            }

            return outcome;
        });
        this.variables = struct.variables.map((variable, i) => {
            return new VariableDefinition().initJson(this, variable);
        });
        this.preScripts = struct.preScripts.map((script, i) => {
            return new ScriptItem().initJson(this, script);
        });
        this.postScripts = struct.postScripts.map((script, i) => {
            return new ScriptItem().initJson(this, script);
        });
        this.description = new LayoutRegionManager().initJson(this, struct.description);
        this.layoutProvider = struct.layoutProvider;

        return this;
    }

    static getDefault(parentSection, isShuffled, title) {
        return new Subject().initCreate(parentSection, <CommentOutlinedIcon />, <CommentIcon />, [], isShuffled, title);
    }

    isLayoutProvider() {
        return this.layoutProvider;
    }

    setProvidesLayout(layoutProvider) {
        this.layoutProvider = layoutProvider;
    }

    getPreScripts() {
        return this.preScripts;
    }

    setPreScripts(scripts) {
        if (Array.isArray(scripts)) {
            this.preScripts = scripts;
        }
    }

    getPostScripts() {
        return this.postScripts;
    }

    setPostScripts(scripts) {
        if (Array.isArray(scripts)) {
            this.postScripts = scripts;
        }
    }

    setDescription = undefined;

    getVariables() {
        return this.variables;
    }

    setVariables(variables) {
        if (Array.isArray(variables)) {
            this.variables = variables;
        }
    }

    getParameters() {
        return this.parameters;
    }

    setParameters(parameters) {
        if (Array.isArray(parameters)) {
            this.parameters = parameters;
        }
    }

    // takes the current layout (description) and updates all contained questions to match, overwriting them
    propagateLayout() {
        for (let i = 0; i < this.getChildren().length; i++) {
            let q = this.getChildren()[i];
            if (q.getDescription !== undefined &&
                q.getDescription().cloneFrom !== undefined) {
                q.getDescription().cloneFrom(this.getDescription());
            }

            if (q.getAnswer !== undefined &&
                q.getAnswer().cloneFrom !== undefined) {
                q.getAnswer().cloneFrom(this.getDescription());
            }
        }
    }

    clone(onlySelected = undefined, stripEmptyChildren = false) {
        let clone = new Subject().initCreate(this.parent, this.icon, this.selectedIcon, [...this.topics], this.shuffled, this.title);
        clone.setId(this.getId());

        let clonedChildren = whereSelected(this.getChildren(), onlySelected).map((o, i) => {
            return o.clone(onlySelected, stripEmptyChildren);
        }).filter((o) => {
            let result = true;
            if (stripEmptyChildren === true) {
                if (o.isEmpty !== undefined) {
                    result = o.isEmpty() === false;
                }
            }

            return result;
        });
        clone.setChildren(clonedChildren);

        clone.getDescription().cloneFrom(this.getDescription());

        let variables = [];
        for (let i = 0; i < this.variables.length; i++) {
            variables.push(this.variables[i].clone());
        }
        clone.setVariables(variables);

        let parameters = [];
        for (let i = 0; i < this.parameters.length; i++) {
            parameters.push(this.parameters[i].clone());
        }
        clone.setParameters(parameters);

        let scripts = [];
        for (let i = 0; i < this.preScripts.length; i++) {
            scripts.push(this.preScripts[i].clone());
        }
        clone.setPreScripts(scripts);

        scripts = [];
        for (let i = 0; i < this.postScripts.length; i++) {
            scripts.push(this.postScripts[i].clone());
        }
        clone.setPostScripts(scripts);

        clone.setProvidesLayout(this.isLayoutProvider());

        return clone;
    }

    getJson() {
        return {
            ...super.getJson(),
            variables: jsonifyArray(this.variables),
            parameters: jsonifyArray(this.parameters),
            description: this.description.getJson(),
            preScripts: jsonifyArray(this.preScripts),
            postScripts: jsonifyArray(this.postScripts),
            layoutProvider: this.layoutProvider,
            _type: this.constructor.ClassName(),
        };
    }

    initStep(state) {
        let result = StepResult.Stepped;
        for (let Pi = 0; Pi < this.parameters.length; Pi++) {
            let stepResult = this.parameters[Pi].initStep();
            if (stepResult === StepResult.Failure) {
                result = StepResult.Failure;
            }
        }

        for (let Vi = 0; Vi < this.variables.length; Vi++) {
            this.variables[Vi].store();
        }

        this.current = 0;
        if (this.isShuffled()) {
            this.working = shuffle([...this.children]);
        } else {
            this.working = [...this.children];
        }

        for (let i = 0; i < this.working.length; i++) {
            this.working[i].initStep(state);
        }
    }

    getCurrent() {
        return this.working[this.current].getCurrent();
    }

    step(executionIndex) {
        let outcome = StepResult.Failure;

        this.current++;
        if (this.current >= this.working.length) {
            this.current = 0;
            if (this.isShuffled()) {
                shuffle(this.working);
            }

            let failures = this.parameters.filter((p) => {
                return p.step(executionIndex) === StepResult.Failure;
            });
            outcome = failures.length > 0 ? StepResult.Failure : StepResult.Stepped;
        } else {
            outcome = StepResult.SteppedWithMore;
        }

        return outcome;
    }

    update(executionIndex) {
        let relevancyListing = [
            this.id,
            ...(this.children.map((q) => {
                return q.getId();
            })
            )
        ];

        //// run pre-scripts ////
        if (this.current === 0) {
            for (let i = 0; i < this.variables.length; i++) {
                if (this.variables[i].getParent().getId() === this.getId()) {
                    this.variables[i].reset(this.id);
                }
            }

            for (let i = 0; i < this.parameters.length; i++) {
                this.parameters[i].update(executionIndex);
            }

            // generate variable and parameter arrays for use with the pre-scripts
            let parameters = this.parameters.map((p) => {
                return { name: p.getName(), value: p.getCurrent(this.id), instance: p };
            });
            let variables = this.variables.map((v) => {
                return { name: v.getTitle(), type: v.getDataType(), value: v.getValue(this.id), instance: v };
            });

            // run all the pre scripts
            for (let i = 0; i < this.preScripts.length; i++) {
                runScript(relevancyListing, this.preScripts[i].getContent(), this.preScripts[i].getTitle(), variables, parameters);
            }
        }
        //// run pre-scripts ////

        this.working[this.current].update(executionIndex);

        //// run post-scripts ////
        if (this.current === this.working.length - 1) {
            let parameters = this.parameters.map((p) => {
                return { name: p.getName(), value: p.getCurrent(this.id), instance: p };
            });
            let variables = this.variables.map((v) => {
                return { name: v.getTitle(), type: v.getDataType(), value: v.getValue(this.id), instance: v };
            });

            // run all the post scripts
            for (let i = 0; i < this.postScripts.length; i++) {
                runScript(relevancyListing, this.postScripts[i].getContent(), this.postScripts[i].getTitle(), variables, parameters);
            }
        }
        //// run post-scripts ////
    }

    static ClassName() {
        return "Subject";
    }
}