import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Switch } from '@mui/material';
import { randomId } from '@mui/x-data-grid-generator';

import FieldContainer from '../../Forms/FieldContainer';
import FEH_Form from '../../Forms/FEH_Form';

export default function FEH_ChoiceItemEditor(props) {
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [defaultFieldSet, setDefaultFieldSet] = React.useState(randomId());

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }
    }, [props]);

    const updateFocus = () => {
        focus.setTitle(document.getElementById("questionchoicedefinition-name").value);
        focus.setDescription(document.getElementById("questionchoicedefinition-description").value);
        focus.setValue(document.getElementById("questionchoicedefinition-value").value);
        if (focus.showCorrectness() === true) {
            focus.setCorrect(document.getElementById("questionchoicedefinition-correct").checked);
        } else {
            focus.setCorrect(false);
        }

        return focus;
    }

    if (isReadOnly) {
        return ( // readonly
            <Box
                sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                }}>
                <Grid spacing={2}>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Typography color="inherit" variant="body" component="div">Name:&nbsp;&nbsp;&nbsp;</Typography>
                        <Typography color="inherit" variant="caption" component="div">{focus.getTitle()}</Typography>
                    </Stack>
                    <Stack>
                        <Typography color="inherit" variant="body" component="div">Description</Typography>
                        <Typography color="inherit" variant="caption" component="div">
                            <pre style={{ fontFamily: 'inherit' }}>
                                {focus.getDescription() || "No Description"}
                            </pre>
                        </Typography>
                    </Stack>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Typography color="inherit" variant="body" component="div">Value:&nbsp;&nbsp;&nbsp;</Typography>
                        <Typography color="inherit" variant="caption" component="div">{focus.getValue()}</Typography>
                    </Stack>
                    {
                        focus.showCorrectness() ?
                            (
                                <Stack direction="row" sx={{ alignItems: 'center' }}>
                                    <Typography color="inherit" variant="body" component="div">Correct:&nbsp;&nbsp;&nbsp;</Typography>
                                    <Typography color="inherit" variant="caption" component="div">{focus.isCorrect() ? 'Yes' : 'No'}</Typography>
                                </Stack>
                            ) : ''
                    }
                </Grid>
            </Box>
        );
    } else {
        return (
            <FEH_Form
                height="88vH"
                formId="ChoiceItemForm"
                type="ChoiceItem"
                getInstance={updateFocus}
                onClose={props.onClose}
                onConfirm={props.onConfirm}
                onReportMessages={props.onReportMessages}>
                <Stack spacing={2}>
                    <FieldContainer
                        fieldSet={defaultFieldSet}
                        label="Name"
                        help="The choice item's name"
                        fullWidth
                        required>
                        <TextField
                            disabled={isReadOnly}
                            id="questionchoicedefinition-name"
                            required
                            defaultValue={focus.getTitle()}
                            sx={{ minWidth: '100%', width: '100%' }} />
                    </FieldContainer>
                    <FieldContainer
                        fieldSet={defaultFieldSet}
                        label="Description"
                        help="If provided, the examinee will see this instead of the item's value."
                        fullWidth>
                        <TextField
                            disabled={isReadOnly}
                            id="questionchoicedefinition-description"
                            defaultValue={focus.getDescription()}
                            sx={{ minWidth: '100%', width: '100%' }} />
                    </FieldContainer>
                    <FieldContainer
                        fieldSet={defaultFieldSet}
                        required
                        label="Value"
                        help={"The choice item's internal value."}
                        fullWidth>
                        <TextField
                            disabled={isReadOnly}
                            id="questionchoicedefinition-value"
                            required
                            defaultValue={focus.getValue()}
                            sx={{ minWidth: '100%', width: '100%' }} />
                    </FieldContainer>
                    {
                        focus.showCorrectness() ?
                            (
                                <FieldContainer
                                    fieldSet='Question_Choice_Correct'
                                    swap
                                    label="Correct"
                                    help="Whether this choice is considered a correct option for the question or not.">
                                    <Switch
                                        id="questionchoicedefinition-correct"
                                        defaultChecked={focus.isCorrect()} />
                                </FieldContainer>
                            ) : ''
                    }
                </Stack>
            </FEH_Form>
        );
    }
}