import { isSame } from "../../Libraries/arrayUtility";

export const LogEntryType = {
    Normal: 0,
    Error: 1,
    Information: 2,
    Warning: 3,
}

class LogEntry {
    constructor(relevancies, message, type, when = undefined) {
        this._relevancies = Array.isArray(relevancies) ? relevancies : [relevancies];
        this._message = message;
        this._type = type;
        this._timeStamp = when === undefined ? this.#getDateTimeStr() : when;
        this._cause = undefined;
        this._coords = undefined;
    }

    #getDateTimeStr() {
        let dt = new Date();
        return dt.toLocaleDateString() + " " + dt.toLocaleTimeString();
    }

    message() {
        return this._message;
    }

    type() {
        return this._type;
    }

    relevancies() {
        return this._relevancies;
    }

    when() {
        return this._timeStamp;
    }

    coordinates() {
        return this._coords;
    }

    reason(cause = undefined) {
        if (cause !== undefined) {
            this._cause = cause;

            if (this._cause !== undefined &&
                this._cause.stack !== undefined) {
                // extract the coordinates
                let rgx = /topicalScriptExecutionFunction[^\<]*<anonymous>:(\d+\:\d+)/;
                let groups = this._cause.stack.match(rgx); // for edge
                if (groups === null) {// check for firefox
                    rgx = /topicalScriptExecutionFunction@.*Function:(\d+\:\d+)/;
                    groups = this._cause.stack.match(rgx);
                }

                if (groups !== null) {
                    const getCoords = (str) => {
                        let vals = str.split(":");
                        return { y: +vals[0], x: +vals[1] };
                    }

                    if (groups.length === 2) {
                        this._coords = getCoords(groups[1]);
                    } else {
                        this._coords = undefined; // coordinate less error
                    }
                } else {
                    this._coords = undefined; // coordinate less error
                }
            }
        }
        return this;
    }

    getReason() {
        return this._cause;
    }

    adjustCoordinates(variableSet, parameterSet) {
        if (this._coords !== undefined) {
            const basePreamble = 11; // this is how long the code preceding the actual script execution is at a minimum

            // then we add one line per item in both the variable and parameter sets
            let delta = basePreamble + variableSet.length + parameterSet.length;

            // this value is then subtracted from the Y coordinate of the coordinate block
            this._coords.y -= delta;
        }

        return this;
    }
}

class ScriptExecutionLog {
    constructor(relevancies) {
        this._relevancies = Array.isArray(relevancies) ? relevancies : [relevancies];

        this.entries = [];
    }

    post(entry) {
        this.entries.push(entry);
        return entry;
    }

    posts() {
        return [...this.entries];
    }

    filtered(filterFunc) {
        return [...this.entries.filter(filterFunc)];
    }

    relevancies() {
        return this._relevancies;
    }
}

// this is the 
export class LogHost {
    constructor() {

    }

    static entries = [];

    // adds a post to a specific log
    static post(relevancies, message, type, when = undefined) {
        // ensure we have a log with this exact set of relevancies
        let found = LogHost.entries.filter((sel) => {
            return isSame(sel.relevancies(), relevancies);
        });

        let target = undefined;
        if (found.length === 1) {
            target = found[0];
        } else {
            target = new ScriptExecutionLog(relevancies);
            LogHost.entries.push(target);
        }

        // create and post the new entry
        return target.post(new LogEntry(relevancies, message, type, when));
    }

    // retrieves all of the posts from a specific log with the given relevancy
    static byId(id) {
        // get the log for this id
        let found = LogHost.entries.filter((sel) => {
            if (Array.isArray(id)) {
                let found = false;
                for (let i = 0; i < id.length; i++) {
                    found = sel.relevancies().includes(id[i]);
                    if (found === true) {
                        break;
                    }
                }

                return found;
            } else {
                return sel.relevancies().includes(id);
            }
        });

        if (found.length > 0) {
            let results = [];
            for (let i = 0; i < found.length; i++) {
                results = results.concat(found[i].posts());
            }

            return results;
        } else {
            return [];
        }
    }

    // retrieves the filtered posts from a specific log
    static byIdFilter(id, filterFunc) {
        // get the log for this id
        let found = LogHost.entries.filter((sel) => {
            if (Array.isArray(id)) {
                let found = false;
                for (let i = 0; i < id.length; i++) {
                    found = sel.relevancies().includes(id[i]);
                    if (found === true) {
                        break;
                    }
                }

                return found;
            } else {
                return sel.relevancies().includes(id);
            }
        });

        if (found.length > 0) {
            let results = [];
            for (let i = 0; i < found.length; i++) {
                results = results.concat(found[i].filtered(filterFunc));
            }

            return results;
        } else {
            return [];
        }
    }

    // removes an entire log
    static remove(id) {
        // get the log for this id
        let found = LogHost.entries.filter((sel) => {
            return sel.id() === id;
        });

        if (found.length === 1) {
            const index = LogHost.entries.indexOf(found[0]);
            if (index > -1) { // only splice array when item is found
                LogHost.entries.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
    }

    static reset() {
        LogHost.entries.length = 0;
    }
}