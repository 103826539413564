export function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

export function getTwelveHourHours() {
    let h = (new Date()).getHours();

    let result = 0;
    if (h === 0 || h === 24) {
        result = 12;
    } else {
        result = h > 12 ? h - 12 : h;
    }

    return result;
}

export function getTimestampSlugMappings() {
    return [
        { code: 'dddd', meaning: "full day", example: 'e.g. Monday' },
        { code: 'dd', meaning: "leading zero date", example: 'e.g. 01' },
        { code: 'd', meaning: "date", example: 'e.g. 1' },
        { code: 'DD', meaning: "leading zero day of the week", example: 'e.g. 01' },
        { code: 'D', meaning: "day of the week", example: 'e.g. 1' },
        { code: 'MMMM', meaning: "full month", example: 'e.g. January' },
        { code: 'MMM', meaning: "month abbreviation", example: 'e.g. Jan' },
        { code: 'MM', meaning: "leading zero month", example: 'e.g. 01' },
        { code: 'M', meaning: "month", example: 'e.g. 1' },
        { code: 'yyyy', meaning: "full year", example: 'e.g. 2023' },
        { code: 'yy', meaning: "two-digit year", example: 'e.g. 23' },
        { code: 'hh', meaning: "two-digit 24-hour hours", example: 'e.g. 04' },
        { code: 'h', meaning: "24-hour hours", example: 'e.g. 4' },
        { code: 'HH', meaning: "AM/PM two-digit hours", example: 'e.g. 04 am' },
        { code: 'H', meaning: "AM/PM hours", example: 'e.g. 4 am' },
        { code: 'mm', meaning: "two-digit minutes", example: 'e.g. 03' },
        { code: 'm', meaning: "minutes", example: 'e.g. 3' },
        { code: 'ss', meaning: "two-digit seconds", example: 'e.g. 05' },
        { code: 's', meaning: "seconds", example: 'e.g. 5' },
        { code: 'TP', meaning: "pre/post midday indicator", example: 'e.g. AM' },
        { code: 'tp', meaning: "pre/post midday indicator", example: 'e.g. am' },
    ];
}

function getSlug(code, d) {
    let weekDayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    let slugValue = '';
    let h = d.getHours();

    switch (code) {
        case 'dddd':
            slugValue = weekDayNames[d.getDay()];
            break;
        case 'dd':
            slugValue = pad(d.getDate(), 2);
            break;
        case 'd':
            slugValue = d.getDate();
            break;
        case 'DD':
            slugValue = pad(d.getDay() + 1, 2);
            break;
        case 'D':
            slugValue = d.getDay() + 1;
            break;
        case 'MMMM':
            slugValue = monthNames[d.getMonth()];
            break;
        case 'MMM':
            slugValue = monthShortNames[d.getMonth()];
            break;
        case 'MM':
            slugValue = pad(d.getMonth(), 2);
            break;
        case 'M':
            slugValue = d.getMonth();
            break;
        case 'yyyy':
            slugValue = d.getFullYear();
            break;
        case 'yy':
            slugValue = d.getFullYear().toString().slice(2);
            break;
        case 'hh':
            slugValue = pad(d.getHours(), 2);
            break;
        case 'h':
            slugValue = d.getHours();
            break;
        case 'HH':
            slugValue = pad(getTwelveHourHours(), 2);
            break;
        case 'H':
            slugValue = getTwelveHourHours();
            break;
        case 'mm':
            slugValue = pad(d.getMinutes(), 2);
            break;
        case 'm':
            slugValue = d.getMinutes();
            break;
        case 'ss':
            slugValue = pad(d.getSeconds(), 2);
            break;
        case 's':
            slugValue = d.getSeconds();
            break;
        case 'TP':
            slugValue = h > 12 ? 'PM' : 'AM';
            break;
        case 'tp':
            slugValue = h > 12 ? 'pm' : 'am';
            break;
    }

    return slugValue;
}

// takes a timestamp pattern and converts it using the current date and time
export function doTimestampSubstitution(text) {
    let operations = getTimestampSlugMappings();
    let result = text;

    let d = new Date();

    // loop through the key text, keeping track of the starting point and the ending point seperately.
    // move the starting point until a slug component is found, then extend the ending until a non-slug component is found
    // the slug is the starting point -> ending point - 1.
    // replace that, move the start to the end of the replacement, and repeat until done.
    // Note: eP = end point, sP = start point

    let allTokens = operations.map((op) => { return op.code; });
    let eP = 0;
    // loop through the key text until done
    for (let sP = 0; sP < result.length; sP++) {
        // find all tokens that match sP until more than zero are found.
        let possibles = allTokens.filter((t) => {
            return t.startsWith(result[sP]);
        });
        if (possibles.length > 0) {
            // we have the possible matches.
            // sort them by length, in descending order, and take the biggest one that finds a match.
            possibles.sort((a, b) => {
                if (a.length > b.length) {
                    return -1;
                } else if (a.length < b.length) {
                    return 1;
                } else {
                    return 0;
                }
            });

            let token = undefined;
            for (let i = 0; i < possibles.length; i++) {
                eP = possibles[i].length;

                token = allTokens.filter((t) => {
                    return result.substr(sP, eP) === t;
                });

                if (token.length === 1) {
                    break;
                }
            }

            if (token.length > 0) {
                // perform the replacement:
                let slug = getSlug(token[0], d);
                result = result.substr(0, sP) + slug + result.substr(sP + eP);

                // adjust sP's value in accordance with the new length due to the slug's length
                // note that we don't have to update eP because it won't be referenced again until its value is overwritten in the for loop
                sP += (slug + '').length - 1;
            }
        }
    }

    return result;
}