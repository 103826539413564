import React, { useContext } from 'react';
import GenericList from '../Controls/Lists/GenericList';
import { RoleAssociation } from '../Classes/Personnel/RoleAssociation';

import AddLinkIcon from '@mui/icons-material/AddLink';
import LinkOffIcon from '@mui/icons-material/LinkOff';

import { QuizzicalContext, ScreenModes } from '../Context/QuizzicalContext';

export default function AssociationScreen(props) {
    const state = useContext(QuizzicalContext);

    return (
        <GenericList
            rows={9}
            state={state}
            fullscreen={false}
            isReadOnly={false}
            isCollapsable={false}
            isExpandable={false}
            label="Assignment"
            showHeaderLabel={false}
            canAdd={false}
            overrideAddIcon={<AddLinkIcon />}
            overrideDeleteSelectedIcon={<LinkOffIcon />}
            getTargetItems={() => {
                return state.Associations;
            }}
            setTargetItems={(items) => {
                state.setAssociations(items);
            }}
            getDefaultItem={() => {
                return RoleAssociation.getDefault();
            }} />);
}