import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export default function AboutDialog(props) {
    const [isOpen, setIsOpen] = React.useState(props.isOpen);

    React.useEffect((orig) => {
        if (isOpen !== props.isOpen &&
            props.isOpen !== undefined &&
            props.isOpen !== null) {
            setIsOpen(props.isOpen);
        }
    }, [props]);

    const handleConfirm = (event) => {
        props.onConfirm(event);
    }

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleConfirm}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>About</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Typography color="inherit" >
                            {"Designed and built by Zain T. Al-Ahmary with input from Prof. Paul L Redd"}
                        </Typography>
                        <Typography color="inherit" variant="caption" >
                            {"This website uses no cookies, stores no data, and maintains no information about any and all users.  Any information held from session to session must be done so by the user and uploaded upon need."}
                        </Typography>
                        <Typography color="inherit" variant="caption" >
                            {"Zain Al-Ahmary (c) All Rights Reserved"}
                        </Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" onClick={handleConfirm}>Close</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}