import * as React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import Grid from '@mui/material/Unstable_Grid2';
import { randomId } from '@mui/x-data-grid-generator';
import { useTheme } from '@mui/material/styles';

import TermInsertion from '../../ContentEditor/TermInsertion';
import { getMenuUtilityClass } from '@mui/material';
import { calculatePinnedRowsHeight } from '@mui/x-data-grid/internals';
import FieldContainer from '../../Forms/FieldContainer';
import FEH_Form from '../../Forms/FEH_Form';
import ScriptItem from '../../../Classes/ScriptItem';
import Subject from '../../../Classes/Subjects';
import { FillInQuestion, MultipleChoiceQuestion } from '../../../Classes/Questions';
import InsertableJavascriptEditor from '../../InsertableJavascriptEditor';
import { QuizzicalContext } from '../../../Context/QuizzicalContext';

export default function FEH_ScriptEditor(props) {
    const state = React.useContext(QuizzicalContext);
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [isMouseOver, setIsMouseOver] = React.useState(props.isMouseOver || false);
    const [defaultFieldSet, setDefaultFieldSet] = React.useState(randomId());

    const theme = useTheme();
    const editorRef = React.useRef(null);

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }

        if (props.isMouseOver !== undefined &&
            props.isMouseOver !== null &&
            props.isMouseOver !== isMouseOver) {
            setIsMouseOver(props.isMouseOver);
        }
    }, [props]);

    const handleEditorChange = (value) => {
        focus.setContent(value);
    }

    const updateFocus = () => {
        focus.setTitle(document.getElementById("script-title").value);
        focus.setDescription(document.getElementById("script-description").value);

        return focus;
    }

    /* const onEdited = (event) => {
        if (event.target.id === "script-title") {
            focus.setTitle(event.target.value);
        } else if (event.target.id === "script-description") {
            focus.setDescription(event.target.value);
        }
    } */

    const onInsertItem = (text, isVariable) => {
        if (editorRef !== undefined &&
            editorRef.current !== null &&
            editorRef.current !== undefined) {
            const editor = editorRef.current.editor;
            editor.session.insert(editor.getCursorPosition(), text);
            editor.focus();
        }
    }

    const getTargetEntity = () => {
        let target = focus;
        while ([Subject.ClassName(), FillInQuestion.ClassName(), MultipleChoiceQuestion.ClassName()]
            .includes((target = target.getParent()).constructor.ClassName()) === false) {
            if (target.getParent() === undefined) {
                break;
            }
        }

        return target;
    }

    let display = (<div />);
    if (focus !== undefined &&
        focus.constructor.ClassName() === ScriptItem.ClassName()) {
        if (isReadOnly) {
            display = (
                <Box
                    sx={{
                        paddingTop: 2,
                        paddingLeft: 2,
                        paddingRight: 2,
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                        color: (theme) =>
                            theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                        border: '1px solid',
                        borderColor: (theme) =>
                            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                        borderRadius: 2,
                        fontSize: '0.875rem',
                        fontWeight: '700',
                    }}>
                    <Grid spacing={2}>
                        <Stack direction="row" sx={{ alignItems: 'center' }} spacing={1}>
                            <Typography color="inherit" variant="body" component="div">Title:</Typography>
                            <Typography color="inherit" variant="caption" component="div">{focus.getTitle()}</Typography>
                        </Stack>
                        <Stack>
                            <Typography color="inherit" variant="body" component="div">Description</Typography>
                            <Typography color="inherit" variant="caption" component="div">
                                <pre style={{ fontFamily: 'inherit' }}>
                                    {focus.getDescription() || "No Description"}
                                </pre>
                            </Typography>
                        </Stack>
                    </Grid>
                </Box>
            );
        } else {
            display = (
                <FEH_Form
                    height="88vH"
                    formId="ScriptForm"
                    type="ScriptItem"
                    getInstance={updateFocus}
                    onClose={props.onClose}
                    onConfirm={props.onConfirm}
                    onReportMessages={props.onReportMessages}>
                    <Stack spacing={2}>
                        <FieldContainer
                            fieldSet={defaultFieldSet}
                            label="Title"
                            help="The script's title"
                            fullWidth
                            required>
                            <TextField
                                disabled={isReadOnly}
                                id="script-title"
                                fullWidth
                                required
                                defaultValue={focus.getTitle()} />
                        </FieldContainer>
                        <FieldContainer
                            fieldSet={defaultFieldSet}
                            label="Description"
                            help="A description of the script"
                            fullWidth
                            required>
                            <TextField
                                disabled={isReadOnly}
                                id="script-description"
                                fullWidth
                                required
                                multiline
                                rows={4}
                                defaultValue={focus.getDescription()} />
                        </FieldContainer>
                        <Box
                            fullWidth
                            sx={{
                                position: 'relative',
                                width: '100%',
                                height: '40vH',
                            }}>
                            <FieldContainer
                                display="column"
                                fieldSet={defaultFieldSet}
                                label="Script"
                                help="The entry's javascript code"
                                fullWidth
                                required>
                                <InsertableJavascriptEditor
                                    styling={{
                                        border: 1,
                                        borderColor: (state.theme === 'light' ? theme.palette.primary.main : '#000000'),
                                    }}
                                    width='100%'
                                    height='350px'
                                    focus={focus}
                                    source={getTargetEntity()}
                                    isReadOnly={isReadOnly}
                                    isMouseOver={true}
                                    setter="setContent"
                                    getter="getContent" />
                            </FieldContainer>
                        </Box>
                    </Stack>
                </FEH_Form >
            );
        }
    }

    return display;
}