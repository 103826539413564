import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

import JavascriptIcon from '@mui/icons-material/Javascript';
import ImageIcon from '@mui/icons-material/Image';
import DescriptionIcon from '@mui/icons-material/Description';
import PermMediaIcon from '@mui/icons-material/PermMedia';

import { LayoutRegion } from '../../Classes/Layout/LayoutRegion';
import { ContentTypes } from '../../Classes/Layout/LayoutMeta';
import IconSelectionGroup from '../IconSelectionGroup';
import nameToRgba from '../../Libraries/nameToRgba';

export default function ContentTypeEditor(props) {
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }
    }, [props]);

    if (focus !== undefined &&
        focus.constructor.ClassName() === LayoutRegion.ClassName()) {
        //let data = nameToRgba(props.sourceTypeBackgroundColor());

        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                }}>
                {props.show ?
                    <IconSelectionGroup
                        onSelection={(item) => {
                            switch (item.label) {
                                case 'Image':
                                    focus.setType(ContentTypes.Image);
                                    break;
                                /* case 'Image Set':
                                    focus.setType(ContentTypes.ImageSet);
                                    break; */
                                case 'Text':
                                    focus.setType(ContentTypes.Text);
                                    break;
                                case 'Script':
                                    focus.setType(ContentTypes.Script);
                                    break;
                            }
                            props.onNeedRefresh();
                        }}
                        content={[
                            {
                                label: 'Image',
                                icon: <ImageIcon />,
                            },
                            /* {
                                label: 'Image Set',
                                icon: <PermMediaIcon />,
                            }, */
                            {
                                label: 'Text',
                                icon: <DescriptionIcon />,
                            },
                            {
                                label: 'Script',
                                icon: <JavascriptIcon />,
                            },
                        ]} /> : ""}
            </Box>
        );
    } else {
        return (<div />);
    }
}