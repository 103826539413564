import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';

import IndexingControls from '../../Controls/Presentation/IndexingControls';
import PresentationDisplay from '../../Controls/Presentation/PresentationDisplay';

import { QuizzicalContext, RunStates, ScreenModes } from '../../Context/QuizzicalContext';
import TimeDisplay from '../../Controls/Presentation/TimeDisplay';
import { LogHost } from '../../Classes/Resolution/ScriptExecutionLog';
import { Tooltip } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PresentationDialog(props) {
    const state = React.useContext(QuizzicalContext);
    const [isOpen, setIsOpen] = React.useState(props.isOpen);
    const [pregenQuestions, setPregenQuestions] = React.useState(undefined);
    const [presentationState, setPresentationState] = React.useState(undefined);
    const [presentationSet, setPresentationSet] = React.useState([]);
    const [forceRefresh, setForceRefresh] = React.useState(false);

    React.useEffect(() => {
        if (isOpen !== props.isOpen &&
            props.isOpen !== undefined &&
            props.isOpen !== null) {
            setIsOpen(props.isOpen);
        }

        if (pregenQuestions !== props.pregenQuestions &&
            props.pregenQuestions !== undefined &&
            props.pregenQuestions !== null) {
            setPregenQuestions(props.pregenQuestions);
        }

        if (presentationSet !== props.presentationSet &&
            props.presentationSet !== undefined &&
            props.presentationSet !== null) {
            // we don't want to present everything.
            // we want to present a filtered selected clone
            let filteredSet = props.presentationSet.map((o, i) => {
                return o.clone(true, true);
            });

            setPresentationSet(filteredSet);
        }
    }, [props]);

    React.useEffect(() => {
        setForceRefresh(!forceRefresh);
    }, [presentationState]);

    const refreshRequestHandle = () => {
        setForceRefresh(!forceRefresh);
    }

    const StateUpdated = (newState) => {
        setPresentationState(newState);
    }

    const handleClose = (event) => {
        LogHost.reset();
        props.onCancel(event, props.bucket);
    };

    const getTitleTimeInfoString = () => {
        // date
        return (presentationState !== undefined ? presentationState.when : 'now');
    }

    return (
        <Dialog
            fullScreen
            open={isOpen}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle>
                <Stack direction="row" alignItems={'center'}>
                    <Typography>{state.AdministratorName !== undefined ? state.AdministratorName + ' - ' : ''}{(props.replay === true ? 'Replayed on ' : 'Presented on ') + " " + getTitleTimeInfoString() + ' - elapsed: '}</Typography>
                    {presentationState !== undefined && presentationState.when !== undefined && presentationState.when !== 'now' ?
                        <TimeDisplay time={Date.now() - Date.parse(presentationState.when)} showIcon={false} />
                        : ''}
                    <Typography sx={{ flexGrow: 1 }}></Typography>
                    <Tooltip title={"Loaded content version number - " + (state.Mode.Version === undefined ? "Unversioned" : state.Mode.Version)}>
                        <Typography
                            sx={{
                                width: 150,
                                background: '#000000',
                                marginRight: 1,
                            }}
                            variant='caption'>
                            {state.Mode.Version === undefined ? "Unversioned" : state.Mode.Version}
                        </Typography>
                    </Tooltip>
                    <Button color="inherit" onClick={handleClose}>Close</Button>
                </Stack></DialogTitle>
            <DialogContent>
                <IndexingControls
                    shuffleReplaysTogether={props.shuffleReplaysTogether}
                    replay={props.replay}
                    lessonKey={props.lessonKey}
                    onAttendanceManagementRequest={props.onAttendanceManagementRequest}
                    pregenQuestions={pregenQuestions}
                    state={state}
                    set={presentationSet}
                    onRefreshRequested={refreshRequestHandle}
                    onStateUpdate={StateUpdated}
                    questionsGenerated={props.questionsGenerated} />
                <Box sx={{ height: '85%', width: '100%' }}>
                    <PresentationDisplay
                        state={state}
                        presentationState={presentationState} />
                </Box>
            </DialogContent>
        </Dialog>
    );
}
