import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { randomId } from '@mui/x-data-grid-generator';
import { Switch } from '@mui/material';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import CancelIcon from '@mui/icons-material/Close';

import { QuizzicalContext, RunStates, ScreenModes } from '../Context/QuizzicalContext';
import QuestionPoolSelector from '../Controls/QuestionPoolSelector';
import GenerateQuestionPool from '../Controls/Editors/Assignments/GenerateQuestionPool';
import QuestionPool from '../Classes/Pools';
import { PoolQuestion, FillInQuestion } from '../Classes/Questions';
import { ContentTypes, ContentSources } from '../Classes/Layout/LayoutMeta';
import { RandomizedIntegerParameter } from '../Classes/Parameters';
import handleFileUpload, { getDuplicationKey, getRowObject } from '../Libraries/CSVImportHelper';
import ConfirmationDialog from './Dialogs/ConfirmationDialog';

//////////////////////////////////////////////////
/// needed for tables in CSV import results
//////////////////////////////////////////////////
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import LoopIcon from '@mui/icons-material/Loop';
import LinearProgress from '@mui/material/LinearProgress';
import BlockIcon from '@mui/icons-material/Block';
import DoneIcon from '@mui/icons-material/Done';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { getPoolById } from '../Context/ContextUtility';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

//////////////////////////////////////////////////
/// needed for tables in CSV import results
//////////////////////////////////////////////////

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function LegacyImportScreen(props) {
    const state = React.useContext(QuizzicalContext);
    const [showPoolDetails, setShowPoolDetails] = React.useState(true);
    const [expanded, setExpanded] = React.useState('panel1');
    const [fakePool, setFakePool] = React.useState(() => { // this is used to make the pool selector work
        let result = QuestionPool.getDefault('fake pool', 'a fake pool for use with the selector');
        let q = PoolQuestion.getDefault(result, 'a fake pool question');
        result.setChildren([q]);

        return q;
    });
    const [importPoolId, setImportPoolId] = React.useState(undefined);
    const [newPool, setNewPool] = React.useState(QuestionPool.getDefault(
        'Legacy Import Pool - ' + randomId(),
        'This question pool was generated by a legacy import.'
    ));
    const [lastImportSuccess, setLastImportSuccessful] = React.useState(false);

    // various configurations required for the import and related displays
    const [importContext, setImportContext] = React.useState({
        model: {
            show: false,
            items: [],
            key: 0
        },
        headerValidation: [
            ["ID", 10, "string"],
            ["Operator", 5, "string"],
            ["Display Expression", 10, "string"],
            ["Minimum Term Value", 8, "number"],
            ["Maximum Term Value", 8, "number"],
            ["Display Duration (10ths of a second)", 8, "number"],
            ["Dwell Duration (10ths of a second)", 8, "number"],
            ["Number of Parameters", 8, "number"],
            ["Javascript Solver", 15, "string"],
            ["Date", 20, "string"]
        ],
        expanderStates: [],
        width: props.width || 800,
        page: 0,
        rowsPerPage: 5,
        overwriteWithNew: true,
        uniqueBy: ["Operator"],
        headerFieldMapping: [
            { field: 'id', headerName: 'ID' },
            { field: 'operator', headerName: 'Operator' },
            { field: 'expression', headerName: 'Display Expression' },
            { field: 'minimum', headerName: 'Minimum Term Value' },
            { field: 'maximum', headerName: 'Maximum Term Value' },
            { field: 'duration', headerName: 'Display Duration (10ths of a second)' },
            { field: 'dwell', headerName: 'Dwell Duration (10ths of a second)' },
            { field: 'params', headerName: 'Number of Parameters' },
            { field: 'solver', headerName: 'Javascript Solver' }
        ],
        completedResults: [],
    });

    const getDefaultImportModel = () => {
        return {
            model: {
                show: false,
                items: [],
                key: 0
            },
            headerValidation: [
                ["ID", 10, "string"],
                ["Operator", 5, "string"],
                ["Display Expression", 10, "string"],
                ["Minimum Term Value", 8, "number"],
                ["Maximum Term Value", 8, "number"],
                ["Display Duration (10ths of a second)", 8, "number"],
                ["Dwell Duration (10ths of a second)", 8, "number"],
                ["Number of Parameters", 8, "number"],
                ["Javascript Solver", 15, "string"],
                ["Date", 20, "string"]
            ],
            expanderStates: [],
            width: props.width || 800,
            page: 0,
            rowsPerPage: 5,
            overwriteWithNew: true,
            uniqueBy: ["Operator"],
            headerFieldMapping: [
                { field: 'id', headerName: 'ID' },
                { field: 'operator', headerName: 'Operator' },
                { field: 'expression', headerName: 'Display Expression' },
                { field: 'minimum', headerName: 'Minimum Term Value' },
                { field: 'maximum', headerName: 'Maximum Term Value' },
                { field: 'duration', headerName: 'Display Duration (10ths of a second)' },
                { field: 'dwell', headerName: 'Dwell Duration (10ths of a second)' },
                { field: 'params', headerName: 'Number of Parameters' },
                { field: 'solver', headerName: 'Javascript Solver' }
            ],
            completedResults: [],
        };
    }

    const onEdited = (event) => {
        if (event.target.id === "will-overwrite") {
            setImportContext({ ...importContext, overwriteWithNew: event.target.checked });
        }
    }

    const handleChangePage = (event, newPage) => {
        setImportContext({ ...importContext, page: newPage });
    };

    const handleChangeRowsPerPage = (event) => {
        setImportContext({
            ...importContext,
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10)
        });
    };

    const getEmptyRowCount = (o) => {
        return (importContext.page > 0 ? Math.max(0, (1 + importContext.page) * importContext.rowsPerPage - o.records.length) : 0);
    };

    const handleClick = (index) => {
        importContext.expanderStates[index] = (importContext.expanderStates[index] ? false : true);
        setImportContext({ ...importContext });
    };

    const removeImportItem = (index) => {
        importContext.model.items.splice(index, 1);
        importContext.expanderStates.splice(index, 1);
        setImportContext({ ...importContext });
    }

    const onFileUpload = (event) => {
        handleFileUpload(
            event,
            importContext.model.items,
            () => { setImportContext({ ...importContext }); },
            importContext.headerValidation,
            onUploadCompleted);
    }

    const onUploadCompleted = (items) => {
        // we only return non-duplicate, validated results merged into a single array
        var result = [];
        var dupDetection = {};
        for (var i = 0; i < importContext.model.items.length; i++) {
            var item = importContext.model.items[i];

            if (item.progress === 1 && item.records.length > 0) {
                if (importContext.uniqueBy === null || importContext.headerFieldMapping === null) {
                    for (var r = 0; r < item.records.length; r++) {
                        result.push(item.records[r]);
                    }
                } else {
                    for (var r = 0; r < item.records.length; r++) {
                        var key = getDuplicationKey(item.records[r], item.headers, importContext.uniqueBy)
                        if (dupDetection[key] === undefined) {
                            dupDetection[key] = true;
                            result.push(getRowObject(item.records[r], item.headers, importContext.headerFieldMapping));
                        }
                    }
                }
            }
        }

        if (result.length > 0) {
            for (let i = 0; i < result.length; i++) {
                importContext.completedResults.push(result[i]);
            }
        }
        setImportContext({ ...importContext });
    }

    const handleConfirm = () => {
        let targetPool = newPool === undefined ? getPoolById(state, importPoolId) : newPool;
        if (targetPool !== undefined) {
            let orig = undefined;
            let remnant = state.Examinations.Pools.filter((pool) => {
                let keep = pool.getId() !== targetPool.getId();
                if (!keep) {
                    orig = pool;
                }
                return keep;
            });
            let index = state.Examinations.Pools.indexOf(orig);
            if (index >= 0) {
                remnant.splice(index, 0, targetPool);
                state.setPools(remnant);
            } else {
                state.setPools(remnant.concat([targetPool]));
            }
        }

        if (targetPool !== undefined) {
            let questions = [];
            for (let cri = 0; cri < importContext.completedResults.length; cri++) {
                let cr = importContext.completedResults[cri];
                if (cr['expression'] !== undefined &&
                    cr['solver'] !== undefined) {
                    let question = FillInQuestion.getDefault(
                        targetPool,
                        cr['operator'],
                        parseInt(cr['duration'] + '', 10),
                        parseInt(cr['dwell'] + '', 10));
                    question.setId(cr['id']);

                    // set the question and answer regions and set their scripts
                    let paramCount = parseInt(cr['params'] + '', 10);
                    let regions = question.getDescription().getRegions();

                    // region 0 is the question
                    let contentType = cr['expression'].trim().startsWith("return") ? ContentTypes.Script : ContentTypes.Text;
                    regions[0].setSource(ContentSources.Question);
                    regions[0].setType(contentType);
                    regions[0].setContent(swapForGeneratedNames(cr['expression'], paramCount, contentType === ContentTypes.Script));

                    // region 1 is the answer
                    contentType = cr['solver'].trim().startsWith("return") ? ContentTypes.Script : ContentTypes.Text;
                    regions[1].setSource(ContentSources.Answer);
                    regions[1].setType(contentType);
                    regions[1].setContent(swapForGeneratedNames(cr['solver'], paramCount, contentType === ContentTypes.Script));

                    // set the simplified fields
                    question.setExpectedFormat('return "_____________";');
                    question.setSimpleQuestion(swapForGeneratedNames(cr['expression'], paramCount, contentType === ContentTypes.Script));
                    question.setSimpleAnswer(swapForGeneratedNames(cr['solver'], paramCount, contentType === ContentTypes.Script));

                    // add parameters for use by the script
                    for (let pi = 0; pi < paramCount; pi++) {
                        let parameter = RandomizedIntegerParameter.getDefault(
                            question,
                            "generated_param_" + pi,
                            parseInt(cr['minimum'] + '', 10),
                            parseInt(cr['maximum'] + '', 10));

                        let params = question.getParameters();
                        params.push(parameter);
                        question.setParameters(params);
                    }

                    questions.push(question);
                }
            }

            targetPool.setChildren(questions);
        }

        setImportContext(getDefaultImportModel());
    };

    // this function takes a string and swaps the variables 
    const swapForGeneratedNames = (scriptLine, parameterCount, isScript) => {
        let result = scriptLine;
        let changed = false;
        let index = 0;
        do {
            let altered = result
                .replace(new RegExp("vals\\[" + index + "\\]", 'g'), "generated_param_" + index)
                .replace(new RegExp("\\{" + index + "\\}", 'g'), "generated_param_" + index);
            changed = altered !== result;
            if (changed) {
                index++;
                result = altered;
            }

            if (index >= parameterCount) {
                break;
            }
        }
        while (changed);

        if (isScript) {
            if (!result.trim().endsWith(";")) {
                result = result + ";";
            }
        }

        return result;
    }

    const targetPoolChanged = (question, poolId) => {
        setImportPoolId(poolId === "" ? undefined : poolId);

        if (poolId === "") {
            setNewPool(QuestionPool.getDefault(
                'Legacy Import Pool - ' + randomId(),
                'This question pool was generated by a legacy import.'
            ));
        } else {
            setNewPool(undefined);
        }
    }

    var listItems = importContext.model.items.map(function (o, i) {
        if (o.error !== null) {
            return (
                <ListItem key={"item-" + i} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <ReportGmailerrorredIcon />
                        </ListItemIcon>
                        <ListItemText primary={o.file} />
                        <ListItemText primary={o.error} />
                    </ListItemButton>
                </ListItem>
            );
        } else if (o.progress < 1) {
            return (
                <ListItem key={"item-" + i} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <LoopIcon />
                        </ListItemIcon>
                        <ListItemText primary={o.file} />
                        <LinearProgress />
                    </ListItemButton>
                </ListItem>
            );
        } else if (o.records.length === 0) {
            return (
                <ListItem key={"item-" + i} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <BlockIcon />
                        </ListItemIcon>
                        <ListItemText primary={o.file} />
                        <ListItemText primary={"Success with no records to report"} />
                    </ListItemButton>
                </ListItem>
            );
        } else if (o.records.length > 0) {
            return (
                <div key={"item-" + i} style={{ width: '100%' }}>
                    <Stack direction="row">
                        <ListItemButton style={{ width: '100%' }} key={o.id} onClick={handleClick.bind(importContext, i)}>
                            <ListItemIcon>
                                <DoneIcon />
                            </ListItemIcon>
                            <ListItemText primary={o.file} />
                            <ListItemText primary={o.error} />
                            {importContext.expanderStates[i] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <IconButton onClick={(e) => { removeImportItem(i); }}><CancelIcon /></IconButton>
                    </Stack>
                    <Collapse key={"collapse-" + i} component="li" timeout="auto" in={importContext.expanderStates[i]} unmountOnExit>
                        <List disablePadding key={"collapse-content-" + i}>
                            <ListItem key={"sItemRoot-" + i}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                                        <colgroup>
                                            {o.headers.map((h, j) => {
                                                return (
                                                    <col key={"colWidth_" + j} width={h[1] + "%"} />
                                                )
                                            })}
                                        </colgroup>
                                        <TableHead>
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                {o.headers.map((h, j) => {
                                                    return (
                                                        <StyledTableCell>{h[0]}</StyledTableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(importContext.rowsPerPage > 0
                                                ? o.records.slice(importContext.page * importContext.rowsPerPage, importContext.page * importContext.rowsPerPage + importContext.rowsPerPage)
                                                : o.records).map((r, j) => {
                                                    return (
                                                        <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                            {r.map((value) => (
                                                                <StyledTableCell>{value}</StyledTableCell>
                                                            ))}
                                                        </StyledTableRow>
                                                    )
                                                })}

                                            {getEmptyRowCount(o) > 0 && (
                                                <TableRow style={{ height: 53 * getEmptyRowCount(o) }}>
                                                    <TableCell colSpan={o.headers.length} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10, 25]}
                                                    colSpan={o.headers.length}
                                                    count={o.records.length}
                                                    rowsPerPage={importContext.rowsPerPage}
                                                    page={importContext.page}
                                                    SelectProps={{
                                                        inputProps: {
                                                            'aria-label': 'rows per page',
                                                        },
                                                        native: true,
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActions}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </ListItem>
                        </List>
                    </Collapse>
                </div>
            );
        }
    });

    return (
        <Stack direction="row">
            <Box
                sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                }}>
                <Button
                    variant='text'
                    color="inherit"
                    sx={{
                        width: '100%',
                        textTransform: 'none'
                    }}
                    onClick={(e) => { setShowPoolDetails(!showPoolDetails); }}>
                    <Stack direction="row">
                        <Typography
                            color="inherit"
                            variant="caption"
                            component="div"
                            sx={{ flexGrow: 1, textAlign: 'center' }}>{showPoolDetails ? "Hide" : "Show"} Pool Details</Typography>
                        {showPoolDetails ? <ExpandLess /> : <ExpandMore />}
                    </Stack>
                </Button>
                <Collapse timeout="auto" in={showPoolDetails} unmountOnExit>
                    <Grid container spacing={2}>
                        <Grid xs={12}>
                            <QuestionPoolSelector focus={fakePool} isReadOnly={false} onChange={targetPoolChanged} isRequired={false} />
                        </Grid>
                        <Grid xs={12}>
                            <GenerateQuestionPool focus={newPool} isReadOnly={false} showListing={false} isDisabled={newPool === undefined} />
                        </Grid>
                    </Grid>
                </Collapse>
                <Grid xs={12}>
                    <Stack direction="row">
                        <Button
                            color="inherit"
                            component="label"
                            variant="outlined"
                            startIcon={<UploadFileIcon />}
                            sx={{ marginRight: "1rem" }}>
                            {"Upload CSV(s)"}
                            <input type="file" accept=".csv" hidden onChange={onFileUpload} />
                        </Button>
                        <Typography color="inherit" variant="h6" component="div">Overwrite Existing Pools</Typography>
                        <Switch
                            id="will-overwrite"
                            defaultChecked={importContext.overwriteWithNew}
                            onChange={onEdited} />
                    </Stack>
                </Grid>
                {/* <Paper style={{ height: '44vh', maxHeight: '44vh', width: '100%', overflow: 'auto' }}> */}
                <Box sx={{ flexGrow: 1, flexDirection: 'column', overflow: 'auto' }}>
                    <List>{listItems}</List>
                </Box>
            </Box>
            <Box>
                <IconButton
                    onClick={(e) => {
                        handleConfirm();
                        setLastImportSuccessful(true);
                    }}
                    disabled={importContext.completedResults.length === 0}>
                    <UploadFileIcon />
                </IconButton>
            </Box>
            <ConfirmationDialog
                title='Import Successful'
                confirmText='Okay'
                message='The import succeeded, and your question pool has been created.'
                isOpen={lastImportSuccess}
                onConfirm={(e) => { setLastImportSuccessful(false); }}
                onCancel={(e) => { setLastImportSuccessful(false); }}
            />
        </Stack>
    );
}