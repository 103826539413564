
import * as React from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import { randomId } from '@mui/x-data-grid-generator';

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import { QuizzicalContext } from '../../Context/QuizzicalContext';
import FormEditorHost_Validator from '../../Libraries/FormEditorHost_Validator';

/*
** Encapsulates the FEH form implementation for easy reproduction
**
** Handles the validation call, confirmation, and cancelation via parametized event handlers
**
** Property Attributes:
**
** formId: the explicit formId (defaults to random guid)
** width: the form's maximum width (defaults to 100%)
** height: the form's maximum height (defaults to 100%)
** show: boolean; if set, will display a set of buttons at the top of the form in addition to the ones at the bottom
** disabled: boolean; if true, the form is disabled
** 
** onClose: called when the form's cancel button is clicked
** onConfirm: called when the form's confirmation is clicked and validation is passed
** onReportMessages: 
   used to relay messages generated by validation failure back up to the Form Editor Host dialog
** getInstance:
   Retrieves and returns the up-to-date editing target
** type: the class name of the provided type
*/
export default function FEH_Form(props) {
    const state = React.useContext(QuizzicalContext);
    const [formId, setFormId] = React.useState((props.formId || randomId())  + '_' + randomId());
    const [disabled, setDisabled] = React.useState(props.disabled !== undefined ? props.disabled : false);
    const formRef = React.useRef();

    React.useEffect(() => {
        if (props.disabled !== undefined &&
            props.disabled !== null &&
            props.disabled !== disabled) {
            setDisabled(props.disabled);
        }
    }, [props])

    const triggerFormSubmission = (event) => {
        FEH_Form.SubmittedForm = event.currentTarget.id;
    }

    const handleFormSubmission = (event, callingFormId) => {
        event.preventDefault();
        if (props.disabled === true) return false;

        if (FEH_Form.SubmittedForm === callingFormId) {
            let result = FormEditorHost_Validator(props.getInstance(), props.type, state);
            if (result.isValid === true) {
                props.onConfirm();
            } else {
                props.onReportMessages(result.issues);
            }
        }
    }

    let buttonBar = (
        <Grid
            container
            justifyContent="flex-end"
            sx={{ flexGrow: 1 }}>
            <Button
                color="inherit"
                onClick={props.onClose}>
                <Stack direction='row' spacing={1}>
                    <CancelIcon />
                    <Typography variant='button'>Cancel</Typography>
                </Stack>
            </Button>
            <Button
                color="inherit"
                type="submit"
                form={formId}
                onClick={triggerFormSubmission}
                id={formId}>
                <Stack direction='row' spacing={1}>
                    <SaveIcon />
                    <Typography variant='button'>Confirm</Typography>
                </Stack>
            </Button>
        </Grid>
    );

    return (
        <form            
            id={formId}
            ref={formRef}
            onSubmit={(event) => { handleFormSubmission(event, formId); }}>
            {props.show === true ? buttonBar : undefined}
            <Paper
                sx={{
                    overflow: 'auto',
                    padding: 3,
                    width: (props.width || '100%'),
                    height: (props.height || '100%'),
                }}>
                {props.children}
            </Paper>
            {buttonBar}
        </form>
    );
}

FEH_Form.SubmittedForm = undefined;