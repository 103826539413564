import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Checkbox } from '@mui/material';
import { randomId } from '@mui/x-data-grid-generator';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import FieldContainer from '../../Forms/FieldContainer';
import FEH_Form from '../../Forms/FEH_Form';
import Subject from '../../../Classes/Subjects';
import ScriptItem from '../../../Classes/ScriptItem';
import { TabPanel, a11yProps } from '../../../Libraries/TabPanel';
import GenericList from '../../Lists/GenericList';
import RegionLayoutEditor from '../../ContentEditor/RegionLayoutEditor';
import RegionLayoutDimensionsEditor from '../../ContentEditor/RegionLayoutDimensionsEditor';
import { FillInQuestion, MultipleChoiceQuestion } from '../../../Classes/Questions';

export default function FEH_LayoutEditor(props) {
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [defaultFieldSet, setDefaultFieldSet] = React.useState(randomId());
    const [tabIndex, setTabIndex] = React.useState(0);
    const [refreshRequired, setRefreshRequired] = React.useState(false);

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }
    }, [props]);

    const updateFocus = () => {
        if (tabIndex === 0) {
            // if we aren't on the details tab then there won't be any of the expected controls.
            // skip the update and just return what we have (which will be up-to-date)
            if (focus !== undefined && getTargetEntity().constructor.ClassName() !== Subject.ClassName()) {
                focus.setTitle(document.getElementById("layout-title").value);
                focus.setIsExposition(document.getElementById("layout-explanatory").checked);
            } else {
                focus.setTitle("A");
                focus.setIsExposition(false);
            }
        }

        return focus;
    }

    const getTargetEntity = () => {
        let target = focus;
        while ([Subject.ClassName(), FillInQuestion.ClassName(), MultipleChoiceQuestion.ClassName()]
            .includes((target = target.getParent()).constructor.ClassName()) === false) {
            if (target.getParent() === undefined) {
                break;
            }
        }

        return target;
    }

    const handleTabChanged = (event, newValue) => {
        if (tabIndex === 0) {
            updateFocus();
            setFocus(focus);
        }
        setTabIndex(newValue);
    };

    if (focus === undefined || focus === null) {
        return '';
    }

    if (isReadOnly) {
        return ( // readonly
            <Box
                sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                }}>
                <Grid spacing={2}>
                    {
                        focus !== undefined && focus.getParent().constructor.ClassName() !== Subject.ClassName() ?
                            <Stack spacing={1}>
                                <Stack direction="row" sx={{ alignItems: 'center' }}>
                                    <Typography color="inherit" variant="body" component="div">Layout Name:&nbsp;&nbsp;&nbsp;</Typography>
                                    <Typography color="inherit" variant="caption" component="div">{focus.getTitle()}</Typography>
                                </Stack>
                                <Stack>
                                    <Typography color="inherit" variant="body" component="div">Is Explanatory:&nbsp;&nbsp;&nbsp;</Typography>
                                    <Typography color="inherit" variant="caption" component="div">{focus.isExplanatory() ? 'Yes' : 'No'}</Typography>
                                </Stack>
                            </Stack> : ''
                    }
                    <Stack direction="row">
                        <RegionLayoutEditor
                            sx={{
                                position: 'relative',
                                width: '64px',
                                height: '64px',
                            }}
                            refreshRequired={refreshRequired}
                            focus={focus}
                            isReadOnly={true}
                            asMosaic={true} />
                    </Stack>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabIndex} onChange={handleTabChanged} aria-label="basic tabs example">
                            <Tab label="Pre-Step Scripts" {...a11yProps(0)} />
                            <Tab label="Post-Step Scripts" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={tabIndex} index={0} sx={{ width: '100%', height: '100%' }}>
                        <Typography color="inherit" component="div">These pre-scripts are executed in order, each time before this specific layout is displayed.</Typography>
                        {/* <ScriptList
                            focus={scriptListingSource}
                            property="getPreScripts" /> */}
                        <GenericList
                            isReadOnly={isReadOnly}
                            isCollapsable={false}
                            label="Pre-Step Script"
                            showHeaderLabel={false}
                            getTargetItems={() => {
                                return focus.getPreScripts();
                            }}
                            setTargetItems={(items) => {
                                focus.setPreScripts(items);
                            }}
                            getDefaultItem={() => {
                                return ScriptItem.getDefault(focus, undefined, undefined);
                            }} />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1} sx={{ width: '100%', height: '100%' }}>
                        <Typography color="inherit" component="div">These post-scripts are executed in order, each time after this specific layout is displayed.</Typography>
                        {/* <ScriptList
                            focus={scriptListingSource}
                            property="getPostScripts" /> */}
                        <GenericList
                            isReadOnly={isReadOnly}
                            isCollapsable={false}
                            label="Pre-Step Script"
                            showHeaderLabel={false}
                            getTargetItems={() => {
                                return focus.getPostScripts();
                            }}
                            setTargetItems={(items) => {
                                focus.setPostScripts(items);
                            }}
                            getDefaultItem={() => {
                                return ScriptItem.getDefault(focus, undefined, undefined);
                            }} />
                    </TabPanel>
                </Grid>
            </Box>
        );
    } else {
        return (
            <FEH_Form
                height="87vH"
                formId="QuestionLayoutForm"
                type="QuestionLayout"
                getInstance={updateFocus}
                onClose={props.onClose}
                onConfirm={props.onConfirm}
                onReportMessages={props.onReportMessages}>
                <Box sx={{ width: '100%', height: '90%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabIndex} onChange={handleTabChanged} aria-label="basic tabs example">
                            <Tab label="Details" {...a11yProps(0)} />
                            <Tab label="Pre-Step Scripts" {...a11yProps(1)} />
                            <Tab label="Post-Step Scripts" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={tabIndex} index={0} sx={{ width: '100%', height: '100%' }}>
                        <Stack>
                            <Stack>
                                {
                                    focus !== undefined && focus.getParent().constructor.ClassName() !== Subject.ClassName() ?
                                        <Stack spacing={2}>
                                            <FieldContainer
                                                fieldSet={defaultFieldSet}
                                                label="Layout Name"
                                                help="This layout's name"
                                                fullWidth
                                                required>
                                                <TextField
                                                    disabled={isReadOnly}
                                                    id="layout-title"
                                                    required
                                                    defaultValue={focus.getTitle()}
                                                    sx={{ minWidth: '100%', width: '100%' }} />
                                            </FieldContainer>
                                            <FieldContainer
                                                fieldSet='Layout_Is_Explanatory'
                                                label="Is Explanatory"
                                                help="If checked, when explanatory layouts are hidden this layout will not be shown">
                                                <Checkbox
                                                    id="layout-explanatory"
                                                    defaultChecked={focus.isExplanatory()} />
                                            </FieldContainer>
                                        </Stack> : ''
                                }
                            </Stack>
                            <Stack>
                                {
                                    focus.getParent().getParent().constructor.ClassName() === Subject.ClassName() ||
                                        (focus.getParent().getQuestionType !== undefined && focus.getParent().getParent().constructor.ClassName() !== Subject.ClassName()) ?
                                        <Grid
                                            container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center">
                                            <RegionLayoutDimensionsEditor
                                                label="Edit Dimensions"
                                                entity={focus}
                                                onRefreshRequired={(e) => { setRefreshRequired(!refreshRequired); }} />
                                        </Grid> : ""
                                }
                                <RegionLayoutEditor
                                    sx={{
                                        position: 'relative',
                                        height: 'calc(80vH - 65px)',
                                    }}
                                    refreshRequired={refreshRequired}
                                    focus={focus}
                                    isReadOnly={isReadOnly} />
                            </Stack>
                        </Stack>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1} sx={{ width: '100%', height: '100%' }}>
                        <Typography color="inherit" component="div">These pre-scripts are executed in order, each time before this specific layout is displayed.</Typography>
                        {/* <ScriptList
                            focus={scriptListingSource}
                            property="getPreScripts" /> */}
                        <GenericList
                            isReadOnly={isReadOnly}
                            isCollapsable={false}
                            label="Pre-Step Script"
                            showHeaderLabel={false}
                            getTargetItems={() => {
                                return focus.getPreScripts();
                            }}
                            setTargetItems={(items) => {
                                focus.setPreScripts(items);
                            }}
                            getDefaultItem={() => {
                                return ScriptItem.getDefault(focus, undefined, undefined);
                            }} />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2} sx={{ width: '100%', height: '100%' }}>
                        <Typography color="inherit" component="div">These post-scripts are executed in order, each time after this specific layout is displayed.</Typography>
                        {/* <ScriptList
                            focus={scriptListingSource}
                            property="getPostScripts" /> */}
                        <GenericList
                            isReadOnly={isReadOnly}
                            isCollapsable={false}
                            label="Pre-Step Script"
                            showHeaderLabel={false}
                            getTargetItems={() => {
                                return focus.getPostScripts();
                            }}
                            setTargetItems={(items) => {
                                focus.setPostScripts(items);
                            }}
                            getDefaultItem={() => {
                                return ScriptItem.getDefault(focus, undefined, undefined);
                            }} />
                    </TabPanel>
                </Box>
            </FEH_Form>
        );
    }
}