import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import DescriptionIcon from '@mui/icons-material/Description';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { QuizzicalContext } from '../Context/QuizzicalContext';

export default function BreadcrumbHelper(target, maxItems = 3, stackSpacing = 0.5, separator = undefined, variant = 'inherit') {
    const state = React.useContext(QuizzicalContext);

    let getHeaderText = (target) => {
        let result = "";
        
        if (target.getTitle !== undefined) {
            result = target.getTitle(state);
        } else if (target.getName !== undefined) {
            result = target.getName();
        }

        return result;
    }

    if (target !== undefined) {
        let series = [];
        let topic = target;

        while (topic !== undefined) {
            /* series.unshift(
                <Button
                    variant='text'
                    sx={{
                        textTransform: 'none'
                    }}
                    color="inherit">
                    <Stack
                        key={topic.getId() + "-" + series.length}
                        direction="row"
                        spacing={stackSpacing}>
                        {topic.getSelectedIcon !== undefined ? topic.getSelectedIcon() : <DescriptionIcon />}
                        <Typography color="inherit" component="div">{getHeaderText(topic)}</Typography>
                    </Stack>
                </Button>); */

            series.unshift(
                <Stack
                    key={topic.getId() + "-" + series.length}
                    direction="row"
                    spacing={stackSpacing}>
                    {topic.getSelectedIcon !== undefined ? topic.getSelectedIcon() : <DescriptionIcon />}
                    <Typography variant={variant}>{getHeaderText(topic)}</Typography>
                </Stack>);

            if (topic.getParent !== undefined) {
                topic = topic.getParent();
            } else {
                topic = undefined;
            }
        }

        return (
            <Breadcrumbs
                maxItems={maxItems}
                separator={separator || <NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                color="inherit">
                {series}
            </Breadcrumbs>
        );
    }
}