import * as React from 'react';
import { randomId } from '@mui/x-data-grid-generator';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import { Switch } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';

import PlayArrowIcon from '@mui/icons-material/PlayArrow'; // playing / not playing
import SlideshowIcon from '@mui/icons-material/Slideshow';
import ReplayIcon from '@mui/icons-material/Replay';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import HelpIcon from '../Forms/HelpIcon';
import GenericList from '../Lists/GenericList';
import PresentationDialog from '../../Screens/Dialogs/PresentationDialog';
import AttendanceTrackingDialog from '../../Screens/Dialogs/AttendanceTrackingDialog';
import GenericSelector from '../GenericSelector';
import { Course } from '../../Classes/Personnel/Course';
import { AssociationTypes } from '../../Classes/Personnel/RoleAssociation';
import downloadFiles from '../../Libraries/jsonDigestion';
import ReplayContainer from '../../Classes/ReplayContainer';

// a dummy class to assist with the course query
class PresentationDataStorer {
    constructor(courseId) {
        this.courseId = courseId;
    }

    getCourseId() {
        return this.courseId;
    }

    setCourseId(courseId) {
        this.courseId = courseId;
    }

    static ClassName() {
        return "presentationDataStorer";
    }
}

// the main container that binds everything together
export default function PresentationRoot(props) {
    const state = props.state;
    const [courseContainer, setCourseContainer] = React.useState(new PresentationDataStorer());
    const [presentationSet, setPresentationSet] = React.useState([...state.Examinations.Assignments]);
    const [showPresentationSelection, setShowPresentationSelection] = React.useState(true);
    const [showAttendance, setShowAttendance] = React.useState(false);
    const [pregenQuestions, setPregenQuestions] = React.useState(undefined);
    const [lessonKey, setLessonKey] = React.useState(undefined);

    const [historicalQuestions, setHistoricalQuestions] = React.useState([]);
    const [studentAttendanceData, setStudentAttendanceData] = React.useState({});
    const [mode, setMode] = React.useState('present');
    const [pregenQuestionCount, setPregenQuestionCount] = React.useState(30);
    const [uploads, setUploads] = React.useState([]);
    const [replays, setReplays] = React.useState([]);
    const [shuffleReplaysTogether, setShuffleReplaysTogether] = React.useState(undefined);
    const [forceRefresh, setForceRefresh] = React.useState(false);

    const onPresentationChanged = (selected, unselected, allSelected) => {
        setPresentationSet(allSelected.map((id) => {
            return state.Examinations.Assignments.filter((a) => {
                return a.getId() === id;
            })[0];
        }));
    }

    const handlePregenQuestionCountChanged = (event) => {
        setPregenQuestionCount(+document.getElementById("pregenQuestionCount").value);
    }

    const handleBeginPresentation = (event) => {
        setLessonKey(randomId());
        setPregenQuestions(document.getElementById("pregenQuestionCount").value);
        setShowPresentationSelection(false);
    }

    const handleBeginReplay = (event) => {
        setLessonKey(randomId());
        setShuffleReplaysTogether(document.getElementById("shuffleReplaysTogether").checked);
        setShowPresentationSelection(false);
    }

    const handleAttendance = () => {
        setShowAttendance(true);
    }

    const onCloseAttendance = () => {
        setShowAttendance(false);
    }

    const getRelevantStudents = () => {
        let students = [];

        if (courseContainer !== undefined &&
            courseContainer.getCourseId() !== undefined) {

            let studentIds = state.Associations.filter((role) => {
                return role.getWhatId() === courseContainer.getCourseId() && role.getType() === AssociationTypes.Student;
            }).map((association) => {
                return association.getWhoId();
            });

            students = state.People.filter((person) => {
                return studentIds.includes(person.getId());
            });
        }

        return students;
    }

    const validateCanBeginPresentations = () => {
        return presentationSet.length <= 0 || courseContainer.getCourseId() === undefined || pregenQuestionCount <= 0;
    }

    const validateCanBeginReplays = () => {
        return replays.length <= 0 || courseContainer.getCourseId() === undefined;
    }

    const onSelectionChanged = (o, wasSelected) => {
        if (wasSelected) {
            setCourseContainer(new PresentationDataStorer(o.getId()));
        } else {
            setCourseContainer(new PresentationDataStorer(undefined));
        }
    }

    const onQuestionsGenerated = (questionsSet) => {
        setHistoricalQuestions(questionsSet);
    }

    const onFileUpload = (event) => {
        let attempted = downloadFiles(
            event,
            (err) => {
                // will deal with later
            },
            (start) => {
                uploads.push(start);
            },
            (prgrs) => {
                let match = getUploadById(prgrs.id);
                if (match !== undefined) {
                    match.progress = prgrs.progress;
                    setForceRefresh(!forceRefresh);
                }
            },
            (done) => {
                let match = getUploadById(done.id);
                if (match !== undefined) {
                    match.progress = 1;
                    match.content = done.content;
                    replays.push(new ReplayContainer(match));
                    setForceRefresh(!forceRefresh);
                }
            },
            () => {
                setForceRefresh(!forceRefresh);
            }
        );

        //if (!attempted) alert("Gah");
    }

    const getUploadById = (id) => {
        let matches = uploads.filter((r) => {
            return r.id === id;
        });

        if (matches.length === 1) {
            return matches[0];
        } else {
            return undefined;
        }
    }

    const deleteArrayItem = (array, item) => {
        let index = array.indexOf(item);
        if (index >= 0) {
            array.splice(index, 1);
        }

        return array;
    }

    if (state !== undefined) {
        return (
            <Grid
                sx={{
                    paddingRight: 2,
                    paddingLeft: 2,
                }}>
                <Stack>
                    <ToggleButtonGroup
                        value={mode}
                        exclusive
                        onChange={(e, newMode) => { setMode(newMode); setReplays([]); setPresentationSet([]); }}
                        aria-label="presentation mode">
                        <Tooltip arrow title="Presentation Mode: present a fresh set of questions to the viewers">
                            <ToggleButton
                                selected={mode === 'present'}
                                value="present"
                                aria-label="centered">
                                <SlideshowIcon fontSize='small' />
                            </ToggleButton>
                        </Tooltip>
                        <Tooltip arrow title="Replay Mode: present questions from a replay file to the viewers">
                            <ToggleButton
                                selected={mode === 'replay'}
                                value="replay"
                                aria-label="left aligned">
                                <ReplayIcon fontSize='small' />
                            </ToggleButton>
                        </Tooltip>
                    </ToggleButtonGroup>

                    <Collapse timeout="auto" in={mode === 'present'} mountOnEnter unmountOnExit>
                        <Stack spacing={2}>
                            <Stack spacing={1}>
                                <GenericList
                                    rows={6}
                                    propagateDefaultSelectedState={true}
                                    defaultSelectedState={true}
                                    help={"Please select any number of assignments to display"}
                                    isReadOnly={true}
                                    isCollapsable={false}
                                    label="Available Assignments"
                                    showHeaderLabel={true}
                                    getTargetItems={() => {
                                        return state.Examinations.Assignments;
                                    }}
                                    setTargetItems={(items) => {
                                        // it's read only
                                    }}
                                    getDefaultItem={() => {
                                        return undefined; // it's read only
                                    }}
                                    onSelectionChanged={onPresentationChanged} />
                            </Stack>
                            <GenericSelector
                                cannotQueryMessage={"Unable to display courses.  Please ensure that there are organizations with associated courses."}
                                query={(() => {
                                    // we want to query orgs and courses at *any* depth
                                    // easiest way to do that is recursion
                                    // this defines a recursive query that searches for orgs under orgs ad infinium
                                    // it searches for courses off of each org

                                    // this works because when records are no longer found,
                                    // the query will cleanly terminate and return the result
                                    let query = [];

                                    let orgNode = {
                                        get: 'getChildren',
                                        items: [],
                                        return: true,
                                        selectable: false,
                                    };
                                    let courseNode = {
                                        get: 'getCourses',
                                        return: true,
                                    };

                                    orgNode.items.push(orgNode);
                                    orgNode.items.push(courseNode);
                                    query.push(courseNode);
                                    query.unshift(orgNode);

                                    return query;
                                })()}
                                root={state.Organizations}
                                focus={courseContainer}
                                selectionRetrieval="getCourseId"
                                selectionStorage="setCourseId"
                                onSelectionChanged={onSelectionChanged}
                                label="Course"
                                multiMode={false}
                                selectionType={Course.ClassName()}
                                isReadOnly={false}
                                disabled={false} />
                            <Stack
                                direction="row"
                                spacing={2}>
                                <Stack
                                    direction="row"
                                    spacing={1}>
                                    <TextField
                                        id="pregenQuestionCount"
                                        label="Pre-generated Questions"
                                        variant="outlined"
                                        type='number'
                                        defaultValue={pregenQuestionCount}
                                        onChange={handlePregenQuestionCountChanged} />
                                </Stack>
                                <Tooltip title="Starts the presentations">
                                    <Button
                                        disabled={validateCanBeginPresentations()}
                                        color="inherit"
                                        startIcon={<PlayArrowIcon />}
                                        variant='outlined'
                                        onClick={handleBeginPresentation}>Begin</Button>
                                </Tooltip>
                            </Stack>
                        </Stack>

                        <PresentationDialog
                            replay={mode === 'replay'}
                            lessonKey={lessonKey}
                            onAttendanceManagementRequest={handleAttendance}
                            isOpen={showPresentationSelection === false}
                            pregenQuestions={pregenQuestions}
                            presentationSet={presentationSet}
                            questionsGenerated={onQuestionsGenerated}
                            onCancel={() => { setShowPresentationSelection(true); }} />
                    </Collapse>
                    {/* end of presentation section */}

                    <Collapse timeout="auto" in={mode === 'replay'} mountOnEnter unmountOnExit>
                        <Stack padding={2} spacing={2}>
                            <Stack
                                container sx={{ alignItems: 'center' }}
                                direction="row-reverse">
                                <HelpIcon help="Upload a valid replay file to present" />
                                <Button
                                    color="inherit"
                                    component="label"
                                    variant="outlined"
                                    startIcon={<PublishIcon />}
                                    sx={{ marginRight: "1rem" }}>
                                    <Typography>Upload</Typography>
                                    <input type="file" accept=".rply" hidden multiple onChange={onFileUpload} />
                                </Button>
                            </Stack>

                            <List>
                                {replays.map((r, i) => {
                                    return (
                                        <ListItem
                                            disablePadding
                                            secondaryAction={
                                                <Tooltip title="Remove this replay file">
                                                    <IconButton onClick={(e) => { deleteArrayItem(replays, r); setForceRefresh(!forceRefresh); }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>}>
                                            <ListItemIcon>
                                                <ReplayCircleFilledIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={r.getTitle()}
                                                secondary={"Replay contains " + r.getCount() + " items."} />
                                        </ListItem>
                                    );
                                })}
                            </List>

                            <Stack spacing={1}>
                                <GenericSelector
                                    cannotQueryMessage={"Unable to retrieve courses.  Please ensure that there are organizations with associated courses."}
                                    query={(() => {
                                        // we want to query orgs and courses at *any* depth
                                        // easiest way to do that is recursion
                                        // this defines a recursive query that searches for orgs under orgs ad infinium
                                        // it searches for courses off of each org

                                        // this works because when records are no longer found,
                                        // the query will cleanly terminate and return the result
                                        let query = [];

                                        let orgNode = {
                                            get: 'getChildren',
                                            items: [],
                                            return: true,
                                            selectable: false,
                                        };
                                        let courseNode = {
                                            get: 'getCourses',
                                            return: true,
                                        };

                                        orgNode.items.push(orgNode);
                                        orgNode.items.push(courseNode);
                                        query.push(courseNode);
                                        query.unshift(orgNode);

                                        return query;
                                    })()}
                                    root={state.Organizations}
                                    focus={courseContainer}
                                    selectionRetrieval="getCourseId"
                                    selectionStorage="setCourseId"
                                    onSelectionChanged={onSelectionChanged}
                                    label="Course"
                                    multiMode={false}
                                    selectionType={Course.ClassName()}
                                    isReadOnly={false}
                                    disabled={false} />
                                <Stack direction="row">
                                    <FormControlLabel control={<Checkbox id='shuffleReplaysTogether' />} label="Shuffle Sets Together" />

                                    <Tooltip title="Starts the replays">
                                        <Button
                                            disabled={validateCanBeginReplays()}
                                            color="inherit"
                                            startIcon={<PlayArrowIcon />}
                                            variant='outlined'
                                            onClick={handleBeginReplay}>Begin</Button>
                                    </Tooltip>
                                </Stack>
                            </Stack>
                        </Stack>

                        <PresentationDialog
                            shuffleReplaysTogether={shuffleReplaysTogether}
                            replay={mode === 'replay'}
                            lessonKey={lessonKey}
                            onAttendanceManagementRequest={handleAttendance}
                            isOpen={showPresentationSelection === false}
                            presentationSet={replays}
                            questionsGenerated={onQuestionsGenerated}
                            onCancel={() => { setShowPresentationSelection(true); }} />
                    </Collapse>
                    {/* end of replay section */}
                </Stack>

                <AttendanceTrackingDialog
                    replay={mode === 'replay'}
                    attendanceData={studentAttendanceData}
                    historicalQuestions={historicalQuestions}
                    isOpen={showAttendance}
                    onCancel={onCloseAttendance}
                    onAttendanceDataUpdated={setStudentAttendanceData}
                    lessonKey={lessonKey}
                    getStudents={getRelevantStudents} />
            </Grid>
        );
    } else {
        return (<Typography>Working</Typography>);
    }
}