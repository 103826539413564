import React, { useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { Checkbox } from '@mui/material';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import InputBase from '@mui/material/InputBase';
import Pagination from '@mui/material/Pagination';

import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import ViewComfyOutlinedIcon from '@mui/icons-material/ViewComfyOutlined';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import AddIcon from '@mui/icons-material/Add';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

import { useTheme } from '@mui/material/styles';

import CheckBoxIcon from '@mui/icons-material/CheckBox'; // all selected (unselects all)
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'; // none selected (selects all)
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'; // some selected (selects all)

import { QuizzicalContext } from '../../Context/QuizzicalContext';
//import EditorDialog from '../../Screens/Dialogs/EditorDialog';
import ConfirmationDialog from '../../Screens/Dialogs/ConfirmationDialog';
//import GenericEditor from '../../Controls/Editors/GenericEditor';
import { jsonifyArray } from '../../Libraries/generalUtility';
import ItemDependencyListing from '../ItemDependencyListing';
import FormEditorHost from '../../Screens/Dialogs/FormEditorHost';
import FEH_GenericProvider from '../Editors/FEH_GenericProvider';
import HelpIcon from '../Forms/HelpIcon';
import Tip from '../Forms/Tip';

import Pluralize from 'pluralize';
import RegionLayoutEditor from '../ContentEditor/RegionLayoutEditor';

/*
** A generic list control used to display the bevy of categories and sub categories in this application
** The following parameters have an effect:
**
** label: the capitalized, singular form of the type this list displays
** showHeaderLabel: whether the header label should be displayed
** isCollapsable: whether the list should allow itself to be collapsed via converting the top label into a button
** isReadOnly: whether the list is to operate in readonly mode
** canAdd: if false, the add button will be disabled
** canEdit: if false, the edit row buttons will not be shown.  If true, overrides the readonly state.
** canDelete: if false, the delete and delete selected buttons will not be displayed
** collapseMoveIcons: display a mouse over menu to access the shifting buttons
** fullscreen: if the opened editor dialog when adding / editing an item should be in fullscreen or normal dialog mode
** isExpandable: whether list rows can be clicked on to show a more detailed subview (default: true)
** canFilter: if true, the filter button appears allowing filtration
** rowContent: if provided, this will be the content of row expansions
** rows: defaults to indeterminate, sets the total number of rows to display
** state: changes the context object to use for editors
** defaultSelectedState: <default: false>: if true then all list items will default to selected.
** propagateDefaultSelectedState: <default: false> if true, the defaultSelectedState property will be carried through the editing system.
** validPastes: <default: undefined> if provided, should be a single or an array of acceptable types for pasting via the copy/paste system
**
** overrideAddIcon: replaces the default add icon (the generic + icon) with the provided one
** overrideDeleteSelectedIcon: replaces the default delete selected icon (the double check mark icon) with the provided one
**
** getTargetItems():
** a method to return an array of the items to display
**
** getAdditionalItems():
** a method to return an array of the items to display
**
** checkIsChild(item):
** a method to determine if the given item should be considered a child of the target object.  
** Affects list item coloration and only items that are immediate children are returned for updates
**
** setTargetItems(items):
** a method to update the provides items to the focus reference
**
** getDefaultItem:
** a method to return a default instance of the item contained in the list
**
** getTitleText(item):
** produces and returns the list item's display text.  Defaults to a call to getTitle()
**
** onSelectionChanged(selected, unselected, allSelected):
** called whenever the user selects or unselects an item in the list via the left most icon on each item
** 
** onAddClicked():
** called when the user clicks the add button on the grid
*/

export const ListRowContentMode = {
    Details: 0,
    Dependencies: 1,
    None: 2,
};

export default function RegionLayoutManagerList(props) {
    const state = props.state;
    const clipboardState = useContext(QuizzicalContext);
    const [isReadOnly, setIsReadOnly] = useState(props.isReadOnly || false);
    const [isExpandable, setIsExpandable] = useState(undefined);
    const [canDelete, setCanDelete] = useState(true);
    const [canAdd, setCanAdd] = useState(true);
    const [canEdit, setCanEdit] = useState(undefined);
    const [expanders, setExpanders] = useState({});
    const [selecteds, setSelecteds] = useState({});
    const [deletionTarget, setDeletionTarget] = useState(undefined);
    const [isDeletionConfirmationOpen, setIsDeletionConfirmationOpen] = useState(false);
    const [deleteSelectedTargets, setDeleteSelectedTargets] = useState(undefined);
    const [isDeleteSelectedConfirmationOpen, setIsDeleteSelectedConfirmationOpen] = useState(false);
    const [isEditShown, setIsEditShown] = useState(false);
    const [editFocus, setEditFocus] = useState(undefined);
    const [isExpanded, setIsExpanded] = React.useState(props.isExpanded || false);
    const [forceRefresh, setForceRefresh] = React.useState(false);
    const [fullscreenEditor, setFullscreenEditor] = React.useState(true);

    const [isFiltering, setIsFiltering] = React.useState(false);
    const [canFilter, setCanFilter] = React.useState(true);
    const [filterValue, setFilterValue] = React.useState(undefined);

    const [rowMode, setRowMode] = React.useState(ListRowContentMode.Details);

    const [pagingData, setPagingData] = React.useState({
        rows: undefined,
        index: 1,
        pages: 0,
        items: undefined,
    });

    React.useEffect(() => {
        if (props.fullscreen !== undefined &&
            props.fullscreen !== fullscreenEditor) {
            setFullscreenEditor(props.fullscreen);
        }

        if (props.canFilter !== undefined &&
            props.canFilter !== canFilter) {
            setCanFilter(props.canFilter);
        }

        if (props.canDelete !== undefined &&
            props.canDelete !== canDelete) {
            setCanDelete(props.canDelete);
        }

        if (props.canAdd !== undefined &&
            props.canAdd !== canAdd) {
            setCanAdd(props.canAdd);
        }

        if (props.canEdit !== undefined &&
            props.canEdit !== canEdit) {
            setCanEdit(props.canEdit);
        }

        if (props.mode !== undefined &&
            props.mode !== rowMode) {
            setRowMode(props.mode);
        }

        if (props.rows !== undefined &&
            props.rows !== pagingData.rows) {
            let update = { ...pagingData, rows: props.rows };
            updatePageCount(update);
            setPagingData(update);
        }

        setIsExpandable(getValueOrDefault(props.isExpandable, true));
    }, [props]);

    const currentTheme = useTheme();

    /* Item Management */

    const updatePageCount = (instance) => {
        if (instance.rows !== undefined &&
            instance.items !== undefined) {
            let pages = instance.items.length / instance.rows;
            if (pages > Math.floor(pages)) {
                pages = Math.floor(pages) + 1;
            }
            instance.pages = pages;
        }
    }

    const doPaging = (items) => {
        if (pagingData.rows !== undefined) {
            let startingPageIndex = ((pagingData.index - 1) * pagingData.rows);
            let endingPageIndex = ((pagingData.index - 1) * pagingData.rows) + pagingData.rows;

            return items.slice(startingPageIndex, endingPageIndex);
        } else {
            return items;
        }
    }

    const updateCache = () => {
        let newItems = getTargetItems();
        let additionalNewItems = getAdditionalItems();
        if (Array.isArray(newItems)) {
            if (Array.isArray(additionalNewItems)) {
                newItems = newItems.concat(additionalNewItems);
            }
        } else {
            if (Array.isArray(additionalNewItems)) {
                newItems = additionalNewItems;
            }
        }

        let cached = jsonifyArray(pagingData.items, false);
        let fresh = jsonifyArray(newItems, false);
        let refreshed = true;

        if (JSON.stringify(cached) !== JSON.stringify(fresh)) {
            let update = { ...pagingData };
            update.items = newItems;

            updatePageCount(update);

            // ensure that the use state contains an expansion tracker for each existing item
            var changed = false;
            for (var i = 0; i < newItems.length; i++) {
                if (!(newItems[i].getId() in expanders)) {
                    expanders[newItems[i].getId()] = false;
                    changed = true;
                }
            }
            if (changed) {
                setExpanders(expanders);
            }

            // ensure that the use state contains a selection tracker for each existing item
            changed = false;
            for (var i = 0; i < newItems.length; i++) {
                if (!(newItems[i].getId() in selecteds)) {
                    if (props.defaultSelectedState === true) {
                        selecteds[newItems[i].getId()] = true;
                        newItems[i].Select();
                    } else {
                        selecteds[newItems[i].getId()] = false;
                        newItems[i].Unselect();
                    }
                    changed = true;
                }
            }
            if (changed) {
                setSelecteds(selecteds);
            }

            setPagingData(update);
            refreshed = true;
        }

        return refreshed;
    }

    /* End Item Management */

    /* Copy / Paste Items Starts */

    const getCopyIcon = () => {
        let keys = Object.keys(selecteds);
        let selectedCount = keys.filter((id) => {
            return selecteds[id] === true;
        }).length;

        let icon = undefined;
        if (selectedCount < 2) {
            icon = <ContentCopyIcon />;
        } else if (selectedCount > 1) {
            icon = <CopyAllIcon />;
        }

        return icon;
    }

    const doCopy = () => {
        let keys = Object.keys(selecteds);
        let selectedKeys = keys.filter((id) => {
            return selecteds[id] === true;
        });

        let selectedItems = pagingData.items.filter((item) => {
            return selectedKeys.includes(item.getId());
        });

        clipboardState.setClipboard(selectedItems);
        setForceRefresh(!forceRefresh);
    }

    const doPaste = () => {
        let typeName = getPasteableTypes();
        let pasted = clipboardState
            .getClipboard(typeName)
            .map((obj) => {
                return obj.clone();
            });

        if (pasted !== undefined) {
            let clone = [...pagingData.items, ...pasted];
            setTargetItems(clone);

            for (let i = 0; i < pasted.length; i++) {
                pasted[i].resetId();
            }
        }
        setForceRefresh(!forceRefresh);
    }

    const canPaste = () => {
        let typeNames = getPasteableTypes();
        let pasted = clipboardState.getClipboard(typeNames);

        return pasted !== undefined;
    }

    const canCopy = () => {
        let onlyUnique = (value, index, array) => {
            return array.indexOf(value) === index;
        }

        let keys = Object.keys(selecteds);
        let selectedKeys = keys.filter((id) => {
            return selecteds[id] === true;
        });

        let selectedItemTypes = pagingData.items.filter((item) => {
            return selectedKeys.includes(item.getId());
        }).map((obj) => {
            return obj.constructor.ClassName();
        }).filter(onlyUnique);

        return selectedKeys.length > 0 && selectedItemTypes.length === 1;
    }

    const getPasteableTypes = () => {
        let result = [];

        if (props.validPastes !== undefined &&
            props.validPastes !== null) {
            result = Array.isArray(props.validPastes) ? props.validPastes : [props.validPastes];
        } else {
            return [];
        }

        return result;
    }

    const showCopyPaste = () => {
        let typeNames = getPasteableTypes();
        return typeNames.length > 0;
    }

    /* Copy / Paste Items Ends */

    React.useEffect(() => {
        setForceRefresh(!forceRefresh);
    }, [state]);

    const getTargetItems = () => {
        if (props.getTargetItems === undefined) alert("props.getTargetItems undefined");
        return props.getTargetItems();
    }

    const getAdditionalItems = () => {
        if (props.getAdditionalItems === undefined) return [];
        return props.getAdditionalItems();
    }

    const showPagination = () => {
        return (
            pagingData.rows !== undefined &&
            (isExpanded === true || props.isCollapsable === false)
        );
    }

    const setTargetItems = (items) => {
        if (props.setTargetItems === undefined) alert("props.setTargetItems undefined");

        if (props.checkIsChild !== undefined &&
            props.checkIsChild !== null) {
            let correctItems = items.filter((item) => {
                return props.checkIsChild(item) === true;
            });

            props.setTargetItems(correctItems);
        } else {
            props.setTargetItems(items);
        }
    }

    const getDefaultItem = () => {
        if (props.getDefaultItem === undefined) alert("props.getDefaultItem undefined");
        let d = props.getDefaultItem();
        return d;
    }

    const getTitleText = (item) => {
        if (props.getTitleText === undefined) return item.getTitle(state);

        return props.getTitleText(item);
    }

    const getHeaderLabel = (pluralized = false) => {
        let label = props.label;
        if (label !== undefined) {
            return pluralized ? Pluralize(label, 5) : Pluralize(label, 1);  //label + (pluralized ? Pluralize(label,"s" : "");
        } else {
            return undefined;
        }
    }

    const isReady = () => {
        return (
            props.getTargetItems !== undefined &&
            props.setTargetItems !== undefined &&
            (props.getDefaultItem !== undefined || canAdd === false || isReadOnly)
        );
    }

    // returns true for all, undefined for some, and false for none
    const checkSelectionState = () => {
        let keys = Object.keys(selecteds);

        let totalSelection = keys.filter((key) => {
            return selecteds[key] === true;
        });

        if (totalSelection.length === keys.length) {
            return true;
        } else if (totalSelection.length > 0) {
            return undefined;
        } else if (totalSelection.length === 0) {
            return false;
        }
    }

    const setAllSelectState = (newState) => {
        let slctds = { ...selecteds };
        let changed = [];
        let keys = Object.keys(slctds);

        for (let i = 0; i < keys.length; i++) {
            if (slctds[keys[i]] !== newState) {
                slctds[keys[i]] = newState;
                changed.push(keys[i]);
            }
        }

        // get the modified items
        let targets = pagingData.items.filter((o) => {
            return changed.includes(o.getId());
        });

        if (props.onSelectionChanged !== undefined &&
            typeof props.onSelectionChanged === 'function') {
            let picked = Object.keys(slctds).filter((id) => {
                return slctds[id] === true;
            });

            if (newState) {
                props.onSelectionChanged(changed, undefined, picked);
                for (let i = 0; i < targets.length; i++) {
                    targets[i].Select();
                }
            } else {
                props.onSelectionChanged(undefined, changed, picked);
                for (let i = 0; i < targets.length; i++) {
                    targets[i].Unselect();
                }
            }
        } else {
            if (newState) {
                for (let i = 0; i < targets.length; i++) {
                    targets[i].Select();
                }
            } else {
                for (let i = 0; i < targets.length; i++) {
                    targets[i].Unselect();
                }
            }
        }

        setSelecteds(slctds);
    }

    const getSelectionToggleIconByState = () => {
        let selectionState = checkSelectionState();
        let icon = undefined;
        if (selectionState === true) {
            icon = <CheckBoxIcon />;
        } else if (selectionState === undefined) {
            icon = <IndeterminateCheckBoxIcon />;
        } else if (selectionState === false) {
            icon = <CheckBoxOutlineBlankIcon />;
        }

        return icon;
    }

    const onSelectionStateClick = () => {
        let selectionState = checkSelectionState();

        if (selectionState === true) {
            setAllSelectState(false);
        } else if (selectionState === undefined) {
            setAllSelectState(true);
        } else if (selectionState === false) {
            setAllSelectState(true);
        }
    }

    const getSelectionToggleTooltipByState = () => {
        let selectionState = checkSelectionState();
        let tooltip = undefined;
        if (selectionState === true) {
            tooltip = "Unselect all rows";
        } else if (selectionState === undefined) {
            tooltip = "Select all rows";
        } else if (selectionState === false) {
            tooltip = "Select all rows";
        }

        return tooltip;
    }

    const getValueOrDefault = (value, deflt) => {
        if (value === undefined) {
            return deflt;
        } else {
            return value;
        }
    }

    const checkAnySelected = () => {
        var anySelected = false;
        for (var key in selecteds) {
            if (selecteds[key] === true) {
                anySelected = true;
                break;
            }
        }

        return !anySelected;
    }

    const checkAnyExpanded = () => {
        var anyExpanded = false;
        for (var key in expanders) {
            if (expanders[key] === true) {
                anyExpanded = true;
                break;
            }
        }

        return !anyExpanded;
    }

    const handleReorderUp = (event, o) => {
        let clone = [...pagingData.items];
        let origIndex = pagingData.items.indexOf(o);
        clone.splice(origIndex - 1, 0, clone.splice(origIndex, 1)[0]);
        setTargetItems(clone);

        setForceRefresh(!forceRefresh);
    }

    const handleReorderDown = (event, o) => {
        let clone = [...pagingData.items];
        let origIndex = pagingData.items.indexOf(o);
        clone.splice(origIndex + 1, 0, clone.splice(origIndex, 1)[0]);
        setTargetItems(clone);

        setForceRefresh(!forceRefresh);
    }

    const checkIndexPositionUp = (o) => {
        return pagingData.items.indexOf(o) === 0;
    }

    const checkIndexPositionDown = (o) => {
        return pagingData.items.indexOf(o) === (pagingData.items.length - 1);
    }

    const handleExpand = (event, o) => {
        var expndrs = { ...expanders };
        expndrs[o.getId()] = !expndrs[o.getId()];

        setExpanders(expndrs);
    }

    const handleSelect = (event, o) => {
        var slctds = { ...selecteds };
        let selecting = slctds[o.getId()] !== true;
        slctds[o.getId()] = !slctds[o.getId()];

        if (props.onSelectionChanged !== undefined &&
            typeof props.onSelectionChanged === 'function') {
            let picked = Object.keys(slctds).filter((id) => {
                return slctds[id] === true;
            });

            if (selecting) {
                props.onSelectionChanged([o.getId()], undefined, picked);
                o.Select();
            } else {
                props.onSelectionChanged(undefined, [o.getId()], picked);
                o.Unselect();
            }
        } else {
            if (selecting) {
                o.Select();
            } else {
                o.Unselect();
            }
        }

        setSelecteds(slctds);
    }

    const handleEdit = (event, o) => {
        setEditFocus(o);
        setIsEditShown(true);
    }

    const handleDelete = (event, o) => {
        setDeletionTarget(o);
        setIsDeletionConfirmationOpen(true);
    }

    const onConfirmedDeletion = (event, o) => {
        let remnant = pagingData.items.filter((currentItem) => {
            let keep = currentItem.getId() !== o.getId();
            if (!keep) {
                delete expanders[o.getId()];
            }
            return keep;
        });
        setTargetItems(remnant);

        setForceRefresh(!forceRefresh);
        setExpanders(expanders);
        setEditFocus(undefined);
        setIsDeletionConfirmationOpen(false);
    }

    const handleDeleteSelected = (event) => {
        setDeleteSelectedTargets(selecteds);
        setIsDeleteSelectedConfirmationOpen(true);
    }

    const handleAddItem = (event) => {
        if (props.onAddClicked !== undefined) {
            props.onAddClicked();
        }
        var c = getDefaultItem();
        setEditFocus(c);
        setIsEditShown(true);
    }

    const handleDuplicate = (event, o) => {
        if (props.onAddClicked !== undefined) {
            props.onDuplicateClicked();
        }

        // clone the target
        let clone = o.clone();
        clone.resetId();

        // edit it
        setEditFocus(clone);
        setIsEditShown(true);
    }

    const handleCollapseAll = (event) => {
        let changes = false;
        let result = {};
        for (var key in expanders) {
            if (expanders[key] === true) {
                changes = true;
            }

            result[key] = false;
        }

        if (changes) {
            setExpanders(result);
        }
    }

    const onConfirmedDeleteSelected = (event, o) => {
        let remnant = pagingData.items.filter((currentItem) => {
            let keep = (
                selecteds[currentItem.getId()] !== undefined &&
                selecteds[currentItem.getId()] === false
            );
            if (!keep) {
                delete selecteds[currentItem.getId()];
            }
            return keep;
        });
        setTargetItems(remnant);

        setForceRefresh(!forceRefresh);
        setSelecteds(selecteds);
        setEditFocus(undefined);
        setIsDeleteSelectedConfirmationOpen(false);
    }

    const handleEditClosed = (bucket) => {
        setIsEditShown(false);
    }

    const handleSubmitClosed = (o, bucket) => {
        let orig = undefined;
        let remnant = pagingData.items.filter((currentItem) => {
            let keep = currentItem.getId() !== o.getId();
            if (!keep) {
                orig = currentItem;
            }
            return keep;
        });
        let index = pagingData.items.indexOf(orig);
        if (index >= 0) {
            remnant.splice(index, 0, o);
            setTargetItems(remnant);
        } else {
            setTargetItems(remnant.concat([o]));
        }

        setForceRefresh(!forceRefresh);
        setIsExpanded(true);
        setIsEditShown(false);
    }

    const getPaginationRow = (records) => {
        if (showPagination()) {
            let pages = records.length / pagingData.rows;
            if (pages > Math.floor(pages)) {
                pages = Math.floor(pages) + 1;
            }

            return (
                <ListItem style={{ display: "flex" }}>
                    <Pagination
                        style={{ marginLeft: "auto" }}
                        count={pages}
                        page={pagingData.index}
                        shape="rounded"
                        size="small"
                        onChange={
                            (event, value) => {
                                setPagingData({ ...pagingData, index: value });
                            }
                        } />
                </ListItem>
            );
        } else {
            return '';
        }
    }

    const ensureMinimumRows = (displayedRecordCount) => {
        if (pagingData.rows !== undefined) {
            let required = pagingData.rows - displayedRecordCount;
            if (required > 0) {
                let rows = [];
                for (let i = 0; i < required; i++) {
                    rows.push(
                        <ListItem sx={{ height: '58px' }}>
                        </ListItem>
                    );
                }

                return rows;
            }
        } else {
            return '';
        }
    }

    // if any of the following is true, you can't delete:
    // canDelete = false
    // isReadOnly = true
    const getCanDelete = () => {
        let can = true;

        if (canDelete === false ||
            isReadOnly === true) {
            can = false;
        }

        return can;
    }

    const getCanDeleteMultiple = () => {
        // you can only multi-delete if at least one record will remain
        let keys = Object.keys(selecteds);
        let totalSelected = keys.filter((id) => {
            return selecteds[id] === true;
        }).length;

        return totalSelected === pagingData.items.length;
    }

    // if any of the following is true, you can't add:
    // canAdd = false
    // isReadOnly = true
    const getCanAdd = () => {
        let can = true;

        if (canAdd === false ||
            isReadOnly === true) {
            can = false;
        }

        return can;
    }

    // if any of the following is true, you can't edit
    // canAdd = false
    // isReadOnly = true
    const getCanEdit = () => {
        let can = true;

        if (canAdd === false ||
            isReadOnly === true) {
            can = false;
        }

        if (canEdit === true) {
            can = true;
        } else if (canEdit === false) {
            can = false;
        }

        return can;
    }

    const getRowContent = (o, i) => {
        let result = (
            <FEH_GenericProvider
                propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                state={props.state}
                showRoundedBorderTop={false}
                disablePadding
                key={"collapse-content-" + o.getId() + "-" + i}
                focus={o}
                isReadOnly={true}
                allowGridInteraction={(e) => {
                    return (props.allowGridInteraction && isReadOnly === false)
                }} />
        );

        switch (rowMode) {
            case ListRowContentMode.Dependencies:
                result = (
                    <ItemDependencyListing focus={o} />
                );
                break;
        }

        return result;
    }

    if (isReady() &&
        updateCache() &&
        Array.isArray(pagingData.items)) {
        let header = (
            <Stack direction="row">
                <Tooltip title={getSelectionToggleTooltipByState()}>
                    <IconButton onClick={onSelectionStateClick}>{getSelectionToggleIconByState()}</IconButton>
                </Tooltip>
                {
                    isFiltering ?
                        <InputBase
                            onChange={(event) => {
                                setFilterValue(event.currentTarget.value);
                                setPagingData({ ...pagingData, index: 1 });
                            }}
                            value={filterValue}
                            sx={{ ml: 1, flex: 1 }}
                            placeholder={"Filter " + getHeaderLabel(true)}
                            inputProps={{ 'aria-label': 'Filter ' + getHeaderLabel(true) }}
                        /> :
                        props.label !== undefined && getValueOrDefault(props.isCollapsable, false) && pagingData.items.length > 0 ?
                            <Button
                                variant='text'
                                color="inherit"
                                sx={{
                                    width: '100%',
                                    textTransform: 'none'
                                }}
                                onClick={(e) => { setIsExpanded(!isExpanded); }}>
                                <Typography color="inherit" variant="h6" component="div" style={{ textAlign: 'center' }} sx={{ flexGrow: 1 }}>{getValueOrDefault(props.showHeaderLabel, true) ? getHeaderLabel(true) : ""}</Typography>
                                {isExpanded ? <ExpandLess /> : <ExpandMore />}
                            </Button> :
                            <Typography color="inherit" variant="h6" component="div" style={{ textAlign: 'center' }} sx={{ flexGrow: 1 }}>{getValueOrDefault(props.showHeaderLabel, true) ? getHeaderLabel(true) : ""}{props.help !== undefined ? <HelpIcon help={props.help} /> : ''}</Typography>
                }
                {clipboardState !== undefined && showCopyPaste() === true ?
                    <Tooltip title={"Copy the selected records"}>
                        <IconButton onClick={(e) => { doCopy(); }} disabled={canCopy() === false}>{getCopyIcon()}</IconButton>
                    </Tooltip> : ''
                }
                {getCanAdd() && clipboardState !== undefined && showCopyPaste() === true ?
                    <Tooltip title={"Paste the last copied record of the approriate type"}>
                        <IconButton onClick={(e) => { doPaste(); }} disabled={canPaste() === false}><ContentPasteIcon /></IconButton>
                    </Tooltip> : ''
                }
                {canFilter ? (isFiltering ?
                    <Tooltip title={"Stop Filtering"}>
                        <IconButton onClick={(e) => { setIsFiltering(false); setFilterValue(undefined); }}><FilterAltOffIcon /></IconButton>
                    </Tooltip> :
                    <Tooltip title={"Filter"}>
                        <IconButton onClick={(e) => { setIsFiltering(true); }} ><FilterAltIcon /></IconButton>
                    </Tooltip>) : ''
                }
                {isExpandable ? <Tip title="Collapse All" omit={checkAnyExpanded()}><IconButton disabled={checkAnyExpanded()} onClick={handleCollapseAll} color="inherit"><UnfoldLessIcon /></IconButton></Tip> : ""}
                {getCanDelete() ? <Tip title="Delete Selected" omit={checkAnySelected()}><IconButton disabled={checkAnySelected() || getCanDeleteMultiple()} onClick={handleDeleteSelected} color="inherit">{props.overrideDeleteSelectedIcon === undefined ? <RemoveDoneIcon /> : props.overrideDeleteSelectedIcon}</IconButton></Tip> : ''}
                {getCanAdd() ?
                    <Tooltip title={"Add " + getHeaderLabel(false)}><IconButton onClick={handleAddItem}>
                        {props.overrideAddIcon === undefined ? <AddIcon /> : props.overrideAddIcon}
                        <Typography color="inherit" variant="button" component="div" style={{ textAlign: 'center' }}></Typography>
                    </IconButton></Tooltip> : ""}
            </Stack>
        );

        let unpagedRecords = pagingData.items.filter((item) => {
            if (isFiltering && filterValue !== undefined) {
                return getTitleText(item).search(filterValue) > -1;
            }

            return true;
        });

        let list = doPaging(unpagedRecords).map((o, i) => {
            let disableShiftMouseOver = checkIndexPositionUp(o) && checkIndexPositionDown(o);
            let shiftButtons = (<Stack direction={getValueOrDefault(props.collapseMoveIcons, false) ? "column" : "row"}>
                <Tip title="Move Up"><IconButton disabled={checkIndexPositionUp(o)} onClick={(e) => { handleReorderUp(e, o); e.stopPropagation(); }}><ArrowCircleUpIcon /></IconButton></Tip>
                <Tip title="Move Down"><IconButton disabled={checkIndexPositionDown(o)} onClick={(e) => { handleReorderDown(e, o); e.stopPropagation(); }}><ArrowCircleDownIcon /></IconButton></Tip>
            </Stack>);

            let isChild = true;
            if (props.checkIsChild !== undefined &&
                props.checkIsChild !== null) {
                isChild = props.checkIsChild(o);
            }

            return (
                <div key={o.getId()}>
                    <ListItem
                        button={isExpandable}
                        key={o.getId() + "-0"} onClick={handleExpand.bind("", i, o)}>
                        {isChild ? (<Tooltip title={selecteds[o.getId()] === false ? 'Select' : 'Unselect'}>
                            <Checkbox
                                checked={getValueOrDefault(selecteds[o.getId()], false)}
                                onChange={(e) => { handleSelect(e, o); }}
                                onClick={(e) => { e.stopPropagation(); }}
                                icon={<ViewComfyOutlinedIcon />}
                                checkedIcon={<ViewComfyIcon />} />
                        </Tooltip>) : <IconButton disabled>{o.getSelectedIcon()}</IconButton>}
                        <ListItemText
                            disableTypography
                            primary={isChild ?
                                <Stack
                                    spacing={1}
                                    direction="row"
                                    alignItems={"center"}>
                                    <RegionLayoutEditor
                                        sx={{
                                            position: 'relative',
                                            width: '32px',
                                            height: '32px',
                                        }}
                                        refreshRequired={forceRefresh}
                                        focus={o}
                                        isReadOnly={true}
                                        asMosaic={true} />
                                    <Typography>{getTitleText(o)}</Typography>
                                </Stack> :
                                <Stack
                                    spacing={1}
                                    direction="row"
                                    alignItems={"center"}>
                                    <RegionLayoutEditor
                                        sx={{
                                            position: 'relative',
                                            width: '32px',
                                            height: '32px',
                                        }}
                                        refreshRequired={forceRefresh}
                                        focus={o}
                                        isReadOnly={isReadOnly}
                                        asMosaic={true} />
                                    <Typography color={currentTheme.palette.secondary.main}>{getTitleText(o)}</Typography>
                                </Stack>} />
                        {
                            isChild ?
                                (getValueOrDefault(props.collapseMoveIcons, false) ?
                                    (
                                        disableShiftMouseOver ?
                                            <SwapVertIcon disabled={disableShiftMouseOver} color="disabled" />
                                            : <Tooltip
                                                title={shiftButtons}>
                                                <SwapVertIcon disabled={disableShiftMouseOver} />
                                            </Tooltip>
                                    )
                                    : shiftButtons) : undefined
                        }
                        {isChild ? (getCanAdd() && o.resetId !== undefined ? <Tooltip title={"Duplicate " + getHeaderLabel(false)}><IconButton onClick={(e) => { handleDuplicate(e, o); e.stopPropagation(); }}><FileCopyIcon /></IconButton></Tooltip> : '') : ''}
                        {isChild ? (getCanEdit() ? <Tooltip title={"Edit " + getHeaderLabel(false)}><IconButton onClick={(e) => { handleEdit(e, o); e.stopPropagation(); }}><EditIcon /></IconButton></Tooltip> : '') : ''}
                        {isChild ? (getCanDelete() ? <Tooltip title={"Delete " + getHeaderLabel(false)}><IconButton onClick={(e) => { handleDelete(e, o); e.stopPropagation(); }} disabled={pagingData.items.length <= 1}><DeleteIcon /></IconButton></Tooltip> : '') : ''}
                        {isExpandable ? (expanders[o.getId()] ? <ExpandLess /> : <ExpandMore />) : ""}
                    </ListItem>
                    {
                        isExpandable === true ?
                            <Collapse key={"collapse-" + o.getId() + "-" + i} component="li" timeout="auto" in={expanders[o.getId()]} unmountOnExit>
                                {getRowContent(o, i)}
                            </Collapse> : ""
                    }
                </div>
            );
        });

        return (
            <List
                sx={{ width: '100%' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="li" id="list-subheader">
                        {header}
                    </ListSubheader>}>
                <ConfirmationDialog
                    bucket={deletionTarget}
                    isOpen={isDeletionConfirmationOpen}
                    title={"Deletion Confirmation"}
                    message={"Permanently Delete " + getHeaderLabel(false) + "?"}
                    cancelText={"No"}
                    confirmText={"Yes"}
                    onCancel={() => { setIsDeletionConfirmationOpen(false); }}
                    onConfirm={onConfirmedDeletion} />
                <ConfirmationDialog
                    bucket={deleteSelectedTargets}
                    isOpen={isDeleteSelectedConfirmationOpen}
                    title={"Deletion Confirmation"}
                    message={"Permanently Delete Selected " + getHeaderLabel(false) + "(s)?"}
                    cancelText={"No"}
                    confirmText={"Yes"}
                    onCancel={() => { setIsDeleteSelectedConfirmationOpen(false); }}
                    onConfirm={onConfirmedDeleteSelected} />
                <FormEditorHost
                    propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                    defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                    state={props.state !== undefined && props.state !== null ? props.state : state}
                    fullscreen={fullscreenEditor}
                    clone={true}
                    target={editFocus}
                    open={isEditShown}
                    isReadOnly={isReadOnly}
                    key={"add" + props.label + "-key"}
                    onClose={handleEditClosed}
                    onSubmit={handleSubmitClosed} />
                <Collapse in={isExpanded || getValueOrDefault(props.isCollapsable !== true, false)}>
                    {getPaginationRow(unpagedRecords)}
                    {list}
                    {ensureMinimumRows(list.length)}
                    {getPaginationRow(unpagedRecords)}
                </Collapse>
            </List>
        );
    } else {
        let reasons = [];

        if (props.getTargetItems === undefined) {
            reasons.push('No get target items provided');
        }
        if (props.setTargetItems === undefined) {
            reasons.push('No set target items provided');
        }
        if (props.getDefaultItem === undefined && props.canAdd !== false && isReadOnly !== true) {
            reasons.push('No get default provided, and canAdd is true, and the control is not readonly');
        }

        return (
            <List
                sx={{ width: '100%' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="list-subheader">
                        Grid not properly initialized.
                    </ListSubheader>}>
                {
                    reasons.map((o, i) => {
                        <ListItem key={o.replace(' ', '-') + "-" + i}>
                            {o}
                        </ListItem>
                    })
                }
            </List>
        );
    }
}