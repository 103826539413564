import * as React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';

import ClearIcon from '@mui/icons-material/Clear';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import ImageIcon from '@mui/icons-material/Image';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CropRotateIcon from '@mui/icons-material/CropRotate';

import ConfirmationDialog from '../../Screens/Dialogs/ConfirmationDialog';
import ImageCropperDialog from '../../Screens/Dialogs/ImageCropperDialog';
import { LayoutRegion } from '../../Classes/Layout/LayoutRegion';

export default function ImageContentEditor(props) {
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [isMouseOver, setIsMouseOver] = React.useState(props.isMouseOver || false);
    const [isMosaic, setIsMosaic] = React.useState(false);

    const [forceRefresh, setForceRefresh] = React.useState(false);

    const [imageDetails, setImageDetails] = React.useState(undefined);
    const [imageUploadState, setImageUpdateState] = React.useState([]);

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }

        if (props.isMouseOver !== undefined &&
            props.isMouseOver !== null &&
            props.isMouseOver !== isMouseOver) {
            setIsMouseOver(props.isMouseOver);
        }

        if (props.asMosaic !== undefined &&
            props.asMosaic !== null &&
            props.asMosaic !== isMosaic) {
            setIsMosaic(props.asMosaic);
        }
    }, [props]);

    const onEdited = (event) => {
        if (event.target.id === "region-script") {
            focus.setContent(event.target.value);

            if (props.onNeedRefresh !== undefined) {
                // request a refresh in the parent control
                // this is to sync the containing control and its parent editing screen 
                // with the modifications without redrawing the entire hierarchy
                props.onNeedRefresh();
            }
        }
    }

    const handleImageDetailsUpdated = (updated) => {
        if (updated !== undefined) {
            focus.setContent(updated.getContent());

            if (props.onNeedRefresh !== undefined) {
                // request a refresh in the parent control
                // this is to sync the containing control and its parent editing screen 
                // with the modifications without redrawing the entire hierarchy
                props.onNeedRefresh();
            }
        }

        setImageDetails(undefined);
    }

    const handleFileUpload = (event) => {
        for (var fileIndex = 0; fileIndex < event.target.files.length; fileIndex++) {
            var file = event.target.files[fileIndex];
            const reader = new FileReader();
            reader.onerror = (evt) => {
                var item = null;
                for (var i = 0; i < imageUploadState.length; i++) {
                    if (imageUploadState[i].file === file.name) {
                        item = imageUploadState[i];
                        break;
                    }
                }

                if (item === null) {
                    item = {
                        id: i,
                        file: file.name,
                        error: evt.target.error,
                        progress: -1,
                    };
                    imageUploadState.push(item);
                } else {
                    item.error = evt.target.error;
                    item.progress = -1;
                    item.records.length = 0;
                }

                setForceRefresh(!forceRefresh);
            };
            reader.onprogress = (evt) => {
                var item = null;
                for (var i = 0; i < imageUploadState.length; i++) {
                    if (imageUploadState[i].file === file.name) {
                        item = imageUploadState[i];
                        break;
                    }
                }

                if (item === null) {
                    item = {
                        id: i,
                        file: file.name,
                        error: null,
                        progress: evt.total / evt.loaded,
                    };
                    imageUploadState.push(item);
                } else {
                    item.progress = evt.total / evt.loaded;
                }

                setForceRefresh(!forceRefresh);
            };
            reader.onload = (evt) => {
                var item = null;
                for (var i = 0; i < imageUploadState.length; i++) {
                    if (imageUploadState[i].file === file.name) {
                        item = imageUploadState[i];
                        break;
                    }
                }

                focus.setContent({ base: evt.target.result, edit: evt.target.result, x: 0, x: 0, width: props.dimensions.width, height: props.dimensions.height });
                setImageDetails({ isOpen: true, focus: focus.clone(true) });
                imageUploadState.splice(imageUploadState.indexOf(item), 1);
                setForceRefresh(!forceRefresh);
            };
            reader.readAsDataURL(file);
        }
    }

    let display = (<div />);
    if (focus !== undefined &&
        focus.constructor.ClassName() === LayoutRegion.ClassName()) {
        let fabMenu = (
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    position: 'absolute',
                    top: 10,
                    right: 16,
                }}>
                <Zoom
                    key="fab-image"
                    in={isMouseOver}
                    unmountOnExit>
                    <Fab
                        onClick={() => { setImageDetails({ isOpen: true, focus: focus.clone(true) }); }}
                        size="small">
                        <CropRotateIcon />
                    </Fab>
                </Zoom>
                <Zoom
                    key="fab-upload"
                    in={isMouseOver}
                    unmountOnExit>
                    <Fab
                        component="label"
                        variant="text"
                        size="small">
                        <UploadFileIcon />
                        <input
                            type="file"
                            accept="image/png, image/jpeg"
                            hidden
                            onChange={handleFileUpload} />
                    </Fab>
                </Zoom>
            </Stack>
        );

        if (isReadOnly) {
            display = (
                <Box
                    id="column-image"
                    alt="Region Image Content"
                    sx={{
                        backgroundImage: `url(${focus.getContent().edit})`,
                        backgroundSize: 'cover',
                        width: '100%',
                        height: '100%',
                    }} />
            );
        } else {
            if (imageUploadState.length > 0) {
                let progressListing = imageUploadState.map((o, i) => {
                    if (o.progress > -1) {
                        return (
                            <LinearProgress
                                key={"progressBar-" + i}
                                variant="determinate"
                                value={o.progress} />
                        );
                    } else {
                        return (
                            <LinearProgress key={"progressBar-" + i} />
                        );
                    }
                });

                display = (<Grid
                    sx={{
                        height: 'auto',
                        width: 'auto',
                        border: '1px solid',
                        borderColor: (theme) =>
                            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                        borderRadius: 2,
                    }}
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center">
                    {progressListing}
                </Grid>);
            } else if (focus.getContent() === undefined) {
                display = (
                    <Grid
                        container
                        sx={{
                            /* background: 'radial-gradient(circle, rgba(' + data[0] + ',' + data[1] + ',' + data[2] + ',0) 57%, rgba(' + data[0] + ',' + data[1] + ',' + data[2] + ',1) 100%)', */
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                        }}>
                        {
                            isReadOnly ? <UploadFileIcon /> :
                                <Button
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: 'center',
                                        height: '100%',
                                        width: '100%',
                                    }}
                                    disabled={isReadOnly}
                                    component="label"
                                    variant="text"
                                    color="inherit"
                                    size="small">
                                    <Stack direction="row" spacing={1}>
                                        <UploadFileIcon />
                                        <Typography color="inherit" variant="body" component="div">Upload Image</Typography>
                                    </Stack>
                                    <input
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        hidden
                                        onChange={handleFileUpload} />
                                </Button>
                        }
                    </Grid>
                );
            } else {
                display = (
                    <Grid
                        sx={{
                            position: 'relative',
                            textAlign: 'center',
                            alignItems: 'center',
                            borderRadius: 2,
                            backgroundColor: 'red',
                            width: '100%',
                            height: '100%',
                            opacity: props.opacity,
                        }}
                        container
                        spacing={0}>
                        <Box
                            id="column-image"
                            alt="Region Image Content"
                            sx={{
                                backgroundImage: `url(${focus.getContent().edit})`,
                                backgroundSize: 'cover',
                                width: '100%',
                                height: '100%',
                            }} />
                        {isReadOnly || isMosaic ? "" : fabMenu}
                        <ImageCropperDialog
                            dimensions={props.dimensions}
                            details={imageDetails}
                            onClose={handleImageDetailsUpdated} />
                    </Grid>
                );
            }
        }
    }

    return display;
}