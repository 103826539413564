import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Switch } from '@mui/material';
import { randomId } from '@mui/x-data-grid-generator';

import { QuizzicalContext, RunStates, ScreenModes } from '../../../Context/QuizzicalContext';
import TopicsManager from '../../TopicsManager';
import RegionLayoutManagementDialog from '../../../Screens/Dialogs/RegionLayoutManagementDialog';
import RegionLayoutEditor from '../../ContentEditor/RegionLayoutEditor';
import GenericList from '../../Lists/GenericList';
import GenericStorageManagerList from '../../Lists/GenericStorageManagerList';
import FieldContainer from '../../Forms/FieldContainer';
import FEH_Form from '../../Forms/FEH_Form';
import { StorageItem } from '../../../Classes/StorageManager';
import { isImmediateChild } from '../../../Libraries/generalUtility';
import QuestionChoiceDefinition from '../../../Classes/QuestionChoiceDefinition';

export default function FEH_Parameter_SequentialItemEditor(props) {
    const state = props.state;
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [defaultFieldSet, setDefaultFieldSet] = React.useState(randomId());

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }
    }, [props]);

    const updateFocus = () => {
        focus.setName(document.getElementById("seqItem-name").value);
        focus.setLooping(document.getElementById("seqItem-looping").checked);

        return focus;
    }

    if (isReadOnly) {
        return ( // readonly
            <Box
                sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                }}>
                <Grid>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Typography color="inherit" variant="body" component="div">Name:&nbsp;&nbsp;&nbsp;</Typography>
                        <Typography color="inherit" variant="caption" component="div">{focus.getName()}</Typography>
                    </Stack>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Stack direction="row">
                            <Typography color="inherit" variant="body" component="div">Looping:&nbsp;&nbsp;&nbsp;</Typography>
                            <Typography color="inherit" variant="caption" component="div">{focus.getLooping() ? "Yes" : "No"}</Typography>
                        </Stack>
                    </Stack>
                    {/* <GenericStorageManagerList
                        help="This list contains the parameter's displayed items."
                        isReadOnly={isReadOnly}
                        isCollapsable={false}
                        label="Items"
                        nameLabel='Label'
                        valueLabel='Value'
                        validationType='parameterItem'
                        manager={focus.getItems()}
                        setTargetItems={(items) => {
                            focus.getItems(items);
                        }}
                        getDefaultItem={() => {
                            let i = 0;
                            while (focus.getItems().containsName("new-item-" + i)) {
                                i++;
                            }

                            return {
                                type: "item",
                                host: focus,
                                obj: StorageItem.getDefault("new-item-" + i, undefined),
                                nameLabel: 'Label',
                                valueLabel: 'Value'
                            };
                        }} /> */}
                    <GenericList
                        rows={6}
                        propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                        defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                        help={"The question's available answers."}
                        isReadOnly={isReadOnly}
                        isCollapsable={false}
                        label="Items"
                        getTargetItems={() => {
                            return focus.getItems();
                        }}
                        setTargetItems={(items) => {
                            focus.setItems(items);
                        }}
                        getDefaultItem={() => {
                            return QuestionChoiceDefinition.getDefault(focus, 'item-' + focus.getItems().length, undefined, undefined, false, false);
                        }}
                        validPastes={[
                            QuestionChoiceDefinition.ClassName(),
                        ]} />
                </Grid>
            </Box>
        );
    } else {
        return ( // editable
            <FEH_Form
                height="88vH"
                formId="SequentialParameter"
                type="SequencialParameter"
                getInstance={updateFocus}
                onClose={props.onClose}
                onConfirm={props.onConfirm}
                onReportMessages={props.onReportMessages}>
                <Stack spacing={2}>
                    <FieldContainer
                        display="flow"
                        fieldSet={defaultFieldSet}
                        label="Name"
                        help="The parameter's name"
                        fullWidth
                        required>
                        <TextField
                            disabled={isReadOnly}
                            id="seqItem-name"
                            fullWidth
                            required
                            label="Name"
                            defaultValue={focus.getName()} />
                    </FieldContainer>
                    <FieldContainer
                        fieldSet='Looping_PoolParameter'
                        swap
                        label="Loop"
                        help="If true, the parameter's items will loop when they reach the end">
                        <Switch
                            disabled={isReadOnly}
                            id="seqItem-looping"
                            defaultChecked={focus.getLooping()} />
                    </FieldContainer>
                    {/* <ParameterItemManager focus={focus} isReadOnly={isReadOnly} /> */}
                    {/* <GenericStorageManagerList
                        help="This list contains the parameter's displayed items."
                        isReadOnly={isReadOnly}
                        isCollapsable={false}
                        label="Items"
                        nameLabel='Label'
                        valueLabel='Value'
                        validationType='parameterItem'
                        manager={focus.getItems()}
                        setTargetItems={(items) => {
                            focus.setItems(items);
                        }}
                        getDefaultItem={() => {
                            let i = 0;
                            while (focus.getItems().containsName("new-item-" + i)) {
                                i++;
                            }

                            return {
                                type: "item",
                                host: focus,
                                obj: StorageItem.getDefault("new-item-" + i, undefined),
                                nameLabel: 'Label',
                                valueLabel: 'Value'
                            };
                        }} /> */}
                    <GenericList
                        rows={6}
                        propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                        defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                        help={"The question's available answers."}
                        isReadOnly={isReadOnly}
                        isCollapsable={false}
                        label="Items"
                        getTargetItems={() => {
                            return focus.getItems();
                        }}
                        setTargetItems={(items) => {
                            focus.setItems(items);
                        }}
                        getDefaultItem={() => {
                            return QuestionChoiceDefinition.getDefault(focus, 'item-' + focus.getItems().length, undefined, undefined, false, false);
                        }}
                        validPastes={[
                            QuestionChoiceDefinition.ClassName(),
                        ]} />
                </Stack>
            </FEH_Form>
        );
    }
}