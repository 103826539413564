import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import ClassName from '../ClassName';

export default class SettingIntermediate extends ClassName {
    constructor(struct) {
        super();
        if (struct !== undefined &&
            struct !== null &&
            struct._type === "Settings") {
            this.defaultDuration = struct.DefaultDuration;
            this.defaultDwell = struct.DefaultDwell;
            this.defaultExportTimestamp = struct.DefaultExportTimestamp;
            this.globalDurationHandicapModifier = struct.GlobalDurationHandicapModifier;
            this.globalDwellHandicapModifier = struct.GlobalDwellHandicapModifier;
        }
    }

    getTitle() {
        return "Stored Settings";
    }

    getIcon() {
        return <SettingsOutlinedIcon />;
    }

    getSelectedIcon() {
        return <SettingsIcon />;
    }

    getDefaultDuration() {
        return this.defaultDuration;
    }

    getDefaultDwell() {
        return this.defaultDwell;
    }

    getDefaultExportTimestamp() {
        return this.defaultExportTimestamp;
    }

    getGlobalDurationHandicapModifier() {
        return this.globalDurationHandicapModifier;
    }

    getGlobalDwellHandicapModifier() {
        return this.globalDwellHandicapModifier;
    }

    update(state) {
        if (state.Mode === undefined) {
            state.defaultDuration = this.defaultDuration;
            state.defaultDwell = this.defaultDwell;
            state.defaultExportTimestamp = this.defaultExportTimestamp;
            state.globalDurationHandicapModifier = this.globalDurationHandicapModifier;
            state.globalDwellHandicapModifier = this.globalDwellHandicapModifier;
        } else {
            state.DefaultExportTimestamp = this.defaultExportTimestamp;
            state.Mode.DefaultDuration = this.defaultDuration;
            state.Mode.DefaultDwell = this.defaultDwell;
            state.Mode.GlobalDurationHandicapModifier = this.globalDurationHandicapModifier;
            state.Mode.GlobalDwellHandicapModifier = this.globalDwellHandicapModifier;
        }
    }

    static ClassName() {
        return "SettingIntermediate";
    }
}