import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import RegionDisplay from './RegionDisplay';
import Subject from '../../Classes/Subjects';

export default function RegionLayoutDisplay(props) {
    const [focus, setFocus] = React.useState(undefined); // the target LayoutRegionManager instance to display
    const [questionData, setQuestionData] = React.useState(undefined);
    const [displayingSubject, setDisplayingSubject] = React.useState(undefined);

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);

            if (props.hasSubject === true) {
                setDisplayingSubject(true);
            } else if (props.hasSubject === false) {
                setDisplayingSubject(false);
            } else if (props.focus !== focus) {
                setDisplayingSubject(undefined);
            }
        }

        if (props.questionData !== undefined &&
            props.questionData !== null &&
            props.questionData !== questionData) {
            setQuestionData(props.questionData);
        }
    }, [props]);

    const getXPcntY = (x, y) => {
        return (x / y) * 100;
    }

    let display = (<div />);
    if (displayingSubject !== undefined && focus !== undefined) {
        // build the layout described by the manager using floating divs
        let regionMap = focus.getRegions().map((r, i) => {
            let top = getXPcntY(r.getY(), focus.getHeight()) + "%";
            let right = getXPcntY(r.getX(), focus.getWidth()) - getXPcntY(r.getRight(), focus.getWidth()) + "%";
            let width = getXPcntY(r.getRight() + 1, focus.getWidth()) + "%";
            let height = getXPcntY(r.getBottom() + 1, focus.getHeight()) + "%";

            return <RegionDisplay
                innerId={"region-container-" + r.getX() + "-" + r.getY()}
                focus={r}
                key={"regionMap-" + r.getId()}
                proportions={{ top: top, right: right, width: width, height: height, maxWidth: focus.getWidth(), maxHeight: focus.getHeight() }}
                questionData={questionData}
                sx={{
                    position: 'absolute',
                    right: right,
                    top: top,
                    width: width,
                    height: height,
                }} />;
        });

        return (
            <Grid
                sx={props.sx}
                container>
                {regionMap}
            </Grid>
        );
    } else {
        return display;
    }
}