import * as React from 'react';
import { randomId } from '@mui/x-data-grid-generator';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import { Switch } from '@mui/material';
import HelpIcon from '../../Forms/HelpIcon';
import FormControlLabel from '@mui/material/FormControlLabel';

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import { QuizzicalContext } from '../../../Context/QuizzicalContext';
import { Topic } from '../../../Classes/Topics';
import FormEditorHost_Validator from '../../../Libraries/FormEditorHost_Validator';

export default function TopicEditor(props) {
    const state = props.state;
    const [host, setHost] = React.useState(undefined);
    const [addingRoot, setAddingRoot] = React.useState(false);

    const [formId, setFormId] = React.useState(randomId());
    const formRef = React.useRef();

    React.useEffect(() => {
        if (props.target !== undefined &&
            props.target !== null &&
            props.target !== host) {
            setHost(props.target);
        }

        if (props.isRoot !== undefined &&
            props.isRoot !== null &&
            props.isRoot !== addingRoot) {
            setAddingRoot(props.isRoot);
        }
    }, [props]);

    const updateFocus = () => {
        let topic = undefined;
        if (addingRoot) {
            topic = new Topic().initCreate(
                document.getElementById("topic-label").value,
                document.getElementById("topic-selectable").checked,
                document.getElementById("topic-description").value,
                [],
                state.Topics.length + ''
            );
        } else {
            topic = new Topic().initCreate(
                document.getElementById("topic-label").value,
                document.getElementById("topic-selectable").checked,
                document.getElementById("topic-description").value,
            );
        }

        return topic;
    }

    /* return (
        <FEH_Form
            formId="TopicCreationForm"
            type="Topic"
            getInstance={updateFocus}
            onClose={props.onClose}
            onConfirm={props.onConfirm}
            onReportMessages={props.onReportMessages}>
            <Stack spacing={2}>
                <FieldContainer
                    fieldSet={defaultFieldSet}
                    label="Label"
                    help="The topic's name"
                    fullWidth
                    required>
                    <TextField
                        id="topic-label"
                        required
                        sx={{ minWidth: '100%', width: '100%' }} />
                </FieldContainer>
                <FieldContainer
                    fieldSet={defaultFieldSet}
                    label="Description"
                    help="A brief summary of the topic"
                    fullWidth
                    required>
                    <TextField
                        id="topic-description"
                        required
                        multiline
                        rows={4}
                        sx={{ minWidth: '100%', width: '100%' }} />
                </FieldContainer>
                <FieldContainer
                    fieldSet='Topic_Selectable'
                    swap
                    label="Selectable"
                    help="If false, the topic will not be selectable, but can still have selectable sub-topics">
                    <Switch
                        id="topic-selectable"
                        defaultChecked={true} />
                </FieldContainer>
            </Stack>
        </FEH_Form>
    ); */

    const SubmitForm = () => {
        let topic = updateFocus();
        let results = FormEditorHost_Validator(topic, "Topic", state);

        if (results.isValid) {
            props.onConfirm(host, topic);
        } else {
            props.onReportMessages(results.issues);
        }
    }

    return (
        <div>
            <Paper
                sx={{
                    overflow: 'auto',
                    padding: 3,
                    width: (props.width || '100%'),
                    height: (props.height || '100%'),
                }}>
                <Stack spacing={2}>
                    <Stack spacing={1} direction="row">
                        <TextField
                            label="Label"
                            id="topic-label"
                            required
                            fullWidth />
                        <HelpIcon help={"The topic's name\n\nThis field is required."} />
                    </Stack>
                    <Stack spacing={1} direction="row">
                        <TextField
                            id="topic-description"
                            label="Description"
                            required
                            multiline
                            fullWidth
                            rows={4} />
                        <HelpIcon help={"A brief summary of the topic\n\nThis field is required."} />
                    </Stack>
                    <Stack spacing={1} direction="row">
                        <FormControlLabel
                            control={<Switch id="topic-selectable" defaultChecked={true} />}
                            label={
                                <Stack direction="row" spacing={1}>
                                    <Typography>Selectable</Typography>
                                    <HelpIcon help={"If false, the topic will not be selectable, but can still have selectable sub-topics\n\nThis field is required."} />
                                </Stack>} />
                    </Stack>
                </Stack>
            </Paper>
            <Grid
                container
                justifyContent="flex-end"
                sx={{ flexGrow: 1 }}>
                <Button
                    color="inherit"
                    onClick={props.onCancel}>
                    <Stack direction='row' spacing={1}>
                        <CancelIcon />
                        <Typography variant='button'>Cancel</Typography>
                    </Stack>
                </Button>
                <Button
                    color="inherit"
                    type="submit"
                    form={formId}
                    id={formId}
                    onClick={SubmitForm}>
                    <Stack direction='row' spacing={1}>
                        <SaveIcon />
                        <Typography variant='button'>Confirm</Typography>
                    </Stack>
                </Button>
            </Grid>
        </div>
    );
}