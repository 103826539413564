import { HierarchicalItemBase, HierarchicalItemTypes } from './BaseClassHierarchy';
import CreateIcon from '@mui/icons-material/Create';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { LogEntryType, LogHost } from './Resolution/ScriptExecutionLog';
import runScript from '../Libraries/runScript';

// Used for None
import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

// used for Number
import PinIcon from '@mui/icons-material/Pin';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';

// used for Big Number
import Filter9PlusIcon from '@mui/icons-material/Filter9Plus';
import Filter9PlusOutlinedIcon from '@mui/icons-material/Filter9PlusOutlined';

// used for String
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import FontDownloadOutlinedIcon from '@mui/icons-material/FontDownloadOutlined';

// used for DateTime
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

// used for Boolean
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

// used for Functions
import FunctionsOutlinedIcon from '@mui/icons-material/FunctionsOutlined';

// used for Script Generated
import PsychologyIcon from '@mui/icons-material/Psychology';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';

export const VariableTypes = {
    None: 0,
    Number: 1,
    BigInt: 2,
    String: 3,
    DateTime: 4,
    Boolean: 5,
    Function: 6,
    ScriptGeneration: 7,
};

///
///  Represents a value in the scripting system
///
export default class VariableDefinition extends HierarchicalItemBase {
    constructor() {
        super();
    }

    initCreate(parentItem, icon, selectedIcon, title, description, value, type = VariableTypes.String) {
        super.initCreate(parentItem, icon, selectedIcon, undefined, HierarchicalItemTypes.Variable, title, description);

        this.value = value;
        this.dataType = type;

        return this;
    }

    getIcon() {
        let result = <CancelOutlinedIcon />;

        switch (this.dataType) {
            case VariableTypes.Number:
                result = <PinOutlinedIcon />;
                break;
            case VariableTypes.BigInt:
                result = <Filter9PlusOutlinedIcon />;
                break;
            case VariableTypes.String:
                result = <FontDownloadOutlinedIcon />;
                break;
            case VariableTypes.DateTime:
                result = <AccessTimeOutlinedIcon />;
                break;
            case VariableTypes.Boolean:
                result = <CheckCircleOutlineOutlinedIcon />;
                break;
            case VariableTypes.Function:
                result = <FunctionsOutlinedIcon />;
                break;
            case VariableTypes.ScriptGeneration:
                result = <PsychologyOutlinedIcon />;
                break;
        }

        return result;
    }

    getSelectedIcon() {
        let result = <CancelIcon />;

        switch (this.dataType) {
            case VariableTypes.Number:
                result = <PinIcon />
                break;
            case VariableTypes.BigInt:
                result = <Filter9PlusIcon />;
                break;
            case VariableTypes.String:
                result = <FontDownloadIcon />;
                break;
            case VariableTypes.DateTime:
                result = <AccessTimeFilledIcon />;
                break;
            case VariableTypes.Boolean:
                result = <CheckCircleIcon />;
                break;
            case VariableTypes.Function:
                result = <FunctionsOutlinedIcon />;
                break;
            case VariableTypes.ScriptGeneration:
                result = <PsychologyIcon />;
                break;
        }

        return result;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);

        this.value = struct.value;
        this.dataType = struct.datatype === undefined ? VariableTypes.String : struct.datatype;

        return this;
    }

    static getDefault(parent, title, description, value, type) {
        return new VariableDefinition().initCreate(parent, <CreateOutlinedIcon />, <CreateIcon />, title, description, value, type);
    }

    // called by the initialization process to store the original value
    store() {
        this.originalValue = this.value;
    }

    reset(logId) {
        if (this.dataType === VariableTypes.ScriptGeneration) {
            let sg = runScript(logId, this.originalValue, this.getTitle(), [], []);
            if (sg.hasErrors === true) {
                LogHost.post(logId, "Script '" + this.getTitle() + "' encountered an exception", LogEntryType.Error)
                    .reason(sg.error)
                    .adjustCoordinates([], []);
            } else {
                this.value = sg.result;
            }
        } else {
            this.value = this.originalValue;
        }
    }

    setValue(value) {
        this.value = value;
    }

    getValue(logId) {
        //return this.value;
        // getValue returns a value respecting the defined datatype
        let outcome = undefined;
        switch (this.dataType) {
            case VariableTypes.None:
                LogHost.post(logId, "Variable '" + this.getTitle() + "' is of type 'None'.  Ensure all variables have a proper type assigned.", LogEntryType.Error);
                break;
            case VariableTypes.Number:
                outcome = parseFloat(this.value);
                if (Number.isNaN(outcome) === true) {
                    LogHost.post(logId, "Variable '" + this.getTitle() + "' is of type 'Number', but defines a value that does not match that type.", LogEntryType.Error);
                }
                break;
            case VariableTypes.BigInt:
                outcome = parseFloat(this.value);
                if (Number.isNaN(outcome) === true) {
                    LogHost.post(logId, "Variable '" + this.getTitle() + "' is of type 'Big Number', but defines a value that does not match that type.", LogEntryType.Error);
                }
                break;
            case VariableTypes.String:
                outcome = this.value;
                break;
            case VariableTypes.DateTime:
                outcome = this.value; // will be added to the script as a date instantiation
                break;
            case VariableTypes.Boolean:
                outcome = this.value;
                break;
            case VariableTypes.Function:
                outcome = this.value; // this will be dumped into the script when it's run
                break;
            case VariableTypes.ScriptGeneration:
                outcome = this.value; // this will be executed and the result dumped into the script when it's run
                break;
        }

        return outcome;
    }

    setDataType(type) {
        this.dataType = type;
    }

    getDataType() {
        if (this.dataType === undefined) this.dataType = VariableTypes.String;
        return this.dataType;
    }

    getTypeName() {
        let outcome = "None";
        switch (this.dataType) {
            case VariableTypes.Number:
                outcome = "Number";
                break;
            case VariableTypes.BigInt:
                outcome = "Big Number";
                break;
            case VariableTypes.String:
                outcome = "Text";
                break;
            case VariableTypes.DateTime:
                outcome = "Date and Time";
                break;
            case VariableTypes.Boolean:
                outcome = "Boolean";
                break;
            case VariableTypes.Function:
                outcome = "Function";
                break;
            case VariableTypes.ScriptGeneration:
                outcome = "Script Generated";
                break;
        }

        return outcome;
    }

    static enumerateDataTypes(includeNone = true) {
        let result = [];
        if (includeNone) {
            result.push({
                value: VariableTypes.None,
                label: "None",
            });
        }

        return result.concat([
            {
                value: VariableTypes.Number,
                label: "Number",
            },
            /* {
                value: VariableTypes.BigInt,
                label: "BigInt",
            }, */
            {
                value: VariableTypes.String,
                label: "String",
            },
            {
                value: VariableTypes.DateTime,
                label: "DateTime",
            },
            {
                value: VariableTypes.Boolean,
                label: "Boolean",
            },
            {
                value: VariableTypes.Function,
                label: "Function",
            },
            {
                value: VariableTypes.ScriptGeneration,
                label: "Script Generated",
            },
        ]);
    }

    clone() {
        let clone = new VariableDefinition().initCreate(this.parent, this.icon, this.selectedIcon, this.title, this.description, this.value, this.dataType);
        clone.setId(this.getId());

        return clone;
    }

    getJson() {
        return {
            ...super.getJson(),
            value: this.value,
            datatype: this.dataType,
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "VariableDefinition";
    }
}