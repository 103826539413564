export function getArrayElementByField(set, funcParamArray, ExpectedfuncOutcome, func, expectedPropOutcome, prop) {
    let filtered = set.filter((item) => {
        let funcFound = func !== undefined ? item[func] !== undefined : false;
        let propFound = prop !== undefined ? item[prop] !== undefined : false;

        let funcOutcome = false;
        let propOutcome = false;
        
        if (funcFound) {
            funcOutcome = item[func](...funcParamArray) === ExpectedfuncOutcome;
        }

        if (propFound) {
            propOutcome = item[prop] === expectedPropOutcome;
        }

        if (funcFound && propFound) {
            return funcOutcome && propOutcome;
        } else if (funcFound && !propFound) {
            return funcOutcome;
        } else if (!funcFound && propFound) {
            return propOutcome;
        } else {
            return false;
        }
    });

    if (filtered.length === 1) {
        return filtered[0];
    } else if (filtered.length > 1) {
        return filtered;
    }
}

export function isSame(array1, array2) {
    var is_same = (array1.length == array2.length) && array1.every(function(element, index) {
        return element === array2[index]; 
    });

    return is_same;
}