import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useEffect } from 'react';

import CancelIcon from '@mui/icons-material/Close';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AnimationIcon from '@mui/icons-material/Animation';
import SearchIcon from '@mui/icons-material/Search';
import ShuffleOnIcon from '@mui/icons-material/ShuffleOn';

import { RandomizedIntegerParameter, RandomizedDecimalParameter, NumericalSequenceParameter, SequencialParameter, PoolParameter } from '../../Classes/Parameters';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} mountOnEnter unmountOnExit />;
});

export default function CreateParameterDialog(props) {
    const [host, setHost] = React.useState(undefined);
    const [isOpen, setIsOpen] = React.useState(false);
    const [parameterType, setParameterType] = React.useState(-1);

    useEffect(() => {
        if (props.open !== isOpen) {
            setIsOpen(props.open);
        }

        if (props.focus !== host &&
            props.focus !== undefined) {
            setHost(props.focus);
        }
    }, [props]);

    useEffect(() => {
        if (parameterType > -1) {
            let parameter = undefined;
            switch (parameterType) {
                case 0: // Randomized Integer
                    parameter = RandomizedIntegerParameter.getDefault(host, "parameter-" + host.getParameters().length, 0, 10);
                    break;
                case 1: // Randomized Decimal
                    parameter = RandomizedDecimalParameter.getDefault(host, "parameter-" + host.getParameters().length, 0, 10);
                    break;
                case 2: // Numerical Sequence
                    parameter = NumericalSequenceParameter.getDefault(host, "parameter-" + host.getParameters().length, 0, 10, 1, true);
                    break;
                case 3: // Sequential Items
                    parameter = SequencialParameter.getDefault(host, "parameter-" + host.getParameters().length, true);
                    break;
                case 4: // Randomized Pool
                    parameter = PoolParameter.getDefault(host, "parameter-" + host.getParameters().length, true);
                    break;
            }

            props.onClose(parameter);
        }
    }, [parameterType]);

    const handleClose = () => {
        props.onClose(undefined);
    };

    var choiceDisplay = (<div />);
    if (parameterType === -1) {
        choiceDisplay = (
            <Box sx={{ width: '100%', maxWidth: 360 }}>
                <List subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Choose parameter type
                    </ListSubheader>
                }>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => { setParameterType(0); }}>
                            <ListItemIcon>
                                <ShuffleIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Add Randomized Integer"
                                secondary="Upon each iteration of the question, contains a randomly generated integer between the provided values (inclusive)." />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => { setParameterType(1); }}>
                            <ListItemIcon>
                                <ShuffleOnIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Add Randomized Decimal"
                                secondary="Upon each iteration of the question, contains a randomly generated decimal between the provided values (inclusive)." />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => { setParameterType(2); }}>
                            <ListItemIcon>
                                <ArrowRightAltIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Add Numerical Sequence"
                                secondary="Upon each iteration of the question, returns successive items from a sequence of numbers until that sequence is exhausted.  The sequence will start over if set to loop, otherwise it will end.  This will result in no subsequent questions being generated." />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => { setParameterType(3); }}>
                            <ListItemIcon>
                                <AnimationIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Add Sequential Items"
                                secondary="Upon each iteration of the question, returns successive items from a finite, predefined set until they are exhausted.    The sequence will start over if set to loop, otherwise it will end.  This will result in no subsequent questions being generated." />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => { setParameterType(4); }}>
                            <ListItemIcon>
                                <SearchIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Add Randomized Pool"
                                secondary="Upon each iteration of the question, returns successive items from a finite, predefined set in a random order until they are exhausted.    The sequence will start over if set to loop, otherwise it will end.  This will result in no subsequent questions being generated." />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        );
    }

    var dialog = (<div />);
    if (host !== undefined && host !== null) {
        dialog = (
            <Dialog
                open={isOpen}
                onClose={handleClose}
                TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <SettingsApplicationsIcon />
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Choose a Parameter Type</Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    {choiceDisplay}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus color="inherit" onClick={handleClose}>
                        <Stack direction="row">
                            <CancelIcon />
                            <div />
                            <Typography color="inherit" variant="button" component="div" style={{ textAlign: 'right' }}>Cancel</Typography>
                        </Stack>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (dialog);
}
