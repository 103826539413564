import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import VolumeUp from '@mui/icons-material/VolumeUp';

const Input = styled(MuiInput)`
  width: 42px;
`;

// a control (based on the example here: (https://mui.com/material-ui/react-slider/)
//
// props:
//  defaultValue: the starting value
//  minimumValue: minimum value allowed
//  maximumValue: maximum value allowed
//  stepValue: the value of movements on the slider control
//  label: textual label to display
//  icon: an icon to show
//
// function: 
//    onValueChanged: called when the value changes
export default function InputSlider(props) {
    const [value, setValue] = React.useState(props.defaultValue);

    React.useEffect(() => {
        if (props.onValueChanged !== undefined) {
            props.onValueChanged(value);
        }
    }, [value]);

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleInputChange = (event) => {
        setValue(event.target.value === '' ? 0 : Number(event.target.value));
    };

    const handleBlur = () => {
        if (value < props.minimumValue) {
            setValue(props.minimumValue);
        } else if (value > props.maximumValue) {
            setValue(props.maximumValue);
        }
    };

    return (
        <Box sx={{ width: 250 }}>
            {
                props.label === undefined ? '' :
                    <Typography id="input-slider" gutterBottom>
                        {props.label}
                    </Typography>
            }
            <Grid container spacing={2} alignItems="center">
                {
                    props.icon === undefined ? '' :
                        <Grid item>
                            {props.icon}
                        </Grid>
                }
                <Grid item xs>
                    <Slider
                        step={props.stepValue}
                        marks={props.marks === true}
                        min={props.minimumValue}
                        max={props.maximumValue}
                        value={typeof value === 'number' ? value : props.minimumValue}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                    />
                </Grid>
                <Grid item>
                    <Input
                        value={value}
                        size="small"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        inputProps={{
                            step: props.stepValue,
                            min: props.minimumValue,
                            max: props.maximumValue,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}