import { HierarchicalRootBase, HierarchicalItemTypes }  from './BaseClassHierarchy';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import { FillInQuestion, MultipleChoiceQuestion, PoolQuestion } from './Questions';
import { whereSelected } from './SelectImplementation';
import { shuffle } from '../Libraries/Ordering';
import { StepResult } from './Stepping';

///
///  QuestionPool -> Question
///
export default class QuestionPool extends HierarchicalRootBase {
    constructor() {
        super();
    }

    initCreate(icon, selectedIcon, shuffled, title, description, topics) {
        super.initCreate(icon, selectedIcon, topics, HierarchicalItemTypes.QuestionPools, title, description, shuffled);

        return this;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);
        
        this.icon = <LibraryBooksOutlinedIcon />;
        this.selectedIcon = <LibraryBooksIcon />;

        this.children = struct.children.map((content, i) => {
            let outcome = undefined;
            switch (content._type) {
                case 'FillInQuestion':
                    outcome = new FillInQuestion().initJson(this, content);
                    break;
                case 'MultipleChoiceQuestion':
                    outcome = new MultipleChoiceQuestion().initJson(this, content);
                    break;
                case 'PoolQuestion':
                    outcome = new PoolQuestion().initJson(this, content);
                    break;
            }

            return outcome;
        });

        return this;
    }

    static getDefault(title, description) {
        return new QuestionPool().initCreate(<LibraryBooksOutlinedIcon />, <LibraryBooksIcon />, false, title, description, []);
    }

    clone(onlySelected) {
        let clone = new QuestionPool().initCreate(this.icon, this.selectedIcon, this.shuffled, this.title, this.description, [...this.topics]);
        clone.setId(this.getId());
        
        let clonedChildren = whereSelected(this.getChildren(), onlySelected).map((o, i) => {
            return o.clone(onlySelected);
        });
        clone.setChildren(clonedChildren);

        return clone;
    }

    getJson() {
        return {
            ...super.getJson(),          
            _type: this.constructor.ClassName(),
        };
    }

    initStep(state) {
        this.currentIndex = 0;
        if (this.isShuffled()) {
            this.working = shuffle([...this.children]);
        } else {
            this.working = [...this.children];
        }

        for (let i = 0; i < this.working.length; i++) {
            this.working[i].initStep(state);
        }

        return true;
    }

    getCurrent() {
        return this.working[this.currentIndex].getCurrent();
    }

    step(executionIndex) {
        let outcome = this.working[this.currentIndex].step(executionIndex);

        if (outcome === StepResult.Stepped) {
            this.currentIndex++;
            if (this.currentIndex >= this.working.length) {
                this.currentIndex = 0;
                if (this.isShuffled()) {
                    shuffle(this.working);
                }

                outcome = StepResult.Stepped;
            } else {
                outcome = StepResult.SteppedWithMore;
            }
        }

        return outcome;
    }
    
    update(executionIndex) {
        this.working[this.currentIndex].update(executionIndex);
    }

    static ClassName() {
        return "QuestionPool";
    }
}