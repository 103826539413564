import React, { useContext } from 'react';

import { QuizzicalContext, ScreenModes } from '../Context/QuizzicalContext';
import DataExportManager from '../Controls/DataManagement/DataExportManager';

export default function ExportScreen(props) {
    const state = useContext(QuizzicalContext);

    return (
        <DataExportManager />
    );
}