import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import { QuizzicalContext } from '../../Context/QuizzicalContext';
import ContentTypeEditor from './ContentTypeEditor';
import ImageContentEditor from './ImageContentEditor';
import ScriptContentEditor from './ScriptContentEditor';
import TextContentEditor from './TextContentEditor';
import { ContentTypes, ContentSources } from '../../Classes/Layout/LayoutMeta';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import SettingsIcon from '@mui/icons-material/Settings';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { randomId } from '@mui/x-data-grid-generator';
import JavascriptIcon from '@mui/icons-material/Javascript';
import ImageIcon from '@mui/icons-material/Image';
import DescriptionIcon from '@mui/icons-material/Description';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import Subject from '../../Classes/Subjects';
import { FillInQuestion } from '../../Classes/Questions';

export default function LayoutRegionContainer(props) {
    const [focus, setFocus] = React.useState(undefined); // the entity where the storage manager resides
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [forceRefresh, setForceRefresh] = React.useState(false);
    const [showResize, setShowResize] = React.useState(true);
    const [renderMosaic, setRenderMosaic] = React.useState(false);
    const containerReference = React.useRef(undefined);
    const [idKey, setIdKey] = React.useState(undefined);

    const [resizeData, setResizeData] = React.useState({
        isResizing: false,
        resizeType: undefined,
        originX: undefined,
        originY: undefined,
        currentX: undefined,
        currentY: undefined,
        deltaX: 0,
        deltaY: 0,
        grewLeft: false,
        grewRight: false,
        shrunkLeft: false,
        shrunkRight: false,
        grewTop: false,
        grewBottom: false,
        shrunkTop: false,
        shrunkBottom: false,
    });
    const [isMouseOver, setIsMouseOver] = React.useState(false);
    const [cursor, setCursor] = React.useState('default');

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }

        if (props.asMosaic !== undefined &&
            props.asMosaic !== null &&
            props.asMosaic !== renderMosaic) {
            setRenderMosaic(props.asMosaic);
        }
    }, [props]);

    React.useEffect(() => {
        if (focus !== undefined) {
            setIdKey("layoutRegionContainer-" + focus.getX() + "-" + focus.getY());
        }
    }, [focus]);

    React.useEffect(() => {
        if (props.selected === true) {
            props.onSelectedChanged(focus, false);
        }
    }, [showResize]);

    React.useEffect(() => {
        // obvious variable name is obvious purpose
    }, [forceRefresh]);

    const getCursorPosition = (rX, rY, ghawX, ghawY, maxX, maxY, returnDirectionKeys = true) => {
        let result = returnDirectionKeys === true ? undefined : 'default';

        let dGhawX = ghawX * 2; // doubled ghawX
        let dGhawY = ghawY * 2; // doubled ghawY

        let bHeight = focus.getParent().getHeight(); // board height (zero indices)
        let bWidth = focus.getParent().getWidth(); // board width (zero indices)

        let fX = focus.getX();
        let fY = focus.getY();
        let fR = focus.getRight();
        let fB = focus.getBottom();

        // check upper left grabby
        if ((fY > 0 || fB > 0) &&
            fX < bWidth &&
            rX <= dGhawX &&
            rY <= dGhawY &&
            isMouseOver) {
            result = returnDirectionKeys ? 'nw' : 'nwse-resize';
        }

        // check upper right grabby
        else if (
            fX > 0 &&
            (fY > 0 || fB > 0) &&
            rX >= (maxX - dGhawX) &&
            rX <= maxX &&
            rY <= dGhawY &&
            isMouseOver) {
            result = returnDirectionKeys ? 'ne' : 'nesw-resize';
        }

        // check lower left grabby
        else if (
            fY < bHeight &&
            fX < bWidth &&
            rX <= dGhawX &&
            rY >= (maxY - dGhawY) &&
            rY <= maxY &&
            isMouseOver) {
            result = returnDirectionKeys ? 'sw' : 'nesw-resize';
        }

        // check lower right grabby
        else if (
            fX > 0 &&
            fY < bHeight &&
            rX >= (maxX - dGhawX) &&
            rX <= maxX &&
            rY >= (maxY - dGhawY) &&
            rY <= maxY &&
            isMouseOver) {
            result = returnDirectionKeys ? 'se' : 'nwse-resize';
        }

        // check left grabby
        else if (
            (fX < (bWidth - 1) || fR > 0) &&
            rX <= ghawX &&
            isMouseOver) {
            result = returnDirectionKeys ? 'w' : 'ew-resize';
        }

        // check right grabby
        else if (
            fX > 0 &&
            rX >= (maxX - ghawX) &&
            rX <= maxX &&
            isMouseOver) {
            result = returnDirectionKeys ? 'e' : 'ew-resize';
        }

        // check top grabby
        else if (
            (fY > 0 || fB > 0) &&
            rY <= ghawY &&
            isMouseOver) {
            result = returnDirectionKeys ? 'n' : 'ns-resize';
        }

        // check bottom grabby
        else if (
            fY < bHeight &&
            rY >= (maxY - ghawY) &&
            rY <= maxY &&
            isMouseOver) {
            result = returnDirectionKeys ? 's' : 'ns-resize';
        }

        return result;
    }

    const getDelta = (oldV, newV) => {
        return (oldV || 0) - (newV || 0);
    }

    const handleMousePress = (event) => {
        if (focus.getType() !== ContentTypes.None) return;
        if (!isMouseOver && !renderMosaic && showResize) return;
        if (event.target.id !== idKey) return;

        let rootRect = containerReference.current.getBoundingClientRect();
        let rX = event.clientX - rootRect.left;
        let rY = event.clientY - rootRect.top;
        let maxX = containerReference.current.clientWidth;
        let maxY = containerReference.current.clientHeight;

        let ghawX = 10; // X grabby hand area width
        let ghawY = 10; // Y grabby hand area width

        let rType = getCursorPosition(rX, rY, ghawX, ghawY, maxX, maxY, true);
        if (rType !== undefined) {
            containerReference.current.setPointerCapture(event.pointerId);
            let pointerCap = containerReference.current.hasPointerCapture(event.pointerId);
            if (pointerCap) {
                let data = {
                    ...resizeData,
                    isResizing: true,
                    resizeType: rType,
                    originX: rX,
                    originY: rY,
                    deltaX: 0,
                    deltaY: 0,
                    grewX: false, // grow
                    grewY: false, // grow
                    shrunkX: false, // shrink
                    shrunkY: false, // shrink
                };
                setResizeData(data);
            }
        }
    }

    const handleMouseRelease = (event) => {
        if (event.target.id !== idKey) return;
        setResizeData({
            ...resizeData,
            isResizing: false,
            resizeType: undefined
        });
        containerReference.current.releasePointerCapture(event.pointerId);
    }

    const handleMouseEnter = (event) => {
        if (props.onMousedInto !== undefined) {
            props.onMousedInto(idKey);
        }
        setIsMouseOver(true);
    };

    const handleMouseExit = () => {
        setIsMouseOver(false);
    };

    const handleMouseMove = (event) => {
        if (event.target.id !== idKey) {
            setCursor('default');
            return;
        }
        if (renderMosaic) return;
        if (!showResize) return;
        if (!isMouseOver && !resizeData.isResizing === true) return;
        event.preventDefault();

        let rootRect = event.target.getBoundingClientRect();
        let rX = event.clientX - rootRect.left;
        let rY = event.clientY - rootRect.top;
        let maxX = containerReference.current.clientWidth;
        let maxY = containerReference.current.clientHeight;

        let ghawX = 10; // X grabby hand area width
        let ghawY = 10; // Y grabby hand area width

        if (resizeData.isResizing === true) {
            let gMaxX = focus.getParent().getWidth();
            let gMaxY = focus.getParent().getHeight();
            let unitWidth = props.unitWidth;
            let unitHeight = props.unitHeight;

            let data = {
                ...resizeData,
                currentX: rX,
                currentY: rY,
                deltaX: ['w', 'e', 'ne', 'nw', 'se', 'sw'].includes(resizeData.resizeType) ? getDelta(resizeData.originX, rX) : undefined,
                deltaY: ['n', 's', 'ne', 'nw', 'se', 'sw'].includes(resizeData.resizeType) ? getDelta(resizeData.originY, rY) : undefined,
                // calculate what the delta needs to reach for resizing
                leftXDelta: maxX / (focus.getRight() + 1),
                topYDelta: maxY / (focus.getBottom() + 1),
                rightXDelta: (maxX / (focus.getRight() + 1)) * -1,
                bottomYDelta: (maxY / (focus.getBottom() + 1)) * -1,
                grewLeft: false,
                grewRight: false,
                shrunkLeft: false,
                shrunkRight: false,
                grewTop: false,
                grewBottom: false,
                shrunkTop: false,
                shrunkBottom: false,
            };

            // check the resize operation type,
            // then which way we are current resizing within the two possibilities of that operation
            // validate that we can currently do that
            // perform the resize
            let changes = false;

            // step 1: determine operation type
            if (data.resizeType.includes('w')) {
                // step 2: determine resize direction
                if (data.deltaX > data.leftXDelta) {
                    // step 3: validate that we can grow to the left
                    if ((focus.getX() + 1) < gMaxX &&
                        (checkNoChanges() || resizeData.grewLeft)) {
                        // step 4: perform the operation
                        focus.shiftRight(1, true);
                        focus.shiftX(1);

                        data = {
                            ...data,
                            originX: rX,
                            deltaX: 0,
                            grewLeft: true,
                        }
                        changes = true;
                    }
                } else if (data.deltaX < data.rightXDelta) {
                    // step 3: validate that we can shrink to the right
                    if ((focus.getX()) > 0 &&
                        (focus.getRight()) > 0 &&
                        (checkNoChanges() || resizeData.shrunkLeft)) {
                        // step 4: perform the operation
                        if (focus.shiftRight(-1) >= 0) {
                            focus.shiftX(-1);
                        }

                        data = {
                            ...data,
                            originX: rX,
                            deltaX: 0,
                            shrunkLeft: true,
                        }
                        changes = true;
                    }
                }
            } else if (data.resizeType.includes('e')) {
                // step 2: determine resize direction
                if (data.deltaX > data.leftXDelta &&
                    (checkNoChanges() || resizeData.shrunkRight)) {
                    // step 3: validate that we can shrink to the left
                    if (focus.getRight() > 0) {
                        // step 4: perform the operation
                        focus.shiftRight(-1);

                        data = {
                            ...data,
                            originX: rX,
                            deltaX: 0,
                            shrunkRight: true,
                        }
                        changes = true;
                    }
                } else if (data.deltaX < data.rightXDelta) {
                    // step 3: validate that we can grow to the right
                    if ((focus.getX() - focus.getRight()) > 0 &&
                        (checkNoChanges() || resizeData.grewRight)) {
                        // step 4: perform the operation
                        focus.shiftRight(1);

                        data = {
                            ...data,
                            originX: rX,
                            deltaX: 0,
                            grewRight: true,
                        }
                        changes = true;
                    }
                }
            }

            // step 1: determine operation type
            if (data.resizeType.includes('n')) {
                // step 2: determine resize direction
                if (data.deltaY > data.topYDelta) {
                    // step 3: validate that we can grow upward
                    if ((focus.getY()) > 0 &&
                        (checkNoChanges() || resizeData.grewTop)) {
                        // step 4: perform the operation
                        focus.shiftBottom(1, true);
                        focus.shiftY(-1);

                        data = {
                            ...data,
                            originY: rY,
                            deltaY: 0,
                            grewTop: true,
                        }
                        changes = true;
                    }
                } else if (data.deltaY < data.bottomYDelta) {
                    // step 3: validate that we can shrink downward
                    if ((focus.getY() + 1) < gMaxY &&
                        (focus.getBottom()) > 0 &&
                        (checkNoChanges() || resizeData.shrunkTop)) {
                        // step 4: perform the operation
                        if (focus.shiftBottom(-1) >= 0) {
                            focus.shiftY(1);
                        }

                        data = {
                            ...data,
                            originY: rY,
                            deltaY: 0,
                            shrunkTop: true,
                        }
                        changes = true;
                    }
                }
            } else if (data.resizeType.includes('s')) {
                // step 2: determine resize direction
                if (data.deltaY > data.topYDelta &&
                    (checkNoChanges() || resizeData.shrunkBottom)) {
                    // step 3: validate that we can shrink upward
                    if (focus.getBottom() > 0) {
                        // step 4: perform the operation
                        focus.shiftBottom(-1);

                        data = {
                            ...data,
                            originY: rY,
                            deltaY: 0,
                            shrunkBottom: true,
                        }
                        changes = true;
                    }
                } else if (data.deltaY < data.bottomYDelta) {
                    // step 3: validate that we can grow downward
                    if ((focus.getY() + focus.getBottom() + 1) < gMaxY &&
                        (checkNoChanges() || resizeData.grewBottom)) {
                        // step 4: perform the operation
                        focus.shiftBottom(1);

                        data = {
                            ...data,
                            originY: rY,
                            deltaY: 0,
                            grewBottom: true,
                        }
                        changes = true;
                    }
                }
            }

            setResizeData(data);
            if (changes) {
                focus.done();
                props.onRefreshRequired();
            }
        } else {
            if (event.target.id === idKey) {
                setCursor(getCursorPosition(rX, rY, ghawX, ghawY, maxX, maxY, false));
            } else {
                setCursor('default');
            }
        }
    }

    const checkNoChanges = () => {
        return (
            resizeData.grewLeft === false &&
            resizeData.grewRight === false &&
            resizeData.shrunkLeft === false &&
            resizeData.shrunkRight === false &&
            resizeData.grewTop === false &&
            resizeData.grewBottom === false &&
            resizeData.shrunkTop === false &&
            resizeData.shrunkBottom === false
        );
    }

    const getBackgroundBySourceType = (theme) => {
        let result = "gray";
        switch (focus.getSource()) {
            case ContentSources.Subject:
                result = "blue";
                break;
            case ContentSources.Question:
                result = "orange";
                break;
            case ContentSources.Answer:
                result = "magenta";
                break;
            case ContentSources.Reserved:
                result = "black";
                break;
        }

        return result;
    }

    const getDragBorderBySourceType = (theme) => {
        let result = theme.palette.mode === 'dark' ? "white" : 'blue';
        switch (focus.getSource()) {
            case ContentSources.Subject:
                result = "darkblue";
                break;
            case ContentSources.Question:
                result = "darkred";
                break;
            case ContentSources.Answer:
                result = "purple";
                break;
            case ContentSources.Reserved:
                result = "darkgray";
                break;
        }

        return result;
    }

    const getRegionContentSources = () => {
        let sources = [];

        if (focus.getSource() === ContentSources.Unassigned) {
            sources.push(
                <MenuItem
                    key="source-1"
                    value={ContentSources.Unassigned}>Unassigned</MenuItem>
            );
        }

        if (isSubject || (focus.getSource() === ContentSources.Subject)) {
            sources.push(
                <MenuItem
                    key="source-2"
                    value={ContentSources.Subject}>Subject Related</MenuItem>
            );
        }

        if (!isSubject || focus.getSource() === ContentSources.Question) {
            sources.push(
                <MenuItem
                    key="source-3"
                    value={ContentSources.Question}>Question Related</MenuItem>
            );
        }

        //if (!isSubject || focus.getSource() === ContentSources.Answer) {
        if (!isSubject) {
            let showAnswerItem = false;
            if (focus.getSource() === ContentSources.Answer) {
                showAnswerItem = true;
            } else if (isFillInChoice) {
                showAnswerItem = true;
            } else if (
                !isFillInChoice &&
                focus.getParent().getRegionsBySource(ContentSources.Answer).length < 1) {
                showAnswerItem = true;
            }

            if (showAnswerItem) {
                sources.push(
                    <MenuItem
                        key="source-4"
                        value={ContentSources.Answer}>Answer Related</MenuItem>
                );
            }
        }

        if (isSubject || focus.getSource() === ContentSources.Reserved) {
            sources.push(
                <MenuItem
                    key="source-5"
                    value={ContentSources.Reserved}>Reserved</MenuItem>
            );
        }

        return sources;
    }

    const handleSourceTypeChange = (event) => {
        focus.setSource(event.target.value);
        focus.setContent(undefined);

        if (focus.getSource() !== ContentSources.Reserved) {
            setShowResize(false);
        } else {
            setShowResize(true);
        }
        setForceRefresh(!forceRefresh);
    };

    const hasNoRightToEdit = () => {
        let isSubject = focus.getParent().getParent().constructor.ClassName() === Subject.ClassName();
        let isparentedBySubject = undefined;
        if (isSubject) {
            isparentedBySubject = false;
        } else {
            isparentedBySubject = focus.getParent().getParent().getParent().constructor.ClassName() === Subject.ClassName();
        }
        let sourceMatches = false;

        if (isSubject) {
            if (
                focus.getSource() !== ContentSources.Question &&
                focus.getSource() !== ContentSources.Answer) {
                sourceMatches = true;
            }
        } else {
            if (
                focus.getSource() === ContentSources.Question ||
                focus.getSource() === ContentSources.Answer ||
                focus.getSource() === ContentSources.Reserved ||
                (!isparentedBySubject && focus.getSource() === ContentSources.Unassigned)) {
                sourceMatches = true;
            }
        }

        return !sourceMatches;
    }

    const getContentTypeIcon = () => {
        let result = '';
        switch (focus.getType()) {
            case ContentTypes.Image:
                result = <ImageIcon />;
                break;
            case ContentTypes.ImageSet:
                result = <PermMediaIcon />;
                break;
            case ContentTypes.Script:
                result = <JavascriptIcon />;
                break;
            case ContentTypes.Text:
                result = <DescriptionIcon />;
                break;
        }

        return result;
    }

    const getRootStyleObject = () => {
        let style = {
            ...props.sx,
            cursor: cursor,
            backgroundColor: (theme) => getBackgroundBySourceType(theme),
        };

        // insert the borders
        if (isMouseOver && showResize && !renderMosaic) {
            if (resizeData.isResizing) {
                style = {
                    ...style,
                    padding: '10px',
                    border: '5px solid',
                    borderRadius: '10px',
                    borderColor: (theme) =>
                        getDragBorderBySourceType(theme),
                }
            } else {
                style = {
                    ...style,
                    padding: '10px',
                    border: '5px solid',
                    borderRadius: '10px',
                    borderColor: (theme) =>
                        getDragBorderBySourceType(theme),
                }
            }
        } else {
            style = {
                ...style,
                border: '1px dashed',
                borderColor: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
            }
        }

        return style;
    }

    if (focus === undefined) {
        return (<div />);
    }

    let isSubject = focus.getParent().getParent().constructor.ClassName() === Subject.ClassName();
    let isFillInChoice = focus.getParent().getParent().constructor.ClassName() === FillInQuestion.ClassName();
    let content = (<div />);

    if (!renderMosaic && !showResize) {
        let globalizeBackground = renderMosaic;

        switch (focus.getType()) {
            case ContentTypes.None:
                let showContentChoice = false;
                if (isSubject) {
                    if (focus.getSource() === ContentSources.Subject) {
                        showContentChoice = true;
                    }
                } else {
                    // only show the content choices for questions if:
                    // 1) the content source is Question
                    // 2) the content source is Answer and the question type is Fill In
                    if (focus.getSource() === ContentSources.Question) {
                        showContentChoice = true;
                    } else if (focus.getSource() === ContentSources.Answer && isFillInChoice) {
                        showContentChoice = true;
                    }
                }

                /* let isMultipleChoice = focus.getParent().getParent().constructor.ClassName() === FillInQuestion.ClassName();
                if ((isSubject && (focus.getSource() === ContentSources.Subject) ||
                    (!isSubject && (focus.getSource() === ContentSources.Question || focus.getSource() === ContentSources.Answer)))) { */
                if (showContentChoice) {
                    content = (
                        <ContentTypeEditor
                            show={!showResize && isMouseOver}
                            isReadOnly={isReadOnly || hasNoRightToEdit()}
                            onNeedRefresh={(e) => { setForceRefresh(!forceRefresh); }}
                            focus={focus}
                            isMouseOver={isMouseOver} />
                    );
                }
                break;
            case ContentTypes.Text:
                content = (
                    <TextContentEditor
                        entity={focus.getParent().getParent()}
                        isReadOnly={isReadOnly || hasNoRightToEdit()}
                        onNeedRefresh={props.onNeedRefresh}
                        focus={focus}
                        isMouseOver={isMouseOver}
                        dimensions={
                            containerReference !== undefined && containerReference.current !== undefined ?
                                { width: containerReference.current.clientWidth, height: containerReference.current.clientHeight } : undefined} />
                );
                break;
            case ContentTypes.Script:
                content = (
                    <ScriptContentEditor
                        entity={focus.getParent().getParent()}
                        isReadOnly={isReadOnly || hasNoRightToEdit()}
                        onNeedRefresh={props.onNeedRefresh}
                        focus={focus}
                        isMouseOver={isMouseOver}
                        dimensions={
                            containerReference !== undefined && containerReference.current !== undefined ?
                                { width: containerReference.current.clientWidth, height: containerReference.current.clientHeight } : undefined} />
                );
                break;
            case ContentTypes.Image:
                content = (
                    <ImageContentEditor
                        opacity={globalizeBackground ? '100%' : '100%'}
                        isReadOnly={isReadOnly || hasNoRightToEdit()}
                        onNeedRefresh={props.onNeedRefresh}
                        focus={focus}
                        isMouseOver={isMouseOver}
                        dimensions={
                            containerReference !== undefined && containerReference.current !== undefined ?
                                { width: containerReference.current.clientWidth, height: containerReference.current.clientHeight } : undefined} />
                );
                break;
            case ContentTypes.ImageSet:
                content = (
                    <ImageContentEditor
                        opacity={globalizeBackground ? '100%' : '100%'}
                        isReadOnly={isReadOnly || hasNoRightToEdit()}
                        onNeedRefresh={props.onNeedRefresh}
                        focus={focus}
                        isMouseOver={isMouseOver}
                        dimensions={
                            containerReference !== undefined && containerReference.current !== undefined ?
                                { width: containerReference.current.clientWidth, height: containerReference.current.clientHeight } : undefined} />
                );
                break;
        }
    }
    /* if (focus !== undefined && !renderMosaic) {
        if (resizeData.isResizing === true) {
            content = (
                <Stack>
                    <Typography color="inherit" variant="caption">{"Resizing from " + resizeData.originX + ", " + resizeData.originY + " along the " + resizeData.resizeType + " axis."}</Typography>
                    <Typography color="inherit" variant="caption">{"Delta of " + resizeData.deltaX + ", " + resizeData.deltaY}</Typography>
                    <Typography color="inherit" variant="caption">{"Coordinates - x: " + focus.getX() + ", y: " + focus.getY()}</Typography>
                    <Typography color="inherit" variant="caption">{"Dimensions - width: " + focus.getRight() + ", height: " + focus.getBottom()}</Typography>
                    <Typography color="inherit" variant="caption">{"Deltas - top: " + resizeData.topYDelta + ", left: " + resizeData.leftXDelta + ", right: " + resizeData.rightXDelta + ", bottom: " + + resizeData.bottomYDelta}</Typography>
                </Stack>
            );
            ;
        } else {
            content = (
                <Stack>
                    <Typography color="inherit" variant="caption">{"Resize system idle..."}</Typography>
                    <Typography color="inherit" variant="caption">{"Coordinates - x: " + focus.getX() + ", y: " + focus.getY()}</Typography>
                    <Typography color="inherit" variant="caption">{"Dimensions - width: " + focus.getRight() + ", height: " + focus.getBottom()}</Typography>
                </Stack>
            );
        }
    } */

    return (
        <Box
            id={idKey}
            ref={containerReference}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseExit}
            onMouseMove={handleMouseMove}
            onPointerDown={handleMousePress}
            onPointerUp={handleMouseRelease}
            sx={getRootStyleObject()}
            spacing={0}>
            <Zoom
                key="content-zoomer"
                in={!showResize && !renderMosaic}
                unmountOnExit>
                <Paper
                    sx={{
                        height: '100%',
                    }}>
                    {content}
                </Paper>
            </Zoom>
            {<Zoom
                key="fab-toggle-source-assignment"
                in={isMouseOver && !renderMosaic}
                unmountOnExit>
                <Paper
                    sx={{
                        position: 'absolute',
                        top: '-35px',
                        left: 'calc(0% + 50px)',
                        width: 'auto',
                        borderRadius: '4px',
                        zIndex: 1000,
                    }}>
                    <Stack direction="row">
                        <Button onClick={(e) => { setShowResize(!showResize); }}>
                            {showResize ?
                                <Tooltip title="Switch to Content Configuration Mode"><SettingsIcon /></Tooltip> :
                                <Tooltip title="Switch to Resizing Mode"><OpenWithIcon /></Tooltip>}
                        </Button>
                        <Collapse
                            unmountOnExit
                            orientation='horizontal'
                            in={focus.getType() === ContentTypes.None}>
                            <FormControl size='small'>
                                <Select
                                    disabled={
                                        (isSubject && (focus.getSource() !== ContentSources.Unassigned && focus.getSource() !== ContentSources.Subject && focus.getSource() !== ContentSources.Reserved)) ||
                                        (!isSubject && (focus.getSource() !== ContentSources.Unassigned && focus.getSource() !== ContentSources.Reserved && focus.getSource() !== ContentSources.Question && focus.getSource() !== ContentSources.Answer))
                                    }
                                    defaultValue={focus.getSource()}
                                    onChange={handleSourceTypeChange}>
                                    {getRegionContentSources()}
                                </Select>
                            </FormControl>
                        </Collapse>
                        <Collapse
                            unmountOnExit
                            orientation='horizontal'
                            in={focus.getType() !== ContentTypes.None}>
                            <Tooltip title="Reset Content Type"><Button onClick={(e) => { focus.setType(ContentTypes.None); setForceRefresh(!forceRefresh); }}><RestartAltIcon /></Button></Tooltip>
                        </Collapse>
                    </Stack>
                </Paper>
            </Zoom>}
        </Box>
    );
}