import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { randomId } from '@mui/x-data-grid-generator';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Switch } from '@mui/material';

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import FieldContainer from '../../Forms/FieldContainer';
import FEH_Form from '../../Forms/FEH_Form';
import VariableDefinition, { VariableTypes } from '../../../Classes/VariableDefinition';
import { MenuItem } from '@mui/material';
import InsertableJavascriptEditor from '../../InsertableJavascriptEditor';

export default function FEH_VariableEditor(props) {
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [defaultFieldSet, setDefaultFieldSet] = React.useState(randomId());

    const [forceRefresh, setForceRefresh] = React.useState(false);

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }
    }, [props]);

    const updateFocus = () => {
        focus.setTitle(document.getElementById("variable-name").value);
        focus.setDescription(document.getElementById("variable-description").value);
        updateValue();

        return focus;
    }

    const onVariableTypeChanged = (event) => {
        updateFocus();
        focus.setDataType(event.target.value);
        setForceRefresh(!forceRefresh);
    }

    const updateValue = () => {
        switch (focus.getDataType()) {
            case VariableTypes.Number:
                focus.setValue(document.getElementById("variable-value").value);
                break;
            /* case VariableTypes.BigInt:
                focus.setValue(document.getElementById("variable-value").value);
                break; */
            case VariableTypes.String:
                focus.setValue(document.getElementById("variable-value").value);
                break;
            case VariableTypes.DateTime:
                // handled through on change events
                break;
            case VariableTypes.Boolean:
                focus.setValue(document.getElementById("variable-value").checked);
                break;
            case VariableTypes.Function:
                // don't need to because it updates in realtime
                break;
            case VariableTypes.ScriptGeneration:
                // don't need to because it updates in realtime
                break;
        }
    }

    const getValueTooltip = () => {
        let outcome = undefined;
        switch (focus.getDataType()) {
            case VariableTypes.Number:
                outcome = "Choose a number or leave blank for undefined";
                break;
            /* case VariableTypes.BigInt:
                outcome = "Choose a number or leave blank for undefined";
                break; */
            case VariableTypes.String:
                outcome = "Enter text for the value of this variable";
                break;
            case VariableTypes.DateTime:
                outcome = "Select the date and time you wish to convey.\n\nNote that if you wish to store the current moment, as in Date.now() in javascript, you should do that through code in your scripts and not with a variable";
                break;
            case VariableTypes.Boolean:
                outcome = "Toggle the switch to your preferred setting.\n\nGrayed is false, while illuminated is true";
                break;
            case VariableTypes.Function:
                outcome = "Enter the full definition for your required anonymous function";
                break;
            case VariableTypes.ScriptGeneration:
                outcome = "The input here will be executed like a prescript and then the result stored for the duration of the question's lifecycle";
                break;
        }

        return outcome;
    }

    const getField = () => {
        let outcome = "None";
        switch (focus.getDataType()) {
            case VariableTypes.Number:
                outcome = (
                    <TextField
                        disabled={isReadOnly}
                        id="variable-value"
                        type="number"
                        defaultValue={focus.getValue()} />
                );
                break;
            /* case VariableTypes.BigInt:
                outcome = (
                    <TextField
                        disabled={isReadOnly}
                        id="variable-value"
                        type="number"
                        defaultValue={focus.getValue()} />
                );
                break; */
            case VariableTypes.String:
                outcome = (
                    <TextField
                        disabled={isReadOnly}
                        id="variable-value"
                        multiline
                        rows={4}
                        defaultValue={focus.getValue()}
                        sx={{ minWidth: '100%', width: '100%' }} />
                );
                break;
            case VariableTypes.DateTime:
                outcome = (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            value={dayjs(focus.getValue())}
                            onChange={(newValue) => focus.setValue(newValue)}
                        />
                    </LocalizationProvider>
                );
                break;
            case VariableTypes.Boolean:
                outcome = (
                    <Switch
                        id="variable-value"
                        defaultChecked={focus.getValue()} />
                );
                break;
            case VariableTypes.Function:
                outcome = (
                    <InsertableJavascriptEditor
                        disableTermInsertion={true}
                        height="35vH"
                        width='100%'
                        fabStyling={{
                            position: 'absolute',
                            top: -35,
                            right: 16,
                        }}
                        focus={focus}
                        source={focus}
                        isReadOnly={isReadOnly}
                        isMouseOver={true}
                        setter="setValue"
                        getter="getValue" />
                );
                break;
            case VariableTypes.ScriptGeneration:
                outcome = (
                    <InsertableJavascriptEditor
                        disableTermInsertion={true}
                        height="35vH"
                        width='100%'
                        fabStyling={{
                            position: 'absolute',
                            top: 465,
                            right: 30,
                            zIndex: 4000,
                        }}
                        focus={focus}
                        source={focus}
                        isReadOnly={isReadOnly}
                        isMouseOver={true}
                        setter="setValue"
                        getter="getValue" />
                );
                break;
        }

        return outcome;
    }

    if (isReadOnly) {
        return ( // readonly
            <Box
                sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                }}>
                <Grid spacing={2}>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Typography color="inherit" variant="body" component="div">Name:&nbsp;&nbsp;&nbsp;</Typography>
                        <Typography color="inherit" variant="caption" component="div">{focus.getTitle()}</Typography>
                    </Stack>
                    <Stack>
                        <Typography color="inherit" variant="body" component="div">Description</Typography>
                        <Typography color="inherit" variant="caption" component="div">
                            <pre style={{ fontFamily: 'inherit' }}>
                                {focus.getDescription() || "No Description"}
                            </pre>
                        </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                        <Typography color="inherit" variant="body" component="div">Type:</Typography>
                        <Typography color="inherit" variant="caption" component="div">{focus.getTypeName()}</Typography>
                    </Stack>
                </Grid>
            </Box>
        );
    } else {
        return (
            <FEH_Form
                height="88vH"
                formId="VariableForm"
                type="Variable"
                getInstance={updateFocus}
                onClose={props.onClose}
                onConfirm={props.onConfirm}
                onReportMessages={props.onReportMessages}>
                <Stack spacing={2}>
                    <FieldContainer
                        fieldSet={defaultFieldSet}
                        label="Name"
                        help="The variable's name"
                        fullWidth
                        required>
                        <TextField
                            disabled={isReadOnly}
                            id="variable-name"
                            required
                            defaultValue={focus.getTitle()}
                            sx={{ minWidth: '100%', width: '100%' }} />
                    </FieldContainer>
                    <FieldContainer
                        fieldSet={defaultFieldSet}
                        label="Description"
                        help="A brief summary of the variable's purpose"
                        fullWidth>
                        <TextField
                            disabled={isReadOnly}
                            id="variable-description"
                            multiline
                            rows={4}
                            defaultValue={focus.getDescription()}
                            sx={{ minWidth: '100%', width: '100%' }} />
                    </FieldContainer>
                    <FieldContainer
                        fieldSet={defaultFieldSet}
                        label="Type"
                        help="The data type of this variable"
                        fullWidth>
                        <FormControl fullWidth>
                            <Select
                                id="variable-type"
                                defaultValue={focus.getDataType()}
                                onChange={onVariableTypeChanged}>
                                {
                                    VariableDefinition.enumerateDataTypes(focus.getDataType() === VariableTypes.None).map((vd) => {
                                        return <MenuItem value={vd.value}>{vd.label}</MenuItem>;
                                    })
                                }
                            </Select>
                        </FormControl>
                    </FieldContainer>
                    <FieldContainer
                        fieldSet={defaultFieldSet}
                        label="Value"
                        help={getValueTooltip()}
                        fullWidth>
                        {getField()}
                    </FieldContainer>
                </Stack>
            </FEH_Form>
        );
    }
}