import { jsonifyArray } from "../Libraries/generalUtility";
import ClassName from "./ClassName";

// Represents the focus of a Question, Section, or Assignment
export class Topic extends ClassName {
    constructor() {
        super();
        this.subTopics = [];
    }

    initCreate(label, selectable, description, subTopics, id) {
        this.id = id || label.replace(" ", "-");
        this.label = label;
        this.isSelectable = selectable;
        this.parent = null;
        this.description = description;

        if (Array.isArray(subTopics)) {
            this.setSubtopics(subTopics);
        } else {
            this.setSubtopics([]);
        }

        return this;
    }

    initJson(parent, struct) {
        this.parent = parent;
        this.id = struct.id;
        this.label = struct.label;
        this.isSelectable = struct.isSelectable;
        this.description = struct.description;
        this.subTopics = struct.subTopics.map((topic, i) => {
            return new Topic().initJson(this, topic);
        });

        return this;
    }

    canSelect() {
        return this.isSelectable;
    }

    setSelectable(selectable) {
        this.isSelectable = selectable;
    }

    getSubtopics() {
        return this.subTopics;
    }

    setSubtopics(subTopics) {
        this.subTopics = subTopics;

        for (var t = 0; t < this.subTopics.length; t++) {
            this.subTopics[t].setDerivedId(this.id + "-" + t);
            this.subTopics[t].setParentTopic(this);
        }
    }

    getParentTopic() {
        return this.parent;
    }

    setParentTopic = (parent) => {
        if (parent !== null && parent !== undefined) {
            this.parent = parent;
            this.id = parent.id + "-" + parent.getSubtopics().indexOf(this);
        } else {
            this.id = "";
            this.parent = null;
        }
    }

    getSimpleLabel() {
        return this.label;
    }

    setSimpleLabel(label) {
        this.label = label;
    }

    getFullLabel() {
        let label = "{" + this.label + "}";
        if (this.parent !== null && this.parent !== undefined) {
            label = this.parent.getFullLabel() + ">" + label;
        }

        return label;
    }

    setDerivedId(id) {
        this.id = id;
    }

    getId() {
        return this.id;
    }

    search(id) {
        let result = undefined;
        if (this.id === id) {
            result = this;
        } else {
            for (var i = 0; i < this.getSubtopics().length; i++) {
                result = this.getSubtopics()[i].search(id);
                if (result !== undefined) {
                    break;
                }
            }
        }
    }

    getJson() {
        return {
            id: this.id,
            label: this.label,
            isSelectable: this.isSelectable,
            description: this.description,
            subTopics: jsonifyArray(this.subTopics),
            _type: this.constructor.ClassName(),
        };
    }

    getDescription() {
        return this.description;
    }

    setDescription(description) {
        this.description = description;
    }

    static ClassName() {
        return "Topic";
    }
}

// takes a [set] of topics and makes sure that all [topics] defined within the second set are present within the first
export function ensureAllPresent(ensureIn, checkFor) {
    // init section, ensure we have what is required to work
    let getTopicArray = (a) => {
        let outcome = undefined;
        if (Array.isArray(a)) {
            outcome = a;
        } else {
            if (a.constructor !== undefined &&
                a.constructor.ClassName() === Topic.ClassName()) {
                outcome = [a];
            }
        }

        return outcome;
    }

    let onlyUnique = (value, index, array) => {
        return array.indexOf(value) === index;
    }

    let getRootTopic = (topic) => {
        let root = topic;
        while (topic.getParentTopic() !== undefined) {
            root = topic.getParentTopic();
        }

        return root;
    }

    // takes a topic structure and generates a flat array containing all present fullNames
    let checkedFlattened = []; // results array passed as initial parameter
    let getFlattenedTopicSet = (topics, fullNames) => {
        if (Array.isArray(topics) === false &&
            topics.constructor.ClassName() === Topic.ClassName()) {
            fullNames.push({
                fullLabel: topics.getFullLabel(),
                topic: topics,
            });
            getFlattenedTopicSet(topics.getSubtopics(), fullNames);
        } else if (Array.isArray(topics)) {
            for (let i = 0; i < topics.length; i++) {
                getFlattenedTopicSet(topics[i], fullNames);
            }
        }
    }

    // a recursive search through the given topic, subtopics, ad infinium for the given fullName or as close as possible to it
    let resemblanceScore = 1000000;
    let resembler = undefined;
    let findClosest = (topic, fullLabel, root = false) => {
        if (root) {
            resemblanceScore = 1000000;
            resembler = undefined;
        }
        let result = undefined;

        if (Array.isArray(topic) === false &&
            topic.constructor.ClassName() === Topic.ClassName()) {
            if (topic.getFullLabel() === fullLabel) {
                result = topic;
            } else {
                if (fullLabel.startsWith(topic.getFullLabel())) {
                    for (var i = 0; i < topic.getSubtopics().length; i++) {
                        result = findClosest(topic.getSubtopics()[i], fullLabel);
                        if (result !== undefined) {
                            break;
                        }
                    }

                    if (result === undefined) {
                        let score = Math.abs(topic.getFullLabel().length - fullLabel.length);
                        if (score < resemblanceScore &&
                            fullLabel.startsWith(topic.getFullLabel())) {
                            resemblanceScore = score;
                            resembler = topic;
                        }
                    }
                }
            }
        } else if (Array.isArray(topic)) {
            for (let i = 0; i < topic.length; i++) {
                result = findClosest(topic[i], fullLabel);
                if (result !== undefined) {
                    break;
                }
            }
        }

        if (root &&
            result === undefined) {
            result = resembler;
        }

        return result;
    }

    // a recursive loop through the given topic array or topic to find any topic that contains a give fullName
    //let searchRecursive =

    let checked = getTopicArray(checkFor); // the set we are ensuring the presence of
    let affirmed = getTopicArray(ensureIn); // the set we are searching in
    if (checked === undefined || affirmed === undefined) return;

    // enumerate the topics into a flat array for ease
    getFlattenedTopicSet(checked, checkedFlattened);

    // function body begins
    for (let Ci = 0; Ci < checkedFlattened.length; Ci++) {
        let start = findClosest(affirmed, checkedFlattened[Ci].fullLabel, true);

        if (start === undefined) {
            // if the tested topic's tree doesn't exist at all, add it
            affirmed.push(getRootTopic(checkedFlattened[Ci].topic));
        } else if (start.getFullLabel() !== checkedFlattened[Ci].fullLabel) {
            // if the tree exists up to a point, then add what's missing
            // to do this, find the starting point in the checked set and add all of its children to the starting point in the affirmed set
            let checkedStart = findClosest(checked, start.getFullLabel(), true);
            let uniqueItems = [...start.getSubtopics(), ...checkedStart.getSubtopics()].filter(onlyUnique);
            start.setSubtopics(uniqueItems);
        }
    }
}

export function searchTopicsByFullLabel(topics, fullLabel) {
    let result = undefined;
    if (Array.isArray(topics)) {
        for (var i = 0; i < topics.length; i++) {
            if (fullLabel === topics[i].getFullLabel()) {
                result = topics[i];
            } else if (fullLabel.startsWith(topics[i].getFullLabel())) {
                result = searchTopicsByFullLabel(topics[i], fullLabel);
            }

            if (result !== undefined) {
                break;
            }
        }
    } else {
        if (fullLabel === topics.getFullLabel()) {
            result = topics;
        } else if (fullLabel.startsWith(topics.getFullLabel())) {
            result = searchTopicsByFullLabel(topics.getSubtopics(), fullLabel);
        }
    }

    return result;
}

export function searchTopicSet(topics, id) {
    let result = undefined;
    for (var i = 0; i < topics.length; i++) {
        result = topics[i].search(id);
        if (result !== undefined) {
            break;
        }
    }

    return result;
}

// Quantifies the default available topics
export const QuestionTopics = [
    new Topic().initCreate("Science", true,
        "The observation, identification, description, experimental investigation, and theoretical explanation of phenomena.",
        [
            new Topic().initCreate("Math", true,
                "That science, or class of sciences, which treats of the exact relations existing between quantities or magnitudes, and of the methods by which, in accordance with these relations, quantities sought are deducible from other quantities known or supposed; the science of spatial and quantitative relations.",
                [
                    new Topic().initCreate("Physics", true, "The science of matter and energy and of interactions between the two, grouped in traditional fields such as acoustics, optics, mechanics, thermodynamics, and electromagnetism, as well as in modern extensions including atomic and nuclear physics, cryogenics, solid-state physics, particle physics, and plasma physics."),
                    new Topic().initCreate("Algebra", true, "A branch of mathematics in which symbols, usually letters of the alphabet, represent numbers or members of a specified set and are used to represent quantities and to express general relationships that hold for all members of the set."),
                    new Topic().initCreate("Calculus", true, "The branch of mathematics that deals with limits and the differentiation and integration of functions of one or more variables."),
                ], "0"),
            new Topic().initCreate("Geology", true, "The scientific study of the origin, history, and structure of the earth."),
            new Topic().initCreate("Oceanography", true, "The exploration and scientific study of the ocean and its phenomena."),
            new Topic().initCreate("Cosmology", true, "The study of the physical universe considered as a totality of phenomena in time and space."),
            new Topic().initCreate("Astronomy", true, "The scientific study of matter and phenomena in the universe, especially in outer space, including the positions, dimensions, distribution, motion, composition, energy, and evolution of celestial objects."),
        ], "1"),
    new Topic().initCreate("Software Development", true,
        "The translation of a user need or marketing goal into a software product.",
        [
            new Topic().initCreate("Object-Oriented Programming", true, "A programming paradigm that uses 'objects' to design applications and computer programs. Abbreviation: OOP."),
            new Topic().initCreate("Boolean Logic", true, "A system of symbolic logic devised by George Boole; used in computers."),
            new Topic().initCreate("C++", true, "C++ (C Plus Plus, abbreviation: CPP) is a high-level, general-purpose programming language created by Danish computer scientist Bjarne Stroustrup."),
            new Topic().initCreate("JavaScript", true, "A scripting or programming language designed to allow the implementation of complex features on web pages."),
            new Topic().initCreate("Java", true, "Java is a widely used object-oriented programming language that can run on a vast number of devices."),
        ], "2"),
    new Topic().initCreate("English", true,
        "The study of the English language.",
        [
            new Topic().initCreate("Grammar", true, "The way the English language is used to form structures that convey consistent meanings."),
            new Topic().initCreate("Reading Comprehension", true, "The way in which grammatical structures should be understood to derive consistent and reliable meaning."),
            new Topic().initCreate("Creative Writing", true, "Art of writing texts such as novels, short stories and poems which fall outside the bounds of professional, journalistic, academic and technical discourse."),
        ], "3"),
];