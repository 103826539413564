import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';

import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import Pluralize from 'pluralize';

import { QuizzicalContext, RunStates, ScreenModes } from '../Context/QuizzicalContext';
import anyQuery from '../Libraries/AnyQuery';
import searchQuery from '../Libraries/SearchQuery';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/*
** The GenericSelector control allows single or multiple selection of components queried from an object hierarchy
** It recognizes the following parameters:
**
** query:
**      this is an object hierarchical query of the form
**      {
            get: 'getChildren',
            items: [],
            return: false (can be a function),
            selectable: true (can be a function)
        }
**      get names the method to call to find subsequent components
**      items provides a list of simular query objects to run against the results of getChildren
**      return is whether or not the object being tested against should be returned in the structure (default: false)
**      selectable is whether or not the object, if returned, should be selectable in the display (default: true)
**
** root: the object, or objects, to start executing the query structure against
** selectionRetrieval: the method used to retrieve the current value of the selection
** selectionStorage: the method used to store the current value of the selection
** focus: the object where the selection methods are located
** isReadOnly: puts the control in a readonly mode, if true
** multiMode: if true, the control allows multi-selection, if false, only a single selection is permitted at any time
** selectionType: the name of the object class to select.  Multiple types are not allowed.
** hideEmpty: if true, hides empty categories (default: false)
**
*/
export default function GenericSelector(props) {
    const state = React.useContext(QuizzicalContext);

    // parameters
    const [query, setQuery] = React.useState(undefined);
    const [entry, setEntry] = React.useState(undefined);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [disabled, setDisabled] = React.useState(props.disabled || false);
    const [multiMode, setMultiMode] = React.useState(props.multiMode || false);
    const [focus, setFocus] = React.useState(undefined);
    const [selectionGet, setSelectionGet] = React.useState(undefined);
    const [selectionSet, setSelectionSet] = React.useState(undefined);
    const [selectionType, setSelectionType] = React.useState(undefined);
    const [hideEmpty, setHideEmpty] = React.useState(props.hideEmpty || false);

    // internal mechanics
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selecteds, setSelecteds] = React.useState({});
    const [expandeds, setExpandeds] = React.useState({});
    const [tree, setTree] = React.useState(undefined);
    const [forceRefresh, setForceRefresh] = React.useState(false);
    const popupHandle = React.createRef();

    const root = [...document.body.children].find(elem => elem._reactRootContainer)

    React.useEffect(() => {
        if (props.multiMode !== undefined &&
            props.multiMode !== multiMode) {
            setMultiMode(props.multiMode);
        }

        if (props.focus !== undefined &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.disabled !== undefined &&
            props.disabled !== null &&
            props.disabled !== disabled) {
            setDisabled(props.disabled);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }

        if (props.selectionStorage !== undefined &&
            props.selectionStorage !== selectionSet) {
            setSelectionSet(props.selectionStorage);
        }

        if (props.selectionRetrieval !== undefined &&
            props.selectionRetrieval !== selectionGet) {
            setSelectionGet(props.selectionRetrieval);
        }

        if (props.selectionType !== undefined &&
            props.selectionType !== null &&
            props.selectionType !== selectionType) {
            setSelectionType(props.selectionType);
        }

        if (props.query !== undefined &&
            props.query !== null &&
            props.query !== query) {
            setQuery(props.query);
        }

        if (props.root !== undefined &&
            props.root !== null &&
            props.root !== entry) {
            setEntry(props.root);
        }

        if (props.hideEmpty !== undefined &&
            props.hideEmpty !== null &&
            props.hideEmpty !== hideEmpty) {
            setHideEmpty(props.hideEmpty);
        }
    }, [props]);

    React.useEffect(() => {
        if (entry !== undefined &&
            query !== undefined &&
            selectionType !== undefined) {
            let [queryResult, selects, expands] = anyQuery(entry, query);
            setTree(queryResult);

            // how this works:
            // collapsers are tracked via id => boolean value
            // selection is tracked via id => object instance
            // both are done via properties on objects

            // take the catalogues provided by the any query function and
            // set initial states based on the target property on focus
            let ids = getSelectedIds();

            let records = searchQuery(entry, query, ids);
            for (let i = 0; i < ids.length; i++) {
                let match = records.filter((r) => {
                    return r.getId() === ids[i];
                });

                if (match.length === 1) {
                    selects[ids[i]] = match[0];
                }
            }

            setSelecteds(selects);
            setExpandeds(expands);
        }
    }, [entry, query, selectionType]);

    React.useEffect(() => {
        if (selectionGet !== undefined) {
            // update the focus
            let items = Object.keys(selecteds).filter((item) => {
                return selecteds[item] !== undefined;
            });

            if (focus[selectionSet] !== undefined) {
                if (multiMode === false) {
                    focus[selectionSet](items[0]);
                } else {
                    focus[selectionSet](items);
                }
            }

            if (multiMode === false) {
                handleConfirm();
            } else {
                setForceRefresh(!forceRefresh);
            }
        }
    }, [selecteds])

    React.useEffect(() => {
        /* if (entry !== undefined &&
            query !== undefined) {
            let items = [];
            if (focus !== undefined) {
                let ids = getSelectedIds();
                items = searchQuery(entry, query, ids);
            }

            if (selectionType === undefined) {
                setSelecteds({});
            } else {
                let slctds = {};
                for (let i = 0; i < items.length; i++) {
                    if (items[i].constructor.ClassName() === selectionType) {
                        slctds[items[i].getId()] = items[i];
                    }
                }

                setSelecteds(slctds);
            }
        } */
    }, [selectionType]);

    // wrapper methods for selectionGet and selectionSet
    /* const set = (param) => {
        if (focus[selectionSet] !== undefined) {
            if (typeof focus[selectionSet] === 'function') {
                if (multiMode === false) {
                    focus[selectionSet](param[0]);
                } else {
                    focus[selectionSet](param);
                }
            } else if (Object.hasOwn(focus, selectionSet)) {
                if (multiMode === false) {
                    focus[selectionSet] = param[0];
                } else {
                    focus[selectionSet] = param;
                }
            }
        }
    }

    const get = () => {
        let ids = undefined;
        if (focus[selectionGet] !== undefined) {
            if (typeof focus[selectionGet] === 'function') {
                if (Array.isArray(focus[selectionGet]())) {
                    ids = focus[selectionGet]();
                } else {
                    ids = [focus[selectionGet]()];
                }
            } else if (Object.hasOwn(focus, selectionGet)) {
                if (Array.isArray(focus[selectionGet])) {
                    ids = focus[selectionGet];
                } else {
                    ids = [focus[selectionGet]];
                }
            }
        }
    } */

    const getSelectedIds = () => {
        let ids = undefined;
        if (focus[selectionGet] !== undefined &&
            typeof focus[selectionGet] === 'function') {
            if (Array.isArray(focus[selectionGet]())) {
                ids = focus[selectionGet]();
            } else {
                ids = [focus[selectionGet]()];
            }
        }

        return ids;
    }

    const getValueOrDefault = (value, deflt) => {
        if (value === undefined) {
            return deflt;
        } else {
            return value;
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleConfirm = () => {
        setAnchorEl(null);
    }

    const checkIsSelectable = (o) => {
        let keys = Object.keys(selecteds);
        let found = false;
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] = o.getId() &&
                o.constructor.ClassName() === selectionType) {
                found = true;
                break;
            }
        }

        return found;
    }

    const handleSelectToggle = (o) => {
        if (!checkIsSelectable(o)) return;
        let selected = false;

        var slctds = { ...selecteds };
        if (slctds[o.getId()] === undefined) {
            if (multiMode === false) {
                let keys = Object.keys(selecteds);
                for (let i = 0; i < keys.length; i++) {
                    if (slctds[keys[i]] !== undefined) {
                        slctds[keys[i]] = undefined;
                    }
                }
            }

            slctds[o.getId()] = o;
            selected = true;
        } else {
            slctds[o.getId()] = undefined;
        }

        setSelecteds(slctds);
        if (props.onSelectionChanged !== undefined) {
            props.onSelectionChanged(o, selected);
        }
    }

    const handleExpandToggle = (o) => {
        var xpndds = { ...expandeds };
        if (xpndds[o.getId()] === undefined) {
            xpndds[o.getId()] = true;
        } else {
            xpndds[o.getId()] = !xpndds[o.getId()];
        }

        setExpandeds(xpndds);
    }

    const handlePrimaryClick = (topic, listing) => {
        if (listing.length > 0) {
            handleExpandToggle(topic);
        } else {
            handleSelectToggle(topic);
        }
    }

    const getDisplayedValue = (tree) => {
        let items = [];
        if (focus !== undefined &&
            tree !== undefined) {
            let ids = getSelectedIds();
            items = searchQuery(entry, query, ids);
        }

        let resultItems = [];
        if (isReadOnly) {
            resultItems = items.map((item, i) => {
                return (
                    <Chip avatar={item.getSelectedIcon()} label={item.getTitle()} />
                );
            });
        } else {
            if (items.length > 0) {
                resultItems = items.map((item, i) => {
                    return (
                        <Button
                            disabled={disabled}
                            sx={{
                                textTransform: 'none'
                            }}
                            variant="outlined"
                            color="inherit"
                            key={item.getId() + "-" + i}
                            onClick={(e) => { handleSelectToggle(item); }}>
                            <Stack container spacing={2} direction="row">
                                {item.getSelectedIcon()}
                                <div />
                                {item.getTitle()}
                            </Stack>
                        </Button>);
                });
            } else {

            }
        }

        return resultItems;
    }

    const getDisplayList = (node, i) => {
        // get all keyed properties (the ones that start with underscores)
        // the structure is the result of a query, so we must display everything that is returned, 
        // because it was explicitly returned
        let dsKeys = Object.keys(node).filter((key) => {
            return key.startsWith('_');
        });

        // get the actual datasets
        let dataSets = dsKeys.map((dsKey, i) => {
            return node[dsKey];
        });

        // generate each subitem
        let listings = [];
        for (let dsi = 0; dsi < dataSets.length; dsi++) {
            if (dataSets[dsi].length > 0) {
                let items = dataSets[dsi].map(getDisplayList);

                if (items.length > 0) {
                    listings.push({
                        label: dataSets[dsi].length === 1 ? dataSets[dsi][0].sLabel : dataSets[dsi][0].pLabel,
                        items: items
                    });
                }
            }
        }

        let clickable = checkIsSelectable(node.topic) || listings.length > 0;
        let result = (
            <Stack key={node.topic.getId()}>
                <ListItem
                    button={clickable}
                    key={node.topic.getId() + "-0"}
                    onClick={(e) => { handlePrimaryClick(node.topic, listings); }}>
                    <Checkbox
                        disabled={!checkIsSelectable(node.topic)}
                        checked={getValueOrDefault(selecteds[node.topic.getId()], false)}
                        onChange={(e) => { handleSelectToggle(node.topic); }}
                        onClick={(e) => { e.stopPropagation(); }}
                        icon={node.topic.getIcon()}
                        checkedIcon={node.topic.getSelectedIcon()} />
                    <ListItemText primary={node.topic.getTitle()} />
                    {listings.length > 0 ? (expandeds[node.topic.getId()] ? <ExpandLess /> : <ExpandMore />) : ""}
                </ListItem>
                <Collapse key={"collapse-" + node.topic.getId() + "-" + i} component="li" timeout="auto" in={expandeds[node.topic.getId()]} unmountOnExit>
                    {
                        listings.map((l, i) => {
                            return (
                                <List
                                    key={"subList-" + i}
                                    sx={{ width: '100%' }}
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                    subheader={
                                        <ListSubheader component="div" id="nested-list-subheader">
                                            {l.label}
                                        </ListSubheader>
                                    }>
                                    {l.items}
                                </List>
                            );
                        })
                    }
                </Collapse>
            </Stack>
        );
        if (clickable || hideEmpty === false) {
            return result;
        } else {
            return '';
        }
    }

    const getNatureNoun = () => {
        let result = "N/A";
        let lowerCase = (selectionType + '').toLowerCase();

        if (multiMode === true) {
            result = "one or more " + Pluralize(lowerCase, 3);
        } else {
            result = "the " + lowerCase;
        }

        return result;
    }

    let display = < div />;
    if (isReadOnly) {
        if (focus !== undefined &&
            tree !== undefined) {
            display = (
                <Grid
                    item
                    sx={{
                        flexGrow: 1
                    }}>
                    {getDisplayedValue(tree)}
                </Grid>
            );
        }
    } else {
        if (tree !== undefined) {
            let listing = tree.map((node, i) => {
                return getDisplayList(node);
            }).filter((item) => {
                return item !== undefined;
            });

            if (focus !== undefined) {
                display =
                    <Paper
                        sx={{
                            width: '100%',
                            overflow: 'hidden'
                        }}
                        ref={popupHandle}>
                        <Grid
                            container
                            padding={1}
                            spacing={1}
                            alignItems="center">
                            <Grid
                                item
                                sx={{
                                    display: 'flex',
                                    flexGrow: 1
                                }}>
                                {getDisplayedValue(tree)}
                            </Grid>
                            <Grid>
                                <Button
                                    sx={{
                                        height: popupHandle.current !== null ? popupHandle.current.clientHeight : '100%',
                                        marginTop: 0
                                    }}
                                    variant='outlined'
                                    color='inherit'
                                    disabled={disabled}
                                    onClick={(e) => { setAnchorEl(popupHandle.current) }}>
                                    <Typography>...</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                        {
                            !disabled ?
                                <Popover
                                    BackdropProps={{ invisible: false }}
                                    open={Boolean(anchorEl)}
                                    anchorEl={root}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'center',
                                        horizontal: 'center',
                                    }}>
                                    <List
                                        sx={{ width: '100%', height: '100vH', width: 600, bgcolor: 'background.paper' }}
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                        subheader={
                                            <ListSubheader component="div" id="nested-list-subheader">
                                                Please select {getNatureNoun()}
                                            </ListSubheader>
                                        }>
                                        <Paper style={{ overflow: 'auto', minWidth: 400 }}>
                                            {listing}
                                        </Paper>
                                    </List>
                                </Popover> : ""
                        }
                    </Paper>;
            }
        } else {
            display = (
                <Paper style={{ overflow: 'auto' }}>
                    <Typography color="inherit" variant="caption" component="div">{props.cannotQueryMessage}</Typography>
                </Paper>);
        };
    }

    return display;
}
