import { shuffle } from "../../Libraries/Ordering";
import runScript from "../../Libraries/runScript";
import { LayoutRegionManager } from "../Layout/LayoutRegionManager";
import QuestionChoiceDefinition from "../QuestionChoiceDefinition";
import { StepResult } from "../Stepping";
import ManagerBase from "./ManagerBase";

// handles the storage and management of replays
export default class ReplayManager extends ManagerBase {
    constructor() {
        super();
    }

    reinitReplays(replays, shuffleTogether) {
        this.history.length = 0;

        for (let r = 0; r < replays.length; r++) {
            for (let i = 0; i < replays[r].getRecords().length; i++) {
                this.history.push(replays[r].getRecords()[i]);

                this.history[this.history.length - 1].variables = this.history[this.history.length - 1].variables.map((v) => {
                    if (typeof v === 'string') {
                        return JSON.parse(v);
                    } else {
                        return v;
                    }
                });

                this.history[this.history.length - 1].parameters = this.history[this.history.length - 1].parameters.map((p) => {
                    if (typeof p === 'string') {
                        return JSON.parse(p);
                    } else {
                        return p;
                    }
                });
            }
        }

        if (shuffleTogether) {
            // ensure they are completely shuffled.
            for (let i = 0; i < 100; i++) {
                shuffle(this.history);
            }
        }

        this.maximumHistoryIndex = this.history.length - 1;
        this.maximumFound = true;
        this.historyIndex = 0;

        return this;
    }

    getReplay() {
        return this.history;
    }

    getMaximumIndex() {
        return this.maximumHistoryIndex;
    }

    getCurrentIndex() {
        return this.historyIndex;
    }

    getCurrent() {
        // convert the replay into a proper current record and return it
        let working = this.history[this.historyIndex];

        if (working !== undefined) {
            // run all the pre scripts
            for (let i = 0; i < working.preScripts.length; i++) {
                runScript(this.sessionId, working.preScripts[i].script, working.preScripts[i].title, working.variables, working.parameters);
            }

            // generate the snapshot
            let displaySnapshot = {
                answerFormat: working.answerFormat,
                simpleAnswer: working.simpleAnswer,
                simpleQuestion: working.simpleQuestion,
                layout: new LayoutRegionManager().initJson(undefined, working.layout),
                variables: [...working.variables],
                parameters: [...working.parameters],
                items: [...working.items.map((item) => {
                    return new QuestionChoiceDefinition().initJson(undefined, item);
                })],
                shuffled: working.shuffled,
                label: working.label,
                dwell: working.dwell,
                duration: working.duration,
                allCorrect: working.requireAllCorrect,
                hasSubject: working.hasSubject,
            };

            // run all the post scripts
            for (let i = 0; i < working.postScripts.length; i++) {
                runScript(this.sessionId, working.postScripts[i].script, working.postScripts[i].title, working.variables, working.parameters);
            }

            return displaySnapshot;
        } else {
            return undefined;
        }
    }

    // purges history and resets the assignment index
    reset() {
        this.historyIndex = 0;
    }

    // returns the previous record (starting from the current index) with respect to the showExposition flag
    getPrevRecord(iterations = 1) {
        let outcome = this.history[this.historyIndex];
        let finalIndex = this.history.indexOf(outcome);

        if (this.historyIndex !== 0) {
            if (this.showingExposition() === true) {
                let iteration = 0;
                for (let i = this.historyIndex; i >= 0; i--) {
                    outcome = this.history[i];
                    finalIndex = this.history.indexOf(outcome);
                    if (iteration === iterations) {
                        break;
                    } else {
                        iteration++;
                    }
                }
            } else {
                // starting from the record preceding our current, step back until we find a valid record.
                let iteration = 0;
                for (let i = this.historyIndex; i >= 0; i--) {
                    if (this.history[i].layout.isExposition === false) {
                        outcome = this.history[i];
                        finalIndex = this.history.indexOf(outcome);
                        if (iteration >= iterations) {
                            break;
                        }
                    }
                    
                    iteration++;
                }
            }
        }

        return [outcome, finalIndex];
    }

    // returns the next record (starting from the current index) with respect to the showExposition flag
    getNextRecord(iterations = 1) {
        let outcome = this.history[this.historyIndex];
        let finalIndex = this.history.indexOf(outcome);

        if (this.historyIndex < this.history.length - 1) {
            if (this.showingExposition() === true) {
                let iteration = 0;
                for (let i = this.historyIndex; i < this.history.length; i++) {
                    outcome = this.history[i];
                    finalIndex = this.history.indexOf(outcome);
                    if (iteration === iterations) {
                        break;
                    } else {
                        iteration++;
                    }
                }
            } else {
                // starting from the record preceding our current, step forward until we find a valid record.
                let iteration = 0;
                for (let i = this.historyIndex; i < this.history.length; i++) {
                    if (this.history[i].layout.isExposition === false) {
                        outcome = this.history[i];
                        finalIndex = this.history.indexOf(outcome);
                        if (iteration >= iterations) {
                            break;
                        }
                    }
                    
                    iteration++;
                }
            }
        }

        return [outcome, finalIndex];
    }
}