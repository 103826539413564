import * as React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { randomId } from '@mui/x-data-grid-generator';

import FieldContainer from '../../Forms/FieldContainer';
import FEH_Form from '../../Forms/FEH_Form';

import { QuizzicalContext } from '../../../Context/QuizzicalContext';

export default function FEH_StorageItemEditor(props) {
    const state = props.state;
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [forceRefresh, setForceRefresh] = React.useState(false);
    const [defaultFieldSet, setDefaultFieldSet] = React.useState(randomId());
    const [formId, setFormId] = React.useState(props.formId || randomId());

    const [nameLabel, setNameLabel] = React.useState(props.nameLabel);
    const [valueLabel, setValueLabel] = React.useState(props.valueLabel);
    const [tertiaryLabel, setTertiaryLabel] = React.useState(props.tertiaryLabel);
    const [validationType, setValidationType] = React.useState(undefined);

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }

        if (props.nameLabel !== undefined &&
            props.nameLabel !== null &&
            props.nameLabel !== nameLabel) {
            setNameLabel(props.nameLabel);
        }

        if (props.valueLabel !== undefined &&
            props.valueLabel !== null &&
            props.valueLabel !== valueLabel) {
            setValueLabel(props.valueLabel);
        }

        if (props.tertiaryLabel !== undefined &&
            props.tertiaryLabel !== null &&
            props.tertiaryLabel !== tertiaryLabel) {
            setTertiaryLabel(props.tertiaryLabel);
        }

        if (props.type !== undefined &&
            props.type !== null &&
            props.type !== validationType) {
            setValidationType(props.type);
        }
    }, [props]);

    React.useEffect(() => {
        // we need to redraw the page when the state changes
    }, [state]);

    const updateFocus = () => {
        let i = getInstance();

        i.obj.name = document.getElementById("item-name").value.replace(/\s+/g, "-");
        i.obj.value = document.getElementById("item-value").value;
        i.obj.tertiary = document.getElementById("item-tertiary").value;

        return getInstance()[0];
    }

    const getInstance = () => {
        let result = undefined;
        let isSO = true;

        if (typeof focus === 'object' &&
            focus.obj !== undefined) { // storage item
            result = focus.obj;
        } else {
            result = focus;
            isSO = false;
        }

        return { obj: result, isStorageObj: isSO };
    }

    if (isReadOnly) {
        return (
            <Stack spacing={1}>
                <FieldContainer
                    display="row"
                    fieldSet={defaultFieldSet}
                    label={nameLabel}>
                    <Typography variant="caption">{getInstance().obj.name}</Typography>
                </FieldContainer>
                <FieldContainer
                    display="row"
                    fieldSet={defaultFieldSet}
                    label={valueLabel}>
                    <Typography variant="caption">{getInstance().obj.value}</Typography>
                </FieldContainer>
            </Stack>
        );
    } else {
        /* if (props.nameLabel !== undefined) {
            return '';
        } */

        let content = (
            <Stack spacing={2}>
                <TextField
                    id="item-name"
                    fullWidth
                    required
                    defaultValue={getInstance().obj.name} />
                <TextField
                    id="item-value"
                    fullWidth
                    defaultValue={getInstance().obj.value} />
                <TextField
                    id="item-tertiary"
                    fullWidth
                    defaultValue={getInstance().obj.tertiary} />
            </Stack>
        );
        if (validationType === 'variable') {
            content = (
                <FEH_Form
                    formId={"StorageItemForm"}
                    type={validationType}
                    getInstance={updateFocus}
                    onClose={props.onClose}
                    onConfirm={props.onConfirm}
                    onReportMessages={props.onReportMessages}>
                    <Stack spacing={2}>
                        <FieldContainer
                            display="flow"
                            fieldSet={defaultFieldSet}
                            label={nameLabel}
                            help={"The name of this " + nameLabel + "."}
                            fullWidth
                            required>
                            <TextField
                                id="item-name"
                                fullWidth
                                required
                                defaultValue={getInstance().obj.name} />
                        </FieldContainer>
                        <FieldContainer
                            display="flow"
                            fieldSet={defaultFieldSet}
                            label={valueLabel}
                            help={"The value of this " + nameLabel + "."}
                            fullWidth>
                            <TextField
                                id="item-value"
                                fullWidth
                                defaultValue={getInstance().obj.value} />
                        </FieldContainer>
                        <FieldContainer
                            display="flow"
                            fieldSet={defaultFieldSet}
                            label={tertiaryLabel}
                            help={"This variable's purpose."}
                            fullWidth>
                            <TextField
                                id="item-tertiary"
                                fullWidth
                                defaultValue={getInstance().obj.tertiary} />
                        </FieldContainer>
                    </Stack>
                </FEH_Form >
            );
        } else if (validationType === 'item') {
            content = (
                <FEH_Form
                    height="88vH"
                    formId={"StorageItemForm"}
                    type={validationType}
                    getInstance={updateFocus}
                    onClose={props.onClose}
                    onConfirm={props.onConfirm}
                    onReportMessages={props.onReportMessages}>
                    <Stack spacing={2}>
                        <FieldContainer
                            display="flow"
                            fieldSet={defaultFieldSet}
                            label={nameLabel}
                            help={"The name of this " + nameLabel + "."}
                            fullWidth
                            required>
                            <TextField
                                id="item-name"
                                fullWidth
                                required
                                defaultValue={getInstance().obj.name} />
                        </FieldContainer>
                        <FieldContainer
                            display="flow"
                            fieldSet={defaultFieldSet}
                            label={valueLabel}
                            help={"The value of this " + nameLabel + "."}
                            fullWidth>
                            <TextField
                                id="item-value"
                                fullWidth
                                defaultValue={getInstance().obj.value} />
                        </FieldContainer>
                        <FieldContainer
                            display="flow"
                            fieldSet={defaultFieldSet}
                            label={tertiaryLabel}
                            help={"An explanation of the purpose of this " + nameLabel + "."}
                            fullWidth>
                            <TextField
                                id="item-tertiary"
                                fullWidth
                                defaultValue={getInstance().obj.tertiary} />
                        </FieldContainer>
                    </Stack>
                </FEH_Form >
            );
        }

        return content;
    }
}