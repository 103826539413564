import { randomId } from "@mui/x-data-grid-generator";
import { jsonifyArray, jsonifyItem } from "../Libraries/generalUtility";
import ClassName from "./ClassName";

export class StorageItem extends ClassName {
    constructor() { 
        super();       
    }

    initCreate(name, initialValue, tertiary = undefined) {
        this.id = randomId();
        this.name = name;
        this.value = initialValue;
        this.tertiary = tertiary;

        return this;
    }

    initJson(parent, struct) {
            this.id = struct.id;
            this.name = struct.name;
            this.value = ((val) => {
                let outcome = undefined;
                if (val._type !== undefined) {
                    let a = 0;
                    a++;
                } else {
                    outcome = val;
                }

                return outcome;
            })(struct.value);
            this.tertiary = struct.tertiary;

        return this;
    }

    static getDefault(name, value) {
        return new StorageItem(name, value);
    }

    getId() {
        return this.id;
    }

    setId(id) {
        this.id = id;
        return this.id;
    }

    getName() {
        return this.name;
    }

    rename(newName) {
        this.name = newName;
    }

    get() {
        return this.value;
    }

    set(value) {
        this.value = value;
        return this.value;
    }

    getTertiary() {
        return this.tertiary;
    }

    setTertiary(tertiary) {
        this.tertiary = tertiary;
    }

    clone() {
        let value = undefined;
        if (this.value !== undefined) {
            if (this.value.clone !== undefined) {
                value = this.value.clone();
            } else {
                value = this.value;
            }
        }

        let clone = new StorageItem().initCreate(this.name, value, this.tertiary);
        clone.setId(this.id);

        return clone;
    }

    getJson() {
        return {
            id: this.id,
            name: this.name,
            value: jsonifyItem(this.value),
            tertiary: this.tertiary,
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "StorageItem";
    }
}

// the manager sits in the subject / question and stores / manages items
export class StorageManager extends ClassName {
    constructor(parentsManager) {
        super();
        this.store = [];

        // a question's variable manager will take the containing subject's manager into account,
        // meaning it will report their values as well
        this.addition = parentsManager;
    }

    link(additionalManager) {
        this.addition = additionalManager;
    }

    getLink() {
        return this.addition;
    }

    unlink() {
        this.addition = undefined;
    }

    renameByName(name, newName) {
        let index = this.indexByName(name);
        if (index > -1) {
            this.store[index].rename(newName);
            return true;
        }

        return false;
    }

    renameById(id, newName) {
        let index = this.indexById(id);
        if (index > -1) {
            this.store[index].rename(newName);
            return true;
        }

        return false;
    }

    setIdByName(name, newId) {
        let index = this.indexByName(name);
        if (index > -1) {
            this.store[index].setId(newId);
            return true;
        }

        return false;
    }

    setIdById(id, newId) {
        let index = this.indexById(id);
        if (index > -1) {
            this.store[index].setId(newId);
            return true;
        }

        return false;
    }

    setByName(name, value) {
        let index = this.indexByName(name);
        if (index > -1) {
            this.store[index].set(value);
            return true;
        } else {
            let item = new StorageItem(name, value);
            if (value.getId !== undefined) {
                item.setId(value.getId());
            }
            this.store.push(item);
        }

        return false;
    }

    setById(id, value) {
        let index = this.indexById(id);
        if (index > -1) {
            this.store[index].set(value);
            return true;
        }

        return false;
    }

    getByName(name, includeAddition = true) {
        let result = undefined;
        let index = this.indexByName(name);
        if (index > -1) {
            result = { item: this.store[index], mine: true };
        }

        if (this.addition !== undefined && result === undefined && includeAddition) {
            result = this.addition.getByName(name, true); // more than two storage managers stacked is impossible due to data structure
            if (result !== undefined) {
                result.mine = false;
            }
        }

        return result;
    }

    getById(id, includeAddition = true) {
        let result = undefined;
        let index = this.indexById(id);
        if (index > -1) {
            result = { item: this.store[index], mine: true };
        }

        if (this.addition !== undefined && result === undefined && includeAddition) {
            result = this.addition.getById(id, true); // more than two storage managers stacked is impossible due to data structure
            if (result !== undefined) {
                result.mine = false;
            }
        }

        return result;
    }

    list(includeAddition = true) {
        let result = [];

        if (this.addition !== undefined && includeAddition === true) {
            let items = this.addition.list();

            for (var i = 0; i < items.length; i++) {
                result.push({ id: items[i].id, name: items[i].name, mine: false });
            }
        }

        for (var i = 0; i < this.store.length; i++) {
            result.push({ id: this.store[i].getId(), name: this.store[i].getName(), mine: true });
        }

        return result;
    }

    itemList(includeAddition = true) {
        let data = this.list(includeAddition).map((item, i) => {
            return { id: item.id, name: item.name, value: this.getByName(item.name).item.get(), mine: item.mine }
        });

        return data;
    }

    undifferentiatedList(includeAddition = true) {
        let list = this.list(includeAddition);
        let result = [];

        for (let i = 0; i < list.length; i++) {
            result.push(this.getById(list[i].id));
        }

        return result;
    }

    containsId(id) {
        return this.indexById(id) > -1;
    }

    containsName(name) {
        return this.indexByName(name) > -1;
    }

    contains(instance) {
        return this.indexOf(instance) > -1;
    }

    indexById(id) {
        let index = -1;
        let list = this.list(false);
        for (var i = 0; i < list.length; i++) {
            if (list[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    indexByName(name) {
        let index = -1;
        let list = this.list(false);
        for (var i = 0; i < list.length; i++) {
            if (list[i].name === name) {
                index = i;
                break;
            }
        }

        return index;
    }

    indexOf(instance) {
        return this.indexById(instance.id);
    }

    shiftByName(name, amount) {
        let origIndex = this.indexByName(name);
        this.store.splice(origIndex + amount, 0, this.store.splice(origIndex, 1)[0]);
    }

    shiftById(id, amount) {
        let origIndex = this.indexById(id);
        this.store.splice(origIndex + amount, 0, this.store.splice(origIndex, 1)[0]);
    }

    at(index, includeAddition = false) {
        let result = undefined;

        let items = this.list(includeAddition);
        if (items.length > index) {
            result = items[index];
        }

        return result;
    }

    length(includeAddition = false) {
        return this.list(includeAddition).length;
    }

    reset() {
        this.store.length = 0;
    }

    removeId(id) {
        let index = this.indexById(id);
        if (index > -1) {
            this.store.splice(index, 1);
            return true;
        }

        return false;
    }

    removeName(name) {
        let index = this.indexByName(name);
        if (index > -1) {
            this.store.splice(index, 1);
            return true;
        }

        return false;
    }

    remove(instance) {
        return this.removeId(instance.getId());
    }

    clone(copyAddition = true) {
        let copy = new StorageManager(copyAddition === true ? this.addition : undefined);

        for (var i = 0; i < this.store.length; i++) {
            copy.setByName(
                this.store[i].getName(),
                this.store[i].get().clone === undefined ? this.store[i].get() : this.store[i].get().clone());
            copy.getByName(this.store[i].getName()).item.setId(this.store[i].getId());
        }

        return copy;
    }

    cloneFrom(target, reset = true) {
        if (reset === true) {
            this.reset();
        }

        let items = target.list(false);
        for (var i = 0; i < items.length; i++) {
            let clonedItem = undefined;
            let result = target.getByName(items[i].name).item;
            if (result.get() !== undefined) {
                clonedItem =
                    target.getByName(items[i].name).item.get().clone === undefined ?
                        target.getByName(items[i].name).item.get() :
                        target.getByName(items[i].name).item.get().clone();
            }

            this.setByName(items[i].name, clonedItem);
        }
    }

    getJson() {
        return {
            store: jsonifyArray(this.store),
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "StorageManager";
    }
}