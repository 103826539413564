import * as React from 'react';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import SquareFootIcon from '@mui/icons-material/SquareFoot';
import RefreshIcon from '@mui/icons-material/Refresh';

export default function RegionLayoutDimensionsEditor(props) {
    const [entity, setEntity] = React.useState(undefined); // this is the subject / question that this manager ultimately resides on
    const [proposedWidth, setProposedWidth] = React.useState(0);
    const [proposedHeight, setProposedHeight] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const min = 1;
    const max = 20;

    React.useEffect(() => {
        if (props.entity !== undefined &&
            props.entity !== null &&
            props.entity !== entity) {
            setEntity(props.entity);
        }
    }, [props]);

    React.useEffect(() => {
        if (entity !== undefined &&
            entity !== null) {
            setProposedWidth(entity.getWidth());
            setProposedHeight(entity.getHeight());
        }
    }, [entity]);

    const handleClick = (event) => {        
        setProposedWidth(entity.getWidth());
        setProposedHeight(entity.getHeight());
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleUpdate = (e) => {
        let vWidth = proposedWidth <= max && proposedWidth >= min;
        let vHeight = proposedHeight <= max && proposedHeight >= min;

        if (vWidth && vHeight) {
            entity.setDimensions(proposedWidth, proposedHeight);
            setAnchorEl(null);
            if (props.onRefreshRequired !== undefined) props.onRefreshRequired();
        } else {

        }
    }

    const widthDimensionUpdate = (e) => {
        setProposedWidth(safeNumber(e.currentTarget.value));
    }

    const heightDimensionUpdate = (e) => {
        setProposedHeight(safeNumber(e.currentTarget.value));
    }

    const safeNumber = (number) => {
        let result = parseInt(number);
        if (result === NaN) {
            return 0;
        }

        return result;
    }

    if (entity !== undefined) {
        let widthError = proposedWidth < min || proposedWidth > max;
        let heightError = proposedHeight < min || proposedHeight > max;

        return (
            <div>
                <Button
                    onClick={handleClick}
                    color="inherit"
                    sx={{
                        textTransform: 'none'
                    }}
                    startIcon={<SquareFootIcon />}>
                    {props.label}
                </Button>
                <Popover
                    id={"layout-dimensions-id"}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}>
                    <Stack direction="row" margin={2} spacing={1}>
                        <TextField
                            error={widthError}
                            id="grid-width"
                            label="Width"
                            type="number"
                            defaultValue={proposedWidth}
                            onChange={widthDimensionUpdate} />
                        <TextField
                            error={heightError}
                            id="grid-height"
                            label="Height"
                            type="number"
                            defaultValue={proposedHeight}
                            onChange={heightDimensionUpdate} />
                        <IconButton
                            disabled={widthError || heightError}
                            color="inherit"
                            onClick={handleUpdate}>
                            <RefreshIcon />
                        </IconButton>
                    </Stack>
                </Popover>
            </div >
        );
    } else {
        return "";
    }
}