import * as React from 'react';
import { randomId } from '@mui/x-data-grid-generator';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import { Switch } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import Collapse from '@mui/material/Collapse';
import { useTheme } from '@mui/material/styles';

import { green } from '@mui/material/colors';

import LoopIcon from '@mui/icons-material/Loop'; // play looping
import PlayArrowIcon from '@mui/icons-material/PlayArrow'; // playing / not playing
import PauseIcon from '@mui/icons-material/Pause'; // paused / not paused
import StopIcon from '@mui/icons-material/Stop'; // stopped / not stopped
import SkipNextIcon from '@mui/icons-material/SkipNext'; // go to next item
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious'; // go to previous item
import LastPageIcon from '@mui/icons-material/LastPage'; // go to last item in subject / section
import FirstPageIcon from '@mui/icons-material/FirstPage'; // go to first item in subject / section
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'; // used to show the current problem's answer
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes'; // used to show when answers are active for auto play
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff'; // used for to show when answers are hidden during auto play
import VideoSettingsIcon from '@mui/icons-material/VideoSettings'; // used for the delay and duration manual multipliers
import VisibilityIcon from '@mui/icons-material/Visibility'; // used to show when the question progress bar is displayed
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'; // used to show when the question progress bar is hidden
import GroupsIcon from '@mui/icons-material/Groups'; // used for the attendance button
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'; // used for the jump to index button
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'; // used for learning mode (auto show answers permanently)
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined'; // used for learning mode inactive
import UndoIcon from '@mui/icons-material/Undo'; // used for seek backward
import RedoIcon from '@mui/icons-material/Redo'; // used for seek forward
import ErrorIcon from '@mui/icons-material/Error';

import FeedIcon from '@mui/icons-material/Feed'; // used to indicate that exposition layouts will be shown
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined'; // used to indicate that exposition records are hidden
import ContactSupportIcon from '@mui/icons-material/ContactSupport'; // used to indicate that the current record is explanatory
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined'; // used to indicate that the current layout is not explanatory

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import HelpIcon from '../Forms/HelpIcon';
import BreadcrumbHelper from '../BreadcrumbHelper';
import ResolutionManager from '../../Classes/Resolution/ResolutionManager';
import MouseOverPopup from '../MouseOverPopup';
import InputSlider from '../InputSlider';
import { getQuestionSet, getReplayDownload } from '../../Libraries/SheetGeneration';
import ReplayManager from '../../Classes/Resolution/ReplayManager';
import TimeDisplay from './TimeDisplay';
import ProgressDialog from '../../Screens/Dialogs/ProgressDialog';
import { LogEntryType, LogHost } from '../../Classes/Resolution/ScriptExecutionLog';
import { AdministrationModes, QuizzicalContext } from '../../Context/QuizzicalContext';

const PresentationActivityStates = {
    Idle: 0,
    Playing: 1,
    Looping: 2,
    Paused: 3,
}

// the controls for navigating the presentation
export default function IndexingControls(props) {
    const state = props.state;
    const actualContext = React.useContext(QuizzicalContext);
    const requireRefresh = props.onRefreshRequested;
    const updateState = props.onStateUpdate;
    const pregenQuestions = props.pregenQuestions;
    const [displayAnswersDuringautoPlay, setDisplayAnswersDuringautoPlay] = React.useState(actualContext.Mode.AdministrationMode === AdministrationModes.Exam ? false : true);
    const [durationOverrideMultiplier, setDurationOverrideMultiplier] = React.useState(1);
    const [dwellOverrideMultiplier, setDwellOverrideMultiplier] = React.useState(1);
    const [questionSecondCount, setQuestionSecondCount] = React.useState(0);
    const [showQuestionProgress, setShowQuestionProgress] = React.useState(true);
    const [replaying, setReplaying] = React.useState(undefined);
    const [selectedIndex, setSelectedIndex] = React.useState(); // presentationState.state.getCurrentIndex() + 1
    const [soughtIndex, setSoughtIndex] = React.useState(5); // presentationState.state.getCurrentIndex() + 1

    const [doInit, setDoInit] = React.useState(false);

    const [presentationState, setPresentationState] = React.useState({
        presentations: [],
        state: props.replay === true ? new ReplayManager() : new ResolutionManager(),
        activityState: PresentationActivityStates.Idle,
        initialDisplay: true, // used to track duration / dwell timer usage
        learningMode: false,
        replaying: false, // whether or not we are playing 'live' records or imported replays
        when: 'now',
    });

    const theme = useTheme();

    React.useEffect(() => {
        if (props.set !== presentationState.presentations) {
            presentationState.presentations = [...props.set];

            let fresh = presentationState.presentations.length > 0 && presentationState.state.getMaximumIndex() === 0;
            if (props.replay === true) {
                if (fresh === true) {
                    presentationState.state.reinitReplays(props.set, props.shuffleReplaysTogether);
                    presentationState.replaying = true;
                    props.questionsGenerated(presentationState.state.getReplay());
                    presentationState.when = getDateTimeStr();
                    setPresentationState(presentationState);
                }
            } else {
                if (fresh === true) {
                    presentationState.state.reinitAssignments(props.set, false, pregenQuestions);
                    presentationState.state.initBegin(props.state);
                    setPresentationState(presentationState);
                    setDoInit(true);
                    /* presentationState.state.reinitAssignments(props.set, false, pregenQuestions);
                    presentationState.state.initStep(props.state);
                    props.questionsGenerated(presentationState.state.getReplay());
                    setPresentationState(presentationState); */
                }
            }
        }
    }, [props]);

    React.useEffect(() => {
        let timerId;

        if (presentationState !== undefined &&
            presentationState.presentations.length > 0 &&
            presentationState.state.getCurrent() !== undefined &&
            (presentationState.activityState === PresentationActivityStates.Looping ||
                presentationState.activityState === PresentationActivityStates.Playing)) {
            // calculate the duration of the pause
            let timeout = calculateTimeout();

            timerId = setInterval(() => {
                if (presentationState.activityState === PresentationActivityStates.Playing ||
                    presentationState.activityState === PresentationActivityStates.Looping) {
                    if (displayAnswersDuringautoPlay === true) {
                        presentationState.initialDisplay = !presentationState.initialDisplay;
                    }
                    if (presentationState.initialDisplay === true) {
                        doNext();
                    } else {
                        setPresentationState({ ...presentationState });
                    }
                }
                setQuestionSecondCount(0);
            }, timeout);
        }

        updateState(presentationState);
        // clearing interval
        return () => clearInterval(timerId);
    }, [presentationState]);

    React.useEffect(() => {
        let questionTimerId = setInterval(() => {
            if (presentationState.activityState === PresentationActivityStates.Playing ||
                presentationState.activityState === PresentationActivityStates.Looping) {
                setQuestionSecondCount(questionSecondCount + 1000);
            }
        }, 1000);
        // clearing interval
        return () => clearInterval(questionTimerId);
    }, [questionSecondCount, presentationState]);

    React.useEffect(() => {
        if ((presentationState.state.getCurrentIndex() + 1) !== selectedIndex) {
            setSelectedIndex(presentationState.state.getCurrentIndex() + 1);
        }
    }, [presentationState]);

    const calculateTimeout = () => {
        let timeout = 1000;

        if (presentationState.state.getCurrent() !== undefined) {
            if (displayAnswersDuringautoPlay === true) {
                if (presentationState.initialDisplay === true) {
                    if (presentationState.state.getCurrent().duration !== undefined) {
                        timeout = presentationState.state.getCurrent().duration * 1000;
                    } else {
                        timeout = state.Mode.DefaultDuration * 1000;
                    }
                    timeout = state.Mode.GlobalDurationHandicapModifier * timeout * durationOverrideMultiplier;
                } else {
                    if (presentationState.state.getCurrent().dwell !== undefined) {
                        timeout = presentationState.state.getCurrent().dwell * 1000;
                    } else {
                        timeout = state.Mode.DefaultDwell * 1000;
                    }
                    timeout = state.Mode.GlobalDwellHandicapModifier * timeout * dwellOverrideMultiplier;
                }
            } else {
                timeout = (
                    (presentationState.state.getCurrent().duration * state.Mode.GlobalDurationHandicapModifier)
                ) * 1000 * durationOverrideMultiplier;
            }
        }

        return timeout;
    }

    const getDateTimeStr = () => {
        let dt = new Date();
        return dt.toLocaleDateString() + " " + dt.toLocaleTimeString();
    }

    const getErrorIndicatorMessage = () => {
        if (presentationState.state.getCurrent() === undefined) return "N/A";

        let errors = LogHost.byIdFilter(presentationState.state.getCurrent().sourceId, (r) => {
            return r.type() === LogEntryType.Error;
        });

        if (errors.length > 0) {
            let items = errors.map((e, i) => {
                let time = e.when().split(' ');
                if (e.reason() !== undefined) {
                    return (
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                border: 0.5,
                                borderColor: (actualContext.theme === 'light' ? theme.palette.primary.main : '#444444'),
                            }}>
                            <Stack
                                alignItems="center">
                                <ErrorIcon color="error" />
                                <Typography variant='caption'>{time[0]}</Typography>
                                <Typography variant='caption'>{time[1]}</Typography>
                            </Stack>
                            <Stack spacing={1}>
                                <Typography variant='caption'>{e.message()}</Typography>
                                <Typography variant='caption'>{e.getReason().message + (e.coordinates() !== undefined ? " at y: " + e.coordinates().y + ', x: ' + e.coordinates().x : '')}</Typography>
                            </Stack>
                        </Stack>
                    );
                } else {
                    return (
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                border: 0.5,
                                borderColor: (actualContext.theme === 'light' ? theme.palette.primary.main : '#444444'),
                            }}>
                            <Stack
                                alignItems="center">
                                <ErrorIcon color="error" />
                                <Typography variant='caption'>{time[0]}</Typography>
                                <Typography variant='caption'>{time[1]}</Typography>
                            </Stack>
                            <Stack spacing={1}>
                                <Typography variant='caption'>{e.message()}</Typography>
                            </Stack>
                        </Stack>
                    );
                }
            });
            return (
                <Stack
                    sx={{
                        width: '100%',
                        maxWidth: 450,
                        maxHeight: 300,
                        overflow: 'auto',
                        bgcolor: 'background.paper'
                    }}>
                    {items}
                </Stack>
            );
        } else {
            return "This question has no script errors.";
        }
    }

    const getErrorIndicatorColor = () => {
        if (presentationState.state.getCurrent() === undefined) return "disabled";

        let hasErrors = LogHost.byIdFilter(presentationState.state.getCurrent().sourceId, (r) => {
            return r.type() === LogEntryType.Error;
        }).length > 0;

        if (hasErrors) {
            return "error";
        } else {
            return "disabled";
        }
    }

    const getQuestionCountColor = () => {
        if (presentationState.initialDisplay === true) {
            return 'info';
        } else {
            return 'secondary';
        }
    }

    const getAnswerColor = () => {
        if (presentationState.initialDisplay === true) {
            return 'secondary';
        } else {
            return 'disabled';
        }
    }

    const getPlayColor = () => {
        if (presentationState.activityState === PresentationActivityStates.Playing) {
            if (presentationState.initialDisplay === true) {
                return 'success';
            } else {
                return 'secondary';
            }
        } else {
            return 'inherit';
        }
    }

    const getLoopColor = () => {
        if (presentationState.activityState === PresentationActivityStates.Looping) {
            if (presentationState.initialDisplay === true) {
                return 'success';
            } else {
                return 'secondary';
            }
        } else {
            return 'inherit';
        }
    }

    const getStopColor = () => {
        if (presentationState.activityState === PresentationActivityStates.Idle) {
            return 'error';
        } else {
            return 'inherit';
        }
    }

    const getPauseColor = () => {
        if (presentationState.activityState === PresentationActivityStates.Paused) {
            return 'info';
        } else {
            return 'inherit';
        }
    }

    const goLearningMode = () => {
        setQuestionSecondCount(0);
        setPresentationState({
            ...presentationState,
            learningMode: !presentationState.learningMode,
            initialDisplay: presentationState.learningMode,
        });
    }

    const goAttendance = () => {
        if (typeof props.onAttendanceManagementRequest === 'function') {
            props.onAttendanceManagementRequest();
        }
    }

    const goAnswer = () => {
        presentationState.initialDisplay = !presentationState.initialDisplay;
        setQuestionSecondCount(0);
        setPresentationState({ ...presentationState });
    }

    const doFirst = () => {
        presentationState.initialDisplay = !presentationState.learningMode;
        presentationState.state.first();
        setQuestionSecondCount(0);
        setPresentationState({ ...presentationState });
    }

    const doPrevious = () => {
        presentationState.initialDisplay = !presentationState.learningMode;
        presentationState.state.previous();
        setQuestionSecondCount(0);
        setPresentationState({ ...presentationState });
    }

    const doPlay = () => {
        presentationState.activityState = PresentationActivityStates.Playing;
        setPresentationState({ ...presentationState });
    }

    const doLoop = () => {
        presentationState.activityState = PresentationActivityStates.Looping;
        setPresentationState({ ...presentationState });
    }

    const doStop = () => {
        presentationState.activityState = PresentationActivityStates.Idle;
        presentationState.initialDisplay = (presentationState.learningMode === true ? false : true);
        presentationState.state.restart();
        setQuestionSecondCount(0);
        setPresentationState({ ...presentationState });
    }

    const doPause = () => {
        presentationState.activityState = PresentationActivityStates.Paused;
        setQuestionSecondCount(0);
        setPresentationState({ ...presentationState });
    }

    const doNext = () => {
        presentationState.initialDisplay = !presentationState.learningMode;
        if (presentationState.state.next() === false) {
            if (presentationState.activityState === PresentationActivityStates.Playing) {
                presentationState.activityState = PresentationActivityStates.Idle;
            } else if (presentationState.activityState === PresentationActivityStates.Looping) {
                presentationState.state.restart();
            }
        }
        setQuestionSecondCount(0);
        setPresentationState({ ...presentationState });
    }

    const doLast = () => {
        presentationState.initialDisplay = !presentationState.learningMode;
        presentationState.state.last();
        setQuestionSecondCount(0);
        setPresentationState({ ...presentationState });
    }

    const onAnswersDuringAutoPlayChanged = (event) => {
        setDisplayAnswersDuringautoPlay(event.target.checked);
    }

    const onShowQuestionProgressChanged = (event) => {
        setShowQuestionProgress(event.target.checked);
    }

    const updateDurationOverrideMultiplier = (event) => {
        setDurationOverrideMultiplier(event.currentTarget.value);
    }

    const updateDwellOverrideMultiplier = (event) => {
        setDwellOverrideMultiplier(event.currentTarget.value);
    }

    const canPause = () => {
        return (
            presentationState.activityState === PresentationActivityStates.Looping ||
            presentationState.activityState === PresentationActivityStates.Playing
        );
    }

    if (doInit === false) {
        if (state !== undefined) {
            return (
                <Grid sx={{ width: '100%' }}>
                    <Stack direction='row'>
                        <Grid
                            sx={{
                                flexGrow: 1,
                                textAlign: 'center'
                            }}>
                            {presentationState.state.getCurrent() !== undefined ?
                                <Typography>{presentationState.state.getCurrent().label}</Typography> :
                                <Typography variant='caption'>...</Typography>}
                        </Grid>
                        <Stack direction='row'>
                            {actualContext.Mode.AdministrationMode === AdministrationModes.Exam ? [] : [
                                <Tooltip arrow title="Go to the first question"><IconButton onClick={doFirst} disabled={!presentationState.state.canFirst()}><FirstPageIcon /></IconButton></Tooltip>,
                                <Tooltip arrow title="Go to the previous question"><IconButton onClick={doPrevious} disabled={!presentationState.state.canPrevious()}><SkipPreviousIcon /></IconButton></Tooltip>,
                            ]}
                            <Tooltip arrow title="Continue playing from the current location until the end is reached"><IconButton onClick={doPlay}><PlayArrowIcon color={getPlayColor()} /></IconButton></Tooltip>
                            {actualContext.Mode.AdministrationMode === AdministrationModes.Exam ? [] : [
                                <Tooltip arrow title="Loop through the questions"><IconButton onClick={doLoop}><LoopIcon color={getLoopColor()} /></IconButton></Tooltip>,
                                <Tooltip arrow title="Stop presentation and start over when resuming"><IconButton onClick={doStop}><StopIcon color={getStopColor()} /></IconButton></Tooltip>,
                            ]}
                            <Tooltip arrow title="Pause the presentation for resumption at the current question"><IconButton onClick={doPause} disabled={!canPause()}><PauseIcon color={getPauseColor()} /></IconButton></Tooltip>
                            <Tooltip arrow title="Go to the next question"><IconButton onClick={doNext} disabled={!presentationState.state.canNext()}><SkipNextIcon /></IconButton></Tooltip>
                            {actualContext.Mode.AdministrationMode === AdministrationModes.Exam ? [] : [
                                <Tooltip arrow title="Go to the last question"><IconButton onClick={doLast} disabled={!presentationState.state.canLast()}><LastPageIcon /></IconButton></Tooltip>
                            ]}
                        </Stack>
                    </Stack>
                    <Stack spacing={1}>
                        <Tooltip arrow title="Total Progress through the current Presentation">
                            <LinearProgress variant="determinate" value={(presentationState.state.getCurrentIndex() / presentationState.state.getMaximumIndex()) * 100} />
                        </Tooltip>
                        <Collapse timeout="auto" in={showQuestionProgress}>
                            <Tooltip arrow title="Remaining time for the current question">
                                <LinearProgress variant="determinate" color={getQuestionCountColor()} value={(((calculateTimeout() - questionSecondCount) - 1000) / (calculateTimeout() - 1000)) * 100} />
                            </Tooltip>
                        </Collapse>
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                            {actualContext.Mode.AdministrationMode === AdministrationModes.Exam ? [
                                <Typography>{presentationState.state.getCurrentIndex() + 1} / {presentationState.state.getMaximumIndex() + (props.replay === true ? 1 : 0)}</Typography>
                            ] : [
                                <Tooltip arrow title="Navigates to the question at the provided index">
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        sx={{
                                            alignItems: 'center',
                                        }}>
                                        <TextField
                                            placeholder="Index"
                                            value={selectedIndex}
                                            onChange={(e) => {
                                                setSelectedIndex(e.currentTarget.value);
                                            }}
                                            sx={{
                                                width: 60,
                                                border: 'hidden'
                                            }}
                                            InputProps={{ sx: { height: 20, fontSize: 14 } }}
                                            margin="none"
                                            type="number"
                                            size="small"
                                            variant='standard' />
                                        <Typography> / {presentationState.state.getMaximumIndex() + (props.replay === true ? 1 : 0)}</Typography>
                                        <IconButton
                                            disabled={selectedIndex === presentationState.state.getCurrentIndex() + 1 || selectedIndex <= 0 || selectedIndex === undefined || selectedIndex === null || selectedIndex > presentationState.state.getMaximumIndex()}
                                            onClick={(e) => {
                                                presentationState.state.setCurrentIndex(selectedIndex - 1);
                                                setPresentationState({ ...presentationState });
                                            }}>
                                            <ThumbUpAltIcon fontSize='small' />
                                        </IconButton>
                                    </Stack>
                                </Tooltip>,
                                <Box sx={{ width: '10px' }} />,
                                <Tooltip arrow title="Seeks the current index forward or backward by the given amount or as close to it as possible">
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        sx={{
                                            alignItems: 'center',
                                        }}>
                                        <TextField
                                            placeholder="Desired Change"
                                            value={soughtIndex}
                                            onChange={(e) => {
                                                setSoughtIndex(e.currentTarget.value);
                                            }}
                                            sx={{
                                                width: 60,
                                                border: 'hidden'
                                            }}
                                            InputProps={{ sx: { height: 20, fontSize: 14 } }}
                                            margin="none"
                                            type="number"
                                            size="small"
                                            variant='standard' />
                                        <Typography> / {presentationState.state.getMaximumIndex() + (props.replay === true ? 1 : 0)}</Typography>
                                        <IconButton
                                            disabled={soughtIndex <= 0 || soughtIndex === undefined || soughtIndex === null || (soughtIndex) > presentationState.state.getMaximumIndex() || presentationState.state.getCurrentIndex() + 1 === 1}
                                            onClick={(e) => {
                                                presentationState.state.seekNewIndex((+soughtIndex) * -1);
                                                setPresentationState({ ...presentationState });
                                            }}>
                                            <UndoIcon fontSize='small' />
                                        </IconButton>
                                        <IconButton
                                            disabled={soughtIndex <= 0 || soughtIndex === undefined || soughtIndex === null || soughtIndex > presentationState.state.getMaximumIndex() || presentationState.state.getCurrentIndex() + 1 === presentationState.state.getMaximumIndex()}
                                            onClick={(e) => {
                                                presentationState.state.seekNewIndex(+soughtIndex);
                                                setPresentationState({ ...presentationState });
                                            }}>
                                            <RedoIcon fontSize='small' />
                                        </IconButton>
                                    </Stack>
                                </Tooltip>,
                            ]}
                            <Box sx={{ flexGrow: 1 }} />
                            <Paper>
                                <Stack direction="row">
                                    {actualContext.Mode.AdministrationMode === AdministrationModes.Exam ? [] : [
                                        <Tooltip arrow title="Toggles the display of explanatory question content">
                                            <IconButton onClick={(e) => {
                                                presentationState.state.setShowingExposition(!presentationState.state.showingExposition());
                                                presentationState.state.next();
                                                presentationState.state.previous();
                                                setPresentationState({ ...presentationState });
                                            }}>
                                                {
                                                    presentationState.state.showingExposition() ?
                                                        <FeedIcon color='inherit' /> : <FeedOutlinedIcon color='disabled' />
                                                }
                                            </IconButton>
                                        </Tooltip>,
                                        <Tooltip arrow title="Toggles learning mode.  When active, answers are always displayed">
                                            <IconButton onClick={goLearningMode}>
                                                {
                                                    presentationState.learningMode === true ? <LocalLibraryIcon color="secondary" /> : <LocalLibraryOutlinedIcon color="disabled" />
                                                }
                                            </IconButton>
                                        </Tooltip>,
                                        <Tooltip arrow title="Displays the attendance management dialog"><IconButton onClick={goAttendance}><GroupsIcon color='inherit' /></IconButton></Tooltip>,
                                        <Tooltip arrow title="Display the current question's answer"><IconButton onClick={goAnswer} disabled={presentationState.learningMode === true}><QuestionAnswerIcon color={getAnswerColor()} /></IconButton></Tooltip>,
                                        <Tooltip arrow title="Toggles the display of answers during auto play"><Checkbox icon={<SpeakerNotesOffIcon />} checkedIcon={<SpeakerNotesIcon />} defaultChecked={displayAnswersDuringautoPlay} onChange={onAnswersDuringAutoPlayChanged} /></Tooltip>,
                                    ]}
                                    <Tooltip arrow title="Toggles the display of the question progress read out"><Checkbox icon={<VisibilityOffIcon />} checkedIcon={<VisibilityIcon />} defaultChecked={showQuestionProgress} onChange={onShowQuestionProgressChanged} /></Tooltip>
                                    {actualContext.Mode.AdministrationMode === AdministrationModes.Exam ? [] : [
                                        <MouseOverPopup
                                            title="Question Duration Multipliers"
                                            help="Toggle an area to adjust the duration and dwell multipliers for this presentation"
                                            handle={<VideoSettingsIcon />}>
                                            <Stack
                                                spacing={2}
                                                sx={{
                                                    backgroundColor: 'background.paper',
                                                    padding: 2,
                                                }}>
                                                <TextField
                                                    type='number'
                                                    label="Duration Multiplier (% of original)"
                                                    defaultValue={durationOverrideMultiplier}
                                                    onChange={updateDurationOverrideMultiplier} />
                                                <TextField
                                                    type='number'
                                                    label="Dwell Multiplier (% of original)"
                                                    defaultValue={dwellOverrideMultiplier}
                                                    onChange={updateDwellOverrideMultiplier} />
                                            </Stack>
                                        </MouseOverPopup>
                                    ]}
                                </Stack>
                            </Paper>
                            {
                                presentationState.state.getCurrent() !== undefined && presentationState.state.getCurrent().layout.isExposition === true ?
                                    <Tooltip arrow title="This display is explanatory in nature"><ContactSupportIcon color='inherit' /></Tooltip> :
                                    <Tooltip arrow title="This display provides a task to accomplish"><ContactSupportOutlinedIcon color='disabled' /></Tooltip>
                            }
                            <Tooltip arrow title={getErrorIndicatorMessage()}><ErrorIcon color={getErrorIndicatorColor()} /></Tooltip>
                            <Tooltip title="Remaining time for the current question">
                                <TimeDisplay time={(calculateTimeout() - questionSecondCount) - 1000} />
                            </Tooltip>
                        </Stack>
                    </Stack>
                </Grid>
            );
        } else {
            return (<Typography>Indexing Controls</Typography>);
        }
    } else {
        return (
            <ProgressDialog
                isOpen={doInit}
                job="Generating Questions..."
                process={"Please wait while we generate your questions.\n\nWhen this process concludes you will be able to close this window."}
                work={() => {
                    /* const targetIterations = 100;
                    let iterations = (
                        (presentationState.state.getPregenCount() - presentationState.state.getCurrentPregenCount()) > targetIterations ?
                            targetIterations : presentationState.state.getPregenCount() - presentationState.state.getCurrentPregenCount()
                    );

                    let i = 0;
                    let success = true;
                    for (; i < iterations; i++) {
                        success = presentationState.state.initStep(props.state) && success;
                    }
                    return {
                        outcome: success,
                        iterations: i,
                    }; */
                    return presentationState.state.initStep(props.state);
                }}
                current={() => {
                    return presentationState.state.getCurrentPregenCount();
                }}
                total={() => {
                    return presentationState.state.getPregenCount();
                }}
                done={() => {
                    props.questionsGenerated(presentationState.state.getReplay());
                    presentationState.when = getDateTimeStr();
                    presentationState.state.next();
                    presentationState.state.previous();
                    setPresentationState({ ...presentationState });
                }}
                onConfirm={(successful) => {
                    setDoInit(false);
                }}
            />);
    }
}