import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import RefreshIcon from '@mui/icons-material/Refresh';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import Subject, { LayoutTypes } from '../../Classes/Subjects';
import { QuestionTypes } from '../../Classes/Questions';
import RegionLayoutDimensionsEditor from '../../Controls/ContentEditor/RegionLayoutDimensionsEditor';
import LayoutRegionContainer from '../../Controls/ContentEditor/LayoutRegionContainer';
import RegionLayoutEditor from '../../Controls/ContentEditor/RegionLayoutEditor';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog';
/* import ScriptList from '../../Controls/Lists/ScriptList'; */
import GenericList from '../../Controls/Lists/GenericList';
import ScriptItem from '../../Classes/ScriptItem';
import { TabPanel, a11yProps } from '../../Libraries/TabPanel';
import InsertableJavascriptEditor from '../../Controls/InsertableJavascriptEditor';
import BreadcrumbHelper from '../../Controls/BreadcrumbHelper';
import HelpIcon from '../../Controls/Forms/HelpIcon';
import FormEditorHost from './FormEditorHost';
import { QuizzicalContext } from '../../Context/QuizzicalContext';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function RegionLayoutEditorDialog(props) {
    const state = props.state;
    const actualContext = React.useContext(QuizzicalContext);
    const [original, setOriginal] = React.useState(undefined);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [showPreview, setShowPreview] = React.useState(true);

    React.useEffect(() => {
        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }

        if (props.original !== undefined &&
            props.original !== null &&
            props.original !== original) {
            setOriginal(props.original);
        }

        if (props.showPreview !== undefined &&
            props.showPreview !== null &&
            props.showPreview !== showPreview) {
            setShowPreview(props.showPreview);
        }
    }, [props]);

    const handleOpen = (e) => {
        setIsOpen(true);
    }

    const handleClose = (o, bucket) => {
        setIsOpen(false);
    }

    const handleConfirm = (o, bucket) => {
        original.copy(o);
        if (props.onConfirm !== undefined) {
            props.onConfirm(o);
        }

        setIsOpen(false);
    }

    let display = (<div />);
    if (original !== undefined) {
        display = (
            <>
                <Button
                    onClick={handleOpen}
                    variant="outlined"
                    color="inherit"
                    sx={{
                        textTransform: 'none'
                    }}>
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center">
                        <ViewComfyIcon />
                        <Typography
                            color="inherit"
                            variant="body"
                            component="div">
                            Manage Layout
                        </Typography>
                        {showPreview ?
                            <RegionLayoutEditor
                                sx={{
                                    position: 'relative',
                                    width: '32px',
                                    height: '32px',
                                }}
                                refreshRequired={true}
                                focus={original}
                                isReadOnly={isReadOnly}
                                asMosaic={true} /> : ""}
                    </Stack>
                </Button>
                <FormEditorHost
                    state={state !== undefined && state !== null ? state : actualContext}
                    fullscreen={true}
                    clone={true}
                    target={original}
                    open={isOpen}
                    isReadOnly={isReadOnly}
                    key={"add" + props.label + "-key"}
                    onClose={handleClose}
                    onSubmit={handleConfirm} />
            </>
        )
    }

    return display;
}