import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { getTimestampSlugMappings, doTimestampSubstitution } from '../Libraries/TimestampSubstitution';

// props:
// default: the default format to display (fall back if actual value is cleared)
// initialValue: initial format provided by user (supercedes the default)
// updateTarget: a function to call with the new value whenever it requires an update (newValue) => { }
export default function TimestampFormatEditor(props) {
    const [defaultValue, setDefaultValue] = React.useState(props.default);
    const [currentValue, setCurrentValue] = React.useState(props.initialValue);

    const isReady = () => {
        let readiness = (
            (defaultValue !== undefined || currentValue !== undefined) &&
            props.updateTarget !== undefined
        );

        return readiness;
    }

    const updateValue = (currentValue) => {
        if (isReady()) {
            if (props.updateTarget !== undefined) {
                let value = getAppropriateValue(currentValue);
                props.updateTarget(value);
            }
        }
    }

    const getAppropriateValue = (currentValue) => {
        let result = defaultValue;

        if (currentValue !== undefined &&
            (typeof currentValue === 'string' && currentValue.trim() !== '')
        ) {
            result = currentValue;
        }

        return result;
    }

    const onEditTimestampFormat = (event) => {
        if (event.target.value !== undefined &&
            event.target.value.trim() !== '') {
            setCurrentValue(event.target.value);
        } else {
            setCurrentValue(undefined);
        }

        updateValue(event.target.value);
    }

    if (!isReady()) return <></>;

    return (
        <Stack
            direction="row"
            spacing={1}
            alignItems='center'>
            <TextField
                item
                id="package-timestamp-format"
                fullWidth
                required
                label="Timestamp Format"
                defaultValue={defaultValue}
                onChange={onEditTimestampFormat} />
            <Tooltip
                item
                title={
                    <Grid container spacing={1}>
                        <Grid>
                            <Typography>Timestamp Formatting</Typography>
                        </Grid>
                        <Grid>
                            <Typography>Enter text using the format you desire.</Typography>
                        </Grid>
                        <Grid>
                            <Typography>"mm-dd-yyyy", the default, will display January first, 2023 as 01-01-2023.</Typography>
                        </Grid>
                        <Grid>
                            <Typography>Below are the available options:</Typography>
                        </Grid>
                        <Grid sx={{ width: '100%', height: '200px', overflow: 'auto' }}>
                            <TableContainer component={Paper}>
                                <Table aria-label="date-format-table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Slug</TableCell>
                                            <TableCell>Meaning</TableCell>
                                            <TableCell>Example</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            getTimestampSlugMappings().map((m, i) => {
                                                return <TableRow
                                                    key={m.code + '-' + i}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" align="left">
                                                        {m.code}
                                                    </TableCell>
                                                    <TableCell>{m.meaning}</TableCell>
                                                    <TableCell>{m.example}</TableCell>
                                                </TableRow>
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                } arrow>
                <HelpOutlineIcon />
            </Tooltip>
        </Stack>
    );
}