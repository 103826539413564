import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Switch } from '@mui/material';
import { randomId } from '@mui/x-data-grid-generator';
import { useTheme } from '@mui/material/styles';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { QuizzicalContext, RunStates, ScreenModes } from '../../../Context/QuizzicalContext';
//import ParameterList from '../../Controls/Lists/ParameterList';
import TopicsManager from '../../../Controls/TopicsManager';
//import VariableManager from '../../Controls/VariableManager';
import RegionLayoutManagementDialog from '../../../Screens/Dialogs/RegionLayoutManagementDialog';
import RegionLayoutEditor from '../../../Controls/ContentEditor/RegionLayoutEditor';
import FieldContainer from '../../Forms/FieldContainer';
import FEH_Form from '../../Forms/FEH_Form';
import GenericList from '../../Lists/GenericList';
import VariableDefinition from '../../../Classes/VariableDefinition';
import Subject from '../../../Classes/Subjects';
import { TabPanel, a11yProps } from '../../../Libraries/TabPanel';
import InsertableJavascriptEditor from '../../InsertableJavascriptEditor';
import ScriptItem from '../../../Classes/ScriptItem';
import { NumericalSequenceParameter, PoolParameter, RandomizedDecimalParameter, RandomizedIntegerParameter, SequencialParameter } from '../../../Classes/Parameters';
import RegionLayoutManagerList from '../../Lists/RegionLayoutManagerList';
import { LayoutRegionManager } from '../../../Classes/Layout/LayoutRegionManager';

export default function FillInQuestionEditor(props) {
    const state = props.state;
    const actualContext = React.useContext(QuizzicalContext);
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [defaultFieldSet, setDefaultFieldSet] = React.useState(randomId());
    const [tabIndex, setTabIndex] = React.useState(0);

    const theme = useTheme();

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }
    }, [props]);

    const handleTabChanged = (event, newValue) => {
        if (tabIndex === 0) {
            updateFocus();
            setFocus(focus);
        }
        setTabIndex(newValue);
    };

    const updateFocus = () => {
        if (tabIndex === 0) {
            // if we aren't on the details tab then there won't be any of the expected controls.
            // skip the update and just return what we have (which will be up-to-date)
            focus.setTitle(document.getElementById("question-title").value);
            focus.setDuration(+document.getElementById("question-duration").value);
            focus.setDwell(+document.getElementById("question-dwell").value);
        }

        return focus;
    }

    if (isReadOnly) {
        return ( // readonly
            <Box
                sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleTabChanged} aria-label="basic tabs example">
                        <Tab label="Details" {...a11yProps(0)} />
                        <Tab label="Pre-Step Scripts" {...a11yProps(1)} />
                        <Tab label="Post-Step Scripts" {...a11yProps(2)} />
                        <Tab label="Expected Answer Format" {...a11yProps(3)} />
                        <Tab label="Simple Question Text" {...a11yProps(4)} />
                        <Tab label="Simple Answer Text" {...a11yProps(5)} />
                    </Tabs>
                </Box>
                <TabPanel value={tabIndex} index={0} sx={{ width: '100%', height: '100%' }}>
                    <Grid>
                        <Stack direction="row" sx={{ alignItems: 'center' }}>
                            <Typography color="inherit" variant="body" component="div">Title:&nbsp;&nbsp;&nbsp;</Typography>
                            <Typography color="inherit" variant="caption" component="div">{focus.getTitle()}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Typography color="inherit" variant="body" component="div">Layout:</Typography>
                            <RegionLayoutEditor
                                sx={{
                                    position: 'relative',
                                    width: '32px',
                                    height: '32px',
                                }}
                                focus={focus.getDescription()[0]}
                                isReadOnly={isReadOnly}
                                asMosaic={true} />
                        </Stack>
                        <Stack direction="row" sx={{ alignItems: 'center' }}>
                            <Typography color="inherit" variant="body" component="div">Duration:&nbsp;&nbsp;&nbsp;</Typography>
                            <Typography color="inherit" variant="caption" component="div">{focus.getDuration()}</Typography>
                        </Stack>
                        <Stack direction="row" sx={{ alignItems: 'center' }}>
                            <Typography color="inherit" variant="body" component="div">Dwell:&nbsp;&nbsp;&nbsp;</Typography>
                            <Typography color="inherit" variant="caption" component="div">{focus.getDwell()}</Typography>
                        </Stack>
                        {/* <VariableManager focus={focus} isReadOnly={isReadOnly} /> */}
                        <TopicsManager focus={focus} isReadOnly={isReadOnly} />
                        {/* <ParameterList isReadOnly={isReadOnly} focus={focus} label="Parameters" /> */}
                        <RegionLayoutManagerList
                            rows={4}
                            help={"Layouts describe the appearance of your questions when shown during presentations and replays.  They are displayed in the listed order.\n\nIt is mandatory that each question have at least one."}
                            isReadOnly={isReadOnly}
                            isCollapsable={false}
                            label="Layouts"
                            showHeaderLabel={true}
                            getTargetItems={() => {
                                return focus.getDescription();
                            }}
                            setTargetItems={(items) => {
                                focus.setDescription(items);
                            }}
                            getDefaultItem={() => {
                                if (focus.getParent().constructor.ClassName() === Subject.ClassName() &&
                                    focus.getParent().isLayoutProvider() === true) {
                                    let n = new LayoutRegionManager().initCreate(focus, 1, 2);
                                    n.copy(focus.getParent().getDescription());
                                    n.setTitle(focus.getNextLayoutTitle());
                                    return n;
                                } else {
                                    let n = new LayoutRegionManager().initCreate(focus, 1, 2);
                                    n.setTitle(focus.getNextLayoutTitle());
                                    return n;
                                }
                            }}
                            validPastes={[
                                LayoutRegionManager.ClassName(),
                            ]} />
                        <GenericList
                            rows={6}
                            state={state}
                            propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                            defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                            help={"Variables maintain their values across iterations and can be accessed by any script within the containing Subject's hierarchy.\n\nNote that the order of items does not matter."}
                            isReadOnly={isReadOnly}
                            isCollapsable={false}
                            label="Variables"
                            getTargetItems={() => {
                                return focus.getVariables();
                            }}
                            getAdditionalItems={() => {
                                let result = [];
                                if (focus.getParent().constructor.ClassName() === Subject.ClassName()) {
                                    result = result.concat(focus.getParent().getVariables());
                                }

                                return result;
                            }}
                            setTargetItems={(items) => {
                                focus.setVariables(items);
                            }}
                            checkIsChild={(item) => {
                                return item.getParent().getId() === focus.getId();
                            }}
                            getDefaultItem={() => {
                                return VariableDefinition.getDefault(focus, 'variable-' + focus.getVariables().length, undefined, undefined);
                            }}
                            validPastes={[
                                VariableDefinition.ClassName(),
                            ]} />
                        {/* <Grid xs={12}>
                        <ParameterList isReadOnly={isReadOnly} focus={focus} label="Parameters" />
                    </Grid> */}
                        <GenericList
                            rows={6}
                            state={state}
                            propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                            defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                            help={"Parameters are recalculated upon each iteration.  They are made available to any scripts within the question's hierarchy.\n\nNote that the order of items does not matter."}
                            isReadOnly={isReadOnly}
                            isCollapsable={false}
                            label="Parameters"
                            getTargetItems={() => {
                                return focus.getParameters();
                            }}
                            setTargetItems={(items) => {
                                focus.setParameters(items);
                            }}
                            getAdditionalItems={() => {
                                let result = [];
                                if (focus.getParent().constructor.ClassName() === Subject.ClassName()) {
                                    result = result.concat(focus.getParent().getParameters());
                                }

                                return result;
                            }}
                            checkIsChild={(item) => {
                                return item.getParent().getId() === focus.getId();
                            }}
                            getDefaultItem={() => {
                                return ["parameter", focus];
                            }}
                            getTitleText={(item) => {
                                return item.getName();
                            }}
                            validPastes={[
                                PoolParameter.ClassName(),
                                SequencialParameter.ClassName(),
                                NumericalSequenceParameter.ClassName(),
                                RandomizedDecimalParameter.ClassName(),
                                RandomizedIntegerParameter.ClassName(),
                            ]} />
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={1} sx={{ width: '100%', height: '100%' }}>
                    <Typography color="inherit" component="div">These pre-scripts are executed in order, each time before this question is displayed.</Typography>
                    {/* <ScriptList
                            focus={scriptListingSource}
                            property="getPreScripts" /> */}
                    <GenericList
                        isReadOnly={isReadOnly}
                        isCollapsable={false}
                        label="Pre-Step Script"
                        showHeaderLabel={false}
                        getTargetItems={() => {
                            return focus.getPreScripts();
                        }}
                        setTargetItems={(items) => {
                            focus.setPreScripts(items);
                        }}
                        getDefaultItem={() => {
                            return ScriptItem.getDefault(focus, undefined, undefined);
                        }}
                        validPastes={[
                            ScriptItem.ClassName(),
                        ]} />
                </TabPanel>
                <TabPanel value={tabIndex} index={2} sx={{ width: '100%', height: '100%' }}>
                    <Typography color="inherit" component="div">These post-scripts are executed in order, each time after this question is displayed.</Typography>
                    {/* <ScriptList
                            focus={scriptListingSource}
                            property="getPostScripts" /> */}
                    <GenericList
                        isReadOnly={isReadOnly}
                        isCollapsable={false}
                        label="Pre-Step Script"
                        showHeaderLabel={false}
                        getTargetItems={() => {
                            return focus.getPostScripts();
                        }}
                        setTargetItems={(items) => {
                            focus.setPostScripts(items);
                        }}
                        getDefaultItem={() => {
                            return ScriptItem.getDefault(focus, undefined, undefined);
                        }}
                        validPastes={[
                            ScriptItem.ClassName(),
                        ]} />
                </TabPanel>
                <TabPanel value={tabIndex} index={3} sx={{ width: '100%', height: '100%' }}>
                    <Stack>
                        <Typography>Enter a script that returns the expected format students should enter their answers in.</Typography>
                        <InsertableJavascriptEditor
                            focus={focus}
                            isReadOnly={isReadOnly}
                            isMouseOver={true}
                            setter="setExpectedFormat"
                            getter="getExpectedFormat" />
                    </Stack>
                </TabPanel>
                <TabPanel value={tabIndex} index={4} sx={{ width: '100%', height: '100%' }}>
                    <Stack>
                        <Typography>Enter a script to return a simple text version of the question.  This is required and cannot be left blank.</Typography>
                        <InsertableJavascriptEditor
                            focus={focus}
                            isReadOnly={isReadOnly}
                            isMouseOver={true}
                            setter="setSimpleQuestion"
                            getter="getSimpleQuestion" />
                    </Stack>
                </TabPanel>
                <TabPanel value={tabIndex} index={5} sx={{ width: '100%', height: '100%' }}>
                    <Stack>
                        <Typography>Enter a script to return a simple text version of the correct question.  This is required and cannot be left blank.</Typography>
                        <InsertableJavascriptEditor
                            focus={focus}
                            isReadOnly={isReadOnly}
                            isMouseOver={true}
                            setter="setSimpleAnswer"
                            getter="getSimpleAnswer" />
                    </Stack>
                </TabPanel>
            </Box>
        );
    } else {
        return ( // editable
            <FEH_Form
                height="88vH"
                formId="FillInQuestion"
                type="FillInQuestion"
                getInstance={updateFocus}
                onClose={props.onClose}
                onConfirm={props.onConfirm}
                onReportMessages={props.onReportMessages}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleTabChanged} aria-label="basic tabs example">
                        <Tab label="Details" {...a11yProps(0)} />
                        <Tab label="Pre-Step Scripts" {...a11yProps(1)} />
                        <Tab label="Post-Step Scripts" {...a11yProps(2)} />
                        <Tab label="Expected Answer Format" {...a11yProps(3)} />
                        <Tab label="Simple Question Text" {...a11yProps(4)} />
                        <Tab label="Simple Answer Text" {...a11yProps(5)} />
                    </Tabs>
                </Box>
                <TabPanel value={tabIndex} index={0} sx={{ width: '100%', height: '100%' }}>
                    <Stack spacing={2}>
                        <FieldContainer
                            display="flow"
                            fieldSet={defaultFieldSet}
                            label="Title"
                            help="The question's name"
                            fullWidth
                            required>
                            <TextField
                                disabled={isReadOnly}
                                id="question-title"
                                fullWidth
                                required
                                defaultValue={focus.getTitle()} />
                        </FieldContainer>
                        <FieldContainer
                            display="flow"
                            fieldSet={defaultFieldSet}
                            label="Duration"
                            help="The amount of time to display the question without its answer"
                            required>
                            <TextField
                                disabled={isReadOnly}
                                id="question-duration"
                                required
                                type="number"
                                defaultValue={focus.getDuration()} />
                        </FieldContainer>
                        <FieldContainer
                            display="flow"
                            fieldSet={defaultFieldSet}
                            label="Dwell"
                            help="The amount of time to display the question with its answer.  This follows the duration's conclusion."
                            required>
                            <TextField
                                disabled={isReadOnly}
                                id="question-dwell"
                                required
                                type="number"
                                defaultValue={focus.getDwell()} />
                        </FieldContainer>
                        {/* <Grid xs={12}>
                        <VariableManager focus={focus} isReadOnly={isReadOnly} />
                    </Grid> */}
                        <TopicsManager focus={focus} isReadOnly={isReadOnly} directions={"Select question topics"} unmountOnExit onReportMessages={props.onReportMessages} />
                        {/* <GenericStorageManagerList
                        fullscreen={false}
                        help={"Variables maintain their values across iterations and can be accessed by any script within the containing question's hierarchy.\n\nNote that the order of items does not matter."}
                        isReadOnly={isReadOnly}
                        isCollapsable={false}
                        label="Variables"
                        nameLabel='Variable'
                        valueLabel='Initial Value'
                        validationType='variable'
                        manager={focus.getVariables()}
                        setTargetItems={(items) => {
                            focus.getVariables(items);
                        }}
                        getDefaultItem={() => {
                            let i = 0;
                            while (focus.getVariables().containsName("new-variable-" + i)) {
                                i++;
                            }

                            return {
                                type: "variable",
                                host: focus,
                                obj: StorageItem.getDefault("new-variable-" + i, undefined),
                                nameLabel: 'Variable',
                                valueLabel: 'Initial Value',
                                tertiaryLabel: 'Comment',
                            };
                        }} /> */}
                        <RegionLayoutManagerList
                            rows={4}
                            help={"Layouts describe the appearance of your questions when shown during presentations and replays.  They are displayed in the listed order.\n\nIt is mandatory that each question have at least one."}
                            isReadOnly={isReadOnly}
                            isCollapsable={false}
                            label="Layouts"
                            showHeaderLabel={true}
                            getTargetItems={() => {
                                return focus.getDescription();
                            }}
                            setTargetItems={(items) => {
                                focus.setDescription(items);
                            }}
                            getDefaultItem={() => {
                                if (focus.getParent().constructor.ClassName() === Subject.ClassName() &&
                                    focus.getParent().isLayoutProvider() === true) {
                                    let n = new LayoutRegionManager().initCreate(focus, 1, 2);
                                    n.copy(focus.getParent().getDescription());
                                    n.setTitle(focus.getNextLayoutTitle());
                                    return n;
                                } else {
                                    let n = new LayoutRegionManager().initCreate(focus, 1, 2);
                                    n.setTitle(focus.getNextLayoutTitle());
                                    return n;
                                }
                            }}
                            validPastes={[
                                LayoutRegionManager.ClassName(),
                            ]} />
                        <GenericList
                            rows={6}
                            state={state}
                            propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                            defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                            help={"Variables maintain their values across iterations and can be accessed by any script within the containing Subject's hierarchy.\n\nNote that the order of items does not matter."}
                            isReadOnly={isReadOnly}
                            isCollapsable={false}
                            label="Variables"
                            getTargetItems={() => {
                                return focus.getVariables();
                            }}
                            getAdditionalItems={() => {
                                let result = [];
                                if (focus.getParent().constructor.ClassName() === Subject.ClassName()) {
                                    result = result.concat(focus.getParent().getVariables());
                                }

                                return result;
                            }}
                            setTargetItems={(items) => {
                                focus.setVariables(items);
                            }}
                            checkIsChild={(item) => {
                                return item.getParent().getId() === focus.getId();
                            }}
                            getDefaultItem={() => {
                                return VariableDefinition.getDefault(focus, 'variable-' + focus.getVariables().length, undefined, undefined);
                            }}
                            validPastes={[
                                VariableDefinition.ClassName(),
                            ]} />
                        {/* <Grid xs={12}>
                        <ParameterList isReadOnly={isReadOnly} focus={focus} label="Parameters" />
                    </Grid> */}
                        <GenericList
                            rows={6}
                            state={state}
                            propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                            defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                            help={"Parameters are recalculated upon each iteration.  They are made available to any scripts within the question's hierarchy.\n\nNote that the order of items does not matter."}
                            isReadOnly={isReadOnly}
                            isCollapsable={false}
                            label="Parameters"
                            getTargetItems={() => {
                                return focus.getParameters();
                            }}
                            setTargetItems={(items) => {
                                focus.setParameters(items);
                            }}
                            getAdditionalItems={() => {
                                let result = [];
                                if (focus.getParent().constructor.ClassName() === Subject.ClassName()) {
                                    result = result.concat(focus.getParent().getParameters());
                                }

                                return result;
                            }}
                            checkIsChild={(item) => {
                                return item.getParent().getId() === focus.getId();
                            }}
                            getDefaultItem={() => {
                                return ["parameter", focus];
                            }}
                            getTitleText={(item) => {
                                return item.getName();
                            }}
                            validPastes={[
                                PoolParameter.ClassName(),
                                SequencialParameter.ClassName(),
                                NumericalSequenceParameter.ClassName(),
                                RandomizedDecimalParameter.ClassName(),
                                RandomizedIntegerParameter.ClassName(),
                            ]} />
                    </Stack>
                </TabPanel>
                <TabPanel value={tabIndex} index={1} sx={{ width: '100%', height: '80%' }}>
                    <Typography color="inherit" component="div">These pre-scripts are executed in order, each time before this question is displayed.</Typography>
                    {/* <ScriptList
                            focus={scriptListingSource}
                            property="getPreScripts" /> */}
                    <GenericList
                        isReadOnly={isReadOnly}
                        isCollapsable={false}
                        label="Pre-Step Script"
                        showHeaderLabel={false}
                        getTargetItems={() => {
                            return focus.getPreScripts();
                        }}
                        setTargetItems={(items) => {
                            focus.setPreScripts(items);
                        }}
                        getDefaultItem={() => {
                            return ScriptItem.getDefault(focus, undefined, undefined);
                        }}
                        validPastes={[
                            ScriptItem.ClassName(),
                        ]} />
                </TabPanel>
                <TabPanel value={tabIndex} index={2} sx={{ width: '100%', height: '80%' }}>
                    <Typography color="inherit" component="div">These post-scripts are executed in order, each time after this question is displayed.</Typography>
                    {/* <ScriptList
                            focus={scriptListingSource}
                            property="getPostScripts" /> */}
                    <GenericList
                        isReadOnly={isReadOnly}
                        isCollapsable={false}
                        label="Pre-Step Script"
                        showHeaderLabel={false}
                        getTargetItems={() => {
                            return focus.getPostScripts();
                        }}
                        setTargetItems={(items) => {
                            focus.setPostScripts(items);
                        }}
                        getDefaultItem={() => {
                            return ScriptItem.getDefault(focus, undefined, undefined);
                        }}
                        validPastes={[
                            ScriptItem.ClassName(),
                        ]} />
                </TabPanel>
                <TabPanel value={tabIndex} index={3} sx={{ width: '100%', height: '80%' }}>
                    <Typography>Enter a script that returns the expected format students should enter their answers in.</Typography>
                    <InsertableJavascriptEditor
                        fabStyling={{
                            position: 'absolute',
                            top: '16%',
                            right: '5%',
                        }}
                        styling={{
                            border: 1,
                            borderColor: (actualContext.theme === 'light' ? theme.palette.primary.main : '#000000'),
                        }}
                        height="70vH"
                        focus={focus}
                        isReadOnly={isReadOnly}
                        isMouseOver={true}
                        setter="setExpectedFormat"
                        getter="getExpectedFormat" />
                </TabPanel>
                <TabPanel value={tabIndex} index={4} sx={{ width: '100%', height: '80%' }}>
                    <Typography>Enter a script to return a simple text version of the question.  This is required and cannot be left blank.</Typography>
                    <InsertableJavascriptEditor
                        fabStyling={{
                            position: 'absolute',
                            top: '16%',
                            right: '5%',
                        }}
                        styling={{
                            border: 1,
                            borderColor: (actualContext.theme === 'light' ? theme.palette.primary.main : '#000000'),
                        }}
                        height="70vH"
                        focus={focus}
                        isReadOnly={isReadOnly}
                        isMouseOver={true}
                        setter="setSimpleQuestion"
                        getter="getSimpleQuestion" />
                </TabPanel>
                <TabPanel value={tabIndex} index={5} sx={{ width: '100%', height: '80%' }}>
                    <Typography>Enter a script to return a simple text version of the correct question.  This is required and cannot be left blank.</Typography>
                    <InsertableJavascriptEditor
                        fabStyling={{
                            position: 'absolute',
                            top: '16%',
                            right: '5%',
                        }}
                        styling={{
                            border: 1,
                            borderColor: (actualContext.theme === 'light' ? theme.palette.primary.main : '#000000'),
                        }}
                        height="70vH"
                        focus={focus}
                        isReadOnly={isReadOnly}
                        isMouseOver={true}
                        setter="setSimpleAnswer"
                        getter="getSimpleAnswer" />
                </TabPanel>
            </FEH_Form>
        );
    }
}