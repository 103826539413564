import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Grow from '@mui/material/Grow';
import Grid from '@mui/material/Unstable_Grid2';

import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { ContentTypes, ContentSources } from '../../Classes/Layout/LayoutMeta';
import runScript, { interpolateText } from '../../Libraries/runScript';
import { shuffle } from '../../Libraries/Ordering';
import { getResponsibleParent } from '../../Libraries/Traversion';
import { MultipleChoiceQuestion } from '../../Classes/Questions';
import { IconButton } from '@mui/material';

export default function RegionDisplay(props) {
    const [target, setTarget] = React.useState(undefined);
    const [questionData, setQuestionData] = React.useState(undefined);
    const containerRef = React.useRef(null);
    const [contentRef, setContentRef] = React.useState(null);

    const [forceRefresh, setForceRefresh] = React.useState(false);

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== target) {
            setTarget(props.focus);
        }

        if (props.questionData !== undefined &&
            props.questionData !== null &&
            props.questionData !== questionData) {
            setQuestionData(props.questionData);
        }
    }, [props]);

    React.useEffect(() => {
        function handleResize() {
            setForceRefresh(!forceRefresh);
        }

        window.addEventListener('resize', handleResize);

        return _ => {
            window.removeEventListener('resize', handleResize);
        }
    });

    let scaled = false;
    ///// Scaling //////
    if (containerRef.current !== null && contentRef !== null) {
        let scaledWrapper = containerRef.current;

        let applyScaling = scaledWrapper => {

            // Get the scaled content, and reset its scaling for an instant
            let scaledContent = contentRef;
            scaledContent.style.transform = 'scale(1, 1)';

            let { width: cw, height: ch } = scaledContent.getBoundingClientRect();
            let { width: ww, height: wh } = scaledWrapper.getBoundingClientRect();

            //  let scaleAmtX = ww / cw;
            //  let scaleAmtY = wh / ch;
            let scaleAmtX = Math.min(ww / cw, wh / ch);
            let scaleAmtY = scaleAmtX;


            scaledContent.style.transform = `scale(${scaleAmtX}, ${scaleAmtY})`;

        };

        applyScaling(scaledWrapper);
        scaled = true;
    }
    ///// End Scaling //////


    const getItemList = (items, keyStub, showingAnswer, startingIndex = 0) => {
        let resultSet = undefined;
        if (Array.isArray(items)) {
            if (items.filter((item) => {
                return typeof item !== 'string';
            }).length === 0) {
                resultSet = items.map((item, i) => {
                    return (
                        <ListItem
                            key={keyStub + "-" + (startingIndex + i)}>
                            <ListItemText primary={<Typography>{item}</Typography>} />
                        </ListItem>
                    );
                });
            } else {
                resultSet = items.map((item, i) => {
                    return (
                        <ListItem
                            key={keyStub + "-" + (startingIndex + i)}
                            secondaryAction={
                                <IconButton edge="end" aria-label="delete" disabled>
                                    {(showingAnswer === true ? (item.correct === true ? <CheckIcon color="success" /> : <CheckIcon color="disabled" />) : <CheckIcon color="disabled" />)}
                                </IconButton>
                            }>
                            <ListItemText primary={<Typography>{item.item !== undefined ? item.item : (item.value !== undefined ? item.value : undefined)}</Typography>} />
                        </ListItem >
                    );
                });
            }
        } else {
            resultSet = [
                <ListItem
                    disablePadding
                    key={keyStub + "-" + startingIndex}>
                    <ListItemText primary={items} />
                </ListItem>
            ];
        }

        return resultSet;
    }

    const getProcessedItems = (inputSet, showingAnswer) => {
        let items = getItemList(inputSet, 'answerItems', showingAnswer);
        if (target.getSource() === ContentSources.Answer &&
            questionData.items.length > 0) {
            items = items.concat(getItemList(questionData.items, 'answerItems', showingAnswer));

            if (questionData.shuffled === true) {
                shuffle(items);
            }
        }

        return items;
    }

    let display = undefined;
    if (target !== undefined) {
        if (target.getParent().getParent().constructor.ClassName() === MultipleChoiceQuestion.ClassName() &&
            target.getSource() === ContentSources.Answer) {
            let items = getProcessedItems([], questionData.showingAnswer === true);

            display = (
                <List ref={newRef => setContentRef(newRef)}>{items}</List>
            );
        } else {
            // generate the content
            switch (target.getType()) {
                case ContentTypes.Text:
                    if ((target.getSource() === ContentSources.Answer && questionData.showingAnswer === true) ||
                        (target.getSource() === ContentSources.Question || target.getSource() === ContentSources.Subject)) {
                        display = (
                            <Typography ref={newRef => setContentRef(newRef)}>{interpolateText(target.getContent(), questionData.variables, questionData.parameters)}</Typography>
                        );
                    }
                    break;
                case ContentTypes.Script:
                    // run the script and check what it returns
                    let scriptResult = runScript(getResponsibleParent(target).getId(), target.getContent(), "Region " + (target.getX() + 1) + ", " + (target.getY() + 1), questionData.variables, questionData.parameters);
                    if (scriptResult.hasErrors === false) {
                        let result = scriptResult.result;

                        // note: only answers consider the items in multiple choice questions
                        //
                        // the following logic is how this works:
                        // 1)   regardless of question or answer, if the script returns an array, convert it to a list for display
                        // 2)   if this is an answer,  and we have items associate with the question, convert them to a list for display
                        // 3)   if the script returns a single item and we have items, add the single item to the converted items for display
                        if (Array.isArray(result)) {
                            let items = getProcessedItems(result, questionData.showingAnswer === true);

                            display = (
                                <List ref={newRef => setContentRef(newRef)}>{items}</List>
                            );
                        } else {
                            if (target.getSource() === ContentSources.Answer &&
                                questionData.items.length > 0) {
                                let items = getProcessedItems(result, questionData.showingAnswer === true);

                                display = (
                                    <List ref={newRef => setContentRef(newRef)}>{items}</List>
                                );
                            } else if ((target.getSource() === ContentSources.Answer && questionData.showingAnswer === true) ||
                                (target.getSource() === ContentSources.Question || target.getSource() === ContentSources.Subject)) {
                                if (/<\/?[a-z][\s\S]*>/i.test(result)) {
                                    display = (
                                        <div ref={newRef => setContentRef(newRef)} dangerouslySetInnerHTML={{ __html: result }} />
                                    );
                                } else {
                                    if (typeof result === 'string') {
                                        display = (
                                            <Typography ref={newRef => setContentRef(newRef)}>{result}</Typography>
                                        );
                                    } else {
                                        display = (
                                            <Typography ref={newRef => setContentRef(newRef)}>{JSON.stringify(result)}</Typography>
                                        );
                                    }
                                }
                            }
                        }
                    } else {
                        let time = scriptResult.when.split(' ');
                        if (scriptResult.error !== undefined) {
                            let rgx = /topicalScriptExecutionFunction[^\<]*<anonymous>:(\d+\:\d+)/;
                            let groups = scriptResult.error.stack.match(rgx); // for edge
                            if (groups === null) {// check for firefox
                                rgx = /topicalScriptExecutionFunction@.*Function:(\d+\:\d+)/;
                                groups = scriptResult.error.stack.match(rgx);
                            }

                            let getCorrectedCoords = (str) => {
                                let vals = str.split(":");
                                vals[0] = (+vals[0]) - (11 + questionData.variables.length + questionData.parameters.length);

                                return 'y: ' + vals[0] + ", x:" + vals[1];
                            }

                            display = (
                                <Box
                                    sx={{
                                        height: '100%',
                                        width: '100%',
                                        border: 0.5,
                                        borderColor: 'red',
                                        bgcolor: 'background.paper',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                    }}>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        sx={{
                                            bgcolor: 'background.paper',
                                        }}>
                                        <Stack
                                            alignItems="center">
                                            <ErrorIcon
                                                fontSize='large'
                                                color="error" />
                                            <Typography variant='caption'>{time[0]}</Typography>
                                            <Typography variant='caption'>{time[1]}</Typography>
                                        </Stack>
                                        <Stack spacing={1}>
                                            <Typography variant='caption'>{"The script in region " + (target.getX() + 1) + ", " + (target.getY() + 1) + " encountered an exception"}</Typography>
                                            {
                                                groups !== null && groups !== undefined ?
                                                    <Typography variant='caption'>{scriptResult.error.message + (groups.length === 2 ? " at " + getCorrectedCoords(groups[1]) : '')}</Typography> :
                                                    <Typography variant='caption'>{scriptResult.error.message}</Typography>
                                            }
                                        </Stack>
                                    </Stack>
                                </Box>
                            );
                        } else {
                            display = (
                                <Box
                                    sx={{
                                        height: '100%',
                                        width: '100%',
                                        border: 0.5,
                                        borderColor: 'red',
                                        bgcolor: 'background.paper',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                    }}>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        sx={{
                                            bgcolor: 'background.paper',
                                        }}>
                                        <Stack
                                            alignItems="center">
                                            <ErrorIcon
                                                fontSize='large'
                                                color="error" />
                                            <Typography variant='caption'>{time[0]}</Typography>
                                            <Typography variant='caption'>{time[1]}</Typography>
                                        </Stack>
                                        <Stack spacing={1}>
                                            <Typography variant='caption'>{"The script in region " + (target.getX() + 1) + ", " + (target.getY() + 1) + " encountered an exception"}</Typography>
                                            <Typography variant='caption'>{scriptResult.error.message}</Typography>
                                        </Stack>
                                    </Stack>
                                </Box>
                            );
                        }
                    }
                    break;
                case ContentTypes.Image:
                    if ((target.getSource() === ContentSources.Answer && questionData.showingAnswer === true) ||
                        (target.getSource() === ContentSources.Question || target.getSource() === ContentSources.Subject)) {
                        display = <Box
                            ref={newRef => setContentRef(newRef)}
                            id="column-image"
                            alt="Region Image Content"
                            sx={{
                                backgroundImage: `url(${target.getContent().edit})`,
                                backgroundSize: 'cover',
                                width: target.getContent().width + 'px',
                                height: target.getContent().height + 'px',
                            }} />
                    }
                    break;
                case ContentTypes.ImageSet:
                    if ((target.getSource() === ContentSources.Answer && questionData.showingAnswer === true) ||
                        (target.getSource() === ContentSources.Question || target.getSource() === ContentSources.Subject)) {
                        // unavailable atm
                    }
                    break;
            }
        }
    }

    let housing = undefined;
    if (target === undefined) {
        housing = '';
    } else if (display === undefined) {
        housing = <div />;
    } else {
        housing = (display);
        /* if (scaled === false && containerRef.current !== null && contentRef.current === null) {
            setForceRefresh(!forceRefresh);
        } */
    }

    return (
        <Grow in={display !== undefined}>
            <Grid
                ref={containerRef}
                container
                alignContent='center'
                justifyContent='center'
                sx={{
                    ...props.sx,
                    backgroundColor: 'background.paper'
                }}>
                {housing}
            </Grid>
        </Grow>
    );
}