import * as React from 'react';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import { QuizzicalContext, RunStates, ScreenModes } from '../../Context/QuizzicalContext';
import Assignment from '../../Classes/Assignment';
import { useEffect } from 'react';
import { QuestionTypes } from '../../Classes/Questions';
import FEH_MultipleChoiceEditor from './Questions/FEH_MultipleChoiceEditor';
import FEH_FillInQuestionEditor from './Questions/FEH_FillInQuestionEditor';
import FEH_PoolQuestionEditor from './Questions/FEH_PoolQuestionEditor';

export default function FEH_QuestionEditor(props) {
    let editor = (< div />);
    if (props.focus !== undefined &&
        props.focus.getType !== undefined) {
        switch (props.focus.getQuestionType()) {
            case QuestionTypes.FillIn:
                editor = (
                    <FEH_FillInQuestionEditor
                        state={props.state}
                        onReportMessages={props.onReportMessages}
                        onClose={props.onClose}
                        onConfirm={props.onConfirm}
                        focus={props.focus}
                        isReadOnly={props.isReadOnly} />
                );
                break;
            case QuestionTypes.MultipleChoice:
                editor = (
                    <FEH_MultipleChoiceEditor
                        state={props.state}
                        onReportMessages={props.onReportMessages}
                        onClose={props.onClose}
                        onConfirm={props.onConfirm}
                        focus={props.focus}
                        isReadOnly={props.isReadOnly} />
                );
                break;
            case QuestionTypes.Pool:
                editor = (
                    <FEH_PoolQuestionEditor
                        state={props.state}
                        onReportMessages={props.onReportMessages}
                        onClose={props.onClose}
                        onConfirm={props.onConfirm}
                        focus={props.focus}
                        isReadOnly={props.isReadOnly} />
                );
                break;
        }

        return editor;
    } else {
        return (<Typography color="inherit" variant="h6" component="div" style={{ textAlign: 'center' }}>Invalid focus provided.</Typography>);
    }
}