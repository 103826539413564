import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { QuizzicalContext, RunStates, ScreenModes } from '../Context/QuizzicalContext';
import GenericList, { ListRowContentMode } from './Lists/GenericList';
import getIdTree from '../Libraries/getIdTree';
import { getDependencyListing, getUniqueElements, safeGetType } from '../Libraries/generalUtility';
import ArbitraryTopicSetListing from './ArbitraryTopicSetListing';
import { Topic } from '../Classes/Topics';

export default function ItemDependencyListing(props) {
    const state = React.useContext(QuizzicalContext);
    let result = 'N/A';

    if (props.focus !== undefined &&
        props.focus.getId !== undefined &&
        props.focus.getDependencies !== undefined) {
        let dependencies = getDependencyListing(props.focus, state);

        if (dependencies.length > 0) {
            let listingContent = [];
            let topicContent = [];
            for (let i = 0; i < dependencies.length; i++) {
                if (typeof dependencies[i] === 'string') {
                    let d = getIdTree(state, dependencies[i]);

                    if (d !== undefined &&
                        Array.isArray(d)) {
                        listingContent.push(d[0]);
                    } else if (d !== undefined &&
                        !Array.isArray(d)) {
                        listingContent.push(d);
                    }
                } else if (safeGetType(dependencies[i]) === Topic.ClassName()) {
                    topicContent.push(dependencies[i]);
                } else {
                    listingContent.push(dependencies[i]);
                }
            }

            result = (
                <Stack>
                    <ArbitraryTopicSetListing topics={topicContent} />
                    <GenericList
                        rows={4}
                        isReadOnly={true}
                        isCollapsable={false}
                        label="Dependencies"
                        showHeaderLabel={true}
                        getTargetItems={() => {
                            return listingContent;
                        }}
                        setTargetItems={(items) => {
                            // do nothing
                        }} />
                </Stack>
            );
        } else {
            result = (
                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                    No external dependencies were detected.
                </Typography>
            );
        }
    }

    return result;
}