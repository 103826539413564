import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { QuizzicalContext } from '../../Context/QuizzicalContext';
import { doTimestampSubstitution } from '../../Libraries/TimestampSubstitution';
import TimestampFormatEditor from '../../Controls/TimestampFormatEditor';
import HelpIcon from '../../Controls/Forms/HelpIcon';

export default function FileNamingDialog(props) {
    const state = React.useContext(QuizzicalContext);
    const [isOpen, setIsOpen] = React.useState(props.isOpen);
    const [bucket, setBucket] = React.useState(props.bucket);
    const [timestampFormat, setTimestampFormat] = React.useState(state.DefaultExportTimestamp);
    const [insertTimestamp, setInsertTimestamp] = React.useState(false);
    const [exportFileName, setExportFileName] = React.useState('file');

    React.useEffect(() => {
        if (isOpen !== props.isOpen &&
            props.isOpen !== undefined &&
            props.isOpen !== null) {
            setIsOpen(props.isOpen);
        }

        if (bucket !== props.bucket) {
            setBucket(props.bucket);
        }

        if (exportFileName !== props.filename) {
            setExportFileName(props.filename);
        }
    }, [props]);

    const handleClose = (event) => {
        setInsertTimestamp(false);
        props.onCancel(event, props.bucket);
    };

    const handleConfirm = (event) => {
        let fileName = exportFileName + (insertTimestamp ? '-' + doTimestampSubstitution(timestampFormat) : '') + (props.extension.startsWith('.') ? props.extension : '.' + props.extension);
        setInsertTimestamp(false);
        props.onConfirm(event, props.bucket, fileName);
    }

    const onEditFilename = (event) => {
        setExportFileName(event.target.value);
    }

    let help = "Enter [key] to insert the lesson key into the file name.  The lesson key is a guid used to tie an answer sheet to the question set.";
    if (props.name !== undefined) {
        help += "\n\nEnter [name] when exporting a student's sheet to insert their name into the file name.";
    }

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>Enter File Name</DialogTitle>
                <DialogContent>
                    <Stack>
                        <Stack
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                            sx={{ paddingTop: 1 }}>
                            <TextField
                                fullWidth
                                id="file-name"
                                required
                                label="File Name"
                                value={exportFileName}
                                onChange={onEditFilename} />
                                <HelpIcon help={help} />
                            {
                                insertTimestamp ?
                                    <>
                                        <Typography style={{ height: '100%', verticalAlign: "middle" }}>&nbsp;-&nbsp;</Typography>
                                        <TimestampFormatEditor
                                            default={state.DefaultExportTimestamp}
                                            updateTarget={(newValue) => { setTimestampFormat(newValue); }}
                                            initialValue={timestampFormat}
                                        />
                                    </> : ""
                            }
                            <Typography style={{ height: '100%', verticalAlign: "middle" }}>{props.extension}</Typography>
                        </Stack>
                        <FormControlLabel control={<Checkbox value={insertTimestamp} onChange={(e) => { setInsertTimestamp(!insertTimestamp) }} />} label="Add Timestamp?" />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    {props.cancelText !== undefined ? <Button color="inherit" onClick={handleClose}>{props.cancelText}</Button> : ''}
                    {props.confirmText !== undefined ? <Button color="inherit" onClick={handleConfirm}>{props.confirmText}</Button> : ''}
                </DialogActions>
            </Dialog>
        </div >
    );
}