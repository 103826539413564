import LinkIcon from '@mui/icons-material/Link';
import { randomId } from '@mui/x-data-grid-generator';

import searchQuery from '../../Libraries/SearchQuery';
import { getDependencySet } from '../../Libraries/generalUtility';
import { getPersonById } from '../../Context/ContextUtility';
import SelectedImplementation from '../SelectImplementation';
import { Organization } from './Organization';

// possible roles
export const AssociationTypes = {
    None: 0,
    Student: 1,
    Faculty: 2,
    Instructor: 3,
};

// associates someone with something in a specified role
export class RoleAssociation extends SelectedImplementation {
    constructor() { 
        super();       
        this.isClonedInstance = false;
    }

    initCreate(whoId, asType, whatId) {
        this.id = 'RoleAssociation_' + randomId();
        this.type = asType;
        this.who = whoId;
        this.what = whatId;

        return this;
    }

    initJson(parent, struct) {
        this.id = struct.id;
        this.type = struct.type;
        this.who = struct.who;
        this.what = struct.what;
        super.Select();

        return this;
    }

    static getDefault(whoId, type, whatId) {
        return new RoleAssociation().initCreate(whoId, type, whatId);
    }

    getDependencies(rootCall = true, state) {
        let items = [];
        if (this.who !== undefined) {
            items.push(this.who);
        }
        if (this.what !== undefined) {
            items.push(this.what);
        }

        if (rootCall) {
            return getDependencySet(items);
        }

        return items;
    }

    isClone() {
        return this.isClonedInstance;
    }

    setIsClone() {
        this.isClonedInstance = true;
    }

    getTitle(state) {
        let records = searchQuery(
            state.Organizations,
            (() => {
                // we want to query orgs and courses at *any* depth
                // easiest way to do that is recursion
                // this defines a recursive query that searches for orgs under orgs ad infinium
                // it searches for courses off of each org

                // this works because when records are no longer found,
                // the query will cleanly terminate and return the result

                let orgNode = {
                    get: 'getChildren',
                    items: [],
                    return: true,
                };
                let courseNode = {
                    get: 'getCourses',
                    return: true,
                };

                orgNode.items.push(orgNode);
                orgNode.items.push(courseNode);

                let query = [orgNode, courseNode];

                return query;
            })(),
            [this.getWhatId()]);

        let who = getPersonById(state, this.getWhoId());
        let what = undefined;
        let nature = "n/a";
        if (Array.isArray(records) && records.length === 1) {
            what = records[0];

            if (what !== undefined) {
                switch (this.getType()) {
                    case AssociationTypes.Faculty:
                        nature = "works for";
                        break;
                    case AssociationTypes.Instructor:
                        if (what.constructor.ClassName() === Organization.ClassName()) {
                            nature = "works for";
                        } else {
                            nature = "teaches";
                        }
                        break;
                    case AssociationTypes.Student:
                        if (what.constructor.ClassName() === Organization.ClassName()) {
                            nature = "enrolled at";
                        } else {
                            nature = "enrolled in";
                        }
                        break;
                }
            }
        }

        return (who !== undefined ? who.getTitle(false) : "n/a") + " " + nature + " " + (what !== undefined ? what.getTitle() : "n/a");
    }

    getIcon() {
        return <LinkIcon />;
    }

    getSelectedIcon() {
        return this.getIcon();
    }

    getId() {
        return this.id;
    }

    setId(id) {
        this.id = id;
    }

    getType() {
        return this.type;
    }

    setType(associationType) {
        this.type = associationType;
    }

    getWhoId() {
        return this.who;
    }

    setWhoId(whoId) {
        this.who = whoId;
    }

    getWhatId() {
        return this.what;
    }

    setWhatId(whatId) {
        this.what = whatId;
    }

    clone() {
        let clone = new RoleAssociation().initCreate(this.getWhoId(), this.getType(), this.getWhatId());
        clone.setId(this.getId());

        return clone;
    }

    getJson() {
        return {
            id: this.id,
            type: this.type,
            who: this.who,
            what: this.what,
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "RoleAssociation";
    }
}