import Dependency from "../Classes/Dependency";

export function jsonifyArray(items, alwaysArray = true) {
    if (!Array.isArray(items)) return (alwaysArray ? [] : '');

    let result = [];

    for (let i = 0; i < items.length; i++) {
        if (items[i].getJson !== undefined) {
            result.push(items[i].getJson());
        } else if (items[i].getParent === undefined) {
            if (typeof items[i] !== 'object') {
                result.push(items[i]);
            } else {
                result.push(JSON.stringify(items[i]));
            }
        }
    }

    return result;
}

export function jsonifyItem(item) {
    let result = '';
    if (item !== undefined) {
        if (item.getJson !== undefined) {
            result = item.getJson();
        } else if (typeof item === 'object' ||
            typeof item === 'function') {
            result = JSON.stringify(item);
        } else {
            result = item;
        }
    }

    return result;
}

// compares two types to each other in a manner to prevent crashes
export function safeSameType(a, b) {
    let tA = safeGetType(a);
    let tB = safeGetType(b);

    return tA === tB;
}

export function safeGetType(a) {
    let outcome = undefined;

    if (typeof a !== 'object') {
        outcome = typeof a;
    } else {
        if (a.constructor.ClassName !== undefined) {
            outcome = a.constructor.ClassName();
        } else {
            outcome = a.constructor.name;
        }
    }

    return outcome;
}

export function safeIsType(a, typeName) {
    if (typeof typeName !== 'string') return false;
    let tA = safeGetType(a);

    return tA === typeName;
}

// returns an array of dependencies
export function getDependencySet(set) {
    let items = set.sort((a, b) => {
        // output explanation:
        // -1 = a first
        // 0 = no change
        // 1 = b first

        // default to tie (no change)
        let outcome = 0;

        // first check strings
        if (typeof a === 'string') {
            // if a is a string then check b
            if (typeof b === 'string') {
                // both are strings, no change
                outcome = 0;
            } else {
                // b is not a string, it comes first
                outcome = 1;
            }
        } else if (typeof b === 'string') {
            // a is not, but b is, a comes first
            outcome = -1;
        } else {
            // if we get here then we are confident that neither a nor b is a string
            // test class
            if (a.constructor.ClassName === undefined) {
                if (b.constructor.ClassName === undefined) {
                    // neither class has a classname function.  no change
                    outcome = 0;
                } else {
                    // a has no classname, but b does.  check if b is a dependency
                    if (b.constructor.ClassName() === Dependency.ClassName()) {
                        // if so, b comes first
                        outcome = 1;
                    } else {
                        // if not, no change
                        outcome = 0;
                    }
                }
            } else {
                if (b.constructor.ClassName === undefined) {
                    // a has a classname, but b doesn't. a first.
                    outcome = -1;
                } else {
                    // both have classnames.  check if either is a dependency
                    if (a.constructor.ClassName() === Dependency.ClassName()) {
                        if (b.constructor.ClassName() === Dependency.ClassName()) {
                            // both a and b are dependencies.  no change.
                            outcome = 0;
                        } else {
                            // a is a dependency, but b isn't.  a comes first.
                            outcome = -1;
                        }
                    } else {
                        if (b.constructor.ClassName() === Dependency.ClassName()) {
                            // a is not a dependency, but b is.  b comes first.
                            outcome = 1;
                        } else {
                            // neither is a dependency.  no change.
                            outcome = 0;
                        }
                    }
                }
            }
        }

        return outcome;
    });

    let results = [];
    if (Array.isArray(items)) {
        let getProper = (type) => {
            for (let i = 0; i < results.length; i++) {
                if (results[i].getType() === type) {
                    return results[i];
                }
            }

            return undefined;
        }

        for (let i = 0; i < items.length; i++) {
            if (safeIsType(items[i], Dependency.ClassName())) {
                results.push(items[i]);
                continue;
            }

            let target = getProper(safeGetType(items[i]));
            if (target !== undefined) {
                target.addNonDuplicate(items[i]);
            } else {
                results.push(new Dependency(safeGetType(items[i]), [items[i]]));
            }
        }
    } else {
        results.push(new Dependency(safeGetType(items), [items]));
    }

    return results;
}

export function getDependencyListing(obj, state) {
    if (obj.getDependencies === undefined) return [];

    let dependencies = obj.getDependencies(true, state);
    if (dependencies.length === 0) return [];

    let dependencyListing = [];
    for (let i = 0; i < dependencies.length; i++) {
        dependencyListing.push(...dependencies[i].getItems());
    }

    return dependencyListing;
}

// returns the unique items in an array
export function getUniqueElements(items) {
    return items.filter((value, index, array) => {
        return array.indexOf(value) === index;
    });
}

// returns true if the child is immediately under the parent
// determines this by calling the provided prop and/func and checking the expected array result for the given item by id
// uses the getId function if present, or the id property
export function isImmediateChild(parent, child, propName, funcName) {
    let result = false;
    const getId = (obj) => {
        let id = undefined;
        if (obj !== undefined &&
            obj !== null) {
            id = obj.getId !== undefined ? obj.getId() : obj.id;
        }

        return id;
    }

    if (child !== undefined &&
        parent !== undefined) {
        let childId = getId(child);

        if (childId !== undefined) {
            if (propName !== undefined &&
                propName !== null &&
                typeof propName === 'string') {
                let items = parent[propName];
                let queryResult = items.filter((item) => {
                    return getId(item) === childId;
                }).length > 0;

                if (queryResult === true) {
                    result = true;
                }
            }

            if (result === false &&
                funcName !== undefined &&
                funcName !== null &&
                typeof funcName === 'string') {
                let items = parent[funcName]();
                let queryResult = items.filter((item) => {
                    return getId(item) === childId;
                }).length > 0;

                if (queryResult === true) {
                    result = true;
                }
            }
        }
    }

    return result;
}

// Determines if the passed element is overflowing its bounds,
// either vertically or horizontally.
// Will temporarily modify the "overflow" style to detect this
// if necessary.
export function checkOverflow(el) {
    var curOverflow = el.style.overflow;

    if (!curOverflow || curOverflow === "visible")
        el.style.overflow = "hidden";

    var isOverflowing = el.clientWidth < el.scrollWidth
        || el.clientHeight < el.scrollHeight;

    el.style.overflow = curOverflow;

    return isOverflowing;
}