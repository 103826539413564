import React, { useContext } from 'react';

import { QuizzicalContext, ScreenModes } from '../Context/QuizzicalContext';
import DataImportManager from '../Controls/DataManagement/DataImportManager';

export default function ImportScreen(props) {
    const state = useContext(QuizzicalContext);

    return (
        <DataImportManager />
    );
}