export function getResponsibleParent(obj) {
    let target = obj;
    while (["Subject", "FillInQuestion", "MultipleChoiceQuestion", "PoolQuestion"].includes(target.constructor.ClassName()) === false) {
        if (target.getParent() !== undefined) {
            target = target.getParent();
        } else {
            break;
        }
    }

    return target;
}