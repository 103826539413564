import * as React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import { randomId } from '@mui/x-data-grid-generator';
import { useTheme } from '@mui/material/styles';

import ClearIcon from '@mui/icons-material/Clear';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import ConfirmationDialog from '../../Screens/Dialogs/ConfirmationDialog';

import { LayoutRegion } from '../../Classes/Layout/LayoutRegion';
import Subject from '../../Classes/Subjects';
import { FillInQuestion, MultipleChoiceQuestion } from '../../Classes/Questions';
import InsertableJavascriptEditor from '../InsertableJavascriptEditor';

export default function ScriptContentEditor(props) {
    const [entity, setEntity] = React.useState(props.entity); // this is the subject / question that this manager ultimately resides on
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [isMouseOver, setIsMouseOver] = React.useState(props.isMouseOver || false);
    const [rootRef, setRootRef] = React.useState(null);

    const [isDeleteRequested, setIsDeleteRequested] = React.useState(false);

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }

        if (props.isMouseOver !== undefined &&
            props.isMouseOver !== null &&
            props.isMouseOver !== isMouseOver) {
            setIsMouseOver(props.isMouseOver);
        }

        if (props.entity !== undefined &&
            props.entity !== null &&
            props.entity !== entity) {
            setEntity(props.entity);
        }
    }, [props]);

    const getTargetEntity = () => {
        let target = focus;
        while ([Subject.ClassName(), FillInQuestion.ClassName(), MultipleChoiceQuestion.ClassName()]
            .includes((target = target.getParent()).constructor.ClassName()) === false) {
            if (target.getParent() === undefined) {
                break;
            }
        }

        return target;
    }

    let display = (<div />);
    if (focus !== undefined &&
        focus.constructor.ClassName() === LayoutRegion.ClassName()) {
        if (isReadOnly) {
            display = (
                <div
                    style={{
                        fontFamily: 'inherit',
                        backgroundColor: 'transparent',
                        height: '100%',
                        width: '100%',
                        overflow: 'scroll',
                        whiteSpace: 'nowrap',
                    }}>
                    {focus.getContent() || "No content"}
                </div>
            );
        } else {
            display = (
                <Box
                    sx={{
                        marginTop: '4px',
                        position: 'relative',
                        textAlign: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: 'calc(100% - 6px)',
                        bgcolor: 'red',
                    }}
                    ref={newRef => setRootRef(newRef)}>
                    {
                        rootRef !== undefined && rootRef !== null ?
                            <InsertableJavascriptEditor
                                height={rootRef.clientHeight}
                                width='100%'
                                fabStyling={{
                                    position: 'absolute',
                                    top: -35,
                                    right: 16,
                                }}
                                focus={focus}
                                source={getTargetEntity()}
                                isReadOnly={isReadOnly}
                                isMouseOver={true}
                                setter="setContent"
                                getter="getContent" /> : ''
                    }
                    <ConfirmationDialog
                        bucket={undefined}
                        isOpen={isDeleteRequested}
                        title={"Deletion Confirmation"}
                        message={"Permanently Delete Region?"}
                        cancelText={"No"}
                        confirmText={"Yes"}
                        onCancel={() => { setIsDeleteRequested(false); }}
                        onConfirm={(e) => { props.onRemove(e, focus); }} />
                </Box>
            );
        }
    }

    return display;
}