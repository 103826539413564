import { Address } from "../Classes/Personnel/Address";
import { Organization } from "../Classes/Personnel/Organization";

export function getPoolById(state, id) {
    let pool = undefined;
    for (let i = 0; i < state.Examinations.Pools.length; i++) {
        if (state.Examinations.Pools[i].getId() === id) {
            pool = state.Examinations.Pools[i];
            break;
        }
    }

    return pool;
}

export function getAssociationsByWho(state, id) {
    return state.Associations.filter((association) => {
        return association.getWhoId() === id;
    });
}

export function getOrganizationById(state, id) {
    let results = state.Organizations.filter((organization) => {
        return organization.getId() === id;
    });

    if (results.length > 0) {
        return results[0];
    } else {
        return undefined;
    }
}

export function getAddressById(state, id, frontFacing) {
    let match = undefined;

    let recursive = (item) => {
        if (match !== undefined) return;

        if (item.constructor.ClassName() === Address.ClassName()) {
            if (item.getId() === id) {
                if (frontFacing === true) {
                    if (item.getPublicFacing() === true) {
                        match = item;
                    }
                } else {
                    match = item;
                }
            }
        } else if (item.constructor.ClassName() === Organization.ClassName()) {
            for (let i = 0; i < item.getAddresses().length; i++) {
                recursive(item.getAddresses()[i]);
            }

            // child orgs
            for (let i = 0; i < item.getChildren().length; i++) {
                recursive(item.getChildren()[i]);
            }
        }
    };

    for (let i = 0; i < state.Organizations.length; i++) {
        if (match !== undefined) break;

        recursive(state.Organizations[i]);
    }

    return match;
}

export function getPersonById(state, id) {
    let p = undefined;

    for (let i = 0; i < state.People.length; i++) {
        if (state.People[i].getId() === id) {
            p = state.People[i];
            break;
        }
    }

    return p;
}

export function getTargetById(state, fullId) {
    // searches the provided org's immediate children for a match
    let searchForOrg = (org, id) => {
        let childOrg = undefined;

        for (let i = 0; i < org.getChildren().length; i++) {
            if (org.getChildren()[i].getId() === id) {
                childOrg = org.getChildren()[i];
                break;
            }
        }

        return childOrg;
    }

    // searches the provided org's immediate courses for a match
    let searchForCourse = (org, id) => {
        let course = undefined;

        for (let i = 0; i < org.getCourses().length; i++) {
            if (org.getCourses()[i].getId() === id) {
                course = org.getCourses()[i];
                break;
            }
        }

        return course;
    }

    // function body starts
    if (fullId === undefined) return undefined;
    let result = undefined;

    // targets (the other half of an association) can only be Organizations and Courses
    // organizations can be root or child (at any depth)
    let idParts = fullId.split('-');

    // first part should be an org
    let currentOrg = getOrganizationById(state, idParts.shift());
    if (currentOrg !== undefined) {
        if (currentOrg.getId() === fullId) {
            result = currentOrg;
        } else {
            // recursion time!
            let recursive = (org, keyParts) => {
                let typeKey = keyParts.shift();
                let idKey = undefined;
                let temp = undefined;

                switch (typeKey) {
                    case 'clss':
                        idKey = keyParts.shift();
                        temp = searchForCourse(org, fullId);

                        if (temp !== undefined &&
                            temp.getId() === fullId) {
                            return temp;
                        }
                        break;
                    case 'org':
                        idKey = keyParts.shift();
                        temp = searchForOrg(org, fullId);

                        if (temp !== undefined &&
                            temp.getId() === fullId) {
                            return temp;
                        } else {
                            temp = recursive(temp, keyParts);
                        }
                        break;
                    default:
                        return undefined;
                }
            }

            recursive(currentOrg, idParts);
        }
    }

    return result;
}

export function getOrganizationAddressTree(state, rootOrgId) {
    let isParentedBy = (item, orgId) => {
        if (orgId === undefined) return false;
        if (item === undefined) return false;

        let outcome = item.getParent().getId() === orgId;
        if (!outcome) {
            let org = item.getParent();

            do {
                outcome = org.constructor.ClassName() === Organization.ClassName() && org.getId() === orgId;
                if (outcome) {
                    break;
                } else {
                    if (org.getParent() === undefined) {
                        break;
                    } else {
                        org = org.getParent();
                    }
                }
            } while (true);
        }

        return outcome;
    }

    // the 'tree' is the hierarchical reference to all public facing addresses defined in the system
    let items = state.Organizations.map((org, i) => {
        let addresses = [];
        // loop through the organization root organization and get all child org's addresses.
        // we only group by root org.
        // this will be handled when displaying data by giving the org it directly belongs to
        let recursive = (item, addresses) => {
            if (item.constructor.ClassName() === Address.ClassName()) {
                if (item.getPublicFacing() === true || isParentedBy(item, rootOrgId)) {
                    addresses.push(item);
                }
            } else if (item.constructor.ClassName() === Organization.ClassName()) {
                for (let i = 0; i < item.getAddresses().length; i++) {
                    recursive(item.getAddresses()[i], addresses);
                }

                // child orgs
                for (let i = 0; i < item.getChildren().length; i++) {
                    recursive(item.getChildren()[i], addresses);
                }
            }
        };

        // run the recursive against the provided root org...
        recursive(org, addresses);

        // we now have all of the addresses that should be displayed and the item they should be dispalyed under (the root org)
        // add the branch to the tree
        return { root: org, items: addresses };
    });

    return items;
}

export function getOrganizationTargetsTree(state) {
    // potential targets: courses and organizations
    // tree: full structure of all of them

    let getBranch = (org) => {
        let courses = [];
        let limbs = [];

        for (let i = 0; i < org.getCourses().length; i++) {
            courses.push(org.getCourses()[i]);
        }

        for (let i = 0; i < org.getChildren().length; i++) {
            limbs.push(getBranch(org.getChildren()[i]));
        }

        // we now have all of the courses that should be displayed and the item they should be dispalyed under (the root org)
        // add the branch to the tree
        return { root: org, items: courses, limbs: limbs };
    }

    let branches = state.Organizations.map((org) => {
        return getBranch(org);
    });

    return branches;
}