import ClassName from "./ClassName";

export default class Dependency extends ClassName {
    constructor(type, items) {
        super();
        this.type = type;
        if (Array.isArray(items)) {
            this.items = [...items];
        } else {
            this.items = [items];
        }
    }

    getType() {
        return this.type;
    }

    getItems() {
        return this.items;
    }

    addNonDuplicate(item) {
        if (this.getItems().filter((o) => {
            let id = (o.getId === undefined ? o : o.getId());
            let itemId = (item.getId === undefined ? item : item.getId());
            return id === itemId;
        }).length === 0) {
            this.getItems().push(item);
        }
    }

    static ClassName() {
        return "Dependency";
    }
}