import { decompressFromUTF16 as lzStringDecompress } from 'lz-string';

// contains and manages uploaded replays (for replay)
export default class ReplayContainer {
    constructor(replay) {
        let replays = lzStringDecompress(replay.content);
        let json = JSON.parse(replays);
        this.records = json.replays;
        this.key = json.lessonKey;
        this.count = this.records.length;
        this.file = replay.file;
    }

    getRecords() {
        return this.records;
    }

    getKey() {
        return this.key;
    }

    getCount() {
        return this.count;
    }

    getTitle(state) {
        return this.file;
    }

    clone() {
        // this gets called in the presentation dialog to filter off selections,
        // but you always play all loaded replays, so this just returns the unaltered replay.
        return this;
    }
}