import * as React from 'react';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ChatBubbleOutlinedIcon from '@mui/icons-material/ChatBubbleOutlined';

import insertTextAtCursor from 'insert-text-at-cursor';
import Subject from '../../Classes/Subjects';

export default function TermInsertion(props) {
    const [entity, setEntity] = React.useState(props.entity); // this is the subject / question that this manager ultimately resides on
    const [target, setTarget] = React.useState(props.target); // this is the textfield control to modify
    const [isVisible, setIsVisible] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [expandVariables, setExpandVariables] = React.useState(false);
    const [expandParameters, setExpandParameters] = React.useState(false);

    React.useEffect(() => {
        if (props.entity !== undefined &&
            props.entity !== null &&
            props.entity !== entity) {
            setEntity(props.entity);
        }

        if (props.target !== undefined &&
            props.target !== null &&
            props.target !== target) {
            setTarget(props.target);
        }

        if (props.isVisible !== undefined &&
            props.isVisible !== null &&
            props.isVisible !== isVisible) {
            setIsVisible(props.isVisible);
        }
    }, [props]);

    const insertVariableSlug = (variable) => {
        let instance = entity.getVariables(true)
            .filter((v) => {
                return v.getId() === variable.getId();
            });
        if (instance.length === 1) {
            instance = instance[0];
        } else {
            instance = undefined;
        }

        if (target !== null && target !== undefined) {
            let specific = document.getElementById(target);
            if (specific !== undefined && insertTextAtCursor !== undefined) {
                insertTextAtCursor(specific, instance.getTitle());
            }
        } else if (props.onInsert !== undefined) {
            props.onInsert(instance.getTitle(), true);
        }
        setAnchorEl(null);
    }

    const insertParameterSlug = (parameter) => {
        let instance = entity.getParameters(true)
            .filter((p) => {
                return p.getId() === parameter.getId();
            });
        if (instance.length === 1) {
            instance = instance[0];
        } else {
            instance = undefined;
        }

        if (target !== null && target !== undefined) {
            let specific = document.getElementById(target);
            if (specific !== undefined && insertTextAtCursor !== undefined) {
                insertTextAtCursor(specific, instance.getName());
            }
        } else if (props.onInsert !== undefined) {
            props.onInsert(instance.getName(), false);
        }
        setAnchorEl(null);
    }

    const getFullVariableSet = () => {
        let list = [];
        if (entity.getParent() !== undefined) {
            if (entity.getParent().constructor.ClassName() === Subject.ClassName()) {
                entity.getParent().getVariables().forEach((v) => {
                    list.push({ obj: v, mine: false });
                });
            }
        }
        entity.getVariables().forEach((v) => {
            list.push({ obj: v, mine: true });
        });

        return list;
    }

    const getFullParameterSet = () => {
        let list = [];
        if (entity.getParent() !== undefined) {
            if (entity.getParent().constructor.ClassName() === Subject.ClassName()) {
                entity.getParent().getParameters().forEach((p) => {
                    list.push({ obj: p, mine: false });
                });
            }
        }
        entity.getParameters().forEach((p) => {
            list.push({ obj: p, mine: true });
        });

        return list;
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (entity !== undefined &&
        isVisible === true) {
        let variablesMenu = getFullVariableSet().map((variable, i) => {
            let icon = (<ChatBubbleOutlineIcon color={variable.mine === true ? "inherit" : "secondary"} />);

            return (
                <ListItem
                    key={"variable-" + variable.obj.getId()}
                    disablePadding>
                    <ListItemButton
                        onClick={(e) => { insertVariableSlug(variable.obj); }} >
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText>{variable.obj.getTitle()}</ListItemText>
                    </ListItemButton>
                </ListItem>
            );
        });

        let parametersMenu = getFullParameterSet().map((parameter, i) => {
            return (
                <ListItem
                    key={"parameter-" + parameter.obj.getId()}
                    disablePadding>
                    <ListItemButton
                        onClick={(e) => { insertParameterSlug(parameter.obj); }} >
                        <ListItemIcon>{parameter.obj.getIcon(parameter.mine === true ? "inherit" : "secondary")}</ListItemIcon>
                        <ListItemText>{parameter.obj.getName()}</ListItemText>
                    </ListItemButton>
                </ListItem>
            );
        }).filter((item) => { return item !== ""; });

        let insertablesContent = [];
        if (variablesMenu.length > 0 && parametersMenu.length > 0) {
            insertablesContent.push(
                <Stack>
                    <Button
                        key={"variable-root-collapser-button"}
                        variant='text'
                        color="inherit"
                        sx={{
                            minWidth: '300px',
                            width: '100%',
                            textTransform: 'none'
                        }}
                        onClick={(e) => { setExpandVariables(!expandVariables); }}>
                        <Typography color="inherit" variant="h6" component="div" style={{ textAlign: 'center' }} sx={{ flexGrow: 1 }}>Variables</Typography>
                        {expandVariables ? <ExpandLess /> : <ExpandMore />}
                    </Button>
                    <Collapse
                        key={"variable-root-collapser"}
                        component="li"
                        timeout="auto"
                        in={expandVariables} unmountOnExit>
                        <List>
                            {variablesMenu}
                        </List>
                    </Collapse>
                </Stack>
            );

            insertablesContent.push(
                <Stack>
                    <Button
                        key={"parameter-root-collapser-button"}
                        variant='text'
                        color="inherit"
                        sx={{
                            minWidth: '300px',
                            width: '100%',
                            textTransform: 'none'
                        }}
                        onClick={(e) => { setExpandParameters(!expandParameters); }}>
                        <Typography color="inherit" variant="h6" component="div" style={{ textAlign: 'center' }} sx={{ flexGrow: 1 }}>Parameters</Typography>
                        {expandParameters ? <ExpandLess /> : <ExpandMore />}
                    </Button>
                    <Collapse
                        key={"parameter-root-collapser"}
                        component="li"
                        timeout="auto"
                        in={expandParameters} unmountOnExit>
                        <List>
                            {parametersMenu}
                        </List>
                    </Collapse>
                </Stack>
            );
        } else {
            if (variablesMenu.length > 0) {
                insertablesContent = variablesMenu;
            } else if (parametersMenu.length > 0) {
                insertablesContent = parametersMenu;
            }
        }

        return (
            <div>
                <Zoom
                    id="fab-insertions-id"
                    key="fab-insertions"
                    in={isVisible}
                    unmountOnExit>
                    <Paper>
                        <Button
                            disabled={insertablesContent.length === 0}
                            onClick={handleClick}
                            size="small">
                            <EditLocationAltIcon />
                        </Button>
                    </Paper>
                </Zoom>
                <Popover
                    BackdropProps={{ invisible: false }}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    id={"fab-insertions-id"}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}>
                    <List
                        sx={{ width: '100%', height: '100vH', width: 600, bgcolor: 'background.paper' }}>
                        {insertablesContent}
                    </List>
                </Popover>
            </div >
        );

    } else {
        return "";
    }
}