import * as React from 'react';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Unstable_Grid2';

import LayoutRegionContainer from './LayoutRegionContainer';
import Subject from '../../Classes/Subjects';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function RegionLayoutEditor(props) {
    const [focus, setFocus] = React.useState(undefined); // the target LayoutRegionManager instance to edit
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [editingSubject, setEditingSubject] = React.useState(undefined);
    const [refreshRequired, setRefreshRequired] = React.useState(false);
    const [externalRefreshRequired, setExternalRefreshRequired] = React.useState(false);
    const [renderMosaic, setRenderMosaic] = React.useState(false);
    const [mergeSelections, setMergeSelections] = React.useState([]);
    const [currentCursoredRegion, setCurrentCursoredRegion] = React.useState(undefined); // used to track the mouse cursor from region to region

    React.useEffect(() => {
        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }

        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);

            if (props.focus !== undefined &&
                props.focus.getParent().constructor !== undefined &&
                props.focus.getParent().constructor.ClassName() === Subject.ClassName()) {
                setEditingSubject(true);
            } else if (props.focus !== undefined &&
                props.focus.getParent().getQuestionType !== undefined) {
                setEditingSubject(false);
            } else if (props.focus !== focus) {
                setEditingSubject(undefined);
            }
        }

        if (props.refreshRequired !== undefined &&
            props.refreshRequired !== null &&
            props.refreshRequired !== externalRefreshRequired) {
            setExternalRefreshRequired(!externalRefreshRequired);
        }

        if (props.asMosaic !== undefined &&
            props.asMosaic !== null &&
            props.asMosaic !== renderMosaic) {
            setRenderMosaic(props.asMosaic);
        }
    }, [props]);

    React.useEffect(() => {
        if (Array.isArray(mergeSelections) && mergeSelections.length === 2) {
            // determine the upper left most region of the two
            let r1 = mergeSelections[0];
            let r2 = mergeSelections[1];

            // all values are distances from the upper left corner (coordinates : {max, 0})
            let r1Dims = {
                leftX: Math.abs(focus.getWidth() - r1.getX()),
                leftY: r1.getY(),
                rightX: Math.abs(focus.getWidth() - (r1.getX() - r1.getRight())) - 1,
                rightY: r1.getY() + r1.getBottom(),
                rightCoordX: r1.getX() - r1.getRight(),
                rightCoordY: r1.getY() + r1.getBottom(),
            };
            let r2Dims = {
                leftX: Math.abs(focus.getWidth() - r2.getX()),
                leftY: r2.getY(),
                rightX: Math.abs(focus.getWidth() - (r2.getX() - r2.getRight())) - 1,
                rightY: r2.getY() + r2.getBottom(),
                rightCoordX: r2.getX() - r2.getRight(),
                rightCoordY: r2.getY() + r2.getBottom(),
            };

            // calculate
            let resultRectangle = undefined;
            {
                let x = r1Dims.leftX < r2Dims.leftX ? r1.getX() : r2.getX();
                let y = r1Dims.leftY < r2Dims.leftY ? r1.getY() : r2.getY();
                let right = r1Dims.rightX > r2Dims.rightX ? Math.abs(r1Dims.rightCoordX - x) : Math.abs(r2Dims.rightCoordX - x);
                let bottom = r1Dims.leftY > r2Dims.leftY ? r1Dims.rightCoordY - y : r2Dims.rightCoordY - y;

                resultRectangle = { x: x, y: y, right: right, bottom: bottom };
            }

            // initiate the merge process
            let finalDims = {
                xDif: resultRectangle.x < r2.getX() ? r2.getX() - resultRectangle.x : resultRectangle.x - r2.getX(),
                yDif: resultRectangle.y < r2.getY() ? resultRectangle.y - r2.getY() : r2.getY() - resultRectangle.y,
                rightDif: resultRectangle.right < r2.getRight() ? r2.getRight() - resultRectangle.right : resultRectangle.right - r2.getRight(),
                bottomDif: resultRectangle.bottom < r2.getBottom() ? r2.getBottom() - resultRectangle.bottom : resultRectangle.bottom - r2.getBottom(),
            };

            r2.shiftX(finalDims.xDif);
            r2.shiftY(finalDims.yDif);
            r2.shiftRight(finalDims.rightDif);
            r2.shiftBottom(finalDims.bottomDif);
            focus.done();

            setMergeSelections([r2]);
            setRefreshRequired(!refreshRequired);
        }
    }, [mergeSelections]);

    React.useEffect(() => {
        // this is to force a refresh
    }, [focus, currentCursoredRegion])

    const onRegionGainedCursor = (gainingRegionIdKey) => {
        if (currentCursoredRegion !== setCurrentCursoredRegion) {
            setCurrentCursoredRegion(gainingRegionIdKey);
        }
    }

    const getXPcntY = (x, y) => {
        return (x / y) * 100;
    }

    const onSelectionStateChanged = (region, mergeSelectionState) => {
        if (mergeSelectionState === true) {
            let result = [...mergeSelections];
            if (mergeSelections.length <= 1) {
                result.push(region);
                setMergeSelections(result);
            }
        } else {
            let result = [...mergeSelections];
            let index = result.indexOf(region);
            if (index > -1) {
                result.splice(result.indexOf(region), 1);
                setMergeSelections(result);
            }
        }
    }

    let display = (<div />);
    if (editingSubject !== undefined && focus !== undefined) {
        // build the layout described by the manager using floating divs
        let regionMap = focus.getRegions().map((r, i) => {
            let top = getXPcntY(r.getY(), focus.getHeight()) + "%";
            let right = getXPcntY(r.getX(), focus.getWidth()) - getXPcntY(r.getRight(), focus.getWidth()) + "%";
            let width = getXPcntY(r.getRight() + 1, focus.getWidth()) + "%";
            let height = getXPcntY(r.getBottom() + 1, focus.getHeight()) + "%";

            return <LayoutRegionContainer
                onMousedInto={onRegionGainedCursor}
                innerId={"region-container-" + r.getX() + "-" + r.getY()}
                asMosaic={renderMosaic}
                focus={r}
                key={"regionMap-" + r.getId()}
                onRefreshRequired={(e) => { setRefreshRequired(!refreshRequired); }}
                onSelectedChanged={onSelectionStateChanged}
                selected={mergeSelections.indexOf(r) > -1}
                proportions={{ top: top, right: right, width: width, height: height, maxWidth: focus.getWidth(), maxHeight: focus.getHeight() }}
                sx={{
                    position: 'absolute',
                    right: right,
                    top: top,
                    width: width,
                    height: height,
                }} />;
        });

        return (
            <Grid
                sx={props.sx}
                container>
                {regionMap}
            </Grid>
        );
    } else {
        return display;
    }
}