import { HierarchicalItemBase, HierarchicalItemTypes } from './BaseClassHierarchy';
import { shuffle } from '../Libraries/Ordering';
import { LayoutRegionManager } from './Layout/LayoutRegionManager';
import Dependency from './Dependency';

// fill in question icons
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import FormatPaintOutlinedIcon from '@mui/icons-material/FormatPaintOutlined';

// multiple choice
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';

// pool question
import WifiFindIcon from '@mui/icons-material/WifiFind';
import WifiFindOutlinedIcon from '@mui/icons-material/WifiFindOutlined';
import { jsonifyArray } from '../Libraries/generalUtility';
import {
    RandomizedIntegerParameter,
    RandomizedDecimalParameter,
    NumericalSequenceParameter,
    SequencialParameter,
    PoolParameter
} from './Parameters';
import VariableDefinition from './VariableDefinition';
import ScriptItem from './ScriptItem';
import QuestionChoiceDefinition from './QuestionChoiceDefinition';
import { StepResult } from './Stepping';
import Subject from './Subjects';
import runScript from '../Libraries/runScript';
import { LogEntryType, LogHost } from './Resolution/ScriptExecutionLog';

export const QuestionTypes = {
    Unknown: 0,
    FillIn: 1,
    MultipleChoice: 2,
    Pool: 3,
};

///
///  Assignment -> Section -> Subject -> Question
///
class QuestionBase extends HierarchicalItemBase {
    constructor() {
        super();

        this.parameters = [];
        this.variables = [];

        this.description = [
            new LayoutRegionManager().initCreate(this, 1, 2)
        ];

        this.expectedFormat = undefined;
        this.simpleQuestion = undefined;
        this.simpleAnswer = undefined;
        this.currentLayoutIndex = 0;
    }

    ensureLayoutTitles() {
        if (this.description !== undefined) {
            let buildTitle = (index) => {
                let alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

                let title = '';
                let i = index;
                do {
                    if (i < alphabet.length) {
                        title += alphabet[i];
                        break;
                    } else {
                        i -= alphabet.length;
                        title += alphabet[alphabet.length - 1];
                    }
                }
                while (i >= alphabet);

                return title;
            }

            for (let i = 0; i < this.description.length; i++) {
                if (['', undefined].includes(this.description[i].getTitle()?.trim())) {
                    let title = buildTitle(i);
                    this.description[i].setTitle(title);
                }
            }
        }
    }

    getNextLayoutTitle() {
        let index = this.description.length;
        let alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

        let title = '';
        let i = index;
        do {
            if (i < alphabet.length) {
                title += alphabet[i];
                break;
            } else {
                i -= alphabet.length;
                title += alphabet[alphabet.length - 1];
            }
        }
        while (i >= alphabet);

        return title;
    }

    getExpectedFormat() {
        return this.expectedFormat;
    }

    setExpectedFormat(script) {
        this.expectedFormat = script;
    }

    getSimpleQuestion() {
        return this.simpleQuestion;
    }

    setSimpleQuestion(script) {
        this.simpleQuestion = script;
    }

    getSimpleAnswer() {
        return this.simpleAnswer;
    }

    setSimpleAnswer(script) {
        this.simpleAnswer = script;
    }

    initCreate(parentItem, icon, selectedIcon, title, duration, dwell, topics) {
        super.initCreate(parentItem, icon, selectedIcon, topics, HierarchicalItemTypes.Questions, title, "");

        this.duration = duration;
        this.dwell = dwell;
        this.parameters = [];
        this.variables = [];
        this.expectedFormat = undefined;
        this.simpleQuestion = undefined;
        this.simpleAnswer = undefined;

        this.description = [
            new LayoutRegionManager().initCreate(this, 1, 2)
        ];
        this.ensureLayoutTitles();

        return this;
    }

    initJson(parent, struct) {
        try {
            super.initJson(parent, struct);

            this.duration = struct.duration;
            this.dwell = struct.dwell;
            this.parameters = struct.parameters.map((parameter, i) => {
                let outcome = undefined;
                switch (parameter._type) {
                    case 'RandomizedIntegerParameter':
                        outcome = new RandomizedIntegerParameter().initJson(this, parameter);
                        break;
                    case 'RandomizedDecimalParameter':
                        outcome = new RandomizedDecimalParameter().initJson(this, parameter);
                        break;
                    case 'NumericalSequenceParameter':
                        outcome = new NumericalSequenceParameter().initJson(this, parameter);
                        break;
                    case 'SequencialParameter':
                        outcome = new SequencialParameter().initJson(this, parameter);
                        break;
                    case 'PoolParameter':
                        outcome = new PoolParameter().initJson(this, parameter);
                        break;
                }

                return outcome;
            });
            this.variables = struct.variables.map((variable, i) => {
                return new VariableDefinition().initJson(this, variable);
            });

            if (struct.description !== undefined) {
                if (Array.isArray(struct.description)) {
                    this.description = [];
                    for (let i = 0; i < struct.description.length; i++) {
                        this.description.push(new LayoutRegionManager().initJson(this, struct.description[i]));
                    }
                } else {
                    this.description = [
                        new LayoutRegionManager().initJson(this, struct.description)
                    ];
                }
                this.ensureLayoutTitles();
            }

            this.expectedFormat = struct.expectedFormat;
            this.simpleQuestion = struct.simpleQuestion;
            this.simpleAnswer = struct.simpleAnswer;

            return this;
        } catch (ex) {
            LogHost.post(this.id, "Failed to load question", LogEntryType.Error)
                .reason(ex);
            return this;
        }
    }

    setDescription = (layouts) => {
        if (Array.isArray(layouts)) {
            this.description = layouts;
        }
    }

    getVariables(includeSubject = false) {
        let outcome = [...this.variables];
        if (includeSubject === true) {
            if (this.getParent().constructor.ClassName() === Subject.ClassName()) {
                outcome = this.getParent().getVariables().concat(outcome);
            }
        }

        return outcome;
    }

    setVariables(variables) {
        if (Array.isArray(variables)) {
            this.variables = variables;
        }
    }

    getParameters(includeSubject = false) {
        let outcome = [...this.parameters];
        if (includeSubject === true) {
            if (this.getParent().constructor.ClassName() === Subject.ClassName()) {
                outcome = this.getParent().getParameters().concat(outcome);
            }
        }

        return outcome;
    }

    setParameters(parameters) {
        if (Array.isArray(parameters)) {
            this.parameters = parameters;
        }
    }

    getQuestionType() {
        return QuestionTypes.Unknown;
    }

    getDuration() {
        return this.duration;
    }

    setDuration(duration) {
        this.duration = parseInt(duration + "");
    }

    getDwell() {
        return this.dwell;
    }

    setDwell(dwell) {
        this.dwell = parseInt(dwell + "");
    }

    getJson() {
        return {
            ...super.getJson(),
            duration: this.duration,
            dwell: this.dwell,
            parameters: jsonifyArray(this.parameters),
            variables: jsonifyArray(this.variables),
            description: (() => {
                let outcome = undefined;
                if (Array.isArray(this.description)) {
                    outcome = jsonifyArray(this.description);
                } else {
                    outcome = this.description !== undefined ? [this.description.getJson()] : [];
                }

                return outcome;
            })(),
            expectedFormat: this.expectedFormat,
            simpleQuestion: this.simpleQuestion,
            simpleAnswer: this.simpleAnswer,
            _type: this.constructor.ClassName(),
        };
    }

    getCurrent() {
        // this returns a big nothing because it is the base class and will never be called in any context
    }

    step(executionIndex) {
        let failures = this.parameters.filter((p) => {
            return p.step(executionIndex) === StepResult.Failure;
        });

        return failures.length > 0 ? StepResult.Failure : StepResult.Stepped;
    }

    update(executionIndex) {
        for (let i = 0; i < this.parameters.length; i++) {
            this.parameters[i].update(executionIndex);
        }
    }

    static ClassName() {
        return "QuestionBase";
    }
}

export class FillInQuestion extends QuestionBase {
    constructor() {
        super();

        this.preScripts = [];
        this.postScripts = [];
    }

    initCreate(parentItem, icon, selectedIcon, title, duration, dwell, topics) {
        super.initCreate(parentItem, icon, selectedIcon, title, duration, dwell, topics);

        //this.answer = new LayoutRegionManager().initCreate(this, 2, 2);
        this.preScripts = [];
        this.postScripts = [];

        return this;
    }

    initJson(parent, struct) {
        try {
            super.initJson(parent, struct);

            this.icon = <FormatPaintOutlinedIcon />;
            this.selectedIcon = <FormatPaintIcon />;

            this.preScripts = struct.preScripts.map((script, i) => {
                return new ScriptItem().initJson(this, script);
            });
            this.postScripts = struct.postScripts.map((script, i) => {
                return new ScriptItem().initJson(this, script);
            });

            return this;
        } catch (ex) {
            LogHost.post(this.id, "Fill in question failed to load from JSON", LogEntryType.Error)
                .reason(ex);
            return this;
        }
    }

    static getDefault(parent, title, duration, dwell) {
        return new FillInQuestion().initCreate(parent, <FormatPaintOutlinedIcon />, <FormatPaintIcon />, title, duration, dwell, []);
    }

    getPreScripts() {
        return this.preScripts;
    }

    setPreScripts(scripts) {
        if (Array.isArray(scripts)) {
            this.preScripts = scripts;
        }
    }

    getPostScripts() {
        return this.postScripts;
    }

    setPostScripts(scripts) {
        if (Array.isArray(scripts)) {
            this.postScripts = scripts;
        }
    }

    getQuestionType() {
        return QuestionTypes.FillIn;
    }

    /* getAnswer() {
        return this.answer;
    } */

    clone() {
        let clone = new FillInQuestion().initCreate(this.parent, this.icon, this.selectedIcon, this.title, this.duration, this.dwell, [...this.topics]);
        clone.setId(this.getId());

        clone.getDescription().length = 0;
        for (let i = 0; i < this.description.length; i++) {
            clone.getDescription().push(this.description[i].clone());
        }
        //clone.getDescription().cloneFrom(this.getDescription());

        let variables = [];
        for (let i = 0; i < this.variables.length; i++) {
            variables.push(this.variables[i].clone());
        }
        clone.setVariables(variables);
        //clone.getAnswer().cloneFrom(this.getAnswer());

        let parameters = [];
        for (let i = 0; i < this.parameters.length; i++) {
            parameters.push(this.parameters[i].clone());
        }
        clone.setParameters(parameters);

        let scripts = [];
        for (let i = 0; i < this.preScripts.length; i++) {
            scripts.push(this.preScripts[i].clone());
        }
        clone.setPreScripts(scripts);

        scripts = [];
        for (let i = 0; i < this.postScripts.length; i++) {
            scripts.push(this.postScripts[i].clone());
        }
        clone.setPostScripts(scripts);

        clone.setExpectedFormat(this.getExpectedFormat());
        clone.setSimpleAnswer(this.getSimpleAnswer());
        clone.setSimpleQuestion(this.getSimpleQuestion());

        return clone;
    }

    getJson() {
        return {
            ...super.getJson(),
            preScripts: jsonifyArray(this.preScripts),
            postScripts: jsonifyArray(this.postScripts),
            _type: this.constructor.ClassName(),
        };
    }

    generateVariableAndParameterArrays() {
        let parameters = this.parameters.map((p) => {
            return { name: p.getName(), value: p.getCurrent(this.id), instance: p };
        });
        let variables = this.variables.map((v) => {
            return { name: v.getTitle(), type: v.getDataType(), value: v.getValue(this.id), instance: v };
        });

        if (this.getParent().constructor.ClassName() === Subject.ClassName()) {
            this.getParent().getParameters().forEach((p) => {
                parameters.push({ name: p.getName(), value: p.getCurrent(this.id), instance: p });
            });
            this.getParent().getVariables().forEach((v) => {
                variables.push({ name: v.getTitle(), type: v.getDataType(), value: v.getValue(this.id), instance: v });
            });
        }

        return { parameters: parameters, variables: variables };
    }

    initStep(state) {
        let result = StepResult.Stepped;
        for (let Pi = 0; Pi < this.parameters.length; Pi++) {
            let stepResult = this.parameters[Pi].initStep();
            if (stepResult === StepResult.Failure) {
                result = StepResult.Failure;
            }
        }

        for (let Vi = 0; Vi < this.variables.length; Vi++) {
            this.variables[Vi].store();
        }

        this.currentLayoutIndex = 0;
    }

    getCurrent() {
        if (this.currentLayoutIndex === 0) {
            for (let i = 0; i < this.variables.length; i++) {
                if (this.variables[i].getParent().getId() === this.getId()) {
                    this.variables[i].reset(this.id);
                }
            }
        }

        let scope = this.generateVariableAndParameterArrays();

        // note about how scripts are stored for execution:
        // scripts are pulled from the question (pre / post) and then from the layout (pre / post)
        // they are stored, as json, for later execution in replays and executed prior to return of the snapshot

        // generate the replay
        let replay = {
            layout: this.description[this.currentLayoutIndex].getJson(),
            preScripts: jsonifyArray([
                ...(this.currentLayoutIndex === 0 ? this.preScripts : []),
                ...this.description[this.currentLayoutIndex].getPreScripts()
            ]),
            postScripts: jsonifyArray([
                ...this.description[this.currentLayoutIndex].getPostScripts(),
                ...(this.currentLayoutIndex === (this.description.length - 1) ? this.postScripts : [])
            ]),
            allCorrect: this.requireAllCorrect,
            parameters: jsonifyArray(scope.parameters.map((p) => {
                return { name: p.name, value: p.value };
            })),
            variables: jsonifyArray(scope.variables.map((v) => {
                return { name: v.name, value: v.value };
            })),
            items: [],
            shuffled: false,
            label: this.title,
            dwell: this.getDwell(),
            duration: this.getDuration(),
            hasSubject: this.getParent().constructor.ClassName() === 'Subject',
            sourceId: this.id,
        };

        // generate the displayed values

        // run all the pre scripts
        let scripts = [
            ...(this.currentLayoutIndex === 0 ? this.preScripts : []),
            ...this.description[this.currentLayoutIndex].getPreScripts()
        ];
        for (let i = 0; i < scripts.length; i++) {
            runScript(this.id, scripts[i].getContent(), scripts[i].getTitle(), scope.variables, scope.parameters);
        }

        // update the variables and parameters after the pre-scripts are run
        //scope = this.generateVariableAndParameterArrays();

        // generate the snapshot
        let displaySnapshot = {
            answerFormat: runScript(this.id, this.getExpectedFormat(), "Expected Format", scope.variables, scope.parameters),
            simpleAnswer: runScript(this.id, this.getSimpleAnswer(), "Simple Answer", scope.variables, scope.parameters),
            simpleQuestion: runScript(this.id, this.getSimpleQuestion(), "Simple Question", scope.variables, scope.parameters),
            layout: this.description[this.currentLayoutIndex],
            variables: [...scope.variables],
            parameters: [...scope.parameters],
            items: [],
            shuffled: false,
            label: this.title,
            dwell: this.getDwell(),
            duration: this.getDuration(),
            allCorrect: this.requireAllCorrect,
            hasSubject: this.getParent().constructor.ClassName() === 'Subject',
            sourceId: this.id,
        };

        // inject the helper values into the replay
        replay.answerFormat = runScript(this.id, this.getExpectedFormat(), "Expected Format", scope.variables, scope.parameters);
        replay.simpleAnswer = runScript(this.id, this.getSimpleAnswer(), "Simple Answer", scope.variables, scope.parameters);
        replay.simpleQuestion = runScript(this.id, this.getSimpleQuestion(), "Simple Question", scope.variables, scope.parameters);

        // run all the post scripts
        scripts = [
            ...this.description[this.currentLayoutIndex].getPostScripts(),
            ...(this.currentLayoutIndex === (this.description.length - 1) ? this.postScripts : [])
        ];
        for (let i = 0; i < scripts.length; i++) {
            runScript(this.id, scripts[i].getContent(), scripts[i].getTitle(), scope.variables, scope.parameters);
        }

        return [displaySnapshot, replay];
    }

    step(executionIndex) {
        let failures = [];
        let reset = false;

        this.currentLayoutIndex++;
        if (this.currentLayoutIndex >= this.description.length) {
            this.currentLayoutIndex = 0;
            reset = true;

            failures = this.parameters.filter((p) => {
                return p.step(executionIndex) === StepResult.Failure;
            });
        }

        return failures.length > 0 ? StepResult.Failure : (reset === false ? StepResult.SteppedWithMore : StepResult.Stepped);
    }

    update(executionIndex) {
        for (let Pi = 0; Pi < this.parameters.length; Pi++) {
            this.parameters[Pi].update(executionIndex);
        }
    }

    static ClassName() {
        return "FillInQuestion";
    }
}

export class MultipleChoiceQuestion extends QuestionBase {
    constructor() {
        super();

        this.items = [];
        this.preScripts = [];
        this.postScripts = [];
    }

    initCreate(parentItem, icon, selectedIcon, title, duration, dwell, shuffled, topics, requireAllCorrect) {
        super.initCreate(parentItem, icon, selectedIcon, title, duration, dwell, topics);

        this.shuffled = shuffled || false;
        this.items = [];
        this.requireAllCorrect = requireAllCorrect;
        this.preScripts = [];
        this.postScripts = [];

        return this;
    }

    initJson(parent, struct) {
        try {
            super.initJson(parent, struct);

            this.icon = <FactCheckOutlinedIcon />;
            this.selectedIcon = <FactCheckIcon />;

            this.shuffled = struct.shuffled;
            this.items = struct.items.map((item, i) => {
                return new QuestionChoiceDefinition().initJson(this, item);
            });
            this.requireAllCorrect = struct.requireAllCorrect;
            this.preScripts = struct.preScripts.map((script, i) => {
                return new ScriptItem().initJson(this, script);
            });
            this.postScripts = struct.postScripts.map((script, i) => {
                return new ScriptItem().initJson(this, script);
            });

            return this;
        } catch (ex) {
            LogHost.post(this.id, "Multiple choice question failed to load from JSON", LogEntryType.Error)
                .reason(ex);
            return this;
        }
    }

    static getDefault(parent, title, duration, dwell, isShuffled, requireAllCorrect) {
        return new MultipleChoiceQuestion().initCreate(parent, <FactCheckOutlinedIcon />, <FactCheckIcon />, title, duration, dwell, isShuffled, [], requireAllCorrect);
    }

    getPreScripts() {
        return this.preScripts;
    }

    setPreScripts(scripts) {
        if (Array.isArray(scripts)) {
            this.preScripts = scripts;
        }
    }

    getPostScripts() {
        return this.postScripts;
    }

    setPostScripts(scripts) {
        if (Array.isArray(scripts)) {
            this.postScripts = scripts;
        }
    }

    getQuestionType() {
        return QuestionTypes.MultipleChoice;
    }

    getMustSelectAllCorrect() {
        return this.requireAllCorrect;
    }

    setMustSelectAllCorrect(allCorrect) {
        this.requireAllCorrect = allCorrect;
    }

    getItems() {
        return this.items;
    }

    setItems(items) {
        if (Array.isArray(items)) {
            this.items = items;
        }
    }

    isShuffled() {
        return this.shuffled;
    }

    setShuffled(shuffled) {
        this.shuffled = shuffled;
    }

    clone() {
        let clone = new MultipleChoiceQuestion().initCreate(this.parent, this.icon, this.selectedIcon, this.title, this.duration, this.dwell, this.shuffled, [...this.topics], this.requireAllCorrect);
        clone.setId(this.getId());

        clone.getDescription().length = 0;
        for (let i = 0; i < this.description.length; i++) {
            clone.getDescription().push(this.description[i].clone());
        }
        //clone.getDescription().cloneFrom(this.getDescription());

        let variables = [];
        for (let i = 0; i < this.variables.length; i++) {
            variables.push(this.variables[i].clone());
        }
        clone.setVariables(variables);

        let items = [];
        for (let i = 0; i < this.items.length; i++) {
            items.push(this.items[i].clone());
        }
        clone.setItems(items);

        let parameters = [];
        for (let i = 0; i < this.parameters.length; i++) {
            parameters.push(this.parameters[i].clone());
        }
        clone.setParameters(parameters);

        let scripts = [];
        for (let i = 0; i < this.preScripts.length; i++) {
            scripts.push(this.preScripts[i].clone());
        }
        clone.setPreScripts(scripts);

        scripts = [];
        for (let i = 0; i < this.postScripts.length; i++) {
            scripts.push(this.postScripts[i].clone());
        }
        clone.setPostScripts(scripts);

        clone.setExpectedFormat(this.getExpectedFormat());
        clone.setSimpleAnswer(this.getSimpleAnswer());
        clone.setSimpleQuestion(this.getSimpleQuestion());

        return clone;
    }

    getJson() {
        return {
            ...super.getJson(),
            shuffled: this.shuffled,
            items: jsonifyArray(this.items),
            requireAllCorrect: this.requireAllCorrect,
            preScripts: jsonifyArray(this.preScripts),
            postScripts: jsonifyArray(this.postScripts),
            _type: this.constructor.ClassName(),
        };
    }

    generateVariableAndParameterArrays() {
        let parameters = this.parameters.map((p) => {
            return { name: p.getName(), value: p.getCurrent(this.id), instance: p };
        });
        let variables = this.variables.map((v) => {
            return { name: v.getTitle(), value: v.getValue(this.id), instance: v };
        });

        if (this.getParent().constructor.ClassName() === Subject.ClassName()) {
            this.getParent().getParameters().forEach((p) => {
                parameters.push({ name: p.getName(), value: p.getCurrent(this.id), instance: p });
            });
            this.getParent().getVariables().forEach((v) => {
                variables.push({ name: v.getTitle(), value: v.getValue(this.id), instance: v });
            });
        }

        return { parameters: parameters, variables: variables };
    }

    initStep(state) {
        let result = StepResult.Stepped;
        for (let Pi = 0; Pi < this.parameters.length; Pi++) {
            let stepResult = this.parameters[Pi].initStep();
            if (stepResult === StepResult.Failure) {
                result = StepResult.Failure;
            }
        }

        for (let Vi = 0; Vi < this.variables.length; Vi++) {
            this.variables[Vi].store();
        }

        // don't actually care about step result
    }

    getCurrent() {
        if (this.currentLayoutIndex === 0) {
            for (let i = 0; i < this.variables.length; i++) {
                if (this.variables[i].getParent().getId() === this.getId()) {
                    this.variables[i].reset(this.id);
                }
            }
        }

        let scope = this.generateVariableAndParameterArrays();

        // generate the replay
        let replay = {
            layout: this.description[this.currentLayoutIndex].getJson(),
            preScripts: jsonifyArray([
                ...(this.currentLayoutIndex === 0 ? this.preScripts : []),
                ...this.description[this.currentLayoutIndex].getPreScripts()
            ]),
            postScripts: jsonifyArray([
                ...this.description[this.currentLayoutIndex].getPostScripts(),
                ...(this.currentLayoutIndex === (this.description.length - 1) ? this.postScripts : [])
            ]),
            allCorrect: this.requireAllCorrect,
            shuffled: this.isShuffled(),
            parameters: jsonifyArray(scope.parameters.map((p) => {
                return { name: p.name, value: p.value };
            })),
            variables: jsonifyArray(scope.variables.map((v) => {
                return { name: v.name, value: v.value };
            })),
            items: jsonifyArray(this.items),
            label: this.title,
            dwell: this.getDwell(),
            duration: this.getDuration(),
            hasSubject: this.getParent().constructor.ClassName() === 'Subject',
            sourceId: this.id,
        };

        // generate the displayed values

        // run all the pre scripts
        let scripts = [
            ...(this.currentLayoutIndex === 0 ? this.preScripts : []),
            ...this.description[this.currentLayoutIndex].getPreScripts()
        ];
        for (let i = 0; i < scripts.length; i++) {
            runScript(this.id, scripts[i].getContent(), scripts[i].getTitle(), scope.variables, scope.parameters);
        }

        // update the variables and parameters after the pre-scripts are run
        //scope = this.generateVariableAndParameterArrays();

        // generate the snapshot
        let displaySnapshot = {
            answerFormat: runScript(this.id, this.getExpectedFormat(), "Expected Format", scope.variables, scope.parameters),
            simpleAnswer: runScript(this.id, this.getSimpleAnswer(), "Simple Answer", scope.variables, scope.parameters),
            simpleQuestion: runScript(this.id, this.getSimpleQuestion(), "Simple Question", scope.variables, scope.parameters),
            layout: this.description[this.currentLayoutIndex],
            variables: [...scope.variables],
            parameters: [...scope.parameters],
            items: [...this.items],
            shuffled: this.isShuffled(),
            label: this.title,
            dwell: this.getDwell(),
            duration: this.getDuration(),
            allCorrect: this.requireAllCorrect,
            hasSubject: this.getParent().constructor.ClassName() === 'Subject',
            sourceId: this.id,
        };

        // inject the helper values into the replay
        replay.answerFormat = runScript(this.id, this.getExpectedFormat(), "Expected Format", scope.variables, scope.parameters);
        replay.simpleAnswer = runScript(this.id, this.getSimpleAnswer(), "Simple Answer", scope.variables, scope.parameters);
        replay.simpleQuestion = runScript(this.id, this.getSimpleQuestion(), "Simple Question", scope.variables, scope.parameters);

        // run all the post scripts
        scripts = [
            ...this.description[this.currentLayoutIndex].getPostScripts(),
            ...(this.currentLayoutIndex === (this.description.length - 1) ? this.postScripts : [])
        ];
        for (let i = 0; i < scripts.length; i++) {
            runScript(this.id, scripts[i].getContent(), scripts[i].getTitle(), scope.variables, scope.parameters);
        }

        return [displaySnapshot, replay];
    }

    step(executionIndex) {
        let failures = [];
        let reset = false;

        this.currentLayoutIndex++;
        if (this.currentLayoutIndex >= this.description.length) {
            this.currentLayoutIndex = 0;
            reset = true;

            failures = this.parameters.filter((p) => {
                return p.step(executionIndex) === StepResult.Failure;
            });
            let result = failures.length > 0 ? StepResult.Failure : StepResult.Stepped;

            if (result === StepResult.Stepped) {
                if (this.isShuffled() === true) {
                    this.working = shuffle([...this.items]);
                } else {
                    this.working = [...this.items];
                }
            }
        }

        return failures.length > 0 ? StepResult.Failure : (reset === false ? StepResult.SteppedWithMore : StepResult.Stepped);
    }

    update(executionIndex) {
        for (let Pi = 0; Pi < this.parameters.length; Pi++) {
            this.parameters[Pi].update(executionIndex);
        }
    }

    static ClassName() {
        return "MultipleChoiceQuestion";
    }
}

// represents a question that sources its content from a random question in the indicated pool
export class PoolQuestion extends QuestionBase {
    constructor() {
        super();
    }

    initCreate(parentItem, icon, selectedIcon, fallbackTitle, sourcePool, overrideDwell, overrideDuration) {
        super.initCreate(parentItem, icon, selectedIcon, "", overrideDuration, overrideDwell, []);

        this.fallbackTitle = fallbackTitle;
        this.sourcePool = sourcePool;
        this.target = undefined;
        this.description = undefined;

        return this;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);

        this.icon = <WifiFindOutlinedIcon />;
        this.selectedIcon = <WifiFindIcon />;

        this.fallbackTitle = struct.fallbackTitle;
        this.sourcePool = struct.sourcePool;
        this.description = undefined;

        return this;
    }

    static getDefault(parent, fallbackTitle) {
        return new PoolQuestion().initCreate(parent, <WifiFindOutlinedIcon />, <WifiFindIcon />, fallbackTitle, undefined, undefined, undefined);
    }

    getDependencies() {
        let results = [new Dependency('Topic', this.topics)];

        if (this.sourcePool !== undefined) {
            results.push(new Dependency("QuestionPool", [this.sourcePool]));
        }

        return results;
    }

    getQuestionType() {
        return QuestionTypes.Pool;
    }

    getDuration() {
        if (this.duration === undefined) {
            if (this.target !== undefined) {
                return this.target.getDuration();
            } else {
                return "Sourced Duration";
            }
        } else {
            return this.duration;
        }
    }

    setDuration(duration) {
        if (duration === undefined) {
            this.duration = undefined;
        } else {
            if (typeof duration === 'string') {
                if (duration.trim() === "" || duration.trim() === "Sourced Duration") {
                    this.duration = undefined;
                } else {
                    this.duration = parseInt(duration + "");
                }
            } else if (typeof duration === 'number') {
                this.duration = duration;
            }
        }
    }

    getDwell() {
        if (this.dwell === undefined) {
            if (this.target !== undefined) {
                return this.target.getDwell();
            } else {
                return "Sourced Dwell";
            }
        } else {
            return this.dwell;
        }
    }

    setDwell(dwell) {
        if (dwell === undefined) {
            this.dwell = undefined;
        } else {
            if (typeof dwell === 'string') {
                if (dwell.trim() === "" || dwell.trim() === "Sourced Dwell") {
                    this.dwell = undefined;
                } else {
                    this.dwell = parseInt(dwell + "");
                }
            } else if (typeof dwell === 'number') {
                this.dwell = dwell;
            }
        }
    }

    getTopics() {
        if (this.target !== undefined) {
            return this.target.getTopics();
        } else {
            return [];
        }
    }

    getDescription() {
        if (this.target !== undefined) {
            return this.target.getDescription();
        } else {
            return "Sourced Description";
        }
    }

    setDescription(description) {
        // this is N/A here
    }

    getTitle() {
        if (this.fallbackTitle === undefined) {
            if (this.target !== undefined) {
                return this.target.getTitle();
            } else {
                return "Sourced Title";
            }
        } else {
            return this.fallbackTitle;
        }
    }

    setTitle(title) {
        if (title === undefined) {
            this.fallbackTitle = undefined;
        } else {
            if (title.trim() === "") {
                this.fallbackTitle = undefined;
            } else {
                this.fallbackTitle = title;
            }
        }
    }

    setSourcePool(id) {
        this.sourcePool = id;
    }

    getSourcePool() {
        return this.sourcePool;
    }

    clone() {
        let clone = new PoolQuestion().initCreate(this.parent, this.icon, this.selectedIcon, this.fallbackTitle, this.sourcePool, this.dwell, this.duration);
        clone.setId(this.getId());

        let variables = [];
        for (let i = 0; i < this.variables.length; i++) {
            variables.push(this.variables[i].clone());
        }
        clone.setVariables(variables);

        let parameters = [];
        for (let i = 0; i < this.parameters.length; i++) {
            parameters.push(this.parameters[i].clone());
        }
        clone.setParameters(parameters);

        return clone;
    }

    getJson() {
        return {
            ...super.getJson(),
            fallbackTitle: this.fallbackTitle,
            sourcePool: this.sourcePool,
            description: undefined,
            _type: this.constructor.ClassName(),
        };
    }

    initStep(state) {
        let matches = state.Examinations.Pools.filter((p) => {
            return p.getId() === this.sourcePool;
        });

        if (matches.length === 1) {
            this.actualPool = matches[0].clone();
            this.actualPool.initStep(state);
            return true;
        } else {
            return false;
        }
    }

    getCurrent() {
        return this.actualPool.getCurrent();
    }

    step(executionIndex) {
        return this.actualPool.step(executionIndex);
    }

    update(executionIndex) {
        this.actualPool.update(executionIndex);
    }

    static ClassName() {
        return "PoolQuestion";
    }
}