import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';

import { QuizzicalContext } from '../Context/QuizzicalContext';
import { getPoolById } from '../Context/ContextUtility';

export default function QuestionPoolSelector(props) {
    const state = React.useContext(QuizzicalContext);
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [forceRefresh, setForceRefresh] = React.useState(false);
    const [displayNonRegressiveWarning, setDisplayNonRegressiveWarning] = React.useState(false);
    const [isRequired, setIsRequired] = React.useState(true);

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }

        if (props.isRequired !== undefined &&
            props.isRequired !== null &&
            props.isRequired !== isRequired) {
            setIsRequired(props.isRequired);
        }
    }, [props]);

    const handleCloseWarning = () => {
        setDisplayNonRegressiveWarning(false);
    }

    const onEdited = (event) => {
        if (focus.getParent().getId() === event.target.value) {
            setDisplayNonRegressiveWarning(true);
        } else if (event.target.value === "") {
            focus.setSourcePool(undefined);
        } else {
            focus.setSourcePool(event.target.value);
        }

        if (props.onChange !== undefined) {
            props.onChange(focus, event.target.value);
        }
        setForceRefresh(!forceRefresh);
    }

    if (isReadOnly) {
        if (focus.getSourcePool() !== undefined &&
            focus.getSourcePool().trim() !== "") {
            let pool = getPoolById(state, focus.getSourcePool());
            return (
                <Stack spacing={2} direction="row">
                    <Typography color="inherit" variant="body" component="div">Source Pool:</Typography>
                    <Typography color="inherit" variant="caption" component="div">{pool.getTitle()}</Typography>
                </Stack>
            );
        } else {
            return (
                <Stack spacing={2} direction="row">
                    <Typography color="inherit" variant="body" component="div">Source Pool:</Typography>
                    <Typography color="inherit" variant="caption" component="div">None</Typography>
                </Stack>
            );
        }
    } else {
        let listing = state.Examinations.Pools
        .filter((pool) => {
            return pool.getId() !== focus.getParent().getId();
        })
        .map((pool, i) => {
            return (<MenuItem key={"pool-" + pool.getId() + "-" + i} value={pool.getId()}>{pool.getTitle()}</MenuItem>);
        });
        listing.push(<MenuItem key={"pool-empty-item"} value="">None</MenuItem>);

        return (
            <Stack
                spacing={2}
                direction="row"
                sx={{ alignItems: 'center' }}>
                {focus.getSourcePool() === undefined ? <LibraryBooksOutlinedIcon /> : <LibraryBooksIcon />}
                <FormControl fullWidth>
                    <Select
                        id="pool-selector"
                        value={focus.getSourcePool() || ''}
                        required={isRequired}
                        fullWidth
                        onChange={onEdited}>
                        {listing}
                    </Select>
                </FormControl>
                <Dialog
                    open={displayNonRegressiveWarning}
                    onClose={handleCloseWarning}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <WarningIcon />
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Alert</Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>You cannot use a pool question's parent as the source.</DialogContent>
                    <DialogActions>
                        <Button autoFocus color="inherit" onClick={handleCloseWarning}>
                            <Stack direction="row" spacing={2}>
                                <CheckIcon />
                                <Typography color="inherit" variant="button" component="div" style={{ textAlign: 'right' }}>Okay</Typography>
                            </Stack>
                        </Button>
                    </DialogActions>
                </Dialog>
            </Stack>
        );
    }
}