import { FeedbackMessage, MessageSeverities } from "../Classes/FeedbackMessage";
import { ContentSources, ContentTypes } from "../Classes/Layout/LayoutMeta";
import Subject from "../Classes/Subjects";
import VariableDefinition from "../Classes/VariableDefinition";

/*

This function simulates a backend call by validating the class and returning any problems as FeedbackMessages

All validators take a state instance, the expected type, and an object instance

*/

export default function FormEditorHost_Validator(target, type, state) {
    let outcome = [];

    switch (type) {
        case 'Address':
            outcome = validate_Address(target, state);
            break;
        case 'Organization':
            outcome = validate_Organization(target, state);
            break;
        case 'Course':
            outcome = validate_Course(target, state);
            break;
        case 'Person':
            outcome = validate_Person(target, state);
            break;
        case 'RoleAssociation':
            outcome = validate_roleAssociation(target, state);
            break;

        case 'Assignment':
            outcome = validate_Assignment(target, state);
            break;
        case 'QuestionPool':
            outcome = validate_QuestionPool(target, state);
            break;
        case 'Section':
            outcome = validate_Section(target, state);
            break;
        case 'Subject':
            outcome = validate_Subject(target, state);
            break;
        case 'Variable':
            outcome = validate_Variable(target, state);
            break;
        case 'Topic':
            outcome = validate_Topic(target, state);
            break;

        case 'FillInQuestion':
            outcome = validate_FillInQuestion(target, state);
            break;
        case 'MultipleChoiceQuestion':
            outcome = validate_MultipleChoiceQuestion(target, state);
            break;
        case 'PoolQuestion':
            outcome = validate_PoolQuestion(target, state);
            break;
        case 'ChoiceItem':
            outcome = validate_ChoiceItem(target, state);
            break;
        case 'QuestionLayout':
            outcome = validate_QuestionLayout(target, state);
            break;

        case 'ScriptItem':
            outcome = validate_ScriptItem(target, state);
            break;

        case 'RandomizedIntegerParameter':
            outcome = validate_RandomizedIntegerParameter(target, state);
            break;
        case 'RandomizedDecimalParameter':
            outcome = validate_RandomizedDecimalParameter(target, state);
            break;
        case 'NumericalSequenceParameter':
            outcome = validate_NumericalSequenceParameter(target, state);
            break;
        case 'SequencialParameter':
            outcome = validate_SequencialParameter(target, state);
            break;
        case 'PoolParameter':
            outcome = validate_PoolParameter(target, state);
            break;
    }

    return {
        isValid: outcome.length === 0,
        issues: outcome,
        for: target,
        forType: type
    };
}

function isValue(value, expectedType = 'string') {
    return (
        value !== undefined &&
        value !== null &&
        typeof value === expectedType &&
        (value + '').trim() !== ''
    );
}

/* ***************************** */
/*  Personnel Components - Start */
/* ***************************** */

// validates addresses
function validate_Address(address, state) {
    // to be valid, an address requires a title, state, street, city/state/district, and postal code
    let outcome = [];

    if (!isValue(address.getId())) {
        outcome.push(new FeedbackMessage('Address object has no id', MessageSeverities.Error));
    }

    if (!isValue(address.getTitle())) {
        outcome.push(new FeedbackMessage('Title is required', MessageSeverities.Error));
    }

    if (!isValue(address.getStreet())) {
        outcome.push(new FeedbackMessage('Street is required', MessageSeverities.Error));
    }

    if (!isValue(address.getCityState())) {
        outcome.push(new FeedbackMessage('City / State / District is required', MessageSeverities.Error));
    }

    if (!isValue(address.getPostalCode())) {
        outcome.push(new FeedbackMessage('Postal Code is required', MessageSeverities.Error));
    }

    if (!isValue(address.getCountry())) {
        outcome.push(new FeedbackMessage('Country is required', MessageSeverities.Error));
    }

    return outcome;
}

// validates organizations
function validate_Organization(organization, state) {
    // to be valid, an organization requires an identity code, title, and description
    let outcome = [];

    if (!isValue(organization.getId())) {
        outcome.push(new FeedbackMessage('Organization object has no id', MessageSeverities.Error));
    }

    if (!isValue(organization.getCode())) {
        outcome.push(new FeedbackMessage('Identity Code is required', MessageSeverities.Error));
    }

    if (!isValue(organization.getTitle())) {
        outcome.push(new FeedbackMessage('Title is required', MessageSeverities.Error));
    }

    if (!isValue(organization.getDescription())) {
        outcome.push(new FeedbackMessage('Description is required', MessageSeverities.Error));
    }

    return outcome;
}

// validates courses
function validate_Course(course, state) {
    // to be valid, a course requires an identity code, title, description, and a location
    let outcome = [];

    if (!isValue(course.getId())) {
        outcome.push(new FeedbackMessage('Course object has no id', MessageSeverities.Error));
    }

    if (!isValue(course.getCode())) {
        outcome.push(new FeedbackMessage('Identity Code is required', MessageSeverities.Error));
    }

    if (!isValue(course.getTitle())) {
        outcome.push(new FeedbackMessage('Title is required', MessageSeverities.Error));
    }

    if (!isValue(course.getDescription())) {
        outcome.push(new FeedbackMessage('Description is required', MessageSeverities.Error));
    }

    if (!isValue(course.getLocationId())) {
        outcome.push(new FeedbackMessage('Location is required', MessageSeverities.Error));
    }

    return outcome;
}

// validates people
function validate_Person(person, state) {
    // to be valid, a person needs an identity code, age, and if not a minor, a first name, and last name
    let outcome = [];

    if (!isValue(person.getId())) {
        outcome.push(new FeedbackMessage('Person object has no id', MessageSeverities.Error));
    }

    if (!isValue(person.getCode())) {
        outcome.push(new FeedbackMessage('Identity Code is required', MessageSeverities.Error));
    }

    if (!isValue(person.getAge(), 'number')) {
        outcome.push(new FeedbackMessage('Age is required and must be a number', MessageSeverities.Error));
    }

    if (!isValue(person.getAge(), 'number') && person.getAge() < 0) {
        outcome.push(new FeedbackMessage('Age must be a positive, non-zero number.', MessageSeverities.Error));
    }

    if (!person.isMinor()) {
        if (!isValue(person.getFirstName())) {
            outcome.push(new FeedbackMessage('First Name is required for adults', MessageSeverities.Error));
        }

        if (!isValue(person.getLastName())) {
            outcome.push(new FeedbackMessage('Last Name is required for adults', MessageSeverities.Error));
        }
    }

    return outcome;
}

// validates role associations
function validate_roleAssociation(roleAssociation, state) {
    // to be valid, an association requires a whoId, type, and whatId
    let outcome = [];

    if (!isValue(roleAssociation.getId())) {
        outcome.push(new FeedbackMessage('Role Association object has no id', MessageSeverities.Error));
    }

    if (!isValue(roleAssociation.getWhoId())) {
        outcome.push(new FeedbackMessage('Who is required', MessageSeverities.Error));
    }

    if (!isValue(roleAssociation.getType(), 'number')) {
        outcome.push(new FeedbackMessage('Type is required', MessageSeverities.Error));
    }

    if (!isValue(roleAssociation.getWhatId())) {
        outcome.push(new FeedbackMessage('What is required', MessageSeverities.Error));
    }

    return outcome;
}

/* ***************************** */
/*   Personnel Components - End  */
/* ***************************** */

/* ***************************** */
/* Assignment Components - Start */
/* ***************************** */

// validates assignments
function validate_Assignment(assignment, state) {
    // to be valid, an assignment requires a title, and description
    let outcome = [];

    if (!isValue(assignment.getId())) {
        outcome.push(new FeedbackMessage('Assignment object has no id', MessageSeverities.Error));
    }

    if (!isValue(assignment.getTitle())) {
        outcome.push(new FeedbackMessage('Title is required', MessageSeverities.Error));
    }

    if (!isValue(assignment.getDescription())) {
        outcome.push(new FeedbackMessage('Description is required', MessageSeverities.Error));
    }

    return outcome;
}

// validates sections
function validate_Section(section, state) {
    // to be valid, a section requires a title, and description
    let outcome = [];

    if (!isValue(section.getId())) {
        outcome.push(new FeedbackMessage('Section object has no id', MessageSeverities.Error));
    }

    if (!isValue(section.getTitle())) {
        outcome.push(new FeedbackMessage('Title is required', MessageSeverities.Error));
    }

    if (!isValue(section.getDescription())) {
        outcome.push(new FeedbackMessage('Description is required', MessageSeverities.Error));
    }

    if (!isValue(section.isShuffled(), 'boolean')) {
        outcome.push(new FeedbackMessage('Shuffled is required', MessageSeverities.Error));
    }

    return outcome;
}

// validates subjects
function validate_Subject(subject, state) {
    // to be valid, a subject requires a title, and description
    let outcome = [];

    if (!isValue(subject.getId())) {
        outcome.push(new FeedbackMessage('Subject object has no id', MessageSeverities.Error));
    }

    if (!isValue(subject.getTitle())) {
        outcome.push(new FeedbackMessage('Title is required', MessageSeverities.Error));
    }

    if (!isValue(subject.isShuffled(), 'boolean')) {
        outcome.push(new FeedbackMessage('Shuffled is required', MessageSeverities.Error));
    }

    if (subject.isLayoutProvider() === true) {
        if (subject.getDescription().isCompleted() === false) {
            outcome.push(new FeedbackMessage('Dictating subjects must define a completed layout', MessageSeverities.Error));
        }
    }

    return outcome;
}

function validate_Variable(variable, state) {
    // to be valid, a variable must have a name and value
    let outcome = [];

    if (!isValue(variable.getId())) {
        outcome.push(new FeedbackMessage('Variable object has no id', MessageSeverities.Error));
    }

    if (!isValue(variable.getTitle())) {
        outcome.push(new FeedbackMessage('Name is required', MessageSeverities.Error));
    }

    if (checkDuplicateIdentifier(variable, state)) {
        outcome.push(new FeedbackMessage('Variable and Parameter names must be unique within their scope', MessageSeverities.Error));
    }

    return outcome;
}

function validate_Topic(topic, state) {
    // to be valid, a topic must have a label (name), description, and define the selectability
    let outcome = [];

    if (!isValue(topic.getId())) {
        outcome.push(new FeedbackMessage('Topic object has no id', MessageSeverities.Error));
    }

    if (!isValue(topic.getSimpleLabel())) {
        outcome.push(new FeedbackMessage('Label is required', MessageSeverities.Error));
    }

    if (!isValue(topic.getDescription())) {
        outcome.push(new FeedbackMessage('Description is required', MessageSeverities.Error));
    }

    if (!isValue(topic.canSelect(), 'boolean')) {
        outcome.push(new FeedbackMessage('CanSelect must be Yes or No', MessageSeverities.Error));
    }

    return outcome;
}

// validates question pools
function validate_QuestionPool(questionPool, state) {
    // to be valid, a question pool requires a title, and description
    let outcome = [];

    if (!isValue(questionPool.getId())) {
        outcome.push(new FeedbackMessage('Question Pool object has no id', MessageSeverities.Error));
    }

    if (!isValue(questionPool.getTitle())) {
        outcome.push(new FeedbackMessage('Title is required', MessageSeverities.Error));
    }

    if (!isValue(questionPool.getDescription())) {
        outcome.push(new FeedbackMessage('Description is required', MessageSeverities.Error));
    }

    if (questionPool.getParent() !== undefined) {
        if (questionPool.getParent().getId() === questionPool.getSourcePool()) {
            outcome.push(new FeedbackMessage("You cannot use a pool question's parent as the source.", MessageSeverities.Error));
        }
    }

    return outcome;
}

// validates fill-in questions
function validate_FillInQuestion(fillinQuestion, state) {
    // to be valid, a fill-in question requires a title, duration, and dwell
    let outcome = [];

    if (!isValue(fillinQuestion.getId())) {
        outcome.push(new FeedbackMessage('Fill-in Question object has no id', MessageSeverities.Error));
    }

    if (!isValue(fillinQuestion.getTitle())) {
        outcome.push(new FeedbackMessage('Title is required', MessageSeverities.Error));
    }

    if (!isValue(fillinQuestion.getDuration(), 'number')) {
        outcome.push(new FeedbackMessage('Duration is required', MessageSeverities.Error));
    }

    if (!isValue(fillinQuestion.getDwell(), 'number')) {
        outcome.push(new FeedbackMessage('Dwell is required', MessageSeverities.Error));
    }

    // the last layout cannot be explanatory, and there must be at least one layout present
    if (fillinQuestion.getDescription().length === 0) {
        outcome.push(new FeedbackMessage('A minimum of one layout must be defined', MessageSeverities.Error));
    }

    if (fillinQuestion.getDescription().filter((layout) => {
        return layout.isExplanatory() !== true
    }).length === 0) {
        outcome.push(new FeedbackMessage('The question must have at least one non-explanatory layout defined', MessageSeverities.Error));
    }

    if (fillinQuestion.getDescription().filter((l) => {
        return l.hasAnswers() === true;
    }).length === 0) {
        outcome.push(new FeedbackMessage('A question must have at least one layout with an answer region defined', MessageSeverities.Error));
    }

    return outcome;
}

// validates multiple choice questions
function validate_MultipleChoiceQuestion(multipleChoiceQuestion, state) {
    // to be valid, a multiple choice question requires a title, duration, and dwell, shuffled, must select all, and at least one item defined
    let outcome = [];

    if (!isValue(multipleChoiceQuestion.getId())) {
        outcome.push(new FeedbackMessage('Multiple Choice Question object has no id', MessageSeverities.Error));
    }

    if (!isValue(multipleChoiceQuestion.getTitle())) {
        outcome.push(new FeedbackMessage('Title is required', MessageSeverities.Error));
    }

    if (!isValue(multipleChoiceQuestion.getDuration(), 'number')) {
        outcome.push(new FeedbackMessage('Duration is required', MessageSeverities.Error));
    }

    if (!isValue(multipleChoiceQuestion.getDwell(), 'number')) {
        outcome.push(new FeedbackMessage('Dwell is required', MessageSeverities.Error));
    }

    if (!isValue(multipleChoiceQuestion.isShuffled(), 'boolean')) {
        outcome.push(new FeedbackMessage('Shuffled is required', MessageSeverities.Error));
    }

    if (!isValue(multipleChoiceQuestion.getMustSelectAllCorrect(), 'boolean')) {
        outcome.push(new FeedbackMessage('Must Select All Correct is required', MessageSeverities.Error));
    }

    if (multipleChoiceQuestion.getItems().length === 0) {
        outcome.push(new FeedbackMessage('A minimum of one item must be defined', MessageSeverities.Error));
    }

    if (multipleChoiceQuestion.getItems().filter((o) => {
        return o.correct === true;
    }).length === 0) {
        outcome.push(new FeedbackMessage('A minimum of one item must be marked as correct', MessageSeverities.Error));
    }

    // the last layout cannot be explanatory, and there must be at least one layout present
    if (multipleChoiceQuestion.getDescription().length === 0) {
        outcome.push(new FeedbackMessage('A minimum of one layout must be defined', MessageSeverities.Error));
    }

    if (multipleChoiceQuestion.getDescription().filter((layout) => {
        return layout.isExplanatory() !== true
    }).length === 0) {
        outcome.push(new FeedbackMessage('The question must have at least one non-explanatory layout defined', MessageSeverities.Error));
    }

    if (multipleChoiceQuestion.getDescription().filter((l) => {
        return l.hasAnswers() === true;
    }).length === 0) {
        outcome.push(new FeedbackMessage('A question must have at least one layout with an answer region defined', MessageSeverities.Error));
    }

    return outcome;
}

// validates pool questions
function validate_PoolQuestion(poolQuestion, state) {
    // to be valid, a pool question requires a fallback title, and a target question pool
    let outcome = [];

    if (!isValue(poolQuestion.getId())) {
        outcome.push(new FeedbackMessage('Pool Question object has no id', MessageSeverities.Error));
    }

    if (!isValue(poolQuestion.getTitle())) {
        outcome.push(new FeedbackMessage('Title is required', MessageSeverities.Error));
    }

    if (!isValue(poolQuestion.getSourcePool())) {
        outcome.push(new FeedbackMessage('Source Pool is required', MessageSeverities.Error));
    }

    return outcome;
}

// validates script items
function validate_ScriptItem(scriptItem, state) {
    // to be valid, a script item requires a title, description, and script
    let outcome = [];

    if (!isValue(scriptItem.getId())) {
        outcome.push(new FeedbackMessage('Script Item object has no id', MessageSeverities.Error));
    }

    if (!isValue(scriptItem.getTitle())) {
        outcome.push(new FeedbackMessage('Title is required', MessageSeverities.Error));
    }

    if (!isValue(scriptItem.getDescription())) {
        outcome.push(new FeedbackMessage('Description is required', MessageSeverities.Error));
    }

    if (!isValue(scriptItem.getContent())) {
        outcome.push(new FeedbackMessage('Script is required', MessageSeverities.Error));
    }

    return outcome;
}

function validate_ChoiceItem(item, state) {
    // to be valid, a choice item must have a name and value
    let outcome = [];

    if (!isValue(item.getId())) {
        outcome.push(new FeedbackMessage('Item object has no id', MessageSeverities.Error));
    }

    if (!isValue(item.getTitle())) {
        outcome.push(new FeedbackMessage('Name is required', MessageSeverities.Error));
    }

    if (!isValue(item.getValue())) {
        outcome.push(new FeedbackMessage('Value is required', MessageSeverities.Error));
    }

    if (!isValue(item.isCorrect(), 'boolean')) {
        outcome.push(new FeedbackMessage('Is correct is required and must have a boolean value', MessageSeverities.Error));
    }

    return outcome;
}

function validate_QuestionLayout(layout, state) {
    // to be valid, a layout must have must have a name and value
    let outcome = [];

    if (!isValue(layout.getId())) {
        outcome.push(new FeedbackMessage('Layout has no id', MessageSeverities.Error));
    }

    if (layout.getParent().constructor.ClassName() !== Subject.ClassName()) {
        if (!isValue(layout.getTitle())) {
            outcome.push(new FeedbackMessage('Name is required', MessageSeverities.Error));
        }
    }

    let improperSource = layout.getRegions().filter((r) => {
        return [ContentSources.Unassigned].includes(r.getSource()) === true;
    }).length > 0;

    let improperType = layout.getRegions().filter((r) => {
        return [ContentTypes.None].includes(r.getSource()) === true;
    }).length > 0;
    if (improperSource || improperType) {
        outcome.push(new FeedbackMessage('All regions must be fully configured', MessageSeverities.Error));
    }

    return outcome;
}

/* ***************************** */
/* Assignment Components - ENd   */
/* ***************************** */

/* ***************************** */
/*        Parameters - Start     */
/* ***************************** */

// validates randomized integer parameters
function validate_RandomizedIntegerParameter(parameter, state) {
    // to be valid, a randomized integer parameter needs a name, minimum, and maximum
    let outcome = [];

    if (!isValue(parameter.getId())) {
        outcome.push(new FeedbackMessage('Parameter object has no id', MessageSeverities.Error));
    }

    if (!isValue(parameter.getName())) {
        outcome.push(new FeedbackMessage('Name is required', MessageSeverities.Error));
    }

    if (!isValue(parameter.getMinimum(), 'number')) {
        outcome.push(new FeedbackMessage('Minimum is required and must be a number', MessageSeverities.Error));
    }

    if (!isValue(parameter.getMaximum(), 'number')) {
        outcome.push(new FeedbackMessage('Maximum is required and must be a number', MessageSeverities.Error));
    }

    if (parameter.getMinimum() >= parameter.getMaximum()) {
        outcome.push(new FeedbackMessage('Minimum must be less than Maximum', MessageSeverities.Error));
    }

    if (checkDuplicateIdentifier(parameter, state)) {
        outcome.push(new FeedbackMessage('Variable and Parameter names must be unique within their scope', MessageSeverities.Error));
    }

    return outcome;
}

// validates randomized decimal parameters
function validate_RandomizedDecimalParameter(parameter, state) {
    // to be valid, a randomized decimal parameter needs a name, minimum, and maximum
    let outcome = [];

    if (!isValue(parameter.getId())) {
        outcome.push(new FeedbackMessage('Parameter object has no id', MessageSeverities.Error));
    }

    if (!isValue(parameter.getName())) {
        outcome.push(new FeedbackMessage('Name is required', MessageSeverities.Error));
    }

    if (!isValue(parameter.getMinimum(), 'number')) {
        outcome.push(new FeedbackMessage('Minimum is required and must be a number', MessageSeverities.Error));
    }

    if (!isValue(parameter.getMaximum(), 'number')) {
        outcome.push(new FeedbackMessage('Maximum is required and must be a number', MessageSeverities.Error));
    }

    if (parameter.getMinimum() >= parameter.getMaximum()) {
        outcome.push(new FeedbackMessage('Minimum must be less than Maximum', MessageSeverities.Error));
    }

    if (checkDuplicateIdentifier(parameter, state)) {
        outcome.push(new FeedbackMessage('Variable and Parameter names must be unique within their scope', MessageSeverities.Error));
    }

    return outcome;
}

// validates numerical sequence parameters
function validate_NumericalSequenceParameter(parameter, state) {
    // to be valid, a numerical sequence parameter needs a name, looping, start, end, and step
    let outcome = [];

    if (!isValue(parameter.getId())) {
        outcome.push(new FeedbackMessage('Parameter object has no id', MessageSeverities.Error));
    }

    if (!isValue(parameter.getName())) {
        outcome.push(new FeedbackMessage('Name is required', MessageSeverities.Error));
    }

    if (!isValue(parameter.getLooping(), 'boolean')) {
        outcome.push(new FeedbackMessage('Looping is required', MessageSeverities.Error));
    }

    if (!isValue(parameter.getStart(), 'number')) {
        outcome.push(new FeedbackMessage('Start is required and must be a number', MessageSeverities.Error));
    }

    if (!isValue(parameter.getEnd(), 'number')) {
        outcome.push(new FeedbackMessage('End is required and must be a number', MessageSeverities.Error));
    }

    if (!isValue(parameter.getStep(), 'number')) {
        outcome.push(new FeedbackMessage('Step is required and must be a number', MessageSeverities.Error));
    }

    if (parameter.getStart() >= parameter.getEnd()) {
        outcome.push(new FeedbackMessage('Start must be less than End', MessageSeverities.Error));
    }

    if ((parameter.getEnd() - parameter.getStart()) <= parameter.getStep()) {
        outcome.push(new FeedbackMessage('Step cannot exceed or equal the different between Start and End in size', MessageSeverities.Error));
    }

    if (checkDuplicateIdentifier(parameter, state)) {
        outcome.push(new FeedbackMessage('Variable and Parameter names must be unique within their scope', MessageSeverities.Error));
    }

    return outcome;
}

// validates sequential parameters
function validate_SequencialParameter(parameter, state) {
    // to be valid, a sequential parameter needs a name, looping, and to have at least one item defined
    let outcome = [];

    if (!isValue(parameter.getId())) {
        outcome.push(new FeedbackMessage('Parameter object has no id', MessageSeverities.Error));
    }

    if (!isValue(parameter.getName())) {
        outcome.push(new FeedbackMessage('Name is required', MessageSeverities.Error));
    }

    if (!isValue(parameter.getLooping(), 'boolean')) {
        outcome.push(new FeedbackMessage('Looping is required', MessageSeverities.Error));
    }

    if (parameter.getItems().length <= 0) {
        outcome.push(new FeedbackMessage('A minimum of one item must be defined', MessageSeverities.Error));
    }

    if (checkDuplicateIdentifier(parameter, state)) {
        outcome.push(new FeedbackMessage('Variable and Parameter names must be unique within their scope', MessageSeverities.Error));
    }

    return outcome;
}

// validates pool parameters
function validate_PoolParameter(parameter, state) {
    // to be valid, a pool parameter needs a name, looping, and to have at least one item defined
    let outcome = [];

    if (!isValue(parameter.getId())) {
        outcome.push(new FeedbackMessage('Parameter object has no id', MessageSeverities.Error));
    }

    if (!isValue(parameter.getName())) {
        outcome.push(new FeedbackMessage('Name is required', MessageSeverities.Error));
    }

    if (!isValue(parameter.getLooping(), 'boolean')) {
        outcome.push(new FeedbackMessage('Looping is required', MessageSeverities.Error));
    }

    if (parameter.getItems().length <= 0) {
        outcome.push(new FeedbackMessage('A minimum of one item must be defined', MessageSeverities.Error));
    }

    if (checkDuplicateIdentifier(parameter, state)) {
        outcome.push(new FeedbackMessage('Variable and Parameter names must be unique within their scope', MessageSeverities.Error));
    }

    return outcome;
}

/* ***************************** */
/*        Parameters - End       */
/* ***************************** */


/* **************************************************** */
// checks to make sure that the given identifier is unique on the parent (should be a question), and the parent's parent (if it's a subject)
function checkDuplicateIdentifier(target, state) {
    let targetName = undefined;
    if (target.constructor.ClassName().endsWith("Parameter")) {
        targetName = target.getName();
    } else if (target.constructor.ClassName() === VariableDefinition.ClassName()) {
        targetName = target.getTitle();
    }

    let check = (parent, identifier, id) => {
        let duplicateFound = false;
        if (parent !== undefined) {
            if (parent.constructor.ClassName().endsWith("Question") ||
                parent.constructor.ClassName() === Subject.ClassName()) {

                // check parameters
                for (let pi = 0; pi < parent.getParameters().length; pi++) {
                    if (parent.getParameters()[pi].getId() !== id &&
                        parent.getParameters()[pi].getName() === targetName) {
                        duplicateFound = true;
                    }
                }

                // check variables
                for (let vi = 0; vi < parent.getVariables().length; vi++) {
                    if (parent.getVariables()[vi].getId() !== id &&
                        parent.getVariables()[vi].getTitle() === targetName) {
                        duplicateFound = true;
                    }
                }
            }
        }

        if (parent.getParent().constructor.ClassName() === Subject.ClassName() &&
            duplicateFound === false) {
            duplicateFound = check(parent.getParent(), identifier, id);
        }

        return duplicateFound;
    }

    let duplicateFound = check(target.getParent(), targetName, target.getId());
    return duplicateFound;
}