import ClassName from "./ClassName";

export default class SelectedImplementation extends ClassName {
    constructor(selected) {
        super();
        this.selectState = selected !== undefined && selected !== null && typeof selected === 'boolean' ? selected : false;
        this.selectionGhost = undefined;
    }

    Select(permeateChildren) {
        this.selectState = true;
        if (this.selectionGhost !== undefined && this.selectionGhost !== null) {
            this.selectionGhost.Select(permeateChildren);
        }

        this.#permeateSelectionState();
    }

    Unselect(permeateChildren) {
        this.selectState = false;
        if (this.selectionGhost !== undefined && this.selectionGhost !== null) {
            this.selectionGhost.Unselect(permeateChildren);
        }

        this.#permeateSelectionState();
    }

    #permeateSelectionState() {
        // contains the names of all used child entity getters
        let childProperties = [
            'getChildren',
            'getAddresses',
            'getCourses',
            'getItems',
            'getVariables'
        ];

        for (let i = 0; i < childProperties.length; i++) {
            let prop = childProperties[i];
            if (this[prop] === undefined) continue;

            // loop through and set their selected state to match their parent's (this object)
            for (let Ci = 0; Ci < this[prop]().length; Ci++) {
                let target = this[prop]()[Ci];
                if (this.selectState === true) {
                    target.Select(true);
                } else {
                    target.Unselect(true);
                }
            }
        }
    }

    isSelected() {
        return this.selectState;
    }

    setSelectGhost(ghost) {
        this.selectionGhost = ghost;
    }

    static ClassName() {
        return "SelectedImplementation";
    }
}

// the filter parameter exists so I can blindly call this with a trigger parameter
// if false, the parameter will make the function not filter
export function whereSelected(set, shouldFilter = false) {
    if (!Array.isArray(set)) return [];

    if (shouldFilter === false) {
        return set;
    } else {
        let output = set.filter((item) => {
            let outcome = item.isSelected !== undefined ? item.isSelected() === true : false;
            return outcome;
        });

        return output;
    }
}