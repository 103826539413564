// this library contains functions to allow the generation of Teacher Answer Keys and Answer Sheets for teachers and students respectively.
//  These are exports for download to assist in the learning process and drilling.  
// in the future, imports will allow cumulative grades to be generated and graphed for further analysis.
// these files will be in the .csv format.

import { compressToUTF16 as lzStringCompress } from 'lz-string';
import download, { downloadCSV } from "./download";

export function getReplayDownload(idKey, replays, fileName) {
    let json = JSON.stringify(
        {
            lessonKey: idKey,
            replays: replays
        },
        null, 4);

    let fName = fileName.replace('[key]', idKey);
    json = lzStringCompress(json);
    download(json, fName);
}

export function getQuestionSet(idKey, historicalData, fileName) {
    const headers = [
        "Question Index",
        "Lesson Key",
        "Question",
        "Answer",
        "Expected Answer Format",
        "Given"];
    var csvRows = [];
    csvRows.push(headers.join(','));

    // get a proper date string
    var elapsed = Date.now();
    var today = new Date(elapsed);

    var o = historicalData;
    for (var j = 0; j < o.length; j++) {
        var rowVals = [
            j + 1,
            idKey,
            o[j].simpleQuestion,
            o[j].simpleAnswer,
            o[j].answerFormat,
            '"' + today.toUTCString() + '"'];
        csvRows.push(rowVals);
    }
    var csvResult = csvRows.join('\n');

    downloadCSV(csvResult, fileName.replace('[key]', idKey));
}

export function getStudentSheet(idKey, name, historicalData, fileName) {
    const headers = [
        "Question Index",
        "Lesson Key",
        "Answer Provider",
        "Question",
        "Provided Answer",
        "Taken"];
    var csvRows = [];
    csvRows.push(headers.join(','));

    // get a proper date string
    var elapsed = Date.now();
    var today = new Date(elapsed);

    var o = historicalData;
    for (var j = 0; j < o.length; j++) {
        var rowVals = [
            j + 1,
            idKey,
            name,
            o[j].simpleQuestion,
            o[j].answerFormat,
            '"' + today.toUTCString() + '"'];
        csvRows.push(rowVals);
    }
    var csvResult = csvRows.join('\n');

    downloadCSV(csvResult, fileName.replace('[key]', idKey).replace('[name]', name));
}