import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';

import { QuizzicalContext, ScreenModes } from '../Context/QuizzicalContext';
import GenericList from '../Controls/Lists/GenericList';
import { Person } from '../Classes/Personnel/Person';

export default function PeopleScreen(props) {
    const state = useContext(QuizzicalContext);

    return (
        <GenericList
            rows={9}
            state={state}
            isReadOnly={false}
            isCollapsable={false}
            label="Person"
            showHeaderLabel={false}
            overrideAddIcon={<PersonAddIcon />}
            overrideDeleteSelectedIcon={<GroupRemoveIcon />}
            getTargetItems={() => {
                return state.People;
            }}
            setTargetItems={(items) => {
                state.setPeople(items);
            }}
            getDefaultItem={() => {
                return Person.getDefault();
            }}
            validPastes={[
                Person.ClassName(),
            ]} />
    );
}