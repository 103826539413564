import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

import AboutDialog from '../Screens/Dialogs/AboutDialog';
import { QuizzicalContext, RunStates, ScreenModes } from '../Context/QuizzicalContext';
import { Stack } from '@mui/material';
import LegalDialog from '../Screens/Dialogs/LegalDialog';

export default function AppFooter(props) {
    const state = React.useContext(QuizzicalContext);
    const [aboutIsOpen, setAboutIsOpen] = React.useState(false);
    const [legalIsOpen, setLegalIsOpen] = React.useState(false);

    return (
        <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
            <Toolbar sx={{ justifyContent: "space-between" }}>
                <div />
                <Stack direction="row">
                    <Button sx={{ textTransform: "none" }} color="inherit" onClick={() => { setLegalIsOpen(!legalIsOpen); }}>Legal</Button>
                    <Button sx={{ textTransform: "none" }} color="inherit" onClick={() => { setAboutIsOpen(!aboutIsOpen); }}>About Adminsterfy.com™</Button>
                </Stack>
            </Toolbar>
            <AboutDialog isOpen={aboutIsOpen} onConfirm={() => { setAboutIsOpen(!aboutIsOpen); }} />
            <LegalDialog isOpen={legalIsOpen} onConfirm={() => { setLegalIsOpen(!legalIsOpen); }} />
        </AppBar>
    );
}