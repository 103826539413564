import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

/*
** Places a circled question mark with a tooltip to allow help items to be placed where desired
**
** Property Attributes:
**
** help: the help message
**
**
**
**
*/
export default function HelpIcon(props) {
    return (
        <Tooltip
            title={<span style={{ whiteSpace: 'pre-line' }}>{props.help}</span>}
            arrow>
            <HelpOutlineIcon fontSize='small' sx={{ verticalAlign: 'middle' }} />
        </Tooltip>
    );
}