import { StepResult } from "../Stepping";
import ManagerBase from "./ManagerBase";

// handles the resolution and storage of questions their resolution history
export default class ResolutionManager extends ManagerBase {
    constructor() {
        super();

        this.assignments = []; // the assignments for presentation
        this.assignmentIndex = 0; // the index of the assignment currently being given (these are generated and added to the history when needed)
        this.replay = [];
        this.pregenCount = 10;
        this.generationCount = 1;
    }

    reinitAssignments(assignments, preCloned, pregenQuestionCount) {
        this.pregenCount = +pregenQuestionCount;
        this.assignments =
            preCloned === true ?
                assignments :
                assignments.map((a) => {
                    return a.clone();
                });

        return this;
    }

    initBegin(state) {
        for (let i = 0; i < this.assignments.length; i++) {
            this.assignments[i].initStep(state);
        }

        this.maximumHistoryIndex = 0;
        this.maximumFound = false;

        this.generationCount = 1;

        // update and add the first one to history
        this.update(0);
    }

    // the init method family are a deconstructed loop
    // they collectively do what initStep used to:
    //      (go through and pregenerate all of the questions so the user can see a max count and have solid history to move through)
    initStep(state) {
        if (this.history.length < this.pregenCount) {
            let result = this.step(this.generationCount - 1) !== StepResult.Failure;
            this.update(this.generationCount);

            // perform an iteration of the loop
            this.generationCount++;
            if (this.generationCount == this.pregenCount) {
                this.maximumFound = true;
                // this means the loop has concluded
            }

            // if the loop has concluded then we're done, reset the index
            if (this.maximumFound) {
                this.historyIndex = 0;
            }

            return result;
        } else {
            return false;
        }
    }

    /* initStep(state) {
        // find the maximum before we start so we can show the user

        // the loop starts by advancing to the next one
        // if that succeeds (because the previous worked)
        // it updates (which stores the current one)
        while (this.step(count - 1) !== StepResult.Failure) {
            this.update(count);

            // ... just running through
            count++;
            if (count == this.pregenCount) {
                this.maximumFound = true;
                break;
            }
        }
        this.historyIndex = 0;
    } */

    getPregenCount() {
        return this.pregenCount;
    }

    getCurrentPregenCount() {
        return this.history.length;
    }

    getReplay() {
        return this.replay;
    }

    getMaximumIndex() {
        return this.history.length;
    }

    getCurrentIndex() {
        return this.historyIndex;
    }

    getCurrent() {
        if (this.historyIndex < this.history.length) {
            return this.history[this.historyIndex];
        } else if (this.assignments.length > 0) {
            let c = this.assignments[this.assignmentIndex].getCurrent();
            if (c !== undefined) {
                this.history.push(c[0]);
                this.replay.push(c[1]);
                this.historyIndex = this.history.length - 1;
                return this.history[this.historyIndex];
            } else if (this.step() !== StepResult.Failure) {
                return this.getCurrent();
            }
        }

        return undefined;
    }

    step(executionIndex) {
        if (this.maximumFound) {
            return StepResult.Failure;
        }

        let outcome = this.assignments[this.assignmentIndex].step(executionIndex);
        if (outcome !== StepResult.Failure) {
            switch (outcome) {
                case StepResult.Stepped:
                    this.assignmentIndex++;
                    break;
            }

            this.maximumHistoryIndex++;
            if (this.assignmentIndex >= this.assignments.length) {
                this.assignmentIndex = 0;
            }
        }

        return outcome;
    }

    update(executionIndex) {
        // regenerate the current output
        this.assignments[this.assignmentIndex].update(executionIndex);

        let prevHL = this.history.length;
        // store it in history
        let c = this.assignments[this.assignmentIndex].getCurrent();
        if (this.history.length === prevHL) {
            this.history.push(c[0]);
            this.replay.push(c[1]);
        }
    }

    // purges history and resets the assignment index
    reset() {
        this.assignmentIndex = 0;
        this.historyIndex = 0;
        this.history = [];
        this.replay = [];
    }

    /* // returns the previous record (starting from the current index) with respect to the showExposition flag
    getPrevRecord(iterations = 1) {
        let outcome = undefined;
        let finalIndex = undefined;

        if (this.showingExposition() === true) {
            let iteration = 0;
            for (let i = this.historyIndex - 1; i >= 0; i--) {
                if (this.history[i] === undefined) break;
                
                outcome = this.history[i];
                finalIndex = this.history.indexOf(outcome);
                if (iteration === iterations) {
                    break;
                } else {
                    iteration++;
                }
            }
        } else {
            // starting from the record preceding our current, step back until we find a valid record.
            let iteration = 0;
            for (let i = this.historyIndex - 1; i >= 0; i--) {
                if (this.history[i] === undefined) break;
                
                if (this.history[i].layout.isExplanatory() === false) {
                    outcome = this.history[i];
                    finalIndex = this.history.indexOf(outcome);
                    if (iteration === iterations) {
                        break;
                    } else {
                        iteration++;
                    }
                }
            }
        }

        return [outcome, finalIndex];
    }

    // returns the next record (starting from the current index) with respect to the showExposition flag
    getNextRecord(iterations = 1) {
        let outcome = undefined;
        let finalIndex = undefined;

        if (this.showingExposition() === true) {
            let iteration = 0;
            for (let i = this.historyIndex; i <= this.history.length; i++) {
                if (this.history[i] === undefined) break;

                outcome = this.history[i];
                finalIndex = this.history.indexOf(outcome);
                if (iteration === iterations) {
                    break;
                } else {
                    iteration++;
                }
            }
        } else {
            // starting from the record preceding our current, step forward until we find a valid record.
            let iteration = 0;
            for (let i = this.historyIndex; i <= this.history.length; i++) {
                if (this.history[i] === undefined) break;
                
                if (this.history[i].layout.isExplanatory() === false) {
                    outcome = this.history[i];
                    finalIndex = this.history.indexOf(outcome);
                    if (iteration === iterations) {
                        break;
                    } else {
                        iteration++;
                    }
                }
            }
        }

        return [outcome, finalIndex];
    } */

    /* // resets the question index
    restart() {
        this.historyIndex = 0;
    }

    first() {
        this.historyIndex = 0;
    }

    previous() {
        if (this.historyIndex > 0) {
            this.historyIndex--;
        }
    }

    latest() {
        this.historyIndex = this.history.length > 0 ? this.history.length - 1 : 0;
    }

    next() {
        let result = true;
        if ((this.history.length - 1) > this.historyIndex) {
            this.historyIndex++;
        } else {
            if (this.step() === StepResult.Failure) {
                result = false;
            }
        }

        return result;
    }

    last() {
        this.historyIndex = this.history.length - 1;
    }

    canFirst() {
        return this.historyIndex > 0;
    }

    canPrevious() {
        return this.historyIndex > 0;
    }

    canLatest() {
        return this.history.length > 1 && this.historyIndex < this.history.length - 1;
    }

    canNext() {
        return this.historyIndex < this.maximumHistoryIndex || this.maximumFound === false;
    }

    canLast() {
        return this.historyIndex < this.maximumHistoryIndex;
    } */
}