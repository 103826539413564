import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Switch } from '@mui/material';
import { randomId } from '@mui/x-data-grid-generator';

//import AddressSelector from '../AddressSelector';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';

import { QuizzicalContext, RunStates, ScreenModes } from '../../../Context/QuizzicalContext';
import TopicsManager from '../../../Controls/TopicsManager';
import GenericSelector from '../../GenericSelector';
import searchQuery from '../../../Libraries/SearchQuery';
import FieldContainer from '../../Forms/FieldContainer';
import FEH_Form from '../../Forms/FEH_Form';
import { Organization } from '../../../Classes/Personnel/Organization';
import { Address } from '../../../Classes/Personnel/Address';
import { AssociationTypes, RoleAssociation } from '../../../Classes/Personnel/RoleAssociation';
import GenericList from '../../Lists/GenericList';

export default function FEH_CourseEditor(props) {
    const state = props.state;
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [defaultFieldSet, setDefaultFieldSet] = React.useState(randomId());

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }
    }, [props]);

    const updateFocus = () => {
        focus.setTitle(document.getElementById("course-title").value);
        focus.setDescription(document.getElementById("course-description").value);
        focus.setCode(document.getElementById("course-code").value);

        return focus;
    }

    /* const onEdited = (event) => {
        if (event.target.id === "course-title") {
            focus.setTitle(event.target.value);
        } else if (event.target.id === "course-description") {
            focus.setDescription(event.target.value);
        } else if (event.target.id === "course-code") {
            focus.setCode(event.target.value);
        }
    } */

    if (isReadOnly) {
        return ( // readonly
            <Box
                sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                }}>
                <Grid spacing={2}>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Typography color="inherit" variant="body" component="div">Code:&nbsp;&nbsp;&nbsp;</Typography>
                        <Typography color="inherit" variant="caption" component="div">{focus.getCode()}</Typography>
                    </Stack>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Typography color="inherit" variant="body" component="div">Title:&nbsp;&nbsp;&nbsp;</Typography>
                        <Typography color="inherit" variant="caption" component="div">{focus.getTitle()}</Typography>
                    </Stack>
                    <Stack>
                        <Typography color="inherit" variant="body" component="div">Description</Typography>
                        <Typography color="inherit" variant="caption" component="div">
                            <pre style={{ fontFamily: 'inherit' }}>
                                {focus.getDescription() || "No Description"}
                            </pre>
                        </Typography>
                    </Stack>
                    <GenericSelector
                        query={(() => {
                            // we want to query orgs and address at *any* depth
                            // easiest way to do that is recursion
                            // this defines a recursive query that searches for orgs under orgs ad infinium
                            // it searches for address off of each org

                            // this works because when records are no longer found,
                            // the query will cleanly terminate and return the result

                            let orgNode = {
                                get: 'getChildren',
                                items: [],
                                return: true,
                            };
                            let addressNode = {
                                get: 'getAddresses',
                                return: true,
                            };

                            orgNode.items.push(orgNode);
                            orgNode.items.push(addressNode);

                            return [orgNode, addressNode];
                        })()}
                        root={state.Organizations}
                        focus={focus}
                        selectionRetrieval="getLocationId"
                        selectionStorage="setLocationId"
                        label="Location"
                        multiMode={false}
                        selectionType={Address.ClassName()}
                        isReadOnly={isReadOnly} />
                    <TopicsManager focus={focus} isReadOnly={isReadOnly} />
                    <GenericList
                        rows={6}
                        propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                        defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                        state={state}
                        fullscreen={false}
                        isReadOnly={isReadOnly}
                        isExpandable={false}
                        label="Students"
                        isCollapsable={false}
                        overrideAddIcon={<PersonAddIcon />}
                        overrideDeleteSelectedIcon={<GroupRemoveIcon />}
                        getTargetItems={() => {
                            return state.Associations.filter((role) => {
                                return role.getWhatId() === focus.getId() && role.getType() === AssociationTypes.Student;
                            });
                        }}
                        setTargetItems={(items) => {
                            let clone = [...state.Associations.filter((role) => {
                                return role.getWhatId() === focus.getId() && role.getType() === AssociationTypes.Student;
                            })];
                            for (let i = 0; i < items.length; i++) {
                                clone.push(items[i]);
                            };

                            state.setAssociations(items);
                        }}
                        getDefaultItem={() => {
                            return RoleAssociation.getDefault(undefined, AssociationTypes.Student, focus.getId());
                        }} />
                </Grid>
            </Box>
        );
    } else {
        let orgIdListing = [];
        {
            let cursor = focus.getParent();
            // build the ancestry tree
            while (cursor.getParent() !== undefined) {
                cursor = cursor.getParent();
                if (cursor.constructor.ClassName() === Organization.ClassName()) {
                    orgIdListing.push(cursor.getId());
                }
            }

            // build the child tree
            let orgs = searchQuery([focus.getParent()],
                (() => {
                    let orgNode = {
                        get: 'getChildren',
                        items: [],
                        return: true,
                    };
                    orgNode.items.push(orgNode);

                    return [orgNode];
                })());
            for (let i = 0; i < orgs.length; i++) {
                orgIdListing.push(orgs[i].getId());
            }
        }

        return ( // editable
            <FEH_Form
                height="88vH"
                formId="CourseForm"
                type="Course"
                getInstance={updateFocus}
                onClose={props.onClose}
                onConfirm={props.onConfirm}
                onReportMessages={props.onReportMessages}>
                <Stack spacing={2}>
                    <FieldContainer
                        display="flow"
                        fieldSet={defaultFieldSet}
                        label="Code"
                        help="The course's identifying code"
                        fullWidth
                        required>
                        <TextField
                            disabled={isReadOnly}
                            id="course-code"
                            fullWidth
                            required
                            defaultValue={focus.getCode()} />
                    </FieldContainer>
                    <FieldContainer
                        display="flow"
                        fieldSet={defaultFieldSet}
                        label="Title"
                        help="The course's title"
                        fullWidth
                        required>
                        <TextField
                            disabled={isReadOnly}
                            id="course-title"
                            fullWidth
                            required
                            defaultValue={focus.getTitle()} />
                    </FieldContainer>
                    <FieldContainer
                        display="flow"
                        fieldSet={defaultFieldSet}
                        label="Description"
                        help="The course's description"
                        fullWidth
                        required>
                        <TextField
                            disabled={isReadOnly}
                            id="course-description"
                            fullWidth
                            required
                            multiline
                            rows={4}
                            defaultValue={focus.getDescription()} />
                    </FieldContainer>
                    <FieldContainer
                        display="column"
                        label="Location"
                        fieldSet={"AddressSelector_Course"}
                        help="The address where the course takes place"
                        required>
                        <GenericSelector
                            query={(() => {
                                // we want to query orgs and address at *any* depth
                                // easiest way to do that is recursion
                                // this defines a recursive query that searches for orgs under orgs ad infinium
                                // it searches for address off of each org

                                // this works because when records are no longer found,
                                // the query will cleanly terminate and return the result

                                let orgNode = {
                                    get: 'getChildren',
                                    items: [],
                                    return: true,
                                };
                                let addressNode = {
                                    get: 'getAddresses',
                                    items: [{
                                        return: (topic, node) => {
                                            // we only want to return addresses that are associated with an organization associated with this course
                                            // (the focus of the CourseEditor control)
                                            return orgIdListing.includes(topic.getParent().getId());
                                        },
                                    }],
                                    return: true,
                                };

                                orgNode.items.push(orgNode);
                                orgNode.items.push(addressNode);

                                return [orgNode, addressNode];
                            })()}
                            root={state.Organizations}
                            focus={focus}
                            selectionRetrieval="getLocationId"
                            selectionStorage="setLocationId"
                            label="Location"
                            multiMode={false}
                            selectionType={Address.ClassName()}
                            isReadOnly={isReadOnly}
                            hideEmpty={true} />
                    </FieldContainer>
                    <TopicsManager focus={focus} isReadOnly={isReadOnly} directions={"Select course topics"} unmountOnExit onReportMessages={props.onReportMessages} />
                    <GenericList
                        rows={6}
                        propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                        defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                        state={state}
                        fullscreen={false}
                        isReadOnly={isReadOnly}
                        isExpandable={false}
                        label="Students"
                        isCollapsable={false}
                        overrideAddIcon={<PersonAddIcon />}
                        overrideDeleteSelectedIcon={<GroupRemoveIcon />}
                        getTargetItems={() => {
                            return state.Associations.filter((role) => {
                                return role.getWhatId() === focus.getId() && role.getType() === AssociationTypes.Student;
                            });
                        }}
                        setTargetItems={(items) => {
                            let clone = [...state.Associations.filter((role) => {
                                return role.getWhatId() === focus.getId() && role.getType() === AssociationTypes.Student;
                            })];
                            for (let i = 0; i < items.length; i++) {
                                clone.push(items[i]);
                            };

                            state.setAssociations(items);
                        }}
                        getDefaultItem={() => {
                            return RoleAssociation.getDefault(undefined, AssociationTypes.Student, focus.getId());
                        }} />
                </Stack>
            </FEH_Form>
        );
    }
}