import * as React from 'react';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Switch } from '@mui/material';
import { randomId } from '@mui/x-data-grid-generator';

import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import { QuizzicalContext, RunStates, ScreenModes } from '../../../Context/QuizzicalContext';
/* import QuestionPool from '../../Classes/Pools';
import QuestionEditor from '../../Controls/Editors/QuestionEditor';
import QuestionList from '../../Controls/Lists/QuestionList'; */
import GenericList from '../../Lists/GenericList';
/* import GenericStorageManagerList from '../../Lists/GenericStorageManagerList'; */
import FieldContainer from '../../Forms/FieldContainer';
import FEH_Form from '../../Forms/FEH_Form';
import { FillInQuestion, MultipleChoiceQuestion, PoolQuestion } from '../../../Classes/Questions';

export default function FEH_QuestionPoolEditor(props) {
    const state = props.state;
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [showListing, setShowListing] = React.useState(props.showListing || true);
    const [isDisabled, setIsDisabled] = React.useState(props.isDisabled || false);
    const [defaultFieldSet, setDefaultFieldSet] = React.useState(randomId());

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }

        if (props.showListing !== undefined &&
            props.showListing !== null &&
            props.showListing !== showListing) {
            setShowListing(props.showListing);
        }

        if (props.isDisabled !== undefined &&
            props.isDisabled !== null &&
            props.isDisabled !== isDisabled) {
            setIsDisabled(props.isDisabled);
        }
    }, [props]);

    const updateFocus = () => {
        focus.setTitle(document.getElementById("questionpool-title").value);
        focus.setDescription(document.getElementById("questionpool-description").value);
        focus.setShuffled(document.getElementById("questionpool-shuffled").checked);

        return focus;
    }

    if (isReadOnly) {
        return ( // readonly
            <Box
                sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                }}>
                <Grid>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Typography color="inherit" variant="body" component="div">Title:&nbsp;&nbsp;&nbsp;</Typography>
                        <Typography color="inherit" variant="caption" component="div">{focus.getTitle()}</Typography>
                    </Stack>
                    <Stack>
                        <Typography color="inherit" variant="body" component="div">Description</Typography>
                        <Typography color="inherit" variant="caption" component="div">
                            <pre style={{ fontFamily: 'inherit' }}>
                                {focus.getDescription() || "No description"}
                            </pre>
                        </Typography>
                    </Stack>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Typography color="inherit" variant="body" component="div">Shuffled:&nbsp;&nbsp;</Typography>
                        <Typography color="inherit" variant="caption" component="div">{focus.isShuffled() ? "Yes" : "No"}</Typography>
                    </Stack>
                    {
                        showListing ?
                            <GenericList
                                rows={6}
                                propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                                defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                                help={"This list displays the pool's questions"}
                                state={state}
                                isReadOnly={isReadOnly}
                                isCollapsable={false}
                                label="Questions"
                                getTargetItems={() => {
                                    return focus.getChildren();
                                }}
                                setTargetItems={(items) => {
                                    focus.setChildren(items);
                                }}
                                getDefaultItem={() => {
                                    return ["question", focus];
                                }}
                                validPastes={[
                                    FillInQuestion.ClassName(),
                                    MultipleChoiceQuestion.ClassName(),
                                    PoolQuestion.ClassName(),
                                ]} /> : ""
                    }
                </Grid>
            </Box>
        );
    } else {
        return ( // editable
            <FEH_Form
                height="88vH"
                formId="QuestionPoolForm"
                type="QuestionPool"
                getInstance={updateFocus}
                onClose={props.onClose}
                onConfirm={props.onConfirm}
                onReportMessages={props.onReportMessages}>
                <Stack spacing={2}>
                    <FieldContainer
                        display="flow"
                        fieldSet={defaultFieldSet}
                        label="Title"
                        help="The question pool's title"
                        fullWidth
                        required>
                        <TextField
                            disabled={isDisabled}
                            id="questionpool-title"
                            fullWidth
                            required
                            defaultValue={focus.getTitle()} />
                    </FieldContainer>
                    <FieldContainer
                        display="flow"
                        fieldSet={defaultFieldSet}
                        label="Description"
                        help="The question pool's description"
                        fullWidth
                        required>
                        <TextField
                            disabled={isDisabled}
                            id="questionpool-description"
                            fullWidth
                            required
                            multiline
                            rows={4}
                            defaultValue={focus.getDescription()} />
                    </FieldContainer>
                    <FieldContainer
                        fieldSet='shuffled-questionpool'
                        swap
                        label="Shuffled"
                        help="If true, question pool will return its sourced questions in random order.">
                        <Switch
                            disabled={isDisabled}
                            id="questionpool-shuffled"
                            defaultChecked={focus.isShuffled()} />
                    </FieldContainer>
                    {
                        showListing ?
                            <GenericList
                                rows={6}
                                propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                                defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                                state={state}
                                help={"This list displays the pool's questions"}
                                isReadOnly={isReadOnly}
                                isCollapsable={false}
                                label="Questions"
                                getTargetItems={() => {
                                    return focus.getChildren();
                                }}
                                setTargetItems={(items) => {
                                    focus.setChildren(items);
                                }}
                                getDefaultItem={() => {
                                    return ["question", focus];
                                }}
                                validPastes={[
                                    FillInQuestion.ClassName(),
                                    MultipleChoiceQuestion.ClassName(),
                                    PoolQuestion.ClassName(),
                                ]} /> : ""
                    }
                </Stack>
            </FEH_Form >
        );
    }
}