import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Switch } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import SaveIcon from '@mui/icons-material/Save';

import { QuizzicalContext, ScreenModes } from '../Context/QuizzicalContext';
import TimestampFormatEditor from '../Controls/TimestampFormatEditor';

export default function SettingsScreen(props) {
    const state = React.useContext(QuizzicalContext);
    const [focus, setFocus] = React.useState(undefined);
    const [expanded, setExpanded] = React.useState('panel1');
    const [needsSave, setNeedsSave] = React.useState(undefined);

    React.useEffect(() => {
        if (state !== undefined) {
            setFocus(getSettingObject());
        }
    }, [state]);

    React.useEffect(() => {
        setNeedsSave(false);
    }, [focus]);

    const getSettingObject = () => {
        if (state === undefined) return {};
        let s = { ...state.Mode };
        s.DefaultExportTimestamp = state.DefaultExportTimestamp;

        return s;
    }

    const doesNeedChanges = () => {
        if (state.Mode.ShowAnswers !== focus.ShowAnswers ||
            state.Mode.ContinuousPlay !== focus.ContinuousPlay ||
            state.Mode.DefaultDuration !== focus.DefaultDuration ||
            state.Mode.DefaultDwell !== focus.DefaultDwell ||
            state.Mode.GlobalDurationHandicapModifier !== focus.GlobalDurationHandicapModifier ||
            state.Mode.GlobalDwellHandicapModifier !== focus.GlobalDwellHandicapModifier ||
            state.Mode.DefaultExportTimestamp !== focus.DefaultExportTimestamp) {
            return true;
        }

        return false;
    }

    const handleSave = () => {
        state.setShowAnswers(focus.ShowAnswers);
        state.setContinuousPlay(focus.ContinuousPlay);
        state.setDefaultDuration(focus.DefaultDuration);
        state.setDefaultDwell(focus.DefaultDwell);
        state.setGlobalDurationHandicapModifier(focus.GlobalDurationHandicapModifier);
        state.setGlobalDwellHandicapModifier(focus.GlobalDwellHandicapModifier);
        state.setDefaultExportTimestamp(focus.DefaultExportTimestamp);
    };

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    if (focus === undefined) return '';
    return (
        <Stack
            direction="row">
            <Stack>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>{state.getRunStateVerb()}s</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack spacing={1} direction="column" alignItems="left">
                            <Stack spacing={1} direction="row" alignItems="center">
                                <Typography>Show Answers</Typography>
                                <Switch
                                    defaultChecked={focus.ShowAnswers}
                                    onChange={(event) => { focus.ShowAnswers = event.target.checked; setNeedsSave(doesNeedChanges()); }} />
                            </Stack>
                            <Stack spacing={1} direction="row" alignItems="center">
                                <Typography>Loop Continuously</Typography>
                                <Switch
                                    defaultChecked={focus.ContinuousPlay}
                                    onChange={(event) => { focus.ContinuousPlay = event.target.checked; setNeedsSave(doesNeedChanges()); }} />
                            </Stack>
                        </Stack>
                        <Stack spacing={1} direction="column" alignItems="left">
                            <Stack spacing={1} direction="row" alignItems="center">
                                <Typography>Default Duration</Typography><TextField
                                    defaultValue={focus.DefaultDuration}
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    onChange={(event) => { focus.DefaultDuration = event.target.value; setNeedsSave(doesNeedChanges()); }} />
                            </Stack>
                            <Stack spacing={1} direction="row" alignItems="center">
                                <Typography>Default Dwell</Typography><TextField
                                    defaultValue={focus.DefaultDwell}
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    onChange={(event) => { focus.DefaultDwell = event.target.value; setNeedsSave(doesNeedChanges()); }} />
                            </Stack>
                            <Stack spacing={1} direction="row" alignItems="center">
                                <Typography>Global Duration Multiplier</Typography>
                                <TextField
                                    defaultValue={focus.GlobalDurationHandicapModifier}
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    onChange={(event) => { focus.GlobalDurationHandicapModifier = event.target.value; setNeedsSave(doesNeedChanges()); }} />
                            </Stack>
                            <Stack spacing={1} direction="row" alignItems="center">
                                <Typography>Global Dwell Multiplier</Typography>
                                <TextField
                                    defaultValue={focus.GlobalDwellHandicapModifier}
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    onChange={(event) => { focus.GlobalDwellHandicapModifier = event.target.value; setNeedsSave(doesNeedChanges()); }} />
                            </Stack>
                        </Stack>
                    </AccordionDetails>
                </Accordion >
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <Typography>Exports</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack spacing={1} direction="row" alignItems="center">
                            <Typography>Default Timestamp Format</Typography>
                            <TimestampFormatEditor
                                default={focus.DefaultExportTimestamp}
                                updateTarget={(newValue) => { 
                                    focus.DefaultExportTimestamp = newValue; 
                                    setNeedsSave(doesNeedChanges()); }}
                                initialValue={state.DefaultExportTimestamp}
                            />
                        </Stack>
                    </AccordionDetails>
                </Accordion >
            </Stack>
            <Box>
                <IconButton disabled={!needsSave} onClick={handleSave}><SaveIcon /></IconButton>
            </Box>
        </Stack>
    );

}