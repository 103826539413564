import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Switch } from '@mui/material';
import { randomId } from '@mui/x-data-grid-generator';

import TopicsManager from '../../TopicsManager';
import GenericList from '../../Lists/GenericList';
import Section from '../../../Classes/Sections';
import FieldContainer from '../../Forms/FieldContainer';
import FEH_Form from '../../Forms/FEH_Form';

export default function FEH_AssignmentEditor(props) {
    const state = props.state;
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [defaultFieldSet, setDefaultFieldSet] = React.useState(randomId());

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }
    }, [props]);

    const updateFocus = () => {
        focus.setTitle(document.getElementById("assignment-title").value);
        focus.setDescription(document.getElementById("assignment-description").value);
        focus.setShuffled(document.getElementById("assignment-shuffled").checked);

        return focus;
    }

    if (isReadOnly) {
        return ( // readonly
            <Box
                sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                }}>
                <Grid spacing={2}>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Typography color="inherit" variant="body" component="div">Title:&nbsp;&nbsp;&nbsp;</Typography>
                        <Typography color="inherit" variant="caption" component="div">{focus.getTitle()}</Typography>
                    </Stack>
                    <Stack>
                        <Typography color="inherit" variant="body" component="div">Description</Typography>
                        <Typography color="inherit" variant="caption" component="div">
                            <pre style={{ fontFamily: 'inherit' }}>
                                {focus.getDescription() || "No Description"}
                            </pre>
                        </Typography>
                    </Stack>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Typography color="inherit" variant="body" component="div">Shuffled:&nbsp;&nbsp;</Typography>
                        <Typography color="inherit" variant="caption" component="div">{focus.isShuffled() ? "Yes" : "No"}</Typography>
                    </Stack>
                    <TopicsManager focus={focus} isReadOnly={isReadOnly} />
                    <GenericList
                        rows={6}
                        propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                        defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                        state={state}
                        help="This list contains the assignment's sections."
                        isReadOnly={isReadOnly}
                        label="Section"
                        isCollapsable={false}
                        getTargetItems={() => {
                            return focus.getChildren();
                        }}
                        setTargetItems={(items) => {
                            focus.setChildren(items);
                        }}
                        getDefaultItem={() => {
                            return Section.getDefault(focus, false, undefined, undefined);
                        }}
                        validPastes={[
                            Section.ClassName(),
                        ]} />
                </Grid>
            </Box>
        );
    } else {
        return (
            <FEH_Form
                height="88vH"
                formId="AssignmentForm"
                type="Assignment"
                getInstance={updateFocus}
                onClose={props.onClose}
                onConfirm={props.onConfirm}
                onReportMessages={props.onReportMessages}>
                <Stack spacing={2}>
                    <FieldContainer
                        fieldSet={defaultFieldSet}
                        label="Title"
                        help="The assignment's title"
                        fullWidth
                        required>
                        <TextField
                            disabled={isReadOnly}
                            id="assignment-title"
                            required
                            defaultValue={focus.getTitle()}
                            sx={{ minWidth: '100%', width: '100%' }} />
                    </FieldContainer>
                    <FieldContainer
                        fieldSet={defaultFieldSet}
                        label="Description"
                        help="A brief summary of the assignment"
                        fullWidth
                        required>
                        <TextField
                            disabled={isReadOnly}
                            id="assignment-description"
                            required
                            multiline
                            rows={4}
                            defaultValue={focus.getDescription()}
                            sx={{ minWidth: '100%', width: '100%' }} />
                    </FieldContainer>
                    <FieldContainer
                        fieldSet='Shuffled_Assignment'
                        swap
                        label="Shuffled"
                        help="If true, the assignment's contents will be displayed in random order">
                        <Switch
                            id="assignment-shuffled"
                            defaultChecked={focus.isShuffled()} />
                    </FieldContainer>
                    <TopicsManager focus={focus} isReadOnly={isReadOnly} directions={"Select assignment topics"} unmountOnExit onReportMessages={props.onReportMessages} />
                    <GenericList
                        rows={6}
                        propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                        defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                        state={state}
                        help="This list contains the assignment's sections."
                        isReadOnly={isReadOnly}
                        isCollapsable={false}
                        label="Section"
                        getTargetItems={() => {
                            return focus.getChildren();
                        }}
                        setTargetItems={(items) => {
                            focus.setChildren(items);
                        }}
                        getDefaultItem={() => {
                            return Section.getDefault(focus, false, undefined, undefined);
                        }}
                        validPastes={[
                            Section.ClassName(),
                        ]} />
                </Stack>
            </FEH_Form>
        );
    }
}