import * as React from 'react';
import { randomId } from '@mui/x-data-grid-generator';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import { Switch } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import HelpIcon from '../Forms/HelpIcon';
import runScript from '../../Libraries/runScript';
import RegionLayoutDisplay from '../ContentDisplay/RegionLayoutDisplay';

// handles display the questions and answers as designed by the user through the editor
export default function PresentationDisplay(props) {
    const state = props.state;
    const [presentationState, setPresentationState] = React.useState(undefined);

    React.useEffect(() => {
        if (props.presentationState !== undefined &&
            props.presentationState !== null &&
            props.presentationState !== presentationState) {
            setPresentationState(props.presentationState);
        }
    }, [props]);

    if (presentationState !== undefined) {
        if (presentationState.state !== undefined &&
            presentationState.when !== undefined &&
            presentationState.when !== 'now') {
            let working = presentationState.state.getCurrent();
            if (working !== undefined) {
                // generate the display
                let display = (<RegionLayoutDisplay
                    sx={{
                        position: 'relative',
                        height: '100%',
                    }}
                    questionData={{
                        showingAnswer: !presentationState.initialDisplay,
                        variables: working.variables,
                        parameters: working.parameters,
                        items: working.items,
                        shuffled: working.shuffled,
                    }}
                    hasSubject={working.hasSubject}
                    focus={working.layout} />);

                return display;
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    } else {
        return undefined;
    }
}