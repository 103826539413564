import PendingIcon from '@mui/icons-material/Pending';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';

import { randomId } from '@mui/x-data-grid-generator';
import { decompressFromUTF16 as lzStringDecompress } from 'lz-string';

import digestSettings from '../../Libraries/JsonDigestion/DigestSettings';
import { getProperDigest } from '../../Libraries/jsonDigestion';
import SelectedImplementation, { whereSelected } from '../SelectImplementation';
import { ensureAllPresent } from '../Topics';
import { AdministrationModes } from '../../Context/QuizzicalContext';

export const PendingImportState = {
    Error: -2,
    Unknown: -1,
    Encrypted: 0,
    Unencrypted: 1,
    ObjectConverted: 2,
    DuplicateImport: 3,
    Preexisting: 4,
    Completed: 5,
};

// This is a wrapper class to make import stage data compatible with the generic list
export class PendingImport extends SelectedImplementation {
    constructor(importStructure, existingImports) {
        super();
        this.existingImports = existingImports;
        this.struct = importStructure;
        this.content = this.struct.encrypted === false ? importStructure.content : null;
        this.obj = null;
        this.id = (importStructure.id + '').includes('-') ? importStructure.id : 'pendingImport_' + randomId();
        this.isClonedInstance = false;
        this.determineState();

        // stores the settings once reobjectified
        this.settings = undefined;
        this.assignments = undefined;
    }

    determineState() {
        if (this.state === PendingImportState.Completed) return;
        let state = PendingImportState.Unknown;

        if (this.struct.encrypted && this.content === null) {
            state = PendingImportState.Encrypted;
        } else if (this.content !== null && this.obj === null) {
            state = PendingImportState.Unencrypted;
        } else if (this.obj !== null) {
            state = PendingImportState.ObjectConverted;

            // check for duplicate imports
            let matches = this.existingImports.filter((pi) => {
                if (pi.getId() === this.getId() ||
                    pi.getState() !== PendingImportState.ObjectConverted) return false;

                return pi.checkAnyMatches(this);
            }).length > 0;

            if (matches) {
                state = PendingImportState.DuplicateImport;
            }
        }

        this.state = state;
    }

    decompress() {
        if (this.getState() === PendingImportState.Encrypted) {
            this.content = lzStringDecompress(this.struct.content);
            this.determineState();
        }
    }

    objectify() {
        try {
            let struct = JSON.parse(this.content);

            let obj = {
                Settings: undefined,
                People: [],
                Associations: [],
                Organizations: [],
                Topics: [],
                Examinations: {
                    Assignments: [],
                    Pools: [],
                },
                AdministrationMode: undefined,
                Version: undefined,
            };

            if (struct.IsExam !== undefined) {
                obj.AdministrationMode = struct.IsExam === true ? AdministrationModes.Exam : AdministrationModes.Normal;
            } else if (struct.AdministrationMode !== undefined) {
                obj.AdministrationMode = struct.AdministrationMode;
            } else {
                obj.AdministrationMode = AdministrationModes.Normal;
            }
            obj.Version = struct.Version;

            if (struct.settings !== undefined) {
                obj.Settings = digestSettings(typeof struct.settings === 'string' ? JSON.parse(struct.settings) : struct.settings);
            }
            obj.Examinations.Assignments = struct.assignments.map((a, i) => {
                return getProperDigest(undefined, a);
            });
            obj.Organizations = struct.organizations.map((o, i) => {
                return getProperDigest(undefined, o);
            });
            obj.People = struct.people.map((p, i) => {
                return getProperDigest(undefined, p);
            });
            obj.Examinations.Pools = struct.questionPools.map((qp, i) => {
                return getProperDigest(undefined, qp);
            });
            obj.Associations = struct.associations.map((a, i) => {
                return getProperDigest(undefined, a);
            });
            obj.Topics = struct.topics.map((t, i) => {
                return getProperDigest(undefined, t);
            });

            this.obj = obj;
            this.determineState();
        } catch (error) {
            this.state = PendingImportState.Error;
        }
    }

    getContent() {
        return this.content;
    }

    getState() {
        return this.state;
    }

    getTitle(state) {
        return this.struct.file;
    }

    getIcon() {
        return <PendingOutlinedIcon />;
    }

    getSelectedIcon() {
        return <PendingIcon />;
    }

    getId() {
        return this.id;
    }

    setId(id) {
        this.id = id;
    }

    getVersion() {
        return this.obj.Version;
    }

    getAdministrationMode() {
        return this.obj.AdministrationMode;
    }

    getJson() {
        return JSON.stringify(this.struct.content);
    }

    checkAnyMatches(pi) {
        // check every root importable data type for id-level duplication with the given pending import instance

        let dupeA = this.obj.Examinations.Assignments.filter((a) => {
            return pi.obj.Examinations.Assignments.filter((pia) => {
                return pia.getId() === a.getId();
            }).length > 0;
        }).length > 0;
        let dupeO = this.obj.Organizations.filter((o) => {
            return pi.obj.Organizations.filter((pio) => {
                return pio.getId() === o.getId();
            }).length > 0;
        }).length > 0;
        let dupeP = this.obj.People.filter((p) => {
            return pi.obj.People.filter((pip) => {
                return pip.getId() === p.getId();
            }).length > 0;
        }).length > 0;
        let dupeQP = this.obj.Examinations.Pools.filter((qp) => {
            return pi.obj.Examinations.Pools.filter((piqp) => {
                return piqp.getId() === qp.getId();
            }).length > 0;
        }).length > 0;
        let dupeR = this.obj.Associations.filter((a) => {
            return pi.obj.Associations.filter((pia) => {
                return pia.getId() === a.getId();
            }).length > 0;
        }).length > 0;

        return (dupeA || dupeO || dupeP || dupeQP || dupeR);
    }

    mergeWith(items) {
        let work = undefined;
        if (!Array.isArray(items)) {
            work = [items];
        } else {
            work = items;
        }

        for (let i = 0; i < work.length; i++) {

        }
    }

    importInto(state) {
        let importedAssignments = whereSelected(this.obj.Examinations.Assignments, true).map((a) => {
            return a.clone(true);
        });

        let importedOrganizations = whereSelected(this.obj.Organizations, true).map((o) => {
            return o.clone(true);
        });

        let importedPeople = whereSelected(this.obj.People, true).map((p) => {
            return p.clone(true);
        });

        let importedPools = whereSelected(this.obj.Examinations.Pools, true).map((qp) => {
            return qp.clone(true);
        });

        let importedAssociations = whereSelected(this.obj.Associations, true).map((r) => {
            return r.clone(true);
        });

        // add the new records to the existing record list in the state object
        state.Examinations.Assignments = [...state.Examinations.Assignments, ...importedAssignments];
        state.Organizations = [...state.Organizations, ...importedOrganizations];
        state.People = [...state.People, ...importedPeople];
        state.Examinations.Pools = [...state.Examinations.Pools, ...importedPools];
        state.Associations = [...state.Associations, ...importedAssociations];
        ensureAllPresent(state.Topics, this.obj.Topics);

        // update the settings
        if (this.obj.Settings !== undefined) {
            this.obj.Settings.update(state);
        }
        if (state.Mode !== undefined) {
            state.Mode.AdministrationMode = this.obj.AdministrationMode;
            state.Mode.Version = this.obj.Version;
        } else {
            state.AdministrationMode = this.obj.AdministrationMode;
            state.Version = this.obj.Version;
        }

        this.state = PendingImportState.Completed;
        return state;
    }

    static ClassName() {
        return "PendingImport";
    }
}