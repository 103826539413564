import Pluralize from 'pluralize';

export default function anyQuery(entry, structure) {
    if (entry === undefined || structure === undefined) {
        return [];
    }

    let result = undefined;
    let cursor = undefined;

    // these are generated to assist the calling control in tracking selection and expansion states for the hierarchical data
    let selectionStructure = {};
    let expansionStructure = {};

    let log = [];
    let maxDepth = 0;

    // prepends an underscore to mark key properties
    let keyify = (text) => {
        return "_" + text;
    }

    // adds a subitem to the current cursor position
    // uses the class name as the key
    let add = (resultNode, selectAddition = true) => {
        let node = resultNode;

        if (!select(node.topic.getId(), node.key)) {
            if (cursor === undefined) {
                if (result === undefined) {
                    result = [node];
                } else {
                    result.push(node);
                }
                cursor = node;
                selectionStructure[node.topic.getId()] = undefined;
            } else {
                let added = false;

                if (cursor[node.key] === undefined) {
                    node.parent = cursor;
                    cursor[node.key] = [node];
                    added = true;
                } else {
                    node.parent = cursor;
                    cursor[node.key].push(node);
                    added = true;
                }

                if (added && selectAddition) {
                    cursor = node;
                }
                selectionStructure[node.topic.getId()] = undefined;
            }
        }
    };

    // selects an immediate child of the current node as the new cursor position
    // returns true upon success, false otherwise
    let select = (id, key) => {
        let found = false;
        if (cursor !== undefined) {
            if (Array.isArray(cursor[key])) {
                for (let i = 0; i < cursor[key].length; i++) {
                    if (cursor[key][i].topic.getId() === id) {
                        cursor = cursor[key][i];
                        found = true;
                        break;
                    }
                }
            }
        } else {
            // check the root results for the given id and type
            if (Array.isArray(result)) {
                for (let i = 0; i < result.length; i++) {
                    if (keyify(result[i].topic.constructor.ClassName()) === key) {
                        if (result[i].topic.getId() === id) {
                            cursor = result[i];
                            found = true;
                            break;
                        }
                    }
                }
            } else if (result !== undefined) {
                if (keyify(result.topic.constructor.ClassName()) === key) {
                    if (result.topic.getId() === id) {
                        cursor = result;
                        found = true;
                    }
                }
            }
        }

        return found;
    };

    // leaves the current node and returns to its parent
    let exfil = () => {
        if (cursor !== undefined) {
            cursor = cursor.parent;
        }
    };

    let isReturned = (topic, node) => {
        let result = false;
        if (node.return !== undefined) {
            if (typeof node.return === "boolean" && node.return === true) {
                result = true;
            } else if (typeof node.return === "function" && node.return(topic, node) === true) {
                result = true;
            }
        }

        return result;
    }

    // entry should be an array of items that structure can be found within
    // 

    // structure should be a nested array of objects of the following format:
    // {
    //      get:
    //          the method used to get the items for this query 
    //          (this method should be present on the object retrieved by the parent query or entry point)
    //      type:
    //          the class name of the item the subsequent query is against
    //          (this is the class retrieved by the query)
    //      return:
    //          whether or not to return this item in the structure
    //          (query results are only returned by default if their items array is empty)
    //      items: 
    //          more objects of this type depicting further depths
    //          (subsequent queries to make against other getter methods)
    //          
    // }

    let recursive = (topic, node, depth) => {
        if (maxDepth < depth) maxDepth = depth;

        if (topic === undefined ||
            node === undefined) {
            log.push({ depth: depth, cause: "topic or node was undefined", node: node });
            return;
        }

        if (topic[node.get] !== undefined && typeof topic[node.get] !== "function") {
            log.push({ depth: depth, cause: "bad getter reference in node.get", node: node });
            return;
        }

        if (!Array.isArray(node.items)) {
            node.items = [];
        }

        if (isReturned(topic, node)) {
            add({
                sLabel: Pluralize(topic.constructor.ClassName(), 1),
                pLabel: Pluralize(topic.constructor.ClassName(), 5),
                topic: topic,
                key: keyify(topic.constructor.ClassName()),
                parent: cursor
            }, true);
        }

        let addCount = 0;
        if (node.get !== undefined) {
            let records = topic[node.get]();
            for (let r = 0; r < records.length; r++) {
                if (node.items.length > 0) {
                    for (let n = 0; n < node.items.length; n++) {
                        addCount += recursive(records[r], node.items[n], depth + 1);
                    }
                } else {
                    // we don't check isReturned here because dead end items (records with no return directive node) are always returned (and selectable)
                    add({
                        sLabel: Pluralize(records[r].constructor.ClassName(), 1),
                        pLabel: Pluralize(records[r].constructor.ClassName(), 5),
                        topic: records[r],
                        key: keyify(records[r].constructor.ClassName()),
                        parent: cursor
                    }, false);
                    addCount++;
                }
            }

            if (addCount > 0) {
                expansionStructure[topic.getId()] = undefined;
            }
        }

        if (isReturned(topic, node)) {
            exfil();
        }

        return addCount;
    }

    // if no query structure is supplied, but a recordset is, then return the record set in the output format
    if (entry !== undefined &&
        Array.isArray(structure) &&
        structure.length === 0) {
        let resultItems = [];
        if (Array.isArray(entry)) {
            for (let i = 0; i < entry.length; i++) {
                resultItems.push({
                    sLabel: Pluralize(entry[i].constructor.ClassName(), 1),
                    pLabel: Pluralize(entry[i].constructor.ClassName(), 5),
                    topic: entry[i],
                    key: keyify(entry[i].constructor.ClassName()),
                    parent: undefined
                });
                selectionStructure[entry[i].getId()] = undefined;
            }
        } else {
            resultItems.push({
                sLabel: Pluralize(entry.constructor.ClassName(), 1),
                pLabel: Pluralize(entry.constructor.ClassName(), 5),
                topic: entry,
                key: keyify(entry.constructor.ClassName()),
                parent: undefined
            });
            selectionStructure[entry.getId()] = undefined;
        }

        return [resultItems, selectionStructure, expansionStructure];
    }

    // starting point dependent on initial structures
    if (Array.isArray(entry)) {
        for (let ei = 0; ei < entry.length; ei++) {
            if (Array.isArray(structure)) {
                for (let si = 0; si < structure.length; si++) {
                    recursive(entry[ei], structure[si], 0);
                }
            } else {
                recursive(entry[ei], structure, 0);
            }
        }
    } else {
        if (Array.isArray(structure)) {
            for (let si = 0; si < structure.length; si++) {
                recursive(entry, structure[si], 0);
            }
        } else {
            recursive(entry, structure, 0);
        }
    }

    return [result, selectionStructure, expansionStructure];
}