export default function handleFileUpload(event, itemsStorage, refreshFunc, headerValidation, doneFunc) {
    if (!Array.isArray(itemsStorage)) return;

    for (var fileIndex = 0; fileIndex < event.target.files.length; fileIndex++)
    {
        var file = event.target.files[fileIndex];
        const reader = new FileReader();
        reader.onerror = (evt) => {
            var item = null;
            for (var i = 0; i < itemsStorage.length; i++) {
                if (itemsStorage[i].file === file.name) {
                    item = itemsStorage[i];
                    break;
                }
            }

            if (item === null) {
                item = {
                    id: i,
                    file: file.name,
                    error: evt.target.error,
                    progress: -1,
                    records: [],
                    headers: []
                };
                itemsStorage.push(item);
            } else {
                item.error = evt.target.error;
                item.progress = -1;
                item.records.length = 0;
            }

            refreshFunc();
        };
        reader.onprogress = (evt) => {
            var item = null;
            for (var i = 0; i < itemsStorage.length; i++) {
                if (itemsStorage[i].file === file.name) {
                    item = itemsStorage[i];
                    break;
                }
            }

            if (item === null) {
                item = {
                    id: i,
                    file: file.name,
                    error: null,
                    progress: evt.total / evt.loaded,
                    records: [],
                    headers: []
                };
                itemsStorage.push(item);
            } else {
                item.progress = evt.total / evt.loaded;
            }

            refreshFunc();
        };
        reader.onload = ((evt) => {
            var item = null;
            for (var i = 0; i < itemsStorage.length; i++) {
                if (itemsStorage[i].file === file.name) {
                    item = itemsStorage[i];
                    break;
                }
            }

            if (item === null) {
                item = {
                    id: i,
                    file: file.name,
                    error: null,
                    progress: 1,
                    records: [],
                    headers: []
                };
                itemsStorage.push(getVerifiedCSVRecords(item, evt.target.result, headerValidation));
            } else {
                getVerifiedCSVRecords(item, evt.target.result, headerValidation);
            }

            doneFunc(itemsStorage);
            refreshFunc();
        });
        reader.readAsText(file);
    }
}

function getNextCSVRow(text, startIndex) {
    var doubleEscaped = false; // "
    var singleEscaped = false; // '
    var result = [];
    var current = "";
    var i = startIndex;
    for (; i < text.length; i++) {
        if (text[i] === "'") {
            if (doubleEscaped) {
                // if it's escaped, then it's a normal character.
                current += text[i];
            } else if (text.length > i + 1) {
                // if it isn't escaped, then check to see if the next character is a duplicate
                if (text[i + 1] === "'") {
                    // if it is, then it's a normal character
                    // import only one character, so skip the first and use the next (the for will skip the second one)
                    i++;
                    current += text[i];
                } else {
                    // if it isn't, then it toggles the escape and gets eaten
                    singleEscaped = !singleEscaped;
                }
            } else {
                // if there are no special conditions then it toggles the escape and gets eaten
                singleEscaped = !singleEscaped;
            }
            continue;
        }

        if (text[i] === '"') {
            if (singleEscaped) { 
                // if it's escaped, then it's a normal character.
                current += text[i];
            } else if (text.length > i + 1) {
                // if it isn't escaped, then check to see if the next character is a duplicate
                if (text[i + 1] === '"') {
                    // if it is, then it's a normal character
                    // import only one character, so skip the first and use the next (the for will skip the second one)
                    i++;
                    current += text[i];
                } else {
                    // if it isn't, then it toggles the escape and gets eaten
                    doubleEscaped = !doubleEscaped;
                }
            } else {
                // if there are no special conditions then it toggles the escape and gets eaten
                doubleEscaped = !doubleEscaped;
            }
            continue;
        }

        if (text[i] === ',' && (!doubleEscaped && !singleEscaped)) {
            result.push(current);
            current = "";
        } else if (text[i] === '"' && singleEscaped) {
            // ignore it
        } else if (text[i] === "'" && doubleEscaped) {
            // ignore it
        } else if (text[i] === '\n') {
            i++;
            break;
        } else if (text[i] === "\r\n") {
            i += 2;
            break;
        } else if (i >= text.length) {
            break;
        } else {
            current += text[i];
        }
    }

    result.push(current);
    return { result: result, endingIndex: i, more: i < text.length }
}

function getCSVRecords(text) {
    var i = 0;
    var result = getNextCSVRow(text, i);
    i = result.endingIndex;
    var results = [];

    var headers = [];
    for (var h = 0; h < result.result.length; h++) {
        headers.push([result.result[h], 130]);
    }

    while (result.more) {
        result = getNextCSVRow(text, i);
        results.push(result.result);
        i = result.endingIndex;
    }

    return { headers: headers, records: results };
}

function getVerifiedCSVRecords(container, text, expectedHeaders) {
    var data = getCSVRecords(text);
    if (data.records.length === 0) return;
    
    var invalid = false;
    var invalidType = false;

    // validate the headers based on the provided pattern
    if (typeof expectedHeaders === 'object' && expectedHeaders.length === data.headers.length) {
        // validate column headers
        for (var i = 0; i < expectedHeaders.length; i++) {
            invalid = !(expectedHeaders[i][0] === data.headers[i][0]);

            if (invalid === true) {
                break;
            } else {
                data.headers[i][1] = expectedHeaders[i][1];
            }
        }

        // validate column values
        for (var r = 0; r < data.records.length; r++) {
            for (var c = 0; c < data.records[r].length; c++) {
                invalidType = !getIsValueValid(data.records[r][c], expectedHeaders[c][2]);

                if (invalidType === true) {
                    break;
                } else {
                    data.records[r][c] = getProperValue(data.records[r][c], expectedHeaders[c][2]);
                }
            }
        }
    } else if (expectedHeaders === undefined || expectedHeaders === null) {
        // explicitly permit no requirements
    } else {
        invalid = true;
    }

    if (invalid === true) {
        container.error = "Header set mismatch.";
        container.records.length = 0;
    } else if (invalidType === true) {
        container.error = "Column type incompatibility.";
        container.records.length = 0;
    } else {
        for (var i = 0; i < data.headers.length; i++) {
            container.headers.push(data.headers[i]);
        }

        for (var i = 0; i < data.records.length; i++) {
            container.records.push(data.records[i]);
            data.records[i][0] = data.records[i][0];
        }
    }
}

function getIsValueValid(value, expectedType) {
    var result = false;
    switch (expectedType) {
        case "string":
            result = true;
            break;
        case "number":
            const parsed = parseInt(value, 10);
            result = !isNaN(parsed);
            break;
        case "boolean":
            result = (value.toLowerCase() == 'true' || value.toLowerCase() == 'false' ? true : false) ;
            break;
    }
    return result;
}

function getProperValue(value, desiredType) {
    var result = null;
    switch (desiredType) {
        case "string":
            result = value + "";
            break;
        case "number":
            const parsed = parseInt(value, 10);
            if (!isNaN(parsed)) {
                result = parsed;
            }
            break;
        case "boolean":
            if (value.toLowerCase() == 'true') {
                result = true;
            } else if (value.toLowerCase() == 'false') {
                result = false;
            }
            break;
    }
    return result;
}

export function getDuplicationKey(record, header, uniqueBy) {
    var parts = [];
    for (var h = 0; h < header.length; h++) {
        for (var u = 0; u < uniqueBy.length; u++) {
            if (header[h][0] === uniqueBy[u]) {
                parts.push(record[h]);
            }
        }
    }

    return parts.join("-");
}

export function getRowObject(record, headers, headerFieldMapping) {
    var row = {};
    for (var m = 0; m < headerFieldMapping.length; m++) {
        for (var h = 0; h < headers.length; h++) {
            if (headerFieldMapping[m].headerName === headers[h][0]) {
                row[headerFieldMapping[m].field] = record[h];
            }
        }
    }

    return row;
}