import { randomId } from "@mui/x-data-grid-generator";
import { LogEntryType, LogHost } from "../Classes/Resolution/ScriptExecutionLog";
import { VariableTypes } from "../Classes/VariableDefinition";

export default function runScript(sourceId, script, name, variableSet, parameterSet) {
    let assembly = undefined;
    let dt = new Date();
    let when = dt.toLocaleDateString() + " " + dt.toLocaleTimeString();

    try {
        // this method returns the string encapsulation character (' or ") to use in the literals when building the script assembly
        let getStringDenomiator = (str) => {
            let result = '\'';
            let indice = str.indexOf('\'');
            if (indice > -1) {
                if (indice === 0) {
                    result = '"';
                } else if (indice > 0 && str[indice - 1] !== '\\') {
                    result = '"';
                }
            } else {
                indice = str.indexOf('"');
                if (indice > -1) {
                    if (indice === 0) {
                        result = '\'';
                    } else if (indice > 0 && str[indice - 1] !== '\\') {
                        result = '\'';
                    }
                }
            }

            return result;
        }

        let id = '_' + randomId().replace(/-/g, '_') + '_output';

        assembly = "\"use strict\";";
        assembly += "\n\n// parameters";
        for (let pi = 0; pi < parameterSet.length; pi++) {
            let p = parameterSet[pi];

            if (typeof p.value === 'string') {
                let encapsulator = getStringDenomiator(p.value);
                assembly += '\nconst ' + p.name + ' = ' + encapsulator + p.value + encapsulator + ';';
            } else {
                let json = JSON.stringify(p.value);
                assembly += '\nconst ' + p.name + ' = ' + json + ';';
            }
        }

        assembly += "\n\n// variables";
        for (let vi = 0; vi < variableSet.length; vi++) {
            let v = variableSet[vi];
            let encapsulator = undefined;

            switch (v.type) {
                case VariableTypes.Number:
                    assembly += '\nlet ' + v.name + ' = ' + v.value + ';';
                    break;
                /* case VariableTypes.BigInt:
                    assembly += '\nlet ' + v.name + ' = ' + v.value + ';';
                    break; */
                case VariableTypes.String:
                    encapsulator = getStringDenomiator(v.value + '');
                    assembly += '\nlet ' + v.name + ' = ' + encapsulator + v.value + encapsulator + ';';
                    break;
                case VariableTypes.DateTime:
                    // handled through on change events
                    if (typeof v.value === 'string') {
                        encapsulator = getStringDenomiator(v.value + '');
                    } else {
                        encapsulator = getStringDenomiator(v.value.toString());
                    }

                    assembly += '\nlet ' + v.name + ' = new Date(' + encapsulator + v.value + encapsulator + ');';
                    break;
                case VariableTypes.Boolean:
                    assembly += '\nlet ' + v.name + ' = ' + v.value + ';';
                    break;
                case VariableTypes.Function:
                    assembly += '\nlet ' + v.name + ' = ' + v.value + ';';
                    break;
                case VariableTypes.ScriptGeneration:
                    assembly += '\nlet ' + v.name + ' = ' + JSON.stringify(v.value) + ';';
                    break;
                default:
                    encapsulator = getStringDenomiator(v.value + '');
                    assembly += '\nlet ' + v.name + ' = ' + encapsulator + v.value + encapsulator + ';';
                    break;
            }
        }

        assembly += "\n\n// script to run\n";
        assembly += "const topicalScriptExecutionFunction = () => {\ntry {\n" + (typeof script === 'string' ? script : '') + "\n}\ncatch(r)\n{\nreturn { hasErrors: true, error: r, script: '" + name + "', };\n}\n};";
        assembly += "\n\n// closing and extraction";

        assembly += "\nreturn {\n";
        assembly += id + ": topicalScriptExecutionFunction(),\n";
        variableSet.forEach((v) => {
            assembly += '\n    ' + v.name + ': ' + v.name + ',';
        });
        assembly += "\n};";

        // run the resulting hodgepodge script and retrieve the returned construct
        let result = Function(assembly)();

        // update the instances using the exported carriage
        let scriptResult = undefined;
        let entries = Object.keys(result);
        for (let i = 0; i < entries.length; i++) {
            let key = entries[i];
            if (key === id) {
                scriptResult = result[key];
            } else {
                let matches = variableSet.filter((v) => {
                    return v.name === key;
                });

                if (matches.length === 1) {
                    if (matches[0].instance !== undefined) {
                        matches[0].instance.setValue(result[key], true);
                    }
                    matches[0].value = result[key];
                }
            }
        }

        if (scriptResult !== undefined) {
            if (scriptResult.hasErrors === true) {
                LogHost.post(sourceId, "Script '" + name + "' encountered an exception", LogEntryType.Error)
                    .reason(scriptResult.error)
                    .adjustCoordinates(variableSet, parameterSet);
                scriptResult.when = when;
                return scriptResult;
            } else {
                return {
                    hasErrors: false,
                    result: scriptResult,
                    script: name,
                    when: when,
                };
            }
        } else {
            return {
                hasErrors: false,
                result: undefined,
                script: name,
                when: when,
            };
        }
    } catch (r) {
        LogHost.post(sourceId, "An error occurred while attemptiong to run script '" + name + "'", LogEntryType.Error)
            .reason(r)
            .adjustCoordinates(variableSet, parameterSet);
        return {
            hasErrors: true,
            error: r,
            script: name,
            when: when,
        };
    }
}

export function interpolateText(text, variableSet, parameterSet) {
    // find and replace all variable and parameter names with their corresponding values.  Strings do not get quates.
    let t = text;
    variableSet.forEach((v) => {
        //const regex = new RegExp(`\\b${v.name}\\b`);
        t = t.replace(v.name, v.value);
    });

    parameterSet.forEach((p) => {
        //const regex = new RegExp(`\\b${p.name}\\b`);
        t = t.replace(p.name, p.value);
    });

    return t;
}