import { HierarchicalItemBase, HierarchicalItemTypes } from '../BaseClassHierarchy';

// private
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

// public
import FlagIcon from '@mui/icons-material/Flag';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';

// defines the geographical location of something
export class Address extends HierarchicalItemBase {
    constructor() {
        super();
    }

    initCreate(parentItem) {
        super.initCreate(parentItem, undefined, undefined, [], HierarchicalItemTypes.Addresss);

        this.street = undefined;
        this.cityState = undefined;
        this.postalCode = undefined;
        this.country = undefined;
        this.publicFacing = false;

        return this;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);

        this.street = struct.street;
        this.cityState = struct.cityState;
        this.postalCode = struct.postalCode;
        this.country = struct.country;
        this.publicFacing = struct.publicFacing;

        return this;
    }

    static getDefault(parent) {
        return new Address().initCreate(parent);
    }

    getIcon() {
        let icon = undefined;
        if (this.publicFacing === true) {
            icon = <FlagOutlinedIcon />;
        } else {
            icon = <LocationOnOutlinedIcon />;
        }

        return icon;
    }

    getSelectedIcon() {
        let icon = undefined;
        if (this.publicFacing === true) {
            icon = <FlagIcon />;
        } else {
            icon = <LocationOnIcon />;
        }

        return icon;
    }

    getStreet() {
        return this.street;
    }

    setStreet(street) {
        this.street = street;
    }

    getCityState() {
        return this.cityState;
    }

    setCityState(cityState) {
        this.cityState = cityState;
    }

    getPostalCode() {
        return this.postalCode;
    }

    setPostalCode(postalCode) {
        this.postalCode = postalCode;
    }

    getCountry() {
        return this.country;
    }

    setCountry(country) {
        this.country = country;
    }

    getPublicFacing() {
        return this.publicFacing;
    }

    setPublicFacing(publicFacing) {
        this.publicFacing = publicFacing;
    }

    clone() {
        let clone = new Address().initCreate(this.getParent(), this.getIcon(), this.getSelectedIcon());

        clone.setId(this.getId());
        clone.setStreet(this.getStreet());
        clone.setCityState(this.getCityState());
        clone.setPostalCode(this.getPostalCode());
        clone.setCountry(this.getCountry());
        clone.setTitle(this.getTitle());
        clone.setDescription(this.getDescription());
        clone.setPublicFacing(this.getPublicFacing());

        return clone;
    }

    getJson() {
        return {
            ...super.getJson(),
            street: this.street,
            cityState: this.cityState,
            postalCode: this.postalCode,
            country: this.country,
            publicFacing: this.publicFacing,
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "Address";
    }
}