import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Switch } from '@mui/material';
import { randomId } from '@mui/x-data-grid-generator';

import { QuizzicalContext, RunStates, ScreenModes } from '../../../Context/QuizzicalContext';
import FieldContainer from '../../Forms/FieldContainer';
import FEH_Form from '../../Forms/FEH_Form';

export default function FEH_Parameter_NumericalSequenceEditor(props) {
    const state = props.state;
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [defaultFieldSet, setDefaultFieldSet] = React.useState(randomId());

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }
    }, [props]);

    const updateFocus = () => {
        focus.setName(document.getElementById("numSeq-name").value);
        focus.setStart(+document.getElementById("numSeq-start").value);
        focus.setEnd(+document.getElementById("numSeq-end").value);
        focus.setStep(+document.getElementById("numSeq-step").value);
        focus.setLooping(document.getElementById("numSeq-looping").checked);

        return focus;
    }

    if (isReadOnly) {
        return ( // readonly
            <Box
                sx={{
                    paddingTop: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                }}>
                <Grid>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Typography color="inherit" variant="body" component="div">Name:&nbsp;&nbsp;&nbsp;</Typography>
                        <Typography color="inherit" variant="caption" component="div">{focus.getName()}</Typography>
                    </Stack>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Stack direction="row">
                            <Typography color="inherit" variant="body" component="div">Start:&nbsp;&nbsp;&nbsp;</Typography>
                            <Typography color="inherit" variant="caption" component="div">{focus.getStart()}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Stack direction="row">
                            <Typography color="inherit" variant="body" component="div">End:&nbsp;&nbsp;&nbsp;</Typography>
                            <Typography color="inherit" variant="caption" component="div">{focus.getEnd()}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Stack direction="row">
                            <Typography color="inherit" variant="body" component="div">Step:&nbsp;&nbsp;&nbsp;</Typography>
                            <Typography color="inherit" variant="caption" component="div">{focus.getStep()}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Stack direction="row">
                            <Typography color="inherit" variant="body" component="div">Looping:&nbsp;&nbsp;&nbsp;</Typography>
                            <Typography color="inherit" variant="caption" component="div">{focus.getLooping() ? "Yes" : "No"}</Typography>
                        </Stack>
                    </Stack>
                </Grid>
            </Box>
        );
    } else {
        return ( // editable
            <FEH_Form
                height="88vH"
                formId="ParameterNumericalSequence"
                type="NumericalSequenceParameter"
                getInstance={updateFocus}
                onClose={props.onClose}
                onConfirm={props.onConfirm}
                onReportMessages={props.onReportMessages}>
                <Stack spacing={2}>
                    <FieldContainer
                        display="flow"
                        fieldSet={defaultFieldSet}
                        label="Name"
                        help="The parameter's name"
                        fullWidth
                        required>
                        <TextField
                            disabled={isReadOnly}
                            id="numSeq-name"
                            fullWidth
                            required
                            defaultValue={focus.getName()} />
                    </FieldContainer>
                    <FieldContainer
                        display="flow"
                        fieldSet={defaultFieldSet}
                        label="Start"
                        help="The sequence's starting numerical value"
                        fullWidth
                        required>
                        <TextField
                            disabled={isReadOnly}
                            id="numSeq-start"
                            type="number"
                            defaultValue={focus.getStart()} />
                    </FieldContainer>
                    <FieldContainer
                        display="flow"
                        fieldSet={defaultFieldSet}
                        label="End"
                        help="The sequence's ending numerical value"
                        fullWidth
                        required>
                        <TextField
                            disabled={isReadOnly}
                            id="numSeq-end"
                            type="number"
                            defaultValue={focus.getEnd()} />
                    </FieldContainer>
                    <FieldContainer
                        display="flow"
                        fieldSet={defaultFieldSet}
                        label="Step"
                        help="The amount by which the sequence's numerical will change on each call of its parent question"
                        fullWidth
                        required>
                        <TextField
                            disabled={isReadOnly}
                            id="numSeq-step"
                            type="number"
                            defaultValue={focus.getStep()} />
                    </FieldContainer>
                    <FieldContainer
                        fieldSet='Looping_NumericalSequenceParameter'
                        swap
                        label="Loop"
                        help="If true, the parameter's items will loop when they reach the end">
                        <Switch
                            disabled={isReadOnly}
                            id="numSeq-looping"
                            defaultChecked={focus.getLooping()} />
                    </FieldContainer>
                </Stack>
            </FEH_Form>
        );
    }
}