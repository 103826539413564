import * as React from 'react';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import { QuizzicalContext, RunStates, ScreenModes } from '../../Context/QuizzicalContext';
import { Parameters, ParameterTypes } from '../../Classes/Parameters';
import { useEffect } from 'react';

import FEH_Parameter_SequentialItemEditor from './Parameters/FEH_Parameter_SequentialItemEditor';
import FEH_Parameter_RandomIntegerEditor from './Parameters/FEH_Parameter_RandomIntegerEditor';
import FEH_Parameter_RandomDecimalEditor from './Parameters/FEH_Parameter_RandomDecimalEditor';
import FEH_Parameter_NumericalSequenceEditor from './Parameters/FEH_Parameter_NumericalSequenceEditor';
import FEH_Parameter_PoolEditor from './Parameters/FEH_Parameter_PoolEditor';

export default function ParameterEditor(props) {
    let editor = (< div />);
    if (props.focus !== undefined &&
        props.focus.getParameterType !== undefined) {
        switch (props.focus.getParameterType()) {
            case ParameterTypes.RandomizedInteger:
                editor = (<FEH_Parameter_RandomIntegerEditor 
                    state={props.state}
                    nameLabel={props.nameLabel}
                    valueLabel={props.valueLabel}
                    type={props.type}
                    onReportMessages={props.onReportMessages}
                    onClose={props.onClose}
                    onConfirm={props.onConfirm}
                    focus={props.focus} 
                    isReadOnly={props.isReadOnly} />);
                break;
            case ParameterTypes.RandomizedDecimal:
                editor = (<FEH_Parameter_RandomDecimalEditor 
                    state={props.state}
                    nameLabel={props.nameLabel}
                    valueLabel={props.valueLabel}
                    type={props.type}
                    onReportMessages={props.onReportMessages}
                    onClose={props.onClose}
                    onConfirm={props.onConfirm}
                    focus={props.focus} 
                    isReadOnly={props.isReadOnly} />);
                break;
            case ParameterTypes.Sequence:
                editor = (<FEH_Parameter_NumericalSequenceEditor 
                    state={props.state}
                    nameLabel={props.nameLabel}
                    valueLabel={props.valueLabel}
                    type={props.type}
                    onReportMessages={props.onReportMessages}
                    onClose={props.onClose}
                    onConfirm={props.onConfirm}
                    focus={props.focus} 
                    isReadOnly={props.isReadOnly} />);
                break;
            case ParameterTypes.Sequential:
                editor = (<FEH_Parameter_SequentialItemEditor 
                    state={props.state}
                    nameLabel={props.nameLabel}
                    valueLabel={props.valueLabel}
                    type={props.type}
                    onReportMessages={props.onReportMessages}
                    onClose={props.onClose}
                    onConfirm={props.onConfirm}
                    focus={props.focus} 
                    isReadOnly={props.isReadOnly} />);
                break;
            case ParameterTypes.Pool:
                editor = (<FEH_Parameter_PoolEditor 
                    state={props.state}
                    nameLabel={props.nameLabel}
                    valueLabel={props.valueLabel}
                    type={props.type}
                    onReportMessages={props.onReportMessages}
                    onClose={props.onClose}
                    onConfirm={props.onConfirm}
                    focus={props.focus} 
                    isReadOnly={props.isReadOnly} />);
                break;
        }

        return editor;
    } else {
        return (<Typography color="inherit" variant="h6" component="div" style={{ textAlign: 'center' }}>Invalid focus provided.</Typography>);
    }
}