import { ShuffleableParentHierarchicalItemBase, HierarchicalItemTypes } from './BaseClassHierarchy';
import DatasetIcon from '@mui/icons-material/Dataset';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import Subject from './Subjects';
import { FillInQuestion, MultipleChoiceQuestion, PoolQuestion } from './Questions';
import { whereSelected } from './SelectImplementation';
import { StepResult } from './Stepping';
import { shuffle } from '../Libraries/Ordering';

///
///  Assignment -> Section -> Subject -> Question
///
export default class Section extends ShuffleableParentHierarchicalItemBase {
    constructor() {
        super();
    }

    initCreate(parentAssignment, icon, selectedIcon, topics, shuffled, title, description) {
        super.initCreate(parentAssignment, icon, selectedIcon, topics, HierarchicalItemTypes.Sections, shuffled, title, description);

        return this;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);

        this.icon = <DatasetOutlinedIcon />;
        this.selectedIcon = <DatasetIcon />;

        this.children = struct.children.map((content, i) => {
            let outcome = undefined;
            switch (content._type) {
                case 'Subject':
                    outcome = new Subject().initJson(this, content);
                    break;
                case 'FillInQuestion':
                    outcome = new FillInQuestion().initJson(this, content);
                    break;
                case 'MultipleChoiceQuestion':
                    outcome = new MultipleChoiceQuestion().initJson(this, content);
                    break;
                case 'PoolQuestion':
                    outcome = new PoolQuestion().initJson(this, content);
                    break;
            }

            return outcome;
        });

        return this;
    }

    static getDefault(parentAssignment, isShuffled, title, description) {
        return new Section().initCreate(parentAssignment, <DatasetOutlinedIcon />, <DatasetIcon />, [], isShuffled, title, description);
    }

    clone(onlySelected = undefined, stripEmptyChildren = false) {
        let clone = new Section().initCreate(this.parent, this.icon, this.selectedIcon, [...this.topics], this.shuffled, this.title, this.description);
        clone.setId(this.getId());

        let clonedChildren = whereSelected(this.getChildren(), onlySelected).map((o, i) => {
            return o.clone(onlySelected, stripEmptyChildren);
        }).filter((o) => {
            let result = true;
            if (stripEmptyChildren === true) {
                if (o.isEmpty !== undefined) {
                    result = o.isEmpty() === false;
                }
            }

            return result;
        });
        clone.setChildren(clonedChildren);

        return clone;
    }

    getJson() {
        return {
            ...super.getJson(),
            _type: this.constructor.ClassName(),
        };
    }

    initStep(state) {
        this.current = 0;
        if (this.isShuffled()) {
            this.working = shuffle([...this.children]);
        } else {
            this.working = [...this.children];
        }

        for (let i = 0; i < this.working.length; i++) {
            this.working[i].initStep(state);
        }
    }

    getCurrent() {
        return this.working[this.current].getCurrent();
    }

    step(executionIndex) {
        let outcome = this.working[this.current].step(executionIndex);

        if (outcome === StepResult.Stepped) {
            this.current++;
            if (this.current >= this.working.length) {
                this.current = 0;
                if (this.isShuffled()) {
                    shuffle(this.working);
                }

                outcome = StepResult.Stepped;
            } else {
                outcome = StepResult.SteppedWithMore;
            }
        }

        return outcome;
    }

    update(executionIndex) {
        this.working[this.current].update(executionIndex);
    }

    static ClassName() {
        return "Section";
    }
}