import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { randomId } from '@mui/x-data-grid-generator';
import { checkOverflow } from '../../Libraries/generalUtility';

export const FieldContainerDisplayModes = {
    Column: 'column', // display label and field vertically
    Row: 'row', // display label and field horizontally
    Flow: 'flow', // display label and field horizontally, but allow them to flow with space constraints
};

/*
** Provides a wrapper for a field, its label, and its helper node
** ensures that all fields in a group have the same proportions
**
** supported properties:
**
** required: displays a required asterisk (*) in the label, if one is provided, and adds a required detail to the help
** swap: swaps the label and control positions
** ignoreLabelWidth: this container will ignore the stored label width
** label: the label to display for the control
** help: the help to display for the property.  This is not displayed if omitted
** fieldSet: <default: 'PrimaryFieldSet'
   used to associate groups of controls together to ensure that all widths will match that of the widest of them
** fullWidth: allows the field to encompass all available horizontal space
** display (default: flow): dictates the way the container is displayed.  
    Accepted values are:

        column: displays label and field vertically
        row: displays label and field horizontally
        flow: displays label and field horizontally, but allow them to flow with space constraints
**
** contents is treated as the field control.
**
**
**
*/
export default function FieldContainer(props) {
    const [selfRefresh, forceSelfRefresh] = React.useState(false);
    const [labelWidth, updateLabelWidth] = React.useState(0);
    const [fieldWidth, updateFieldWidth] = React.useState(0);
    const fieldSetName = React.useRef(undefined);
    const instanceId = React.useRef(randomId());

    const winWidth = React.useRef(window.innerWidth);
    const containerRef = React.useRef(undefined);

    const labelSection = (node) => {
        if (node === null) return;

        if (FieldContainer.FieldSetData[fieldSetName.current] !== undefined) {
            let newValue = getBiggerRealValue(node, FieldContainer.FieldSetData[fieldSetName.current].label);

            if (newValue > FieldContainer.FieldSetData[fieldSetName.current].label) {
                FieldContainer.FieldSetData[fieldSetName.current].label = newValue;
                forceRefreshFieldSet(true, newValue);
            }
        }
    }

    const fieldSection = (node) => {
        if (node === null) return;

        if (FieldContainer.FieldSetData[fieldSetName.current] !== undefined) {
            let newValue = getBiggerRealValue(node, FieldContainer.FieldSetData[fieldSetName.current].field);

            if (newValue > FieldContainer.FieldSetData[fieldSetName.current].field) {
                FieldContainer.FieldSetData[fieldSetName.current].field = newValue;
                forceRefreshFieldSet(false, newValue);
            }
        }
    }

    React.useEffect(() => {
        let fsn = 'PrimaryFieldSet';
        if (props.fieldSet !== undefined) {
            fsn = props.fieldSet;
        }

        if (instanceId.current === null ||
            (typeof instanceId.current === 'string' && instanceId.current.startsWith(fsn) === false)) {
            instanceId.current = fsn + '-' + instanceId.current;
        }

        if (FieldContainer.FieldSetData[fsn] === undefined) {
            FieldContainer.FieldSetData[fsn] = {
                label: getBiggerRealValue(undefined, 0),
                field: getBiggerRealValue(undefined, 0),
                refreshers: {},
            };
        }

        FieldContainer.FieldSetData[fsn].refreshers[instanceId.current] = (isLabel, newValue) => {
            if (isLabel === true) {
                updateLabelWidth(newValue);
            } else {
                updateFieldWidth(newValue);
            }
        };
        fieldSetName.current = fsn;

        forceSelfRefresh(!selfRefresh);
    }, []);

    React.useEffect(() => {
        let a = 0;
        a++;
    }, [selfRefresh]);

    React.useEffect(() => {
        let a = 0;
        a++;
    }, [labelWidth, fieldWidth]);

    React.useLayoutEffect(() => {
        function updateSize() {
            if (winWidth.current !== window.innerWidth &&
                containerRef !== undefined &&
                containerRef.current !== null &&
                containerRef.current !== undefined) {
                winWidth.current = window.innerWidth;

                if (checkOverflow(containerRef.current.parentNode)) {
                    // reset and update the labels
                    FieldContainer.FieldSetData[fieldSetName.current].label = 0;
                    forceRefreshFieldSet(true, 0);

                    // reset and update the fields
                    FieldContainer.FieldSetData[fieldSetName.current].field = 0;
                    forceRefreshFieldSet(false, 0);
                }
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const forceRefreshFieldSet = (isLabel, newValue) => {
        if (fieldSetName.current !== null &&
            fieldSetName.current !== undefined) {
            if (instanceId.current !== null &&
                instanceId.current !== undefined) {
                if (FieldContainer.FieldSetData[fieldSetName.current] !== undefined) {
                    if (FieldContainer.FieldSetData[fieldSetName.current].refreshers[instanceId.current] !== undefined) {
                        let fcIds = Object.keys(FieldContainer.FieldSetData[fieldSetName.current].refreshers);
                        for (let i = 0; i < fcIds.length; i++) {
                            FieldContainer.FieldSetData[fieldSetName.current].refreshers[fcIds[i]](isLabel, newValue);
                        }
                    }
                }
            }
        }
    }

    const getBiggerRealValue = (val1, val2) => {
        let result = undefined;
        if (val1 !== undefined &&
            val1 !== null) {
            result = val1.clientWidth;
        }

        if (val2 !== undefined &&
            val2 !== null) {
            if (result !== undefined) {
                if (val2 > result) {
                    result = val2;
                }
            } else {
                result = val2;
            }
        }

        return result;
    }

    const getSmallerRealValue = (val1, val2) => {
        let result = undefined;
        if (val1 !== undefined &&
            val1 !== null) {
            result = val1.clientWidth;
        }

        if (val2 !== undefined &&
            val2 !== null) {
            if (result !== undefined) {
                if (val2 < result) {
                    result = val2;
                }
            } else {
                result = val2;
            }
        }

        return result;
    }

    const getLabelText = () => {
        let result = props.label;

        if (props.required === true) {
            if (result !== undefined) {
                result = result + '*';
            } else {
                result = '*';
            }
        }

        return result;
    }

    const getHelpText = () => {
        let result = props.help;

        if (props.required === true) {
            if (result !== undefined) {
                result = result + '\n\nField is required';
            } else {
                result = 'Field is required';
            }
        }

        return result;
    }

    const getLabel = () => {
        let styleObj = props.ignoreLabelWidth === true ?
            {} :
            { minWidth: FieldContainer.FieldSetData[fieldSetName.current].label + 'px' };
        if (props.diag === true) {
            styleObj['bgcolor'] = 'brown';
        }

        return (
            props.label !== undefined || props.help !== undefined ?
                <Grid
                    ref={labelSection}
                    key={fieldSetName.current + '-' + randomId()}
                    sx={styleObj}>
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems='center'>
                        {props.label !== undefined ? <Typography item variant='caption'>{getLabelText()}</Typography> : undefined}
                        {props.help !== undefined ?
                            <Tooltip
                                title={<span style={{ whiteSpace: 'pre-line' }}>{getHelpText()}</span>}
                                item
                                arrow>
                                <HelpOutlineIcon fontSize='small' />
                            </Tooltip>
                            : undefined}
                    </Stack>
                </Grid> : undefined
        );
    }

    const getField = () => {
        let styleObj = { minWidth: FieldContainer.FieldSetData[fieldSetName.current].field + 'px' };
        if (props.fullWidth === true) {
            styleObj['flexGrow'] = 1;
        }
        if (props.diag === true) {
            styleObj['bgcolor'] = 'purple';
        }

        return (
            <Grid
                key={fieldSetName.current + '-' + randomId()}
                sx={styleObj}
                ref={fieldSection}>
                {props.children}
            </Grid>
        );
    }

    let display = <Typography>Improver control initialization.  Please see header documentation.</Typography>;
    if (fieldSetName.current !== undefined &&
        fieldSetName.current !== null) {
        let field = getField();
        let label = getLabel();

        if (props.display === FieldContainerDisplayModes.Column ||
            props.display === FieldContainerDisplayModes.Row) {
            let direction = (() => {
                let result = 'row';

                if (props.display === FieldContainerDisplayModes.Column) {
                    if (props.swap === true) {
                        result = 'column-reverse';
                    } else {
                        result = 'column';
                    }
                } else if (props.display === FieldContainerDisplayModes.Row) {
                    if (props.swap === true) {
                        result = 'row-reverse';
                    } else {
                        result = 'row';
                    }
                }

                return result;
            })();

            let styleObj = {};
            if (direction.startsWith('row') === true) {
                styleObj['alignItems'] = 'center';
            }

            display = (
                <Stack
                    ref={containerRef}
                    sx={styleObj}
                    spacing={1}
                    direction={direction}>
                    {[label, field]}
                </Stack>
            );
        } else {
            display = (
                <Grid
                    ref={containerRef}
                    container sx={{ alignItems: 'center' }}
                    spacing={1}>
                    {
                        props.swap === true ? [field, label] : [label, field]
                    }
                </Grid>
            );
        }

        /* return (
            <Grid container sx={{ flexGrow: 1, alignItems: 'center' }} spacing={1}>
                {
                    props.swap === true ? [getField(), getLabel()] : [getLabel(), getField()]
                }
            </Grid>
        ); */
    }
    return display;
}

FieldContainer.FieldSetData = {};