import React, { useEffect, useContext } from 'react';
import AppFooter from './Controls/AppFooter';
import AppMenu from './Controls/AppMenu';

import AssignmentScreen from './Screens/AssignmentScreen';
import PeopleScreen from './Screens/PeopleScreen';
import DashboardScreen from './Screens/DashboardScreen';
import QuestionPoolScreen from './Screens/QuestionPoolScreen';
import OrganizationScreen from './Screens/OrganizationScreen';
import AssociationScreen from './Screens/AssociationScreen';
import SettingsScreen from './Screens/SettingsScreen';
import ImportScreen from './Screens/ImportScreen';
import ExportScreen from './Screens/ExportScreen';

import { QuizzicalContext, ScreenModes } from './Context/QuizzicalContext';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useThemeDetector } from './Libraries/useThemeDetector';
import PresentationScreen from './Screens/PresentationScreen';
import LegacyImportScreen from './Screens/LegacyImportScreen';

export default function AppMain(props) {
    const state = useContext(QuizzicalContext);
    const systemTheme = useThemeDetector() ? 'dark' : 'light';
    const [forceRefresh, setForceRefresh] = React.useState(true);

    useEffect(() => {
        if (state.loading === true) {
            state.theme = systemTheme;
            state.loading = false;

            setForceRefresh(!forceRefresh);
        }
    }, []);

    useEffect(() => {

    }, [forceRefresh]);

    useEffect(() => {
        setForceRefresh(!forceRefresh);
    }, [state])

    const usedTheme = createTheme({ palette: { mode: state.theme, }, });

    const getProperScreen = () => {
        switch (state.Mode.Screen) {
            case ScreenModes.Dashboard:
                return (<DashboardScreen />);
            case ScreenModes.Assignments:
                return (<AssignmentScreen />);
            case ScreenModes.People:
                return (<PeopleScreen />);
            case ScreenModes.Pools:
                return (<QuestionPoolScreen />);
            case ScreenModes.Organizations:
                return (<OrganizationScreen />);
            case ScreenModes.Associations:
                return (<AssociationScreen />);
            case ScreenModes.Settings:
                return (<SettingsScreen />);
            case ScreenModes.Presentation:
                return (<PresentationScreen />);
            case ScreenModes.Import:
                return (<ImportScreen />);
            case ScreenModes.Export:
                return (<ExportScreen />);
            case ScreenModes.LegacyImport:
                return (<LegacyImportScreen />);
        }
    }

    return (
        <ThemeProvider theme={usedTheme}>
            <CssBaseline />
            <AppMenu>
                {getProperScreen()}
            </AppMenu>
            <AppFooter />
        </ ThemeProvider>
    );
}