// hierarchy: <LayoutRegionManager> - 1-many -> <LayoutRegion> - 1-many -> <LayoutContent>
// the region manager manages the size and position of the regions
// the regions manage their content and region merges (they are what the user sees)

import { randomId } from '@mui/x-data-grid-generator';
import SelectedImplementation from '../SelectImplementation';

// this file provides meta content for the layout system data structures,
// along with any required base classes

export const ContentTypes = {
    None: 0,
    Text: 1,
    Image: 2,
    ImageSet: 3,
    Script: 4,
};

export const ContentSources = {
    Unassigned: 0,
    Subject: 1,
    Question: 2,
    Answer: 3,
    Reserved: 4,
}

// The root of the hierarchy.
export class LayoutBase extends SelectedImplementation {
    constructor() {
        super();
        this.parent = undefined;
    }

    initCreate(parent) {
        this.setParent(parent);

        return this;
    }

    initJson(parent, struct) {
        this.parent = parent;
        this.id = struct.id;

        return this;
    }

    getId() {
        return this.id;
    }

    setId(id) {
        this.id = id;
    }

    resetId() {
        if (this.parent !== undefined &&
            this.parent.getId !== undefined) {
            this.setId(this.parent.getId() + "-" + randomId());
        } else {
            this.setId(randomId());
        }
    }

    getParent() {
        return this.parent;
    }

    setParent(parent, updateId = true) {
        this.parent = parent;

        if (updateId) {
            if (this.parent !== undefined &&
                this.parent.getId !== undefined) {
                this.setId(this.parent.getId() + "-" + randomId());
            } else {
                this.setId(randomId());
            }
        }
    }

    clone() {
        let cloned = new LayoutBase(this.getParent());
        cloned.setId(this.getId());

        return cloned;
    }

    getJson() {
        return {
            id: this.id,
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "LayoutBase";
    }
}