/*
    Provides a means of taking an id and getting everything in its chain as a flat array

    Note:
    this is not meant to be dynamic.
    It is hard coded logic based on the known structure of the data (and state variable).  
    Therefore, changes to the system will require that this be updated.
*/
export default function getIdTree(state, id) {
    let results = [];

    if (state === undefined) return results;
    if (id === undefined) return results;
    if (typeof id === 'string' && id.trim() === '') return results;

    // the lookup table works as follows:
    // each class with subsets has an entry
    // the entries will be the class contained, and the value will be the property that contains them:
    // 'Organization': 'getChildren'
    let lookupTable = {
        'Organization': {
            'Address': 'getAddresses',
            'Course': 'getCourses',
            'Organization': 'getChildren'
        },
        'Assignment': {
            'Section': 'getChildren'
        },
        'Section': {
            'Subject': 'getChildren',
            'FillInQuestion': 'getChildren',
            'MultipleChoiceQuestion': 'getChildren',
            'PoolQuestion': 'getChildren',
        },
        'Subject': {
            'FillInQuestion': 'getChildren',
            'MultipleChoiceQuestion': 'getChildren',
            'PoolQuestion': 'getChildren',
        },
    };

    // gets the storage for the given class type on the given cursor instance, if available
    // returns an array: the first value is the result, the second is if the first value is a StorageManager
    let fetchGetter = (cursor, className) => {
        if (lookupTable[cursor.constructor.ClassName()] !== undefined &&
            lookupTable[cursor.constructor.ClassName()][className] !== undefined) {
            return { getter: lookupTable[cursor.constructor.ClassName()][className], isStorage: false };
        } else if (lookupTable[cursor.constructor.ClassName()] !== undefined &&
            lookupTable[cursor.constructor.ClassName()][className + '@'] !== undefined) {
            return { getter: lookupTable[cursor.constructor.ClassName()][className + '@'], isStorage: true };
        }
    }

    // searches the given storage manager for an item with the given key and returns it
    let findInStorage = (storage, id) => {
        if (storage !== undefined && storage.constructor.ClassName() === StorageManager.ClassName()) {
            let items = storage.list().filter((o) => {
                return o.id === id;
            });

            let result = undefined;
            if (items.length === 1) {
                result = items[0];
            }

            return result;
        } else {
            return undefined;
        }
    }

    let findInArray = (array, id) => {
        if (!Array.isArray(array)) return undefined;

        let items = array.filter((o) => {
            return o.id === id;
        });

        let result = undefined;
        if (items.length === 1) {
            result = items[0];
        }

        return result;
    }

    // idParts is the underscore seperated pieces of the original id
    // depthIndex is how far into the interpretation we are (multiples of 2)
    // cursor is the current query target (the derived currentKey should be present within this object)
    let recursive = (idParts, depthIndex, cursor) => {
        let currentKey = idParts.slice(0, depthIndex + 2).join('_');
        let type = idParts[depthIndex];
        let result = undefined;

        if (cursor === state) {
            switch (type) {
                case 'Person':
                    result = findInArray(cursor.People, currentKey);
                    break;
                case 'Organization':
                    result = findInArray(cursor.Organizations, currentKey);
                    break;
                case 'Assignment':
                    result = findInArray(cursor.Examinations.Assignments, currentKey);
                    break;
                case 'QuestionPool':
                    result = findInArray(cursor.Examinations.Pools, currentKey);
                    break;
                case 'RoleAssociation':
                    result = findInArray(cursor.Associations, currentKey);
                    break;
            }
        } else {
            let getterDetails = fetchGetter(cursor, type);
            if (getterDetails.isStorage) {
                result = findInStorage(cursor[getterDetails.getter](), currentKey);
            } else {
                result = findInArray(cursor[getterDetails.getter](), currentKey);
            }
        }

        results.push(result);
        if (result !== undefined &&
            currentKey.length < idParts.join('_').length) {
            result = recursive(idParts, depthIndex + 2, result);
        }
    }

    let idParts = (id + '').split('_');
    recursive(idParts, 0, state);

    return results;
}