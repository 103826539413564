import React, { useContext, useState } from 'react';
import { StateAbbrKeyValueObject } from '../Classes/Personnel/AddressMeta';
import GenericList from '../Controls/Lists/GenericList';
import Assignment from '../Classes/Assignment';

import { QuizzicalContext } from '../Context/QuizzicalContext';

export default function AssignmentScreen(props) {
    const state = useContext(QuizzicalContext);

    return (
        <GenericList
            rows={9}
            isReadOnly={false}
            isCollapsable={false}
            label="Assignment"
            showHeaderLabel={false}
            getTargetItems={() => {
                return state.Examinations.Assignments;
            }}
            setTargetItems={(items) => {
                state.setAssignments(items);
            }}
            getDefaultItem={() => {
                return Assignment.getDefault("New " + state.getRunStateVerb() + " " + (state.Examinations.Assignments.length + 1));
            }}
            validPastes={[
                Assignment.ClassName(),
            ]} />);
}