import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

export default function TimeDisplay(props) {
    const [totalTime, setTotalTime] = React.useState({
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    React.useEffect(() => {
        if (props.time !== undefined &&
            props.time !== null &&
            Number.isInteger(+props.time)) {
            let value = (+props.time) / 1000;

            let seconds = value % 60;
            let hours = Math.floor((value - seconds) / 3600);
            let minutes = Math.floor((value - seconds) / 60) - ((hours * 3600) / 60);

            let result = {
                ...totalTime,
                seconds: Math.floor(seconds),
                minutes: minutes,
                hours: hours,
            };

            setTotalTime(result);
        }
    }, [props]);

    let pad = (num, size) => {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }

    return (
        <Stack padding={1} direction="row" alignItems="center">
            <Typography>{pad(totalTime.hours, 2)}</Typography>
            <Typography>:</Typography>
            <Typography>{pad(totalTime.minutes, 2)}</Typography>
            <Typography>:</Typography>
            <Typography>{pad(totalTime.seconds, 2)}</Typography>
            {props.showIcon === false ? '' : <AccessTimeFilledIcon />}
        </Stack>
    );
}