import { HierarchicalItemBase, HierarchicalItemTypes } from './BaseClassHierarchy';
import DescriptionIcon from '@mui/icons-material/Description';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

///
///  Represents a choice in a multiple choice question
///
export default class QuestionChoiceDefinition extends HierarchicalItemBase {
    constructor() {
        super();
    }

    initCreate(parentItem, icon, selectedIcon, title, description, value, correct, displayCorrectness = true) {
        super.initCreate(parentItem, icon, selectedIcon, undefined, HierarchicalItemTypes.QuestionChoice, title, description)

        this.value = value;
        this.correct = correct;
        this.displayCorrectOptions = displayCorrectness;

        return this;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);
        
        this.icon = <DescriptionOutlinedIcon />;
        this.selectedIcon = <DescriptionIcon />;

        this.value = struct.value;
        this.correct = struct.correct;
        this.displayCorrectOptions = struct.displayCorrectOptions;

        return this;
    }

    static getDefault(parent, title, description, value, isCorrect, displayCorrectOptions = true) {
        let item = new QuestionChoiceDefinition().initCreate(parent, <DescriptionOutlinedIcon />, <DescriptionIcon />, title, description, value, isCorrect, displayCorrectOptions);
        return item;
    }

    setValue(value) {
        this.value = value;
    }

    getValue() {
        return this.value;
    }

    setCorrect(isCorrect) {
        this.correct = isCorrect;
    }

    isCorrect() {
        return this.correct;
    }

    showCorrectness() {
        return this.displayCorrectOptions;
    }

    clone() {
        let clone = new QuestionChoiceDefinition().initCreate(this.parent, this.icon, this.selectedIcon, this.title, this.description, this.value, this.correct, this.displayCorrectOptions);
        clone.setId(this.getId());

        return clone;
    }

    getJson() {
        return {
            ...super.getJson(),
            value: this.value,
            correct: this.correct,
            displayCorrectOptions: this.displayCorrectOptions,
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "QuestionChoiceDefinition";
    }
}