import { randomId } from "@mui/x-data-grid-generator";
import { StepResult } from "../Stepping";

// handles the resolution and storage of questions their resolution history
export default class ManagerBase {
    constructor() {
        this.historyIndex = 0; // the index of the current historical item (this is what current returns)
        this.history = []; // the history array
        this.maximumHistoryIndex = 0;
        this.maximumFound = false;
        this.showExposition = false;
        this.sessionId = randomId();
    }

    initStep(state) {
        this.maximumHistoryIndex = 0;
        this.maximumFound = false;
        this.historyIndex = 0;
        this.showExposition = false;
    }

    showingExposition() {
        return this.showExposition;
    }

    setShowingExposition(show) {
        this.showExposition = show;
    }

    updateCurrentIndex(record) {
        let index = this.history.indexOf(record);
        if (index > -1) {
            this.historyIndex = index;
        }
    }

    getMaximumIndex() {
        return this.maximumHistoryIndex;
    }

    getCurrentIndex() {
        return this.historyIndex;
    }

    setCurrentIndex(index) {
        if (index < this.history.length &&
            index >= 0) {
            this.historyIndex = index;
        }
    }

    seekNewIndex(value) {
        let r, i;
        if (value < 0) {
            [r, i] = this.getPrevRecord(Math.abs(value));
        } else {
            [r, i] = this.getNextRecord(value);
        }
        this.historyIndex = i;
    }

    getCurrent() {
        return undefined;
    }

    step() {
        return StepResult.Failure;
    }

    // purges history and resets the assignment index
    reset() {
        this.historyIndex = 0;
        this.history = [];
    }

    // resets the question index
    restart() {
        this.historyIndex = 0;
    }

    first() {
        let [r, i] = this.getPrevRecord(this.history.length - 1);
        this.historyIndex = i;
    }

    previous() {
        let [r, i] = this.getPrevRecord();
        this.historyIndex = i;
    }

    next() {
        /* let result = true;
        if ((this.history.length - 1) > this.historyIndex) {
            this.historyIndex++;
        } else {
            if (this.step() === StepResult.Failure) {
                result = false;
            }
        }

        return result; */

        let distanceToEnd = (this.history.length - 1) - this.historyIndex;
        let [r, i] = this.getNextRecord();
        let outcome = this.historyIndex !== i && distanceToEnd !== 0;
        if (outcome) {
            this.historyIndex = i;
        }

        return outcome;
    }

    last() {
        let [r, i] = this.getNextRecord(this.history.length - 1);
        this.historyIndex = i;
    }

    // returns the previous record (starting from the current index) with respect to the showExposition flag
    getPrevRecord(iterations = 1) {
        let outcome = this.history[this.historyIndex];
        let finalIndex = this.history.indexOf(outcome);

        if (this.historyIndex !== 0) {
            if (this.showingExposition() === true) {
                let iteration = 0;
                for (let i = this.historyIndex; i >= 0; i--) {
                    outcome = this.history[i];
                    finalIndex = this.history.indexOf(outcome);
                    if (iteration === iterations) {
                        break;
                    } else {
                        iteration++;
                    }
                }
            } else {
                // starting from the record preceding our current, step back until we find a valid record.
                let iteration = 0;
                for (let i = this.historyIndex; i >= 0; i--) {
                    if (this.history[i].layout.isExplanatory() === false) {
                        outcome = this.history[i];
                        finalIndex = this.history.indexOf(outcome);
                        if (iteration >= iterations) {
                            break;
                        }
                    }
                    
                    iteration++;
                }
            }
        }

        return [outcome, finalIndex];
    }

    // returns the next record (starting from the current index) with respect to the showExposition flag
    getNextRecord(iterations = 1) {
        let outcome = this.history[this.historyIndex];
        let finalIndex = this.history.indexOf(outcome);

        if (this.historyIndex < this.history.length - 1) {
            if (this.showingExposition() === true) {
                let iteration = 0;
                for (let i = this.historyIndex; i < this.history.length; i++) {
                    outcome = this.history[i];
                    finalIndex = this.history.indexOf(outcome);
                    if (iteration === iterations) {
                        break;
                    } else {
                        iteration++;
                    }
                }
            } else {
                // starting from the record preceding our current, step forward until we find a valid record.
                let iteration = 0;
                for (let i = this.historyIndex; i < this.history.length; i++) {
                    if (this.history[i].layout.isExplanatory() === false) {
                        outcome = this.history[i];
                        finalIndex = this.history.indexOf(outcome);
                        if (iteration >= iterations) {
                            break;
                        }
                    }
                    
                    iteration++;
                }
            }
        }

        return [outcome, finalIndex];
    }

    canFirst() {
        let [r, i] = this.getPrevRecord(this.history.length - 1);
        return this.historyIndex > i;
    }

    canPrevious() {
        let [r, i] = this.getPrevRecord();
        return this.historyIndex > i;
    }

    canNext() {
        let distanceToEnd = (this.history.length - 1) - this.historyIndex;
        let [r, i] = this.getNextRecord();
        return this.historyIndex !== i && distanceToEnd !== 0;
    }

    canLast() {
        let distanceToEnd = (this.history.length - 1) - this.historyIndex;
        let [r, i] = this.getNextRecord(distanceToEnd);
        return this.historyIndex !== i && distanceToEnd !== 0;
    }
}