import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Paper } from '@mui/material';

import { useTheme } from '@mui/material/styles';

import InsertableJavascriptEditor from '../../Controls/InsertableJavascriptEditor';
import BreadcrumbHelper from '../../Controls/BreadcrumbHelper';

export default function FullscreenCodeEditor(props) {
    const [isOpen, setIsOpen] = React.useState(props.isOpen);
    const [focus, setFocus] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);
    const [disableTermInsertion, setDisableTermInsertion] = React.useState(props.disableTermInsertion === true);

    const theme = useTheme();

    React.useEffect(() => {
        if (isOpen !== props.isOpen &&
            props.isOpen !== undefined &&
            props.isOpen !== null) {
            setIsOpen(props.isOpen);
        }

        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== focus) {
            setFocus(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }

        if (props.disableTermInsertion !== undefined &&
            props.disableTermInsertion !== null &&
            props.disableTermInsertion !== disableTermInsertion) {
            setDisableTermInsertion(props.disableTermInsertion);
        }
    }, [props]);

    const handleConfirm = (event) => {
        props.onConfirm(event);
    }

    return (
        <Dialog
            fullScreen
            open={isOpen}
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle>{BreadcrumbHelper(focus)}</DialogTitle>
            <DialogContent>
                <Paper>
                    <InsertableJavascriptEditor
                        disableTermInsertion={disableTermInsertion}
                        fabStyling={{
                            position: 'absolute',
                            top: '2%',
                            right: '5%',
                        }}
                        width='100%'
                        height='84vH'
                        source={props.source}
                        inEditor={true}
                        focus={focus}
                        isReadOnly={isReadOnly}
                        isMouseOver={true}
                        setter={props.scriptSetter}
                        getter={props.scriptGetter} />
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={handleConfirm}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}