import { Address } from "../Classes/Personnel/Address";
import { Course } from "../Classes/Personnel/Course";
import { Organization } from "../Classes/Personnel/Organization";
import { Person } from "../Classes/Personnel/Person";
import { RoleAssociation } from "../Classes/Personnel/RoleAssociation";
import Assignment from "../Classes/Assignment";
import { FillInQuestion, MultipleChoiceQuestion, PoolQuestion } from "../Classes/Questions";
import {
    RandomizedIntegerParameter,
    RandomizedDecimalParameter,
    NumericalSequenceParameter,
    SequencialParameter,
    PoolParameter
} from "../Classes/Parameters";
import QuestionPool from "../Classes/Pools";
import QuestionChoiceDefinition from "../Classes/QuestionChoiceDefinition";
import ScriptItem from "../Classes/ScriptItem";
import Section from "../Classes/Sections";
import { StorageItem, StorageManager } from "../Classes/StorageManager";
import Subject from "../Classes/Subjects";
import { Topic } from "../Classes/Topics";
import VariableDefinition from "../Classes/VariableDefinition";

import { randomId } from "@mui/x-data-grid-generator";

export default function downloadFiles(event, postErrorFunc, postBeginFunc, postProgressFunc, postCompleteFunc, allDoneFunc) {
    let completionCount = 0;
    let ran = false;
    let files = [];
    for (let i = 0; i < event.target.files.length; i++) {
        files.push({
            f: event.target.files[i],
            id: randomId(),
        });
    }

    files.forEach((r) => {

        const reader = new FileReader();
        reader.onerror = (evt) => {
            postErrorFunc({
                id: r.id,
                file: r.f.name,
                encrypted: (r.f.name + '').endsWith('.sqish'),
                error: evt.target.error,
                progress: -1,
                content: null,
            });
        };

        reader.onloadstart = (evt) => {
            postBeginFunc({
                id: r.id,
                file: r.f.name,
                encrypted: (r.f.name + '').endsWith('.sqish'),
                error: null,
                progress: evt.lengthComputable ? evt.total / evt.loaded : -1,
                content: null,
            });
        };

        reader.onprogress = (evt) => {
            postProgressFunc({
                id: r.id,
                file: r.f.name,
                encrypted: (r.f.name + '').endsWith('.sqish'),
                error: null,
                progress: evt.lengthComputable ? evt.total / evt.loaded : -1,
                content: null,
            });
        };

        reader.onload = (evt) => {
            postCompleteFunc({
                id: r.id,
                file: r.f.name,
                encrypted: (r.f.name + '').endsWith('.sqish'),
                error: null,
                progress: 1,
                content: evt.target.result,
            });

            completionCount++;
            if (completionCount === files.length) {
                allDoneFunc();
            }
        };

        reader.readAsText(r.f);
        ran = true;
    });

    // indicates that the function ran, not that the operations completed
    return ran;
}

export function getProperDigest(parent, struct) {
    let outcome = undefined;

    switch (struct._type) {
        case 'Address':
            outcome = new Address().initJson(parent, struct);
            break;
        case 'Course':
            outcome = new Course().initJson(parent, struct);
            break;
        case 'Organization':
            outcome = new Organization().initJson(parent, struct);
            break;
        case 'Person':
            outcome = new Person().initJson(parent, struct);
            break;
        case 'RoleAssociation':
            outcome = new RoleAssociation().initJson(parent, struct);
            break;
        case 'Assignment':
            outcome = new Assignment().initJson(parent, struct);
            break;
        case 'FillInQuestion':
            outcome = new FillInQuestion().initJson(parent, struct);
            break;
        case 'MultipleChoiceQuestion':
            outcome = new MultipleChoiceQuestion().initJson(parent, struct);
            break;
        case 'PoolQuestion':
            outcome = new PoolQuestion().initJson(parent, struct);
            break;
        case 'RandomizedIntegerParameter':
            outcome = new RandomizedIntegerParameter().initJson(parent, struct);
            break;
        case 'RandomizedDecimalParameter':
            outcome = new RandomizedDecimalParameter().initJson(parent, struct);
            break;
        case 'NumericalSequenceParameter':
            outcome = new NumericalSequenceParameter().initJson(parent, struct);
            break;
        case 'SequencialParameter':
            outcome = new SequencialParameter().initJson(parent, struct);
            break;
        case 'PoolParameter':
            outcome = new PoolParameter().initJson(parent, struct);
            break;
        case 'QuestionPool':
            outcome = new QuestionPool().initJson(parent, struct);
            break;
        case 'QuestionChoiceDefinition':
            outcome = new QuestionChoiceDefinition().initJson(parent, struct);
            break;
        case 'ScriptItem':
            outcome = new ScriptItem().initJson(parent, struct);
            break;
        case 'Section':
            outcome = new Section().initJson(parent, struct);
            break;
        case 'StorageItem':
            outcome = new StorageItem().initJson(parent, struct);
            break;
        case 'StorageManager':
            outcome = new StorageManager().initJson(parent, struct);
            break;
        case 'Subject':
            outcome = new Subject().initJson(parent, struct);
            break;
        case 'Topic':
            outcome = new Topic().initJson(parent, struct);
            break;
        case 'VariableDefinition':
            outcome = new VariableDefinition().initJson(parent, struct);
            break;
        default:
            // do nothing
            break;
    }

    return outcome;
}