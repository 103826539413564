export default function searchQuery(entry, structure, targets) {
    if (entry === undefined || structure === undefined) {
        return [];
    }

    let result = [];

    let log = [];
    let maxDepth = 0;

    let add = (item) => {
        if (result.filter((o) => {
            return o.getId() === item.getId();
        }).length === 0) {
            result.push(item);
        }
    }

    let isReturned = (topic, ids) => {
        if (topic.getId === undefined) return false;
        if (ids === undefined) return true;

        let has = false;
        if (Array.isArray(ids)) {
            has = ids.includes(topic.getId());
        } else {
            has = (topic.getId() === ids);
        }

        return has;
    }

    // entry should be an array of items that structure can be found within
    // 

    // structure should be a nested array of objects of the following format:
    // {
    //      get:
    //          the method used to get the items for this query 
    //          (this method should be present on the object retrieved by the parent query or entry point)
    //      type:
    //          the class name of the item the subsequent query is against
    //          (this is the class retrieved by the query)
    //      return:
    //          whether or not to return this item in the structure
    //          (query results are only returned by default if their items array is empty)
    //      items: 
    //          more objects of this type depicting further depths
    //          (subsequent queries to make against other getter methods)
    //          
    // }

    // targets is an array of ids
    //

    let recursive = (topic, node, depth) => {
        if (maxDepth < depth) maxDepth = depth;

        if (topic === undefined ||
            node === undefined) {
            log.push({ depth: depth, cause: "topic or node was undefined", node: node });
            return;
        }

        if (topic[node.get] !== undefined && typeof topic[node.get] !== "function") {
            log.push({ depth: depth, cause: "bad getter reference in node.get", node: node });
            return;
        }

        if (!Array.isArray(node.items)) {
            node.items = [];
        }

        if (isReturned(topic, targets)) {
            add(topic);
        }

        if (node.get !== undefined) {
            let records = topic[node.get]();
            for (let r = 0; r < records.length; r++) {
                if (node.items.length > 0) {
                    for (let n = 0; n < node.items.length; n++) {
                        recursive(records[r], node.items[n], depth + 1);
                    }
                } else if (isReturned(records[r], targets)) {
                    add(records[r]);
                }
            }
        }
    }

    // if no query structure is supplied, but a recordset is, then return the record set, filtered by the provided ids, in the output format
    if (entry !== undefined &&
        Array.isArray(structure) &&
        structure.length === 0) {
        let resultItems = [];
        if (Array.isArray(entry)) {
            for (let i = 0; i < entry.length; i++) {
                if (isReturned(entry[i], targets)) {
                    resultItems.push(entry[i]);
                }
            }
        } else {
            if (isReturned(entry, targets)) {
                resultItems.push(entry);
            }
        }

        return resultItems;
    }

    // starting point dependent on initial structures
    if (Array.isArray(entry)) {
        for (let ei = 0; ei < entry.length; ei++) {
            if (Array.isArray(structure)) {
                for (let si = 0; si < structure.length; si++) {
                    recursive(entry[ei], structure[si], 0);
                }
            } else {
                recursive(entry[ei], structure, 0);
            }
        }
    } else {
        if (Array.isArray(structure)) {
            for (let si = 0; si < structure.length; si++) {
                recursive(entry, structure[si], 0);
            }
        } else {
            recursive(entry, structure, 0);
        }
    }

    return result;
}