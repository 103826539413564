import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { compressToUTF16 as lzStringCompress } from 'lz-string';

import { AdministrationModes, QuizzicalContext, RunStates, ScreenModes } from '../../Context/QuizzicalContext';
import { HierarchicalItemTypes } from '../../Classes/BaseClassHierarchy';
import { getTimestampSlugMappings, doTimestampSubstitution } from '../../Libraries/TimestampSubstitution';
import TimestampFormatEditor from '../TimestampFormatEditor';
import GenericList, { ListRowContentMode } from '../Lists/GenericList';
import getIdTree from '../../Libraries/getIdTree';
import { jsonifyArray, jsonifyItem, getDependencyListing, safeGetType } from '../../Libraries/generalUtility';
import download from '../../Libraries/download';
import ItemDependencyListing from '../ItemDependencyListing';
import { Topic } from '../../Classes/Topics';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import HelpIcon from '../Forms/HelpIcon';

export default function DataExportManager(props) {
    const state = React.useContext(QuizzicalContext);
    const [expanded, setExpanded] = React.useState(false);
    const [willCompress, setWillCompress] = React.useState(false);
    const [includeSettings, setIncludeSettings] = React.useState(false);
    const [includeDependencies, setIncludeDependencies] = React.useState(true);
    const [insertTimestamp, setInsertTimestamp] = React.useState(false);
    const [exportFileName, setExportFileName] = React.useState('myExport');
    const [timestampFormat, setTimestampFormat] = React.useState(state.DefaultExportTimestamp || 'MMMM-dd-yyyy HH-mm-ss tp');
    const [version, setVersion] = React.useState(state.Mode.Version);
    const [contentType, setContentType] = React.useState(AdministrationModes.Normal);

    const [exportDefinition, setExportDefinition] = React.useState({
        assignments: [],
        questionPools: [],
        people: [],
        associations: [],
        organizations: [],
        topics: [],
    });

    const getRootTopic = (topic) => {
        let t = topic;
        while (t.getParentTopic() !== undefined && t.getParentTopic() !== null) {
            t = t.getParentTopic();
        }

        return t;
    }

    const setContainsTopic = (set, topic) => {
        let result = (
            set.filter((t) => {
                return t.getId() === topic.getId();
            }).length > 0);

        return result;
    }

    const safeGetId = (obj) => {
        if (obj === undefined) return obj;
        return obj.getId !== undefined ? obj.getId() : obj;
    }

    const findInArray = (id, set, idMethod = "getId") => {
        if (!Array.isArray(set)) return undefined;

        let matches = set.filter((item) => {
            return (
                item[idMethod] !== undefined &&
                item[idMethod]() === id
            );
        });

        if (matches.length === 1) {
            return matches[0];
        } else {
            return undefined;
        }
    }

    const getAppropriateArray = (obj, exportResultSet) => {
        let result = [];

        if (obj.getType === undefined) { // topics don't have the getType method
            result = exportResultSet.topics;
        } else {
            switch (obj.getType()) {
                case HierarchicalItemTypes.Assignments:
                    result = exportResultSet.assignments;
                    break;
                case HierarchicalItemTypes.Organizations:
                    result = exportResultSet.organizations;
                    break;
                case HierarchicalItemTypes.People:
                    result = exportResultSet.people;
                    break;
                case HierarchicalItemTypes.QuestionPools:
                    result = exportResultSet.questionPools;
                    break;
                case HierarchicalItemTypes.RoleAssociations:
                    result = exportResultSet.associations;
                    break;
            }
        }

        return result;
    }

    const addDependencies = (obj, exportResultSet) => {
        if (includeDependencies &&
            obj !== undefined &&
            obj.getDependencies !== undefined) {
            // take an object,
            // get its dependencies,
            // loop through the dependencies,
            // check each one against what's in the target set,
            // add the missing ones
            let dependencies = getDependencyListing(obj, state);

            for (let i = 0; i < dependencies.length; i++) {
                if (safeGetType(dependencies[i]) === Topic.ClassName()) {
                    let root = getRootTopic(dependencies[i]);
                    if (!setContainsTopic(exportResultSet.topics, root)) {
                        exportResultSet.topics.push(root);
                    }
                } else {
                    let id = safeGetId(dependencies[i]);
                    if (id !== undefined) {
                        let tree = getIdTree(state, id);

                        if (tree !== undefined && tree[0] !== obj) {
                            if (!findInArray(tree[0].getId(), getAppropriateArray(tree[0], exportResultSet))) {
                                switch (tree[0].getType()) {
                                    case HierarchicalItemTypes.Assignments:
                                        exportResultSet.assignments.push(tree[0]);
                                        break;
                                    case HierarchicalItemTypes.Organizations:
                                        exportResultSet.organizations.push(tree[0]);
                                        break;
                                    case HierarchicalItemTypes.People:
                                        exportResultSet.people.push(tree[0]);
                                        break;
                                    case HierarchicalItemTypes.QuestionPools:
                                        exportResultSet.questionPools.push(tree[0]);
                                        break;
                                    case HierarchicalItemTypes.RoleAssociations:
                                        exportResultSet.associations.push(tree[0]);
                                        break;
                                }

                                // we need to ensure that all requirements for the dependency are exported so the data is complete.
                                // note that this will be a non-issue once a database is implemented because
                                // this lookup won't be nearly as complex
                                addDependencies(tree[0], exportResultSet);
                            }
                        }
                    }
                }
            }
        }
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const getExtension = () => {
        return willCompress ? ".admnPckg.sqish" : ".admnPckg";
    }

    const getExtensionColor = () => {
        let result = "inherit";
        if (exportFileName !== undefined) {
            if (exportFileName.endsWith(getExtension())) {
                result = 'text.secondary';
            }
        }

        return result;
    }

    const onEditFilename = (event) => {
        setExportFileName(event.target.value);
    }

    const onSelectedPeopleChanged = (selected, unselected, allSelected) => {
        setExportDefinition({ ...exportDefinition, people: [...allSelected] });
    }

    const onSelectedRolesChanged = (selected, unselected, allSelected) => {
        setExportDefinition({ ...exportDefinition, associations: [...allSelected] });
    }

    const onSelectedQuestionPoolsChanged = (selected, unselected, allSelected) => {
        setExportDefinition({ ...exportDefinition, questionPools: [...allSelected] });
    }

    const onSelectedAssignmentsChanged = (selected, unselected, allSelected) => {
        setExportDefinition({ ...exportDefinition, assignments: [...allSelected] });
    }

    const onSelectedOrganizationsChanged = (selected, unselected, allSelected) => {
        setExportDefinition({ ...exportDefinition, organizations: [...allSelected] });
    }

    const handleExport = function (exportDef) {

        let results = {
            assignments: [],
            questionPools: [],
            people: [],
            associations: [],
            organizations: [],
            topics: [],
            AdministrationMode: contentType,
            Version: version,
        };

        if (includeSettings) {
            results.settings = {
                AdministratorName: state.AdministratorName,
                DefaultExportTimestamp: state.DefaultExportTimestamp,
                DefaultDuration: state.Mode.DefaultDuration,
                DefaultDwell: state.Mode.DefaultDwell,
                GlobalDwellHandicapModifier: state.Mode.GlobalDwellHandicapModifier,
                GlobalDurationHandicapModifier: state.Mode.GlobalDurationHandicapModifier,
                _type: 'Settings',
            };

            for (let i = 0; i < state.Topics.length; i++) {
                let tree = [state.Topics[i]];
                if (Array.isArray(tree)) {
                    if (!findInArray(tree[0].getId(), getAppropriateArray(tree[0], results))) {
                        results.topics.push(tree[0]);
                    }
                }
            }
        }

        for (let i = 0; i < this.assignments.length; i++) {
            let tree = getIdTree(state, this.assignments[i]);
            if (Array.isArray(tree)) {
                if (!findInArray(safeGetId(tree[0]), getAppropriateArray(tree[0], results))) {
                    results.assignments.push(tree[0]);
                    addDependencies(tree[0], results);
                }
            }
        }

        for (let i = 0; i < this.questionPools.length; i++) {
            let tree = getIdTree(state, this.questionPools[i]);
            if (Array.isArray(tree)) {
                if (!findInArray(safeGetId(tree[0]), getAppropriateArray(tree[0], results))) {
                    results.questionPools.push(tree[0]);
                    addDependencies(tree[0], results);
                }
            }
        }

        for (let i = 0; i < this.people.length; i++) {
            let tree = getIdTree(state, this.people[i]);
            if (Array.isArray(tree)) {
                if (!findInArray(safeGetId(tree[0]), getAppropriateArray(tree[0], results))) {
                    results.people.push(tree[0]);
                    addDependencies(tree[0], results);
                }
            }
        }

        for (let i = 0; i < this.associations.length; i++) {
            let tree = getIdTree(state, this.associations[i]);
            if (Array.isArray(tree)) {
                if (!findInArray(safeGetId(tree[0]), getAppropriateArray(tree[0], results))) {
                    results.associations.push(tree[0]);
                    addDependencies(tree[0], results);
                }
            }
        }

        for (let i = 0; i < this.organizations.length; i++) {
            let tree = getIdTree(state, this.organizations[i]);
            if (Array.isArray(tree)) {
                if (!findInArray(safeGetId(tree[0]), getAppropriateArray(tree[0], results))) {
                    results.organizations.push(tree[0]);
                    addDependencies(tree[0], results);
                }
            }
        }

        let jsonReady = {};
        let properties = Object.keys(results);
        for (let i = 0; i < properties.length; i++) {
            if (Array.isArray(results[properties[i]])) {
                jsonReady[properties[i]] = jsonifyArray(results[properties[i]]);
            } else {
                jsonReady[properties[i]] = jsonifyItem(results[properties[i]]);
            }
        }

        let fileName = exportFileName + (insertTimestamp ? '-' + doTimestampSubstitution(timestampFormat) : '') + getExtension();
        let json = JSON.stringify(jsonReady, null, 4);
        if (willCompress) {
            json = lzStringCompress(json);
        }

        download(json, fileName);
    }

    const handleContentTypeChanged = (e) => {
        setContentType(e.target.value);
    }

    return (
        <FormGroup>
            <Grid spacing={1} padding={2}>
                <Grid sx={12}>
                    <Typography color="inherit">Select Exports</Typography>
                    <FormControlLabel control={<Checkbox value={includeDependencies} defaultChecked onChange={(e) => { setIncludeDependencies(!includeDependencies) }} />} label="Include Dependencies" />
                </Grid>
                <Grid sx={12}>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                Configuration and Settings
                            </Typography>
                            <Typography sx={{ color: 'text.secondary' }}>Export configurations and settings for consistent behavior</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack>
                                <FormControlLabel control={
                                    <Checkbox value={includeSettings} onChange={(e) => { setIncludeSettings(!includeSettings) }} />
                                } label="Include Settings" />
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}>
                                    <Typography>Version</Typography>
                                    <TextField
                                        sx={{
                                            width: 200
                                        }}
                                        label="Version"
                                        onChange={(e) => { setVersion(e.target.value); }}
                                        value={version} />
                                </Stack>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    {
                        state.Organizations.length > 0 ?
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>Organizational Data</Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>Export organizations and related data</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack spacing={2}>
                                        <GenericList
                                            state={state}
                                            mode={ListRowContentMode.Dependencies}
                                            rows={6}
                                            isReadOnly={true}
                                            isCollapsable={false}
                                            label="Organizations"
                                            showHeaderLabel={true}
                                            getTargetItems={() => {
                                                return state.Organizations;
                                            }}
                                            setTargetItems={(items) => {
                                                state.setOrganizations(items);
                                            }}
                                            onSelectionChanged={onSelectedOrganizationsChanged} />
                                    </Stack>
                                </AccordionDetails>
                            </Accordion> : ''
                    }
                    {
                        state.People.length > 0 || state.Associations.length > 0 ?
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        Personnel and Roles
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>Export students and employees</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack spacing={2}>
                                        {
                                            state.People.length > 0 ?
                                                <GenericList
                                                    state={state}
                                                    mode={ListRowContentMode.Dependencies}
                                                    rows={6}
                                                    isReadOnly={true}
                                                    isCollapsable={state.Associations.length > 0}
                                                    label="Personnel"
                                                    showHeaderLabel={true}
                                                    getTargetItems={() => {
                                                        return state.People;
                                                    }}
                                                    setTargetItems={(items) => {
                                                        state.setPeople(items);
                                                    }}
                                                    onSelectionChanged={onSelectedPeopleChanged} /> : ''
                                        }
                                        {
                                            state.Associations.length > 0 ?
                                                <GenericList
                                                    state={state}
                                                    mode={ListRowContentMode.Dependencies}
                                                    rows={6}
                                                    isReadOnly={true}
                                                    isCollapsable={state.People.length > 0}
                                                    label="Personnel Roles"
                                                    showHeaderLabel={true}
                                                    getTargetItems={() => {
                                                        return state.Associations;
                                                    }}
                                                    setTargetItems={(items) => {
                                                        state.setAssociations(items);
                                                    }}
                                                    onSelectionChanged={onSelectedRolesChanged} /> : ''
                                        }
                                    </Stack>
                                </AccordionDetails>
                            </Accordion> : ''
                    }
                    {
                        state.Examinations.Assignments.length > 0 || state.Examinations.Pools.length > 0 ?
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        Classwork
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>Export assignments and question pools</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack spacing={2}>
                                        {
                                            state.Examinations.Assignments.length > 0 ?
                                                <GenericList
                                                    state={state}
                                                    mode={ListRowContentMode.Dependencies}
                                                    rows={6}
                                                    isReadOnly={true}
                                                    isCollapsable={state.Examinations.Pools.length > 0}
                                                    label="Assignment"
                                                    showHeaderLabel={true}
                                                    getTargetItems={() => {
                                                        return state.Examinations.Assignments;
                                                    }}
                                                    setTargetItems={(items) => {
                                                        state.setAssignments(items);
                                                    }}
                                                    onSelectionChanged={onSelectedAssignmentsChanged} /> : ''
                                        }
                                        {
                                            state.Examinations.Pools.length > 0 ?
                                                <GenericList
                                                    state={state}
                                                    mode={ListRowContentMode.Dependencies}
                                                    rows={6}
                                                    isReadOnly={true}
                                                    isCollapsable={state.Examinations.Assignments.length > 0}
                                                    label="Question Pool"
                                                    showHeaderLabel={true}
                                                    getTargetItems={() => {
                                                        return state.Examinations.Pools;
                                                    }}
                                                    setTargetItems={(items) => {
                                                        state.setPools(items);
                                                    }}
                                                    onSelectionChanged={onSelectedQuestionPoolsChanged} /> : ''
                                        }
                                    </Stack>
                                </AccordionDetails>
                            </Accordion> : ''
                    }
                </Grid>
                <Grid
                    marginTop={3}
                    spacing={1}>
                    <Stack
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                        <TextField
                            fullWidth
                            id="export-package-name"
                            required
                            label="Exported Package Name"
                            value={exportFileName}
                            onChange={onEditFilename} />
                        {
                            insertTimestamp ?
                                <>
                                    <Typography style={{ height: '100%', verticalAlign: "middle" }}>&nbsp;-&nbsp;</Typography>
                                    <TimestampFormatEditor
                                        default={state.DefaultExportTimestamp}
                                        updateTarget={(newValue) => { setTimestampFormat(newValue); }}
                                        initialValue={timestampFormat}
                                    />
                                </> : ""
                        }
                        <Typography color={getExtensionColor()} style={{ height: '100%', verticalAlign: "middle" }}>{getExtension()}</Typography>
                    </Stack>
                </Grid>
                <Grid>
                    <FormControlLabel control={<Checkbox value={willCompress} onChange={(e) => { setWillCompress(!willCompress) }} />} label="Compress Exported File" />
                    <FormControlLabel control={<Checkbox value={insertTimestamp} onChange={(e) => { setInsertTimestamp(!insertTimestamp) }} />} label="Include Timestamp" />
                    {/* <FormControlLabel control={<Checkbox value={exportAsExam} onChange={(e) => { setExportAsExam(!exportAsExam) }} />} label="Export as Exam" /> */}
                </Grid>
                <Stack direction="row" alignItems='center'>
                    <FormControl
                        sx={{
                            width: 300
                        }}>
                        <InputLabel id="ContentTypeLabelId">Content Type</InputLabel>
                        <Select
                            labelId="ContentTypeLabelId"
                            id="contentTypeId"
                            value={contentType}
                            label="Content Type"
                            onChange={handleContentTypeChanged}>
                            <MenuItem value={0}>Normal</MenuItem>
                            <MenuItem value={1}>Practice</MenuItem>
                            <MenuItem value={2}>Exam</MenuItem>
                        </Select>
                    </FormControl>
                    <HelpIcon help={"Content Type has three possible values that do the following:\n\n1) Normal mode allows operation of the system as normal after importing the content.\n\n2) Practice mode hides the top menu spread, but does not alter the presentation controls at all.\n\n3) Exam mode hides the top menu spread and limits the user's ability to control the presentation to moving forward and exiting."} />
                    <Box sx={{ flexGrow: 1 }} />
                    <Button
                        color="inherit"
                        variant='outlined'
                        onClick={handleExport.bind(exportDefinition)}>Export</Button>
                </Stack>
            </Grid>
        </FormGroup>
    );
}