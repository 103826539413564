import * as React from 'react';

import FEH_AssignmentEditor from './Assignments/FEH_AssignmentEditor';
import FEH_SectionEditor from './Assignments/FEH_SectionEditor';
import FEH_SubjectEditor from './Assignments/FEH_SubjectEditor';
import FEH_ScriptEditor from './Assignments/FEH_ScriptEditor';
import FEH_QuestionEditor from './FEH_QuestionEditor';
import FEH_QuestionPoolEditor from './Assignments/FEH_QuestionPoolEditor'
import FEH_OrganizationEditor from './Personnel/FEH_OrganizationEditor';
import FEH_CourseEditor from './Personnel/FEH_CourseEditor';
import FEH_AddressEditor from './Personnel/FEH_AddressEditor';
import FEH_AssociationEditor from './Personnel/FEH_AssociationEditor';
import FEH_PeopleEditor from './Personnel/FEH_PeopleEditor';
import FEH_ChoiceItemEditor from './Questions/FEH_ChoiceItemEditor';
import FEH_VariableEditor from './Assignments/FEH_VariableEditor';
/* 
import VariableEditor from '../../Controls/Editors/VariableEditor';
import QuestionItemEditor from '../../Controls/Editors/QuestionItemEditor';
import ParameterItemEditor from '../../Controls/Editors/ParameterItemEditor';
 */
import FEH_ParameterEditor from './FEH_ParameterEditor';
import { HierarchicalItemTypes } from '../../Classes/BaseClassHierarchy';
import FEH_StorageItemEditor from './Assignments/FEH_StorageItemEditor';
import FEH_PendingImport from './Imports/FEH_PendingImport';
import { RoleAssociation } from '../../Classes/Personnel/RoleAssociation';
import { PendingImport } from '../../Classes/Import/PendingImport';
import { LayoutRegionManager } from '../../Classes/Layout/LayoutRegionManager';
import FEH_LayoutEditor from './Questions/FEH_LayoutEditor';

/* 
**  Chooses and displays the correct form based on the provided object 
*/
export default function FEH_GenericProvider(props) {
    const [target, setTarget] = React.useState(props.focus);
    const [isReadOnly, setIsReadOnly] = React.useState(props.isReadOnly || false);

    React.useEffect(() => {
        if (props.focus !== undefined &&
            props.focus !== null &&
            props.focus !== target) {
            setTarget(props.focus);
        }

        if (props.isReadOnly !== undefined &&
            props.isReadOnly !== null &&
            props.isReadOnly !== isReadOnly) {
            setIsReadOnly(props.isReadOnly);
        }
    }, [props]);

    var editor = (<div />);
    if (target !== undefined &&
        target !== null) {
        if (target.constructor.ClassName() === RoleAssociation.ClassName()) {
            editor = (<FEH_AssociationEditor
                propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                state={props.state}
                showRoundedBorderTop={props.showRoundedBorderTop}
                onReportMessages={props.onReportMessages}
                onClose={props.onClose}
                onConfirm={props.onConfirm}
                focus={target}
                isReadOnly={isReadOnly} />);
        } else if (target.constructor.ClassName() === PendingImport.ClassName()) {
            editor = (<FEH_PendingImport
                propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                state={props.state}
                showRoundedBorderTop={props.showRoundedBorderTop}
                onReportMessages={props.onReportMessages}
                onClose={props.onClose}
                onConfirm={props.onConfirm}
                focus={target}
                isReadOnly={isReadOnly} />);
        } else if (target.constructor.ClassName() === LayoutRegionManager.ClassName()) {
            editor = (<FEH_LayoutEditor
                propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                state={props.state}
                showRoundedBorderTop={props.showRoundedBorderTop}
                onReportMessages={props.onReportMessages}
                onClose={props.onClose}
                onConfirm={props.onConfirm}
                focus={target}
                isReadOnly={isReadOnly} />);
        } else if (target.getType === undefined) {
            if (target.getParameterType !== undefined) {

            } else {
                if (props.items === true) {
                    /* editor = (<QuestionItemEditor focus={target} isReadOnly={isReadOnly} />); */
                } else if (props.parameterItems === true) {
                    /* editor = (<ParameterItemEditor focus={target} isReadOnly={isReadOnly} />); */
                } else {
                    /* editor = (<VariableEditor focus={target} isReadOnly={isReadOnly} />); */
                }
            }

            if (target.getParameterType !== undefined) { // parameter
                editor = (<FEH_ParameterEditor
                    propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                    defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                    state={props.state}
                    showRoundedBorderTop={props.showRoundedBorderTop}
                    nameLabel={target.nameLabel}
                    valueLabel={target.valueLabel}
                    type={target.type}
                    onReportMessages={props.onReportMessages}
                    onClose={props.onClose}
                    onConfirm={props.onConfirm}
                    focus={target}
                    isReadOnly={isReadOnly} />);
            } else {// storage manager item
                editor = (<FEH_StorageItemEditor
                    propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                    defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                    state={props.state}
                    showRoundedBorderTop={props.showRoundedBorderTop}
                    nameLabel={target.nameLabel}
                    valueLabel={target.valueLabel}
                    tertiaryLabel={target.tertiaryLabel}
                    type={target.type}
                    onReportMessages={props.onReportMessages}
                    onClose={props.onClose}
                    onConfirm={props.onConfirm}
                    focus={target}
                    isReadOnly={isReadOnly} />);
            }
        } else { // everything else
            if (target.getType() === HierarchicalItemTypes.Assignments) {
                editor = (
                    <FEH_AssignmentEditor
                        propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                        defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                        state={props.state}
                        showRoundedBorderTop={props.showRoundedBorderTop}
                        onReportMessages={props.onReportMessages}
                        onClose={props.onClose}
                        onConfirm={props.onConfirm}
                        focus={target}
                        isReadOnly={isReadOnly} />
                );
            } else if (target.getType() === HierarchicalItemTypes.Sections) {
                editor = (
                    <FEH_SectionEditor
                        propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                        defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                        state={props.state}
                        showRoundedBorderTop={props.showRoundedBorderTop}
                        onReportMessages={props.onReportMessages}
                        onClose={props.onClose}
                        onConfirm={props.onConfirm}
                        focus={target}
                        isReadOnly={isReadOnly} />
                );
            } else if (target.getType() === HierarchicalItemTypes.Subjects) {
                editor = (<FEH_SubjectEditor
                    propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                    defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                    state={props.state}
                    showRoundedBorderTop={props.showRoundedBorderTop}
                    onReportMessages={props.onReportMessages}
                    onClose={props.onClose}
                    onConfirm={props.onConfirm}
                    focus={target}
                    isReadOnly={isReadOnly} />);
            } else if (target.getType() === HierarchicalItemTypes.Script) {
                editor = (<FEH_ScriptEditor
                    propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                    defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                    state={props.state}
                    showRoundedBorderTop={props.showRoundedBorderTop}
                    onReportMessages={props.onReportMessages}
                    onClose={props.onClose}
                    onConfirm={props.onConfirm}
                    focus={target}
                    isReadOnly={isReadOnly} />);
            } else if (target.getType() === HierarchicalItemTypes.Questions) {
                editor = (<FEH_QuestionEditor
                    propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                    defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                    state={props.state}
                    showRoundedBorderTop={props.showRoundedBorderTop}
                    onReportMessages={props.onReportMessages}
                    onClose={props.onClose}
                    onConfirm={props.onConfirm}
                    focus={target}
                    isReadOnly={isReadOnly} />);
            } else if (target.getType() === HierarchicalItemTypes.QuestionPools) {
                editor = (<FEH_QuestionPoolEditor
                    propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                    defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                    state={props.state}
                    showRoundedBorderTop={props.showRoundedBorderTop}
                    onReportMessages={props.onReportMessages}
                    onClose={props.onClose}
                    onConfirm={props.onConfirm}
                    focus={target}
                    isReadOnly={isReadOnly} />);
            } else if (target.getType() === HierarchicalItemTypes.Organizations) {
                editor = (<FEH_OrganizationEditor
                    propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                    defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                    state={props.state}
                    showRoundedBorderTop={props.showRoundedBorderTop}
                    onReportMessages={props.onReportMessages}
                    onClose={props.onClose}
                    onConfirm={props.onConfirm}
                    focus={target}
                    isReadOnly={isReadOnly} />);
            } else if (target.getType() === HierarchicalItemTypes.Classes) {
                editor = (<FEH_CourseEditor
                    propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                    defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                    state={props.state}
                    showRoundedBorderTop={props.showRoundedBorderTop}
                    onReportMessages={props.onReportMessages}
                    onClose={props.onClose}
                    onConfirm={props.onConfirm}
                    focus={target}
                    isReadOnly={isReadOnly} />);
            } else if (target.getType() === HierarchicalItemTypes.Addresss) {
                editor = (<FEH_AddressEditor
                    propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                    defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                    state={props.state}
                    showRoundedBorderTop={props.showRoundedBorderTop}
                    onReportMessages={props.onReportMessages}
                    onClose={props.onClose}
                    onConfirm={props.onConfirm}
                    focus={target}
                    isReadOnly={isReadOnly} />);
            } else if (target.getType() === HierarchicalItemTypes.People) {
                editor = (<FEH_PeopleEditor
                    propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                    defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                    state={props.state}
                    showRoundedBorderTop={props.showRoundedBorderTop}
                    onReportMessages={props.onReportMessages}
                    onClose={props.onClose}
                    onConfirm={props.onConfirm}
                    focus={target}
                    isReadOnly={isReadOnly} />);
            } else if (target.getType() === HierarchicalItemTypes.QuestionChoice) {
                editor = (<FEH_ChoiceItemEditor
                    propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                    defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                    state={props.state}
                    showRoundedBorderTop={props.showRoundedBorderTop}
                    onReportMessages={props.onReportMessages}
                    onClose={props.onClose}
                    onConfirm={props.onConfirm}
                    focus={target}
                    isReadOnly={isReadOnly} />);
            } else if (target.getType() === HierarchicalItemTypes.Variable) {
                editor = (<FEH_VariableEditor
                    propagateDefaultSelectedState={props.propagateDefaultSelectedState}
                    defaultSelectedState={props.propagateDefaultSelectedState === true ? props.defaultSelectedState : undefined}
                    state={props.state}
                    showRoundedBorderTop={props.showRoundedBorderTop}
                    onReportMessages={props.onReportMessages}
                    onClose={props.onClose}
                    onConfirm={props.onConfirm}
                    focus={target}
                    isReadOnly={isReadOnly} />);
            }

            /*        */
        }
    }

    return (
        editor
    );
}