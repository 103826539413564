import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

import TopicIcon from '@mui/icons-material/Topic';

import { getDependencyListing, getUniqueElements } from '../Libraries/generalUtility';

export default function ArbitraryTopicSetListing(props) {
    const getTopicListing = (topicSet) => {
        let treeRoots = topicSet.map((topic) => {
            let t = topic;
            while (t.getParentTopic() !== undefined && t.getParentTopic() !== null) {
                t = t.getParentTopic();
            }

            return t;
        });
        let listing = getUniqueElements(treeRoots).map((topic, i) => {
            return (
                <Chip
                    sx={{
                        textTransform: 'none'
                    }}
                    key={"topic-item-" + i}
                    aria-describedby={"clicky"}
                    variant="outlined"
                    label={
                        <Stack direction="row">
                            <TopicIcon fontSize='small' />&nbsp;&nbsp;
                            <Typography color="inherit" variant="inherit" component="div" style={{ textAlign: 'left' }}>{topic.getSimpleLabel()}</Typography>
                        </Stack>
                    } />
            );
        });

        return listing;
    }

    if (Array.isArray(props.topics)) {
        return (
            <Stack direction='row'>
                {getTopicListing(props.topics)}
            </Stack>
        );
    } else {
        return <div />;
    }
}