import { randomId } from '@mui/x-data-grid-generator';
import { shuffle } from '../Libraries/Ordering';
import { jsonifyArray, getDependencySet } from '../Libraries/generalUtility';
import Dependency from './Dependency';

import { searchTopicsByFullLabel, searchTopicSet } from './Topics';
import SelectedImplementation from './SelectImplementation';
/* import { getProperDigest } from '../Libraries/jsonDigestion'; */

export const HierarchicalItemTypes = {
    Assignments: 0,
    Sections: 1,
    Subjects: 2,
    Questions: 3,
    QuestionPools: 4,
    Script: 5,
    Addresss: 6,
    Classes: 7,
    Organizations: 8,
    People: 9,
    RoleAssociations: 10,
    Variable: 11,
    QuestionChoice: 12,
};

// base class for Questions
export class HierarchicalItemBase extends SelectedImplementation {
    constructor() {
        super();
        this.uniqueId = randomId();
        this.type = undefined;
        this.icon = undefined;
        this.selectedIcon = undefined;
        this.topics = [];
        this.description = undefined;
        this.parent = undefined;
        this.isClonedInstance = false;
        this.title = undefined;
        this.onTitleUpdated = undefined;
    }

    initCreate(parentItem, icon, selectedIcon, topics, type, title, description) {
        this.type = type;
        this.icon = icon;
        this.selectedIcon = selectedIcon;
        this.topics = Array.isArray(topics) ? topics : [];
        this.description = description;
        if (this.setParent !== undefined) {
            this.setParent(parentItem, type);
        }
        this.title = title;
        super.Unselect();

        return this;
    }

    initJson(parent, struct) {
        this.parent = parent;
        this.id = struct.id;
        this.type = struct.type;
        this.topics = struct.topics;
        this.description = struct.description;
        this.title = struct.title;
        super.Select();

        return this;
    }

    setOnTitleUpdated(handler) {
        this.onTitleUpdated = handler;
    }

    getDependencies(rootCall = true, state) {
        let items = [];//.concat(this.topics);
        for (let i = 0; i < this.topics.length; i++) {
            items.push(searchTopicsByFullLabel(state.Topics, this.topics[i]));
        }

        if (rootCall) {
            return getDependencySet(items);
        }

        return items;
    }

    isClone() {
        return this.isClonedInstance;
    }

    setIsClone() {
        this.isClonedInstance = true;
    }

    setId(id) {
        this.id = id;
    }

    getId() {
        return this.id;
    }

    resetId() {
        if (this.parent === undefined) {
            this.id = this.getTypePrefix(this, false) + randomId();
        } else {
            let newId = this.getParent().getNextChildId(this, true);
            this.setId(newId);

            if (this.resetChildIds !== undefined) {
                this.resetChildIds();
            }
        }

        if (this.resetChildIds !== undefined) {
            this.resetChildIds();
        }
    }

    getUniqueId() {
        return this.uniqueId;
    }

    getIcon() {
        return this.icon;
    }

    getSelectedIcon() {
        return this.selectedIcon;
    }

    getType() {
        return this.type;
    }

    getTypePrefix(o, inMiddle = true) {
        return (inMiddle ? '_' : '') + o.constructor.ClassName() + '_'
    }

    getTopics() {
        return this.topics;
    }

    setTopics(topics) {
        this.topics = topics;
    }

    getParent() {
        return this.parent;
    }

    getDescription() {
        return this.description;
    }

    setDescription(description) {
        if (description === undefined) {
            this.description = undefined;
        } else {
            if (description.trim() === "") {
                this.description = undefined;
            } else {
                this.description = description;
            }
        }
    }

    setParent(parentItem) {
        this.parent = parentItem;
        if (parentItem !== null && parentItem !== undefined && parentItem.getNextChildId !== undefined) {
            this.id = parentItem.getNextChildId(this);
        } else {
            this.id = this.getTypePrefix(this, false) + randomId();
        }
    }

    getTitle() {
        return this.title;
    }

    setTitle(title) {
        let t = this.title;
        if (title === undefined) {
            this.title = undefined;
        } else {
            if (title.trim() === "") {
                this.title = undefined;
            } else {
                this.title = title;
            }
        }

        if (this.title !== t &&
            this.onTitleUpdated !== undefined &&
            typeof this.onTitleUpdated === 'function') {
            this.onTitleUpdated(this, t, this.title);
        }
    }

    getJson() {
        return {
            id: this.id,
            type: this.type,
            topics: jsonifyArray(this.topics),
            description: this.description,
            title: this.title,
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "HierarchicalItemBase";
    }
}

// intermediate class
export class ParentHierarchicalItemBase extends HierarchicalItemBase {
    constructor() {
        super();

        this.children = [];
    }

    initCreate(parentItem, icon, selectedIcon, topics, type, title, description) {
        super.initCreate(parentItem, icon, selectedIcon, topics, type, title, description);

        return this;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);

        /* this.children = struct.children.map((child, i) => {
            //return getProperDigest(child, this);
            return undefined;
        }); */

        return this;
    }

    getDependencies(rootCall = true, state) {
        let items = [].concat(super.getDependencies(false, state));

        for (let i = 0; i < this.getChildren().length; i++) {
            items = items.concat(this.getChildren()[i].getDependencies(false, state));
        }

        if (rootCall) {
            return getDependencySet(items.filter((item) => {
                return item !== undefined;
            }));
        }

        return items;
    }

    isEmpty() {
        return this.children.length === 0;
    }

    getChildren() {
        return this.children;
    }

    getFilteredChildren(filterFunc) {
        return this.children.filter(filterFunc);
    }

    listChildren() {
        return this.children;
    }

    setChildren(children) {
        this.children = children;

        if (Array.isArray(this.children)) {
            for (let i = 0; i < this.children.length; i++) {
                this.children[i].parent = this;
            }
        }
    }

    replaceChild(child) {
        let index = -1;
        let remnant = this.getChildren().filter((o) => {
            let keep = o.getId() !== child.getId();
            if (!keep) {
                for (var i = 0; i < this.getChildren().length; i++) {
                    if (this.getChildren()[i].getId() === child.getId()) {
                        index = i;
                    }
                }
            }
            return keep;
        });

        remnant.splice(index, 0, child);
        this.setChildren(remnant);
    }

    isAcceptable(child) {
        let result = false;
        let childType = child.getType();
        if (this.type === HierarchicalItemTypes.Assignments) {
            // assignments can only directly contain sections
            if (childType === HierarchicalItemTypes.Sections) {
                result = true;
            }
        } else if (this.type === HierarchicalItemTypes.Sections) {
            // sections can only directly contain subjects and questions
            if (childType === HierarchicalItemTypes.Subjects ||
                childType === HierarchicalItemTypes.Questions) {
                result = true;
            }
        } else if (this.type === HierarchicalItemTypes.Subjects) {
            // subjects can only directly contain questions
            if (childType === HierarchicalItemTypes.Questions) {
                result = true;
            }
        }

        return result;
    }

    contains(child) {
        return this.children.indexOf(child) >= 0;
    }

    add(child) {
        let changed = false;
        if (!this.contains(child)) {
            if (this.isAcceptable(child)) {
                this.children = this.children.push(child);
                changed = true;
            }
        }

        return changed;
    }

    remove(child) {
        let changed = false;
        if (this.isAcceptable(child)) {
            let i = -1;
            if ((i = this.children.indexOf(child)) >= 0) {
                this.children.splice(i, 1);
                changed = true;
            }
        }

        return changed;
    }

    shift(child, up) {
        let changed = false;
        let i = -1;
        if ((i = this.children.indexOf(child)) >= 0) {
            this.remove(child);
            this.insert(child, up === true ? i - 1 : i + 1);
            changed = true;
        }

        return changed;
    }

    insert(child, index) {
        let changed = false;
        if (!this.contains(child)) {
            if (this.isAcceptable(child)) {
                this.children.splice(index, 0, child);
                changed = true;
            }
        }

        return changed;
    }

    resetChildIds() {
        // contains the names of all used child entity getters
        let childProperties = [
            'getChildren',
            'getAddresses',
            'getCourses',
            'getItems',
            'getVariables'
        ];

        for (let i = 0; i < childProperties.length; i++) {
            let prop = childProperties[i];
            if (this[prop] === undefined) continue;

            // loop through and blank all Ids
            for (let Ci = 0; Ci < this[prop]().length; Ci++) {
                let target = this[prop]()[Ci];
                target.setId(undefined);
            }

            // then loop through again and regenerate them
            for (let Ci = 0; Ci < this[prop]().length; Ci++) {
                let target = this[prop]()[Ci];
                let newId = this.getNextChildId(target, true);

                target.setId(newId);
                if (target.resetChildIds !== undefined) {
                    target.resetChildIds();
                }
            }
        }
    }

    getNextChildId(item, ignoreContained) {
        if (item.getType() === HierarchicalItemTypes.Script) {
            return randomId();
        }

        let result = null;
        if (!this.contains(item) || ignoreContained === true) {
            let pad = (num, size) => {
                num = num.toString();
                while (num.length < size) num = "0" + num;
                return num;
            }

            let newId = undefined;
            let index = 0;
            let matches = [];
            let containingProperty = undefined; // this points to the property where the items of the determined type should be stored

            while (true) {


                // generate the new id
                if (item.getType() === HierarchicalItemTypes.Subjects) {
                    newId = this.getId() + this.getTypePrefix(item) + pad(index, 6);
                    containingProperty = 'getChildren';
                } else if (item.getType() === HierarchicalItemTypes.Sections) {
                    newId = this.getId() + this.getTypePrefix(item) + pad(index, 6);
                    containingProperty = 'getChildren';
                } else if (item.getType() === HierarchicalItemTypes.Questions) {
                    newId = this.getId() + this.getTypePrefix(item) + pad(index, 6);
                    containingProperty = 'getChildren';
                } else if (item.getType() === HierarchicalItemTypes.Addresss) {
                    newId = this.getId() + this.getTypePrefix(item) + pad(index, 6);
                    containingProperty = 'getAddresses';
                } else if (item.getType() === HierarchicalItemTypes.Classes) {
                    newId = this.getId() + this.getTypePrefix(item) + pad(index, 6);
                    containingProperty = 'getCourses';
                } else if (item.getType() === HierarchicalItemTypes.Organizations) {
                    newId = this.getId() + this.getTypePrefix(item) + pad(index, 6);
                    containingProperty = 'getChildren';
                } else if (item.getType() === HierarchicalItemTypes.QuestionChoice) {
                    newId = this.getId() + this.getTypePrefix(item) + pad(index, 6);
                    containingProperty = 'getItems';
                } else if (item.getType() === HierarchicalItemTypes.Variable) {
                    newId = this.getId() + this.getTypePrefix(item) + pad(index, 6);
                    containingProperty = 'getVariables';
                }

                this[containingProperty]().map((o) => {
                    if (o.getId() === newId) {
                        matches.push(o);
                    }
                });

                if (matches.length > 0) {
                    matches.length = 0;
                    index++;
                } else {
                    break;
                }
            }

            result = newId;
        }

        return result;
    }

    getChildTopics(existingSet) {
        let result = [];
        for (var i = 0; i < this.getChildren().length; i++) {
            let item = this.getChildren()[i];
            if (item.getTopics !== undefined) {
                for (var j = 0; j < item.getTopics().length; j++) {
                    let topicPath = item.getTopics()[j];
                    if (!result.includes(topicPath) && !existingSet.includes(topicPath)) {
                        result.push(topicPath);
                    }
                }
            }

            if (item.getChildTopics !== undefined) {
                for (var j = 0; j < item.getChildTopics(existingSet).length; j++) {
                    let topicPath = item.getChildTopics(existingSet)[j];
                    if (!result.includes(topicPath) && !existingSet.includes(topicPath)) {
                        result.push(topicPath);
                    }
                }
            }
        }

        return result;
    }

    getJson() {
        return {
            ...super.getJson(),
            children: jsonifyArray(this.getChildren()),
            _type: this.constructor.ClassName(),
        };
    }

    derivePresentation() {
        return this.clone(true, true);
    }

    static ClassName() {
        return "ParentHierarchicalItemBase";
    }
}

// base class for sections and subjects
export class ShuffleableParentHierarchicalItemBase extends ParentHierarchicalItemBase {
    constructor() {
        super();

        this.shuffled = false;
    }

    initCreate(parentItem, icon, selectedIcon, topics, type, shuffled, title, description) {
        super.initCreate(parentItem, icon, selectedIcon, topics, type, title, description);

        this.shuffled = shuffled || false;

        return this;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);

        this.shuffled = struct.shuffled;

        return this;
    }

    isShuffled() {
        return this.shuffled;
    }

    setShuffled(shuffled) {
        this.shuffled = shuffled;
    }

    listChildren() {
        let result = null;
        if (this.shuffled) {
            result = shuffle(this.getChildren());
        } else {
            result = this.getChildren();
        }

        return result;
    }

    getJson() {
        return {
            ...super.getJson(),
            shuffled: this.shuffled,
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "ShuffleableParentHierarchicalItemBase";
    }
}

// base class for Assignments
export class HierarchicalRootBase extends ShuffleableParentHierarchicalItemBase {
    constructor() {
        super();
    }

    initCreate(icon, selectedIcon, topics, type, title, description, shuffled) {
        super.initCreate(undefined, icon, selectedIcon, topics, type, shuffled, title, description);

        // this must be done because the setParent function is removed
        this.id = this.getTypePrefix(this, false) + randomId();

        return this;
    }

    initJson(parent, struct) {
        super.initJson(parent, struct);

        return this;
    }

    setParent = undefined;

    getJson() {
        return {
            ...super.getJson(),
            _type: this.constructor.ClassName(),
        };
    }

    static ClassName() {
        return "HierarchicalRootBase";
    }
}